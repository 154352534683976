var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Form } from 'antd';
import { Button, Row, Col, Input, InputNumber, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Coatings, Finishes, InputQuantities, Materials, Processes } from '../DropDowns';
import { BulkPrices } from '../BulkPrices';
import QuoteStore from '../../stores/QuoteStore';
import { observer } from 'mobx-react';
import SuggestionField from '../SuggestionField';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import PriceRender from '../PriceRender';
import { QuestionMark } from '../QuestionMark';
var busy = null;
var min = 1;
var QuoteLineItem = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var index = _a.index, item = _a.item, props = __rest(_a, ["index", "item"]);
    var _u = useState(false), loading = _u[0], setLoading = _u[1];
    var _v = useState(true), showQuantity = _v[0], setShowQuantity = _v[1];
    var _w = useState(false), bulkPrices = _w[0], showBulkPrices = _w[1];
    // const [fieldErrors, setFieldErrors] = useState([]);
    var product = item === null || item === void 0 ? void 0 : item.product;
    var form = Form.useForm()[0];
    var getFieldValue = form.getFieldValue, setFieldsValue = form.setFieldsValue;
    var needs_manual_quote = item.needs_manual_quote;
    var analysis_status = (item === null || item === void 0 ? void 0 : item.product).analysis_status;
    var analyzer_finished = ((_b = analysis_status === null || analysis_status === void 0 ? void 0 : analysis_status.file_2d) === null || _b === void 0 ? void 0 : _b.completed) && ((_c = analysis_status === null || analysis_status === void 0 ? void 0 : analysis_status.file_3d) === null || _c === void 0 ? void 0 : _c.completed);
    var has_suggestions = ((_f = (_e = (_d = product === null || product === void 0 ? void 0 : product.attachments) === null || _d === void 0 ? void 0 : _d.files_2d) === null || _e === void 0 ? void 0 : _e.suggestions) === null || _f === void 0 ? void 0 : _f.length) > 0;
    // useEffect(() => {
    //     //The initial value displays only the value passed on the first time and to solve this we are using reset fields to display a new value when the props are changed.
    //     form.resetFields();
    // }, [item]);
    useEffect(function () {
        var _a;
        var _b, _c, _d, _e, _f, _g;
        setFieldsValue((_a = {},
            _a[fieldName('manufacturing_type')] = item.manufacturing_type,
            _a[fieldName('material')] = {
                key: product.material_related.id,
                label: product.material_related.name,
            },
            _a[fieldName('custom_material')] = product.custom_material,
            _a[fieldName('finishes')] = {
                key: (_b = product.finish_related) === null || _b === void 0 ? void 0 : _b.id,
                label: (_c = product.finish_related) === null || _c === void 0 ? void 0 : _c.name,
            },
            _a[fieldName('custom_finish')] = product === null || product === void 0 ? void 0 : product.custom_finish,
            _a[fieldName('coatings')] = {
                key: (_d = product === null || product === void 0 ? void 0 : product.coating_related) === null || _d === void 0 ? void 0 : _d.id,
                label: (_e = product === null || product === void 0 ? void 0 : product.coating_related) === null || _e === void 0 ? void 0 : _e.name,
            },
            _a[fieldName('custom_coating')] = product === null || product === void 0 ? void 0 : product.custom_coating,
            _a[fieldName('quantity')] = item.quantity,
            _a[fieldName('quantities')] = item.quantities,
            _a));
        if (item.quantities && ((_f = item.quantities) === null || _f === void 0 ? void 0 : _f.length) > 0) {
            setShowQuantity(false);
        }
        if (item.quantities && ((_g = item.quantities) === null || _g === void 0 ? void 0 : _g.length) === 0) {
            setShowQuantity(true);
        }
    }, [item]);
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, payload;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    values = _f.sent();
                    payload = {
                        id: item.id,
                        product: {
                            customs: __assign({}, item.product.customs),
                        },
                        // needs_more_quantities: values[fieldName('needs_more_quantities')] || false,
                        needs_more_quantities: QuoteStore.for_staff,
                        quantities: values[fieldName('quantities')] || [],
                        quantity: values[fieldName('quantity')],
                        delivery: values[fieldName('delivery')],
                        manufacturing_type: values[fieldName('manufacturing_type')],
                        manual_adjustment: values[fieldName('manual_adjustment')],
                    };
                    payload['target_price'] = values[fieldName('target_price')] ? parseFloat(values[fieldName('target_price')]) : 0;
                    payload['product']['material'] = (_a = values[fieldName('material')]) === null || _a === void 0 ? void 0 : _a.key;
                    payload['product']['finishes'] = ((_b = values[fieldName('finishes')]) === null || _b === void 0 ? void 0 : _b.key) ? [(_c = values[fieldName('finishes')]) === null || _c === void 0 ? void 0 : _c.key] : [];
                    payload['product']['coatings'] = ((_d = values[fieldName('coatings')]) === null || _d === void 0 ? void 0 : _d.key) ? [(_e = values[fieldName('coatings')]) === null || _e === void 0 ? void 0 : _e.key] : [];
                    if (values[fieldName('custom_material')] || values[fieldName('custom_material')] === '') {
                        payload['product']['custom_material'] = values[fieldName('custom_material')];
                    }
                    if (values[fieldName('custom_finish')] !== null) {
                        payload['product']['custom_finish'] = values[fieldName('custom_finish')];
                    }
                    if (values[fieldName('custom_coating')] !== null) {
                        payload['product']['custom_coating'] = values[fieldName('custom_coating')];
                    }
                    setLoading(true);
                    QuoteStore.updateLineItem(payload).then(function () {
                        setLoading(false);
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var fieldName = function (name) {
        return name + '__' + item.id;
    };
    var handleChange = function () {
        if (busy) {
            clearTimeout(busy);
        }
        busy = setTimeout(handleSubmit, 400);
    };
    var needs_more_quantities = QuoteStore.for_staff && !QuoteStore.isRequote;
    var manufacturing_type = getFieldValue(fieldName('manufacturing_type')) || (item === null || item === void 0 ? void 0 : item.manufacturing_type) || 'CNC';
    return (React.createElement("div", { className: "quote-product-variant" },
        React.createElement(Form, { form: form, layout: "horizontal" },
            React.createElement("div", { className: "quote-product-variant-header" },
                React.createElement("div", { className: "variant-name" }, "Variant #" + (index + 1)),
                React.createElement(Tooltip, { placement: "top", title: "Remove Variation" },
                    React.createElement(Button, { className: "remove-variant-btn", title: "Remove Variation", icon: React.createElement(CloseOutlined, null), size: "small", type: "link", onClick: function () {
                            QuoteStore.deleteLineItem(item.uid);
                        } }))),
            React.createElement("div", { className: "quote-product-variant-details" },
                React.createElement("div", { className: "quote-product-variant-spec" },
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { name: fieldName('manufacturing_type'), label: "Process", rules: [{ required: false, message: 'Required' }], initialValue: item.manufacturing_type },
                            React.createElement(Processes, { onSelect: handleChange, for_staff: QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.for_staff }))),
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { label: "Material" },
                            React.createElement(Form.Item, { name: fieldName('material'), initialValue: {
                                    key: product.material_related.id,
                                    label: product.material_related.name,
                                }, rules: [{ required: false, message: 'Required' }], hasFeedback: !!QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('material'); }), validateStatus: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('material'); })
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('material'); })
                                    ? 'Material Required'
                                    : null },
                                React.createElement(Materials, { onChange: handleChange, manufacturing_type: manufacturing_type, materials: QuoteStore.materials, for_staff: QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.for_staff })),
                            ((_g = product.material_related) === null || _g === void 0 ? void 0 : _g.name) === 'Custom' ? (React.createElement(Form.Item, { name: fieldName('custom_material'), rules: [{ required: false, message: 'Required' }], initialValue: product.custom_material, hasFeedback: true, validateStatus: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('custom_material'); })
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('custom_material'); })
                                    ? 'Custom Material Name Required'
                                    : null },
                                React.createElement(Input, { size: "small", onBlur: handleChange, placeholder: "Enter Custom Material" }))) : null)),
                    !product.material && has_suggestions ? (React.createElement(SuggestionField, { suggestions: ((_h = product.attachments.files_2d) === null || _h === void 0 ? void 0 : _h.suggestions)
                            ? product.attachments.files_2d.suggestions
                                .filter(function (s) { var _a; return (_a = s.suggested_material) === null || _a === void 0 ? void 0 : _a.id; })
                                .map(function (s) {
                                var _a, _b, _c, _d, _e, _f;
                                return {
                                    id: (_b = (_a = s.suggested_material) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                                    name: (_d = (_c = s.suggested_material) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                                    thumbnail: (_f = (_e = s.cropped_image) === null || _e === void 0 ? void 0 : _e.file) !== null && _f !== void 0 ? _f : '',
                                };
                            })
                            : [], handleClick: function (key, label) { return __awaiter(void 0, void 0, void 0, function () {
                            var getValue;
                            var _a;
                            return __generator(this, function (_b) {
                                getValue = {
                                    key: key,
                                    label: label,
                                };
                                setFieldsValue((_a = {},
                                    _a[fieldName('material')] = getValue,
                                    _a));
                                handleChange();
                                return [2 /*return*/];
                            });
                        }); } })) : null,
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { label: "Surface Finish" },
                            React.createElement(Form.Item, { name: fieldName('finishes'), initialValue: {
                                    key: (_j = product.finish_related) === null || _j === void 0 ? void 0 : _j.id,
                                    label: (_k = product.finish_related) === null || _k === void 0 ? void 0 : _k.name,
                                }, rules: [{ required: false, message: 'Please select a value' }], hasFeedback: !!QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('finishes'); }), validateStatus: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('finishes'); })
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('finishes'); })
                                    ? 'Surface Finish Required'
                                    : null },
                                React.createElement(Finishes, { finishes: QuoteStore.finishes, onSelect: handleChange, for_staff: QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.for_staff })),
                            ((_l = product.finish_related) === null || _l === void 0 ? void 0 : _l.name) === 'Custom' ? (React.createElement(Form.Item, { name: fieldName('custom_finish'), rules: [{ required: false, message: 'Please insert a value' }], initialValue: product === null || product === void 0 ? void 0 : product.custom_finish, hasFeedback: true, validateStatus: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('custom_finish'); })
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('custom_finish'); })
                                    ? 'Custom Surface Finish Name Required'
                                    : null },
                                React.createElement(Input, { size: "small", onBlur: handleChange, placeholder: "Enter Custom Surface Finish" }))) : null)),
                    product.finishes.length < 1 && has_suggestions ? (React.createElement(SuggestionField, { suggestions: ((_m = product.attachments.files_2d) === null || _m === void 0 ? void 0 : _m.suggestions)
                            ? product.attachments.files_2d.suggestions
                                .slice()
                                .sort(function (s) { var _a; return (((_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.name) === 'Deburr' ? -1 : 1); })
                                .sort(function (s) { var _a; return (((_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.name) === 'None' ? -1 : 1); })
                                .filter(function (s) { var _a; return (_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.id; })
                                .map(function (s) {
                                var _a, _b, _c, _d, _e, _f;
                                return {
                                    id: (_b = (_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                                    name: (_d = (_c = s.suggested_finish) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                                    thumbnail: (_f = (_e = s.cropped_image) === null || _e === void 0 ? void 0 : _e.file) !== null && _f !== void 0 ? _f : '',
                                };
                            })
                            : [], handleClick: function (key, label) { return __awaiter(void 0, void 0, void 0, function () {
                            var getValue;
                            var _a;
                            return __generator(this, function (_b) {
                                getValue = {
                                    key: key,
                                    label: label,
                                };
                                setFieldsValue((_a = {},
                                    _a[fieldName('finishes')] = getValue,
                                    _a));
                                handleChange();
                                return [2 /*return*/];
                            });
                        }); } })) : null,
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { label: "Coating" },
                            React.createElement(Form.Item, { name: fieldName('coatings'), initialValue: {
                                    key: (_o = product === null || product === void 0 ? void 0 : product.coating_related) === null || _o === void 0 ? void 0 : _o.id,
                                    label: (_p = product === null || product === void 0 ? void 0 : product.coating_related) === null || _p === void 0 ? void 0 : _p.name,
                                }, rules: [{ required: false, message: 'Please select a value' }], hasFeedback: !!QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('coatings'); }), validateStatus: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('coatings'); })
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('coatings'); })
                                    ? 'Coating Required'
                                    : null },
                                React.createElement(Coatings, { coatings: QuoteStore.coatings, onSelect: handleChange, for_staff: QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.for_staff })),
                            ((_q = product.coating_related) === null || _q === void 0 ? void 0 : _q.name) === 'Custom' ? (React.createElement(Form.Item, { name: fieldName('custom_coating'), initialValue: product === null || product === void 0 ? void 0 : product.custom_coating, rules: [{ required: false, message: 'Please insert a value' }], hasFeedback: true, validateStatus: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('custom_coating'); })
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(function (x) { return x.fieldName === fieldName('custom_coating'); })
                                    ? 'Custom Coating Name Required'
                                    : null },
                                React.createElement(Input, { size: "small", onBlur: handleChange, placeholder: "Enter Custom Coating" }))) : null)),
                    React.createElement("div", { className: "d-block" }, ((_r = product.coatings) === null || _r === void 0 ? void 0 : _r.length) < 1 && has_suggestions ? (React.createElement(SuggestionField, { suggestions: ((_s = product.attachments.files_2d) === null || _s === void 0 ? void 0 : _s.suggestions)
                            ? product.attachments.files_2d.suggestions
                                .slice()
                                .sort(function (s) { var _a; return (((_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.name) === 'Deburr' ? -1 : 1); })
                                .sort(function (s) { var _a; return (((_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.name) === 'None' ? -1 : 1); })
                                .filter(function (s) { var _a; return (_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.id; })
                                .map(function (s) {
                                var _a, _b, _c, _d, _e, _f;
                                return {
                                    id: (_b = (_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                                    name: (_d = (_c = s.suggested_coating) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                                    thumbnail: (_f = (_e = s.cropped_image) === null || _e === void 0 ? void 0 : _e.file) !== null && _f !== void 0 ? _f : '',
                                };
                            })
                            : [], handleClick: function (key, label) { return __awaiter(void 0, void 0, void 0, function () {
                            var getValue;
                            var _a;
                            return __generator(this, function (_b) {
                                getValue = {
                                    key: key,
                                    label: label,
                                };
                                setFieldsValue((_a = {},
                                    _a[fieldName('coatings')] = getValue,
                                    _a));
                                handleChange();
                                return [2 /*return*/];
                            });
                        }); } })) : null)),
                React.createElement("div", { className: "quote-product-variant-qty-price" },
                    React.createElement("div", null,
                        React.createElement(PriceRender, { unit_price: item.unit_price, total_price: item.total_price, needs_manual_quote: needs_manual_quote, is_analyzing: !analyzer_finished, loading: loading })),
                    QuoteStore.for_staff ? (React.createElement(Row, { gutter: [8, 0], style: { maxWidth: '480px' } },
                        React.createElement(Col, { span: 12 }, QuoteStore.isRequote ? (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Single Quantity' },
                            React.createElement(Form.Item, { name: fieldName('quantity') },
                                React.createElement(InputNumber, { min: min, step: 1, controls: false, onBlur: handleChange, defaultValue: item.quantity })))) : showQuantity ? (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Single Quantity' },
                            React.createElement(Form.Item, { name: fieldName('quantity'), noStyle: true },
                                React.createElement(InputNumber, { min: min, step: 1, controls: false, onBlur: handleChange, defaultValue: item.quantity, style: { width: '100%' } })),
                            React.createElement(Button, { type: 'link', onClick: function () { return setShowQuantity(false); } },
                                "or Quote Multiple Quantities?",
                                ' ',
                                React.createElement(QuestionMark, { placement: "right" }, "Use this option to request prices of different quantity")))) : (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Multiple Quantities' },
                            React.createElement(Form.Item, { name: fieldName('quantities'), rules: [{ required: false, message: 'Please insert a value' }], initialValue: item.quantities || [], help: React.createElement("div", null, !needs_manual_quote &&
                                    ((_t = QuoteStore.quote) === null || _t === void 0 ? void 0 : _t.email) &&
                                    !QuoteStore.isRequote && (React.createElement("span", { className: 'link', onClick: function () { return showBulkPrices(true); } }, "Show list prices"))), noStyle: true },
                                React.createElement(InputQuantities, { onBlur: handleChange, style: { width: '100%' } })),
                            React.createElement(Button, { type: 'link', onClick: function () { return setShowQuantity(true); } },
                                "or Quote Single Quantity?",
                                React.createElement(QuestionMark, { placement: "right" }, "Quote a single quantity and get an instant price"))))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(Form.Item, { name: fieldName('target_price'), label: index === 0 ? 'Target Price' : null, initialValue: item.target_price, labelCol: { span: 24 } },
                                React.createElement(InputNumber, { onBlur: handleChange, precision: 2, style: { width: '100%' } }))))) : (React.createElement("div", { className: "quantity-selector d-flex flex-row align-items-center" },
                        React.createElement("label", { htmlFor: "quantity", className: "mr-2" }, "Quantity"),
                        React.createElement("div", { className: "button-group" },
                            React.createElement(Button, { size: "small", className: "minus-btn", icon: React.createElement(MinusOutlined, null), onClick: function () {
                                    var _a;
                                    setFieldsValue((_a = {},
                                        _a[fieldName('quantity')] = Number(item.quantity) - 1,
                                        _a));
                                    handleSubmit();
                                }, disabled: loading || Number(getFieldValue(fieldName('quantity'))) === min }),
                            React.createElement(Form.Item, { name: fieldName('quantity'), initialValue: item.quantity, noStyle: true },
                                React.createElement(InputNumber, { min: min, step: 1, controls: false, size: "small", onBlur: handleChange })),
                            React.createElement(Button, { size: "small", className: "plus-btn", icon: React.createElement(PlusOutlined, null), onClick: function () {
                                    var _a;
                                    setFieldsValue((_a = {},
                                        _a[fieldName('quantity')] = Number(item.quantity) + 1,
                                        _a));
                                    handleSubmit();
                                }, disabled: loading }))))),
                bulkPrices ? (React.createElement(Modal, { open: true, onCancel: function () {
                        showBulkPrices(false);
                    }, footer: [] },
                    React.createElement(BulkPrices, { item: item, handleSelect: function (quantity) {
                            var _a;
                            setFieldsValue((_a = {},
                                _a[fieldName('quantity')] = quantity,
                                _a));
                            showBulkPrices(false);
                            handleChange();
                        } }))) : null))));
});
export default QuoteLineItem;
