import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Divider, Empty, notification, Table } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { get, remove } from '../../../shared/api';

export const TaxExemptList = ({ account_id, handleEdit }) => {
    const [loading, setLoading] = useState(false);
    const [TaxForms, setTaxForms] = useState();

    useEffect(() => {
        setLoading(true);

        get(`staff/accounts/${account_id}/tax-forms/`)
            .then(response => {
                setTaxForms(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                notification.error({ message: 'Error' });
            });
    }, [account_id]);

    const handleDelete = id => {
        remove(`staff/accounts/${account_id}/tax-forms/${id}`).then(() => {
            setTaxForms(TaxForms.filter(r => r.id !== id));
        });
    };

    let emptyTable = () => {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No forms for this account</span>}></Empty>
        );
    };

    return (
        <div>
            <Table dataSource={TaxForms} rowKey={'id'} loading={loading} locale={{ emptyText: emptyTable }}>
                <Table.Column
                    title="States"
                    dataIndex="states"
                    key="states"
                    render={(t, record) => {
                        const states = record.states.map(r => {
                            return <li key={r}>{r}</li>;
                        });

                        return <ul>{states}</ul>;
                    }}
                />
                <Table.Column
                    title="Files"
                    dataIndex="files"
                    key="files"
                    render={(t, record) => {
                        const items = record.files.map(f => {
                            return (
                                <li key={f.id}>
                                    <a href={f.file} target={'_blank'} rel="noreferrer">
                                        {f.filename}
                                    </a>
                                </li>
                            );
                        });
                        return <ul>{items}</ul>;
                    }}
                />

                <Table.Column
                    title="Partial Deduction Percent"
                    dataIndex="sales_tax_deduction"
                    key="sales_tax_deduction"
                />

                <Table.Column
                    title="Minimum Amount"
                    dataIndex="minimum_amount_applicable"
                    key="minimum_amount_applicable"
                />

                <Table.Column
                    title="Maximum Amount"
                    dataIndex="maximum_amount_applicable"
                    key="maximum_amount_applicable"
                />

                <Table.Column
                    title="Verified"
                    dataIndex="verified"
                    key="verified"
                    render={(t, record) => {
                        return record.verified ? 'Yes' : 'No';
                    }}
                />

                <Table.Column
                    title="Action"
                    render={(t, record) => {
                        return (
                            <div className={'flex flex-row'}>
                                <span className={'link'} onClick={() => handleEdit(record)}>
                                    <LegacyIcon type={'edit'} />
                                </span>

                                <Divider type={'vertical'} className={'m-2'} />

                                <span className={'link'} onClick={() => handleDelete(record.id)}>
                                    <LegacyIcon type={'delete'} />{' '}
                                </span>
                            </div>
                        );
                    }}
                />
            </Table>
        </div>
    );
};
