var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { PlusOutlined, EditOutlined, LoadingOutlined, MinusOutlined, CloseOutlined, PaperClipOutlined, } from '@ant-design/icons';
import { Alert, Button, Checkbox, Divider, Form, Input, Spin, Typography, Modal, List, Tag, Tooltip, } from 'antd';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';
import { UploadDrawing, UploadOtherFiles } from './UploadParts';
import { PartDimensions } from './PartDimensions';
import { PartPreview } from './PartPreview';
import { Price } from '@partsbadger/library';
import QuoteStore from '../stores/QuoteStore';
import QuoteLineQuantityItem from './QuoteLineQuantityItem';
import QuoteLineItem from './QuoteLineItem';
import { QuestionMark } from './QuestionMark';
var busy = null;
var Paragraph = Typography.Paragraph, Text = Typography.Text, Title = Typography.Title;
var LineItemSummary = function (_a) {
    var index = _a.index, item = _a.item;
    var _b = item || {}, product = _b.product, needs_manual_quote = _b.needs_manual_quote;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "product-summary" },
            React.createElement("div", null,
                React.createElement(Text, { strong: true, className: "mr-2" },
                    "Varient ",
                    index + 1,
                    ":"),
                item.manufacturing_type ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "text-muted" }, "Process:"),
                    " ",
                    React.createElement("span", null, item.manufacturing_type),
                    React.createElement(Divider, { type: 'vertical' }))) : null,
                React.createElement("span", { className: "text-muted" }, "Material:"),
                " ",
                React.createElement("span", null, product.material),
                React.createElement(Divider, { type: 'vertical' }),
                React.createElement("span", { className: "text-muted" }, "Surface Finish:"),
                " ",
                React.createElement("span", null, product.finishes),
                React.createElement(Divider, { type: 'vertical' }),
                React.createElement("span", { className: "text-muted" }, "Coating:"),
                " ",
                React.createElement("span", null, product.coatings)),
            React.createElement("div", { className: "d-inline-block" },
                React.createElement("span", { className: "text-muted" }, "Qty:"),
                " ",
                React.createElement("span", null, item.quantity || item.quantities),
                React.createElement(Divider, { type: 'vertical' }),
                needs_manual_quote ? (React.createElement(Text, null, "Manual Quote Required")) : (React.createElement(Text, null,
                    React.createElement(Price, { value: item.total_price })))))));
};
var HeaderTitle = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var setLoading = _a.setLoading, item = _a.item, collapsed = _a.collapsed, analyzer_all_finished = _a.analyzer_all_finished;
    var product = item.product;
    var _l = useState(false), modalVisible = _l[0], setModalVisible = _l[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "product-name-wrapper", id: String(item.id) }, !QuoteStore.isRequote ? (React.createElement(React.Fragment, null,
            React.createElement(Modal, { open: modalVisible, onCancel: function () { return setModalVisible(false); }, title: 'Edit Product Name', footer: null, centered: true },
                React.createElement(Form, { onFinish: function (values) {
                        var payload = {
                            id: item.id,
                            product: {
                                name: values.name,
                            },
                        };
                        setLoading(true);
                        QuoteStore.updateLineItem(payload)
                            .then(function () {
                            setLoading(false);
                            setModalVisible(false);
                        })
                            .catch(function (err) { return console.log(err); });
                    } },
                    React.createElement("div", { className: "d-flex flex-row align-items-center justify-content-between" },
                        React.createElement(Form.Item, { name: "name", noStyle: true, className: "flex-grow-1" },
                            React.createElement(Input, { defaultValue: (_b = product === null || product === void 0 ? void 0 : product.name) === null || _b === void 0 ? void 0 : _b.toUpperCase() })),
                        React.createElement(Button, { htmlType: "submit", type: "primary", className: "ml-2" }, "Save")))),
            React.createElement(Typography.Title, { level: 4, className: 'product-name m-0' }, (_c = product === null || product === void 0 ? void 0 : product.name) === null || _c === void 0 ? void 0 :
                _c.toUpperCase(),
                !collapsed && analyzer_all_finished ? (React.createElement(Tooltip, { placement: "top", title: "Edit Name" },
                    React.createElement(EditOutlined, { onClick: function () { return setModalVisible(true); }, className: "ml-2" }))) : null))) : (React.createElement(Paragraph, { className: 'product-name', style: {
                display: 'inline',
                fontSize: 18,
                margin: 0,
                fontWeight: 'bold',
            } }, (_d = product === null || product === void 0 ? void 0 : product.name) === null || _d === void 0 ? void 0 : _d.toUpperCase()))),
        ((_f = (_e = item === null || item === void 0 ? void 0 : item.product) === null || _e === void 0 ? void 0 : _e.dimensions_dict) === null || _f === void 0 ? void 0 : _f.volume) ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'product-dimensions' },
                React.createElement(Tag, { className: "mb-0", icon: React.createElement(PaperClipOutlined, null) }, (_j = (_h = (_g = item === null || item === void 0 ? void 0 : item.product) === null || _g === void 0 ? void 0 : _g.attachments) === null || _h === void 0 ? void 0 : _h.file3d) === null || _j === void 0 ? void 0 : _j.filename),
                React.createElement(PartDimensions, __assign({}, (_k = item === null || item === void 0 ? void 0 : item.product) === null || _k === void 0 ? void 0 : _k.dimensions_dict))))) : null));
});
export var MasterProduct = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var for_staff = _a.for_staff, item = _a.item, line_items = _a.line_items, isSelected = _a.isSelected, handleSelect = _a.handleSelect, props = __rest(_a, ["for_staff", "item", "line_items", "isSelected", "handleSelect"]);
    var product = item === null || item === void 0 ? void 0 : item.product;
    var _m = useState(false), loading = _m[0], setLoading = _m[1];
    var _o = useState(false), duplicating = _o[0], setDuplicating = _o[1];
    var _p = useState(false), confirmDeleteModalVisible = _p[0], setConfirmDeleteModalVisible = _p[1];
    var form = Form.useForm()[0];
    var analysis_status = ((item === null || item === void 0 ? void 0 : item.product) || {}).analysis_status;
    var analyzer_2d_finished = (_b = analysis_status.file_2d) === null || _b === void 0 ? void 0 : _b.completed;
    var analyzer_3d_finished = (_c = analysis_status.file_3d) === null || _c === void 0 ? void 0 : _c.completed;
    var analyzer_2d_success = ((_d = analysis_status === null || analysis_status === void 0 ? void 0 : analysis_status.file_2d) === null || _d === void 0 ? void 0 : _d.status) === 'SUCCESS';
    var analyzer_3d_success = ((_e = analysis_status === null || analysis_status === void 0 ? void 0 : analysis_status.file_3d) === null || _e === void 0 ? void 0 : _e.status) === 'SUCCESS';
    var analyzer_all_finished = ((_f = analysis_status.file_2d) === null || _f === void 0 ? void 0 : _f.completed) && ((_g = analysis_status.file_3d) === null || _g === void 0 ? void 0 : _g.completed);
    var isCollapsed = QuoteStore.collapsedLineItems.includes(item.id);
    // Get remote update on tighter tolerance
    React.useEffect(function () {
        if (item.product.tighter_tolerances) {
            form.setFieldsValue({
                tighter_tolerances: item.product.tighter_tolerances,
            });
        }
    }, [(_h = item.product) === null || _h === void 0 ? void 0 : _h.tighter_tolerances]);
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    values = _a.sent();
                    payload = {
                        id: item.id,
                        product: {
                            tighter_tolerances: values[fieldName('tighter_tolerances')],
                            customer_notes: values[fieldName('customer_notes')],
                        },
                        master_product: {
                            tighter_tolerances: values[fieldName('tighter_tolerances')],
                        },
                        batch_shipment: values[fieldName('batch_shipment')],
                    };
                    setLoading(true);
                    QuoteStore.updateLineItem(payload).then(function () {
                        setLoading(false);
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var fieldName = function (name) {
        return name + '__' + item.id;
    };
    var handleChange = function () {
        if (busy) {
            clearTimeout(busy);
        }
        busy = setTimeout(handleSubmit, 400);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: confirmDeleteModalVisible, onCancel: function () { return setConfirmDeleteModalVisible(false); }, okText: "Remove", centered: true, onOk: function () {
                QuoteStore.deleteMasterProduct(item.product.master_product);
                setConfirmDeleteModalVisible(false);
            }, closable: false, width: 400 },
            React.createElement(Title, { level: 4 }, "Remove Item From Quote?")),
        React.createElement(Form, { form: form, colon: false },
            React.createElement("div", { onClick: function () {
                    if (isCollapsed) {
                        QuoteStore.setCollapsed(item.id);
                    }
                }, className: "" + (isCollapsed ? 'quote-product-card cursor-pointer' : 'quote-product-card') },
                React.createElement("div", { className: "quote-product-header" },
                    React.createElement("div", { className: "d-flex align-items-center" },
                        React.createElement("div", { className: "mr-4", onClick: function (e) { return e.stopPropagation(); } },
                            React.createElement(Checkbox, { checked: isSelected, onChange: function (e) {
                                    handleSelect(e.target.checked);
                                } })),
                        isCollapsed && ((_j = product.attachments) === null || _j === void 0 ? void 0 : _j.file3d.filename) && product.thumbnail ? (React.createElement("img", { className: "thumbnail-3d-file mr-1", style: { maxWidth: '75px' }, alt: (_k = product.attachments) === null || _k === void 0 ? void 0 : _k.file3d.filename, src: product.thumbnail })) : null,
                        React.createElement("div", { className: "flex-grow-1" },
                            React.createElement(HeaderTitle, { item: item, setLoading: setLoading, collapsed: isCollapsed, analyzer_all_finished: analyzer_all_finished }))),
                    React.createElement("div", { className: "product-card-actions" },
                        (product.has_file_2d && analyzer_2d_success) ||
                            (product.has_file_3d && analyzer_3d_success) ? (React.createElement(AnimatePresence, null,
                            React.createElement(motion.span, { initial: {
                                    scaleY: 0,
                                    scaleX: 0,
                                }, animate: {
                                    scaleX: 1,
                                    scaleY: 1,
                                    opacity: 1,
                                }, exit: {
                                    scaleX: 0,
                                    scaleY: 0,
                                } },
                                React.createElement(Tooltip, { placement: "top", title: isCollapsed ? 'Expand' : 'Collapse' },
                                    React.createElement(Button, { type: "link", className: "text-primary", icon: isCollapsed ? React.createElement(PlusOutlined, null) : React.createElement(MinusOutlined, null), onClick: function (e) {
                                            e.stopPropagation();
                                            QuoteStore.setCollapsed(item.id);
                                        } }))))) : null,
                        React.createElement("span", { className: 'link' },
                            React.createElement(Tooltip, { placement: "top", title: "Delete Part" },
                                React.createElement(Button, { type: "link", icon: React.createElement(CloseOutlined, null), onClick: function () { return setConfirmDeleteModalVisible(true); } }))))),
                analyzer_all_finished ? (React.createElement("div", { className: "w-100" },
                    React.createElement(AnimatePresence, null, isCollapsed ? (React.createElement(motion.div, { initial: {
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                        }, animate: {
                            zIndex: 1,
                            height: 'auto',
                            scaleY: 1,
                            opacity: 1,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                    delay: 0,
                                },
                            },
                        }, exit: {
                            zIndex: 0,
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                },
                            },
                        } },
                        React.createElement("div", { className: "product-collapsed-container" },
                            React.createElement(List, { itemLayout: "horizontal", dataSource: line_items, renderItem: function (line_item, index) { return (React.createElement(List.Item, { className: "p-0 py-2" },
                                    React.createElement(LineItemSummary, { key: index, item: line_item, index: index }))); } })))) : null))) : null,
                product.has_file_2d && !analyzer_2d_finished ? (React.createElement("div", { className: 'loader pb-4' },
                    React.createElement(Spin, { indicator: React.createElement(React.Fragment, null,
                            React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true }),
                            React.createElement(Text, { className: "d-block text-primary" }, "Analyzing 2D File...")), style: { display: 'block' } }))) : product.has_file_3d && !analyzer_3d_finished ? (React.createElement("div", { className: 'loader pb-4' },
                    React.createElement(Spin, { indicator: React.createElement(React.Fragment, null,
                            React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true }),
                            React.createElement(Text, { className: "d-block text-primary" }, "Analyzing Geometry...")), style: { display: 'block' } }))) : analyzer_all_finished ? (React.createElement(React.Fragment, null,
                    React.createElement(AnimatePresence, null, !isCollapsed ? (React.createElement(motion.div, { initial: {
                            zIndex: 1,
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                        }, animate: {
                            height: 'auto',
                            opacity: 1,
                            scaleY: 1,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                    delay: 0,
                                },
                            },
                        }, exit: {
                            zIndex: 0,
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                },
                            },
                        } },
                        React.createElement("div", { className: 'quote-product-body' },
                            React.createElement("div", { className: "quote-product-preview" },
                                React.createElement(Form, { layout: 'vertical', className: 'form-master-product' },
                                    React.createElement(PartPreview, { item: item }),
                                    React.createElement("div", { className: "d-flex flex-column justify-content-center text-center" },
                                        React.createElement(UploadDrawing, { item: item }),
                                        React.createElement(UploadOtherFiles, { item: item })))),
                            React.createElement("div", { className: "quote-product-details" },
                                React.createElement(React.Fragment, null, line_items === null || line_items === void 0 ? void 0 :
                                    line_items.map(function (item, index) {
                                        if (item.has_dynamic_quantities) {
                                            var rendered_items = item.quote_item_quantities
                                                .filter(function (i) { return i.displayed; })
                                                .map(function (quantity_item, i) {
                                                return (React.createElement(QuoteLineQuantityItem, { key: i, item: {
                                                        quantity: quantity_item.quantity,
                                                        lead_time: quantity_item.lead_time,
                                                        unit_price: Number(quantity_item.unit_price),
                                                        total_price: Number(quantity_item.total_price),
                                                        safe_auto_quote: quantity_item.safe_auto_quote,
                                                    } }));
                                            });
                                            return (React.createElement("div", { className: 'quantity-items' },
                                                React.createElement(Spin, { spinning: loading },
                                                    React.createElement("div", { className: 'flex flex-row' },
                                                        React.createElement("div", { className: 'w-full' },
                                                            React.createElement("div", { className: 'flex flex-row' },
                                                                React.createElement("div", { className: 'flex flex-row w-full' },
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Quantity")),
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Lead Time")),
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Unit Price")),
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Total Price")))),
                                                            rendered_items))),
                                                React.createElement("hr", null)));
                                        }
                                        else {
                                            return (React.createElement(React.Fragment, null,
                                                React.createElement(QuoteLineItem, { index: index, item: item })));
                                        }
                                    }),
                                    React.createElement("div", { className: "quote-product-requirements" },
                                        for_staff &&
                                            item.already_quote_product &&
                                            item.already_quote && (React.createElement("div", { className: "text-center mt-6" },
                                            React.createElement(Spin, { spinning: loading },
                                                React.createElement(Alert, { message: [
                                                        React.createElement("strong", { key: "m-1" }, "It seems that this product has already been quoted."),
                                                        React.createElement(Divider, { key: "d-1", type: "vertical" }),
                                                        React.createElement("span", { key: "m-2", className: "link", onClick: function () {
                                                                setLoading(true);
                                                                QuoteStore.getRequote(item.already_quote).then(function (r) {
                                                                    location.replace('/get-quote/?uid=' +
                                                                        r.uid);
                                                                    setLoading(false);
                                                                });
                                                            } }, "Click here to re quote!"),
                                                    ], type: "info", description: "You can request a re quote or continue to add items and submit your request.", showIcon: true })))),
                                        React.createElement("div", { className: "d-flex flex-column align-items-start" },
                                            React.createElement("div", { className: "d-flex flex-row align-items-baseline" },
                                                React.createElement(Button, { className: "ant-btn-xs mb-2", size: "small", icon: React.createElement(PlusOutlined, { style: { fontSize: '16px' } }), loading: duplicating, onClick: function () {
                                                        setDuplicating(true);
                                                        QuoteStore.duplicateItem(item.product.master_product).then(function (r) {
                                                            setDuplicating(false);
                                                        });
                                                    } }, "Add New Variation"),
                                                React.createElement(QuestionMark, { placement: "right" }, "Add a part variation to see price differences")),
                                            React.createElement(React.Fragment, null, !QuoteStore.isRequote && (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: "d-flex flex-column" },
                                                    React.createElement(Form.Item, { name: fieldName('batch_shipment'), rules: [
                                                            {
                                                                required: false,
                                                                message: 'Please insert a value',
                                                            },
                                                        ], noStyle: true, valuePropName: 'checked', initialValue: item.batch_shipment },
                                                        React.createElement(Checkbox, { onChange: handleChange, className: "text-base", style: { margin: '6px 0' } },
                                                            React.createElement(Tooltip, { title: "Check this box if you require the parts to be delivered in batches" }, "Batch Releases"))),
                                                    React.createElement(Form.Item, { name: fieldName('tighter_tolerances'), rules: [
                                                            {
                                                                required: false,
                                                                message: 'Please insert a value',
                                                            },
                                                        ], noStyle: true, valuePropName: 'checked', initialValue: (_l = item.product) === null || _l === void 0 ? void 0 : _l.tighter_tolerances },
                                                        React.createElement(Checkbox, { onChange: handleChange, className: "text-base", style: { margin: 0 } }, "This part has feature tolerance of +/- 0.001\" or less"))))))))))))) : null))) : null))));
});
