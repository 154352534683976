import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import SalesOrderStore from '../Stores/SalesOrderStore';
import SalesOrderAddBatchForm from '../Forms/SalesOrderAddBatchForm';
import { styleBody } from '../Forms/SalesOrderCreateForm/SalesOrderCreateForm';
import { observer } from 'mobx-react';
import SalesOrderBatchStore from '../Stores/SalesOrderBatchStore';
export var SalesOrderModalBatches = observer(function (props) {
    var _a = useState(false), notification_quantity = _a[0], setNotificationQuantity = _a[1];
    var _b = useState(0), quantity = _b[0], setQuantity = _b[1];
    useEffect(function () {
        var order_item = SalesOrderStore.order_items.filter(function (or) { return props.order_item.id === or.id; });
        SalesOrderBatchStore.getOriginalQuantity(order_item[0].quantity);
        SalesOrderBatchStore.resetTotalQuantity();
        SalesOrderBatchStore.getQuantity(props.order_item.id);
        SalesOrderBatchStore.getMissingQuantity(SalesOrderBatchStore.original_quantity, SalesOrderBatchStore.total_quantity);
        setQuantity(SalesOrderBatchStore.total_quantity);
    }, [props.order_item]);
    useEffect(function () {
        SalesOrderBatchStore.resetTotalQuantity();
    }, []);
    return (React.createElement(Modal, { width: 800, title: React.createElement("div", null,
            React.createElement("b", null,
                "Item: ",
                props.order_item.name)), visible: props.showModalBatches, onCancel: function () {
            props.setShowModalBatches(false);
        }, footer: false },
        React.createElement("div", { className: 'flex justify-center font-bold', style: { color: 'red' } },
            React.createElement("span", null, "Batch Shipments")),
        React.createElement("div", { className: 'pt-4' },
            React.createElement(Col, { className: 'flex justify-center' },
                React.createElement(Row, { style: { margin: '0 auto' }, className: 'w-full' },
                    React.createElement(Col, { span: 12 },
                        React.createElement("div", { className: 'font-bold', style: styleBody }, "Estimated Ship Date")),
                    React.createElement(Col, { span: 6 },
                        React.createElement("div", { className: 'font-bold', style: styleBody }, "Quantity")),
                    React.createElement(Col, { span: 6 },
                        React.createElement("div", { className: 'font-bold', style: styleBody }, "Remove")))),
            props.batches.length > 0 &&
                props.batches.map(function (batch) {
                    return (React.createElement("div", { key: props.batches.indexOf(batch) }, batch.order_item === props.order_item.id && (React.createElement(React.Fragment, null,
                        React.createElement(Col, { className: 'flex justify-center' },
                            React.createElement(Row, { style: { margin: '0 auto' }, className: 'w-full' },
                                React.createElement(Col, { span: 12 },
                                    React.createElement("div", { style: styleBody }, batch.required_part_ship_date)),
                                React.createElement(Col, { span: 6 },
                                    React.createElement("div", { style: styleBody }, batch.quantity)),
                                React.createElement(Col, { span: 6 },
                                    React.createElement("div", { style: styleBody },
                                        React.createElement(DeleteOutlined, { style: { color: 'red' }, onClick: function () {
                                                setNotificationQuantity(false);
                                                SalesOrderBatchStore.removeBatch(props.order_item, props.batches.indexOf(batch));
                                                SalesOrderBatchStore.resetTotalQuantity();
                                                SalesOrderBatchStore.getQuantity(props.order_item.id);
                                                SalesOrderBatchStore.getMissingQuantity(SalesOrderBatchStore.original_quantity, SalesOrderBatchStore.total_quantity);
                                                SalesOrderStore.updateOrderItemBatch(props.order_item, SalesOrderBatchStore.batches, SalesOrderBatchStore.batches.length > 0
                                                    ? true
                                                    : false);
                                            } })))))))));
                })),
        React.createElement(SalesOrderAddBatchForm, { order_item_id: props.order_item.id, onSubmitted: function (data) {
                setNotificationQuantity(false);
                var batch = {
                    order_item: props.order_item.id,
                    quantity: data.batch_quantity,
                    required_part_ship_date: data.batch_ship_part_date,
                };
                SalesOrderBatchStore.setBatch(props.order_item, batch);
                SalesOrderBatchStore.resetTotalQuantity();
                SalesOrderBatchStore.getQuantity(props.order_item.id);
                if (SalesOrderBatchStore.total_quantity > SalesOrderBatchStore.original_quantity) {
                    SalesOrderBatchStore.removeBatch(props.order_item, props.batches.indexOf(batch));
                    SalesOrderBatchStore.resetTotalQuantity();
                    SalesOrderBatchStore.getQuantity(props.order_item.id);
                    setNotificationQuantity(true);
                }
                setQuantity(SalesOrderBatchStore.total_quantity);
                SalesOrderStore.updateOrderItemBatch(props.order_item, SalesOrderBatchStore.batches, SalesOrderBatchStore.batches.length > 0 ? true : false);
            } }),
        React.createElement(Col, { className: 'flex justify-center pt-4' },
            React.createElement("span", null,
                "Total Quantity by Batches: ",
                React.createElement("b", { className: 'text-lg' }, quantity)),
            "\u2003",
            React.createElement("span", null,
                "Quantity requested in quote: ",
                React.createElement("b", { className: 'text-lg' }, SalesOrderBatchStore.original_quantity))),
        notification_quantity && (React.createElement(Col, { className: 'flex justify-center pt-2' },
            React.createElement("b", null, "The total quantity by batches is higher than requested in the quote.")))));
});
