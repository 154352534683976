import * as React from 'react';
import { useEffect } from 'react';
import { TrixEditor } from 'react-trix';
import 'trix/dist/trix.css';
export var RichTextEditor = function (props) {
    useEffect(function () {
        document.addEventListener('trix-file-accept', function (event) {
            event.preventDefault();
        });
    }, []);
    var handleEditorReady = function (editor) {
        if (props.initialValue) {
            editor.loadHTML(props.initialValue);
        }
    };
    var handleChange = function (html, text) {
        var _a, _b;
        if (props === null || props === void 0 ? void 0 : props.onChange) {
            // @ts-ignore
            var new_html = (_b = (_a = html === null || html === void 0 ? void 0 : html.replace('<a', '<div')) === null || _a === void 0 ? void 0 : _a.replace('</a', '</div')) === null || _b === void 0 ? void 0 : _b.replace('href', 'rel');
            props.onChange(new_html, text);
        }
    };
    return (React.createElement(TrixEditor, { autoFocus: props.autoFocus, placeholder: props.placeholder, mergeTags: [], onChange: handleChange, onEditorReady: handleEditorReady, value: props.value && props.value }));
};
