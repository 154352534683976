import React from 'react';
var precision = 12;
export var PartDimensions = function (_a) {
    var _b = _a.length, length = _b === void 0 ? 0 : _b, _c = _a.width, width = _c === void 0 ? 0 : _c, _d = _a.height, height = _d === void 0 ? 0 : _d, _e = _a.unit, unit = _e === void 0 ? 'MM' : _e;
    if (!length || !width || !height) {
        return null;
    }
    var original = "" + length + unit + " x " + width + unit + " x " + height + unit;
    var converted = '';
    if (unit === 'MM') {
        converted = mm_to_in(length) + "IN x " + mm_to_in(width) + "IN x " + mm_to_in(height) + "IN";
    }
    else {
        converted = in_to_mm(length) + "MM x " + in_to_mm(width) + "MM x " + in_to_mm(height) + "MM";
    }
    return (React.createElement("div", { style: {
            display: 'flex',
            fontSize: '12px',
            textTransform: 'lowercase',
            fontWeight: 100,
            flexWrap: 'wrap',
            overflowWrap: 'break-word',
        } }, original + ' | ' + converted));
};
var in_to_mm = function (value_inches) {
    if (value_inches) {
        var result = (value_inches * 25.4).toPrecision(precision);
        return normalize_number(result);
    }
    return value_inches;
};
var mm_to_in = function (value_mm) {
    if (value_mm) {
        var result = (value_mm * 0.0393701).toPrecision(precision);
        return normalize_number(result);
    }
    return value_mm;
};
var normalize_number = function (value) {
    if (value) {
        return parseFloat(value).toFixed(3);
    }
    return value;
};
