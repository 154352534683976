import React, { useEffect, useState } from 'react';

import {
    ArrowLeftOutlined,
    CalendarOutlined,
    HomeOutlined,
    NumberOutlined,
    OrderedListOutlined,
    PullRequestOutlined,
    ReadOutlined,
    UserOutlined,
    WalletOutlined,
    DownloadOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Input,
    List,
    message,
    Modal,
    notification,
    Row,
    Skeleton,
    Table,
    Tabs,
} from 'antd';
import { ColumnProps } from 'antd/es/table';

import { useHistory, useParams } from 'react-router-dom';
import {
    ErrorRender,
    NoteCreateProps,
    NotesListCard,
    Price,
    Addresses,
    is_valid_address,
    AddressForm,
    AddressProps,
} from '@partsbadger/library';
import { post } from '@partsbadger/utils';
import { get } from 'shared';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import Shipments from '../../../components/SalesOrder/Shipments';
import QualityDocs from '../../../components/SalesOrder/QualityDocs';
import Invoices from '../../../components/SalesOrder/Invoices';
import { Chat } from '@partsbadger/quote-tool';

import { INotes, IOrderLineItemProps, VendorProductsProps } from '../../../../utils/types';
import { observer } from 'mobx-react';
import moment from 'moment';
import { OrderLineItemProps } from '@partsbadger/types/lib/SalesOrderInterfaces';
import { NotesForm } from '@partsbadger/library/lib/Form/NotesForm';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { hasGroup } from '../../../components/User';
const FileDownload = require('js-file-download');

const { TabPane } = Tabs;

type Props = FormComponentProps;

type Params = {
    sales_order_id: string;
};

const Details = observer((props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [orderItems, setOrderItems] = useState<any>();
    const [loadingProductionInformation, setLoadingProductionInformation] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalNotesByItem, setShowModalNotesByItem] = useState<boolean>(false);
    const [notesByItem, setNotesByItem] = useState<{ id: number; item: string; notes: INotes[] } | null>(null);
    const [notes, setNotes] = useState<string>('');
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [showAddNewAddress, setShowAddNewAddress] = useState(false);

    const { sales_order_id } = useParams<Params>();

    const history = useHistory();

    const back = () => {
        history.goBack();
    };
    const { addresses } = SalesOrderStore;

    async function getSalesOrderDetails(id: string | number) {
        setLoading(true);
        await SalesOrderStore.getById(id)
            .then(async () => {
                if (SalesOrderStore.order && SalesOrderStore.order?.line_items.length > 0) {
                    setOrderItems(SalesOrderStore.order.line_items);
                    await getVendorInformation(sales_order_id);
                }
            })
            .catch(error => {
                message.error(`Error loading tracking codes: ${error}`);
            });
        setLoading(false);
    }

    const getVendorInformation = async (id: any) => {
        setLoadingProductionInformation(true);
        setLoading(true);
        await SalesOrderStore.getTrackingNumbersBySalesOrderId(id)
            .then(data => {
                if (SalesOrderStore.order && SalesOrderStore.products_tracking_numbers.length > 0) {
                    setOrderItems(
                        mergeLineItemsByZohoId(
                            SalesOrderStore.order.line_items,
                            SalesOrderStore.products_tracking_numbers
                        )
                    );
                }
            })
            .catch(error => {
                message.error(`Error loading tracking codes: ${error}`);
            });

        await SalesOrderStore.getQualityDocumentsBySalesOrderId(id)
            .then(data => {
                setLoadingProductionInformation(false);
            })
            .catch(
                error => (
                    setLoadingProductionInformation(false), message.error(`Error loading quality documents: ${error}`)
                )
            );
        setLoading(false);
    };

    useEffect(() => {
        if (sales_order_id) {
            getSalesOrderDetails(sales_order_id);
            SalesOrderStore.getNotifications(sales_order_id);
            SalesOrderStore.getNotes(sales_order_id);
            SalesOrderStore.getShipmentsBySalesOrderId(sales_order_id);
        }
    }, [sales_order_id]);

    const mergeLineItemsByZohoId = (line_items: OrderLineItemProps[], vendor_products: VendorProductsProps[]) => {
        let tracking: VendorProductsProps[] = [];
        line_items.map((line_item: OrderLineItemProps) => {
            vendor_products.find((vendor_product: VendorProductsProps) => {
                if (vendor_product.zoho_id === line_item.product_zid && vendor_product) {
                    tracking.push(vendor_product);
                }
            });
            line_item.tracking_codes = tracking;
            tracking = [];
        });
        return line_items;
    };

    const downloadPdf = (order_id: string, order_name: string | undefined) => {
        setLoading(true);

        get(`staff/sales-orders/${order_id}/download/`, {}, { responseType: 'blob' })
            .then((response: any) => {
                const data = response.data;

                FileDownload(data, `${order_name}.pdf`);
                setLoading(false);
                notification.success({ message: 'Document downloaded' });
            })
            .catch((error: any) => notification.error(error));
    };

    const columns: ColumnProps<IOrderLineItemProps>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (t, r: IOrderLineItemProps) => {
                return (
                    <>
                        <div className={'flex flex-row'}>
                            <div>{t !== 'CNC Part' ? t : ''}</div>
                        </div>
                        <div className={'text-md'}>{r.product_description}</div>

                        {hasGroup('Production') && (
                            <Button
                                type={'link'}
                                onClick={() => {
                                    if (r.notes) {
                                        setNotesByItem({ id: r.id, item: t, notes: r.notes });
                                        setShowModalNotesByItem(true);
                                    }
                                }}
                            >
                                {r.notes && r.notes.length > 0 ? (
                                    <Badge count={`${r.notes?.length} Notes`} overflowCount={100} />
                                ) : (
                                    'Add Note'
                                )}
                            </Button>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_part_ship_date',
            key: 'required_part_ship_date',
            width: '10%',
        },
        {
            title: 'Required Partner Ship Date',
            dataIndex: 'required_partner_ship_date',
            key: 'required_partner_ship_date',
            width: '10%',
            render: (text, record) => {
                if(record.revised_partner_ship_date) {
                    return <>
                            <div className="line-through">
                                {record.required_partner_ship_date}
                            </div> 
                            <span className='text-red-600'>{record.revised_partner_ship_date}</span>                
                    </>
                } else {
                    return record.required_partner_ship_date;
                }
            }
        },
        {
            title: 'Production Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (text, record) => {
                // 1. Part is in production and not exist yet
                // 2. Part is submitted partially US
                // 3. Part is submitted full to US
                // 4. Part is domestic process
                // 5  Part is submitted to Customer Partial
                // 6. Part is submitted to Customer in Full

                const production_record = SalesOrderStore.quality_documents.find(
                    item => item.product_zid == record.product_zid
                );
                if (production_record) {
                    const quantity_shipped = production_record.quantity_shipped;
                    const required_partner_ship_date = production_record.required_partner_ship_date;
                    const revised_ship_date = production_record.revised_ship_date;
                    const current_date = production_record.current_date;
                    if (required_partner_ship_date && quantity_shipped === 0 && !revised_ship_date) {
                        const datePartner = moment(required_partner_ship_date, 'YYYY-MM-DD');
                        const currentDate = moment(current_date, 'YYYY-MM-DD');
                        const daysDiff = datePartner.diff(currentDate, 'days');
                        if (daysDiff >= 0) {
                            return `On Time`;
                        } else {
                            return `Please Hold for updates`;
                        }                        
                    } else if (required_partner_ship_date && quantity_shipped === 0 && revised_ship_date) {
                        return `New Est. to Ship`;
                    } else if (SalesOrderStore.order?.status === 'Received in Full') {
                        return `Received in Full`;
                    } else if (SalesOrderStore.order?.status === 'Received in Full - Domestic Finish') {
                        return `Pending secondary`;
                    } else if (SalesOrderStore.order?.status.startsWith('Received - Under Review')) {
                        return `Under review, please hold`;
                    } else if (SalesOrderStore.order?.domestic_process_needed === true) {
                        return `Please hold for updates (shop)`;
                    } else if (
                        production_record.quantity_shipped > 0 &&
                        SalesOrderStore.order?.intake_status === 'Intaken None'
                    ) {
                        return `On way to WI`;
                    } else if (record.quantity - production_record.quantity_shipped <= 0) {
                        return `Shipped in Full to USA`;
                    } else {
                        return <span>No Data</span>;
                    }
                }
                return <span>--</span>;
            },
        },
        {
            title: 'Tracking Code to PB (WI)',
            dataIndex: 'vendor_tracking_code',
            key: 'vendor_tracking_code',
            align: 'center',
            render: (text, record: IOrderLineItemProps) => {
                return (
                    <>
                        {record.tracking_codes && record.tracking_codes.length > 0 ? (
                            <>
                                {record.tracking_codes.map((tracking: VendorProductsProps, index: number) => {
                                    const tracking_code = tracking.vendor_tracking_code
                                        ? tracking.vendor_tracking_code.split('/')[0]
                                        : null;
                                    return (
                                        <>
                                            {tracking.vendor_tracking_code !== '' ? (
                                                <>
                                                    <div key={index} className={'mt-2'}>
                                                        <div>
                                                            <b>{index + 1}. </b>
                                                            {tracking_code} - As of{' '}
                                                            {moment(tracking.vendor_created_time).format('M/D/YYYY')}
                                                        </div>
                                                        <div>
                                                            <a
                                                                title={'Try in DHL'}
                                                                target={'_blank'}
                                                                rel={'noreferrer'}
                                                                href={`https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_code}`}
                                                            >
                                                                DHL
                                                            </a>

                                                            <a
                                                                title={'Try in FedEx'}
                                                                style={{
                                                                    marginLeft: 5,
                                                                }}
                                                                target={'_blank'}
                                                                rel={'noreferrer'}
                                                                href={`https://www.fedex.com/fedextrack/?trknbr=${tracking_code}`}
                                                            >
                                                                FEDEX
                                                            </a>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={'mt-2'}>
                                                    {tracking.vendor_created_time ? (
                                                        <>
                                                            <b>{index + 1}. </b>As of{' '}
                                                            {moment(tracking.vendor_created_time).format('M/D/YYYY')}
                                                        </>
                                                    ) : (
                                                        'Pending'
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            'Pending'
                        )}
                    </>
                );
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },
        {
            title: 'Unit Price',
            dataIndex: 'list_price',
            key: 'list_price',
            align: 'center',
            render: (text, record) => <Price value={record.list_price} />,
        },
        {
            title: 'Total Price',
            dataIndex: 'total_after_discount',
            key: 'total_after_discount',
            align: 'right',
            render: (text, record) => <Price value={record.total_after_discount} />,
        },
    ];

    const onSubmit = async () => {
        try {
            const requestData = {
                username: 'staff',
                first_name: 'staff',
                last_name: 'staff',
                company_name: 'staff',
                order: SalesOrderStore.order?.name,
                email: 'staff@gmail.com',
                notes: notes,
            };

            if (SalesOrderStore.order) {
                await SalesOrderStore.sendUpdateRequest(SalesOrderStore.order.id, requestData);

                message.success('Your Update request has been sent successfully');

                return true;
            }

            message.error('The order does not exist.');
        } catch (err) {
            message.error(`Error loading shipments: ${err}`);
        }
    };

    return (
        <Card bodyStyle={{ padding: 0 }} bordered={false}>
            {loading && (
                <div className="w-full m-4 py-8 text-center">
                    <Skeleton paragraph={{ rows: 4 }} />
                </div>
            )}
            {!loading && SalesOrderStore.order && (
                <>
                    <div style={{ margin: '0' }} className="detail-top-row flex flex-row justify-between">
                        <button className="flex items-center justify-start" onClick={back}>
                            <ArrowLeftOutlined className="mr-2" style={{ fontSize: '21px' }} />
                            <span className="font-bold" style={{ fontSize: '18px' }}>
                                {SalesOrderStore.order.name}{' '}
                                <Badge count={SalesOrderStore.order.status} style={{ backgroundColor: 'green' }} />
                            </span>
                        </button>
                        <Button
                            id="action-button"
                            type="primary"
                            onClick={() => {
                                setShowModal(!showModal);
                            }}
                            style={{
                                color: '#fff',
                                backgroundColor: '#d92923',
                                borderColor: '#d92923',
                                marginLeft: 'auto',
                            }}
                        >
                            Request Update
                        </Button>
                        <Button
                            id="action-button"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                downloadPdf(sales_order_id, SalesOrderStore?.order?.name);
                            }}
                            style={{
                                color: '#d92923',
                                borderColor: '#d92923',
                                marginLeft: '10px',
                            }}
                        >
                            Reprint/Download Sales Order PDF
                        </Button>
                    </div>

                    <Divider className={'spacing'} />
                    <div
                        style={{
                            overflowY: 'auto',
                        }}
                        className="ml-5"
                    >
                        <div className={'header max-w-xl'}>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col justify-center">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <NumberOutlined className="mr-1" /> Order Number:
                                    </p>
                                    <p className={'font-bold text-base ml-1'} style={{ width: '7vw' }}>
                                        {SalesOrderStore.order.name ?? '--'}
                                    </p>
                                </div>

                                <div className="flex flex-col">
                                    <p className="label flex items-center text-base m-0 font-serif">
                                        <NumberOutlined className="mr-1" /> PO Number:
                                    </p>
                                    <p className={'font-bold text-base ml-1'} style={{ width: '6vw' }}>
                                        {SalesOrderStore.order.purchase_order ?? '--'}
                                    </p>
                                </div>

                                <div className="flex flex-col justify-center ">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <CalendarOutlined className="mr-1" /> Estimated Ship Date:
                                    </p>
                                    <p className={'font-bold ml-1 text-base ml-1'}>
                                        {SalesOrderStore.order.required_ship_date ?? 'In Review'}
                                    </p>
                                </div>
                            </div>
                            <Divider className="mb-0" />
                            <div className="flex flex-row justify-between" style={{ width: '28vw' }}>
                                <div className="flex flex-col justify-center ">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <UserOutlined className="mr-1" /> Account:
                                    </p>
                                    <p className={'font-bold ml-1 text-base ml-1'}>
                                        {SalesOrderStore.order.account?.name ?? '-'}
                                    </p>
                                </div>
                                <div className="flex flex-col justify-center ">
                                    <p className={'label flex items-center m-0 text-base font-serif'}>
                                        <UserOutlined className="mr-1" /> Contact:
                                    </p>
                                    <p className={'font-bold ml-1 text-base ml-1'}>
                                        {SalesOrderStore.order.contact?.first_name ?? '-'}
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Col md={12}>
                                    <Form.Item label={'Shipping Address'}>
                                        {getFieldDecorator('shipping_address', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'This file is required',
                                                    validator: is_valid_address,
                                                },
                                            ],
                                            initialValue: {
                                                name: SalesOrderStore.order.shipping_name,
                                                street: SalesOrderStore.order.shipping_street,
                                                city: SalesOrderStore.order.shipping_city,
                                                state: SalesOrderStore.order.shipping_state,
                                                country: {
                                                    code: SalesOrderStore.order.shipping_country,
                                                    alpha3: SalesOrderStore.order.shipping_country,
                                                    name: SalesOrderStore.order.shipping_country,
                                                },
                                                zip_code: SalesOrderStore.order.shipping_zip_code,
                                            },
                                        })(
                                            <Addresses
                                                addresses={addresses}
                                                onAddNewAddress={() => setShowAddNewAddress(true)}
                                                onChange={(address: AddressProps) => {
                                                    const payload = {
                                                        shipping_name: address.name,
                                                        shipping_street: address.street,
                                                        shipping_city: address.city,
                                                        shipping_state: address.state,
                                                        shipping_zip_code: address.zip_code,
                                                        shipping_country: address.country?.code,
                                                    };
                                                    SalesOrderStore.update(parseInt(sales_order_id), payload);
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item label={'Billing Address'}>
                                        {getFieldDecorator('billing_address', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'This file is required',
                                                    validator: is_valid_address,
                                                },
                                            ],
                                            initialValue: {
                                                name: SalesOrderStore.order.billing_name,
                                                street: SalesOrderStore.order.billing_street,
                                                city: SalesOrderStore.order.billing_city,
                                                state: SalesOrderStore.order.billing_state,
                                                country: {
                                                    code: SalesOrderStore.order.billing_country,
                                                    alpha3: SalesOrderStore.order.billing_country,
                                                    name: SalesOrderStore.order.billing_country,
                                                },
                                                zip_code: SalesOrderStore.order.billing_zip_code,
                                            },
                                        })(
                                            <Addresses
                                                addresses={addresses}
                                                onAddNewAddress={() => setShowAddNewAddress(true)}
                                                onChange={(address: AddressProps) => {
                                                    const payload = {
                                                        billing_name: address.name,
                                                        billing_street: address.street,
                                                        billing_city: address.city,
                                                        billing_state: address.state,
                                                        billing_zip_code: address.zip_code,
                                                        billing_country: address.country?.code,
                                                    };
                                                    SalesOrderStore.update(parseInt(sales_order_id), payload);
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Modal
                                    visible={showAddNewAddress}
                                    onCancel={() => {
                                        setShowAddNewAddress(false);
                                    }}
                                    footer={[]}
                                >
                                    <AddressForm
                                        handleSave={payload => {
                                            SalesOrderStore.update(parseInt(sales_order_id), payload);
                                            return SalesOrderStore.saveAddress(
                                                SalesOrderStore?.order?.contact?.id,
                                                payload
                                            ).then(() => {
                                                SalesOrderStore.getContactById(SalesOrderStore?.order?.contact?.id);
                                            });
                                        }}
                                        handleClose={() => {
                                            setShowAddNewAddress(false);
                                        }}
                                    />
                                </Modal>
                            </div>
                            <Divider />
                            <Divider className={'spacing'} />
                        </div>

                        <div>
                            <p className={'label flex items-center font-bold font-serif text-base text-red-500'}>
                                <OrderedListOutlined className="mr-1" /> Line Items:
                            </p>
                        </div>
                        <Table
                            rowKey={record => record.id.toString()}
                            pagination={false}
                            columns={columns}
                            dataSource={orderItems}
                            className="border"
                            footer={() => (
                                <Row style={{ marginTop: 15 }}>
                                    <Col sm={24} md={12} />
                                    <Col sm={24} md={12}>
                                        <div className={'flex flex-col justify-between'}>
                                            <div className="flex flex-row justify-between  mb-1">
                                                <div className="summary-label">Subtotal</div>
                                                <div className="summary-value">
                                                    <Price value={SalesOrderStore.order?.subtotal ?? 0} />
                                                </div>
                                            </div>

                                            <div className="flex flex-row justify-between mt-1">
                                                <div className="summary-label">Discount</div>
                                                <div className="summary-value">
                                                    <Price value={SalesOrderStore.order?.discount ?? 0} />
                                                </div>
                                            </div>

                                            <div className="flex flex-row justify-between  mt-2">
                                                <div className="summary-label">Tax</div>
                                                <div className="summary-value">
                                                    <Price value={SalesOrderStore.order?.tax ?? 0} />
                                                </div>
                                            </div>

                                            <div
                                                className="flex flex-row justify-between  mt-2 pt-4"
                                                style={{
                                                    borderTop: 'solid 1px #d9d9d9',
                                                }}
                                            >
                                                <div className="font-bold text-xl">Grand Total</div>
                                                <div className="font-bold text-xl">
                                                    <Price value={SalesOrderStore.order?.grand_total ?? 0} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
                        />
                    </div>
                    <Tabs defaultActiveKey="1" className="mt-10">
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.notes.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <ReadOutlined className="mr-2" />
                                    </Badge>
                                    Notes
                                </span>
                            }
                            key="1"
                        >
                            <List
                                size="small"
                                bordered
                                loading={loading}
                                dataSource={SalesOrderStore.notes}
                                renderItem={item => <List.Item>{item.content}</List.Item>}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.order.additional_requirements?.length ?? 0}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <OrderedListOutlined className="mr-2" />
                                    </Badge>
                                    Additional Requirements
                                </span>
                            }
                            key="2"
                        >
                            <List
                                size="small"
                                bordered
                                loading={loading}
                                dataSource={SalesOrderStore.order.additional_requirements ?? []}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.shipments.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <OrderedListOutlined className="mr-2" />
                                    </Badge>
                                    View Shipments to the Customer
                                </span>
                            }
                            key="3"
                        >
                            {SalesOrderStore.order && <Shipments order={SalesOrderStore.order} />}
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={
                                            SalesOrderStore.quality_documents.filter(item => item.documents.length > 0)
                                                .length
                                        }
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <OrderedListOutlined className="mr-2" />
                                    </Badge>
                                    Quality Documents
                                </span>
                            }
                            key="4"
                        >
                            {loadingProductionInformation && (
                                <div className="w-full m-4 py-8 text-center">
                                    <Skeleton paragraph={{ rows: 4 }} />
                                </div>
                            )}
                            <QualityDocs />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.invoices.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <WalletOutlined className="mr-2" />
                                    </Badge>
                                    Invoices
                                </span>
                            }
                            key="5"
                        >
                            <Invoices />
                        </TabPane>

                        <TabPane
                            tab={
                                <span className="flex items-center font-bold">
                                    <Badge
                                        count={SalesOrderStore.notifications.length}
                                        overflowCount={100}
                                        style={{ position: 'absolute', right: '20px' }}
                                    >
                                        <ReadOutlined className="mr-2" />
                                    </Badge>
                                    Messages
                                </span>
                            }
                            key="6"
                        >
                            <Chat
                                height={450}
                                messages={SalesOrderStore.notifications}
                                onSendMessage={(payload: any) => {
                                    const formData = new FormData();

                                    if (payload.body) {
                                        formData.append('body', payload.body);
                                    }

                                    payload.attachments.forEach((item: any) => {
                                        formData.append('attachments_files', item);
                                    });

                                    const url = `staff/sales-orders/${sales_order_id}/notifications/`;

                                    post(url, formData)
                                        .then((response: any) => {
                                            SalesOrderStore.getNotifications(sales_order_id);
                                        })
                                        .catch((error: any) => notification.error(error));
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </>
            )}

            {showModal && (
                <Modal
                    closable={true}
                    title={
                        <span className="flex items-center font-bold">
                            <PullRequestOutlined className="mr-1" />
                            Request Update
                        </span>
                    }
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            loading={loadingButton}
                            key="button-request-update"
                            type="primary"
                            style={{
                                color: '#fff',
                                backgroundColor: '#d92923',
                                borderColor: '#d92923',
                                marginLeft: 'auto',
                            }}
                            onClick={async () => {
                                setLoadingButton(true);
                                await onSubmit();
                                setShowModal(false);
                                setNotes('');
                                setLoadingButton(false);
                            }}
                        >
                            Send Request
                        </Button>,
                    ]}
                >
                    <p className="font-bold">Describe the changes:</p>
                    <Input.TextArea
                        value={notes}
                        onChange={e => {
                            setNotes(e.target.value);
                        }}
                    />
                </Modal>
            )}

            {showModalNotesByItem && notesByItem && (
                <Modal
                    closable={true}
                    title={
                        <span className="flex items-center font-bold">
                            <PullRequestOutlined className="mr-1" />
                            Notes for Item: {notesByItem.item}
                        </span>
                    }
                    visible={showModalNotesByItem}
                    onCancel={() => setShowModalNotesByItem(false)}
                    destroyOnClose={true}
                    width={800}
                    footer={[
                        <Button
                            key="button-request-update"
                            type="default"
                            onClick={() => {
                                setShowModalNotesByItem(false);
                            }}
                        >
                            Close
                        </Button>,
                    ]}
                >
                    <NotesForm
                        handleSubmit={(data: NoteCreateProps) => {
                            data.id = notesByItem.id;
                            SalesOrderStore.createNoteByItem(sales_order_id, data)
                                .then(async () => {
                                    notification.success({ message: 'New note added' });
                                    setShowModalNotesByItem(false);
                                    await SalesOrderStore.getById(sales_order_id);
                                    await getVendorInformation(sales_order_id);
                                })
                                .catch((err: any) => {
                                    notification.error({
                                        message: <ErrorRender error={err} />,
                                        placement: 'topRight',
                                    });
                                });
                        }}
                        form={props.form}
                    />
                    {notesByItem.notes.length > 0 && <NotesListCard sales_order_notes={notesByItem.notes} />}
                </Modal>
            )}
        </Card>
    );
});

export default Form.create()(Details);
