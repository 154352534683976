import './styles.less';
import styled from 'styled-components';
import { Button, Card, Checkbox, Input, List, Select } from 'antd';

interface IButtonAntd {
    textDecoration: string;
    color: string;
    border: number;
}

interface IInputAntd {
    classNameProps: string;
}

interface ISelect {
    width: number;
}

interface IListAntd {
    classNameProps: string;
    styleProps?: any;
}

interface IButtonProfileAntd {
    styleProps?: any;
}

interface IButtonResetAntd {
    styleProps?: any;
}

interface IInputTextAntd {
    fontSize?: number;
    fontColor?: string;
    fontBold?: boolean;
    fontItalic?: boolean;
}

export const Container = styled.div.attrs({
    className: 'flex flex-row',
})``;

export const Header = styled.div.attrs({
    className: 'flex flex-row',
})``;

export const AlignFields = styled.div.attrs({
    className: 'flex flex-row mt-5',
})``;

export const FlexRow = styled.div.attrs({
    className: 'flex flex-row',
})``;

export const FieldTitle = styled.span.attrs({
    className: 'flex flex-row text-base font-bold mr-1',
})``;

export const FieldText = styled.span.attrs({
    className: 'mr-10 text-base',
})``;

export const InputAntd = styled(Input).attrs((props: IInputAntd) => ({
    className: `${props.classNameProps}`,
}))<IInputAntd>``;

export const FlexCol = styled.div.attrs({
    className: 'flex flex-col',
})``;

export const SelectAntd = styled(Select).attrs((props: ISelect) => ({
    className: 'text-base',
    style: { width: props.width },
}))<ISelect>``;

export const ButtonAntd = styled(Button).attrs({
    className: 'ml-5 mr-5',
})<IButtonAntd>`
    border: ${(props: IButtonAntd) => props.border};

    span {
        text-decoration-line: ${(props: IButtonAntd) => props.textDecoration};
        color: ${(props: IButtonAntd) => props.color};
    }

    box-shadow: 0 4px 22px 0 rgba(71, 69, 69, 0.178);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const CheckboxAntd = styled(Checkbox).attrs({
    className: 'mr-1',
})``;

export const CheckboxDiv = styled.div.attrs({
    // style: { maxWidth: '200px' },
    className: 'flex flex-col justify-between mt-1 ml-10 tolerances-wrapper',
})``;

export const AlignRadioGroup = styled.div.attrs({
    className: 'flex flex-col justify-end ml-2',
})`
    height: 115px;
`;

export const DrawerDiv = styled.div.attrs({})`
    //position: absolute;
    //right: 0;
    //margin-right: 20px;
    width: 100%;
    //background: red;
    height: auto;
`;

export const InputTextAntd = styled(Input.TextArea).attrs((props: IInputTextAntd) => ({
    className: 'w-full mt-1',
    style: {
        fontSize: props.fontSize,
        fontWeight: props.fontBold? 'bold': 'normal',
        fontStyle: props.fontItalic? 'italic' : 'normal',
        color: props.fontColor,
    },
}))<IInputTextAntd>`
    margin-bottom: 5px;
    box-shadow: 0 4px 22px 0 rgba(71, 69, 69, 0.178);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 1px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    overflow-y: 'scroll';
    overflow-x: 'scroll';
    min-width: 12vw;
`;

export const PdfArea = styled.div.attrs({})`
    //position: absolute;
    //top: 180px;
`;

export const ListAntd = styled(List).attrs((props: IListAntd) => ({
    classNameProps: `${props.classNameProps}`,
    style: { ...props.styleProps },
}))<IListAntd>``;

export const HorizontalListAntd = styled(List).attrs((props: IListAntd) => ({
    classNameProps: `${props.classNameProps}`,
    style: { ...props.styleProps },
    grid: { gutter: 5, column: 4 },
}))<IListAntd>``;

export const CardAntd = styled(Card).attrs((props: IListAntd) => ({
    classNameProps: `${props.classNameProps}`,
    style: { ...props.styleProps },
}))<IListAntd>`
    //display: inline-block;
    //position: relative;
`;

export const ButtonProfileAntd = styled(Button).attrs((props: IButtonProfileAntd) => ({
    className: 'button-profile w-full mt-1 ml-0',
    size: 'large',
    style: { ...props.styleProps },
}))<IButtonProfileAntd>`
    /* background: rgba(255, 255, 255, 0.35); */
    box-shadow: 0 4px 22px 0 rgba(71, 69, 69, 0.178);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const ButtonResetAntd = styled(Button).attrs((props: IButtonResetAntd) => ({
    style: { ...props.styleProps },
}))<IButtonResetAntd>`
    position: absolute;
    top: 0em;
    left: -0.5em;
    z-index: 1;
`;

export const ProfilesTitle = styled.p.attrs({
    className: 'flex text-xl mt-2 mb-0 justify-center',
})``;

export const PreviewProfilesAntd = styled.div.attrs({})`
    //position: absolute;
    //top: 1000px;
    width: 100%;
    height: 100%;
    padding: 5px;
`;
