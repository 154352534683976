var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { observer } from 'mobx-react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Input, message, Result, Checkbox, Form, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import api from '../Mobile/utils/api';
import { FormErrorParser } from '../ErrorRender';
export var Mobile = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var props = __rest(_a, []);
    var _m = React.useState(false), loading = _m[0], setLoading = _m[1];
    var _o = React.useState(props.contact ? (_b = props.contact) === null || _b === void 0 ? void 0 : _b.sms_notifications : false), noficated = _o[0], setNotificated = _o[1];
    var _p = React.useState(props.contact ? (_c = props.contact) === null || _c === void 0 ? void 0 : _c.sms_verified : false), verified = _p[0], setVerified = _p[1];
    var _q = React.useState(false), verify = _q[0], setVerify = _q[1];
    var _r = React.useState(false), resend = _r[0], setResend = _r[1];
    var _s = React.useState(null), mobile = _s[0], setMobile = _s[1];
    var _t = React.useState(null), responsetype = _t[0], setResponsetype = _t[1];
    var _u = React.useState(false), receiveSms = _u[0], setReceiveSms = _u[1];
    var isSmsNotifications = (_d = props.contact) === null || _d === void 0 ? void 0 : _d.sms_notifications;
    var form = Form.useForm()[0];
    var info = 'I would like to receive SMS text message notifications when a quote is completed';
    var handleSubmit = function (e) {
        form.validateFields()
            .then(function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setLoading(true);
                        return [4 /*yield*/, api.requests
                                .post("/customer/contacts/" + ((_a = props.contact) === null || _a === void 0 ? void 0 : _a.id) + "/send-sms/", { mobile: values.mobile })
                                .then(function (data) {
                                setResponsetype('success');
                                setMobile(data.mobile);
                                setNotificated(data.sms_notifications);
                                setVerified(data.sms_verified);
                                setLoading(false);
                                form.resetFields();
                                props.updateData && props.updateData();
                            })
                                .catch(function (error) {
                                setResponsetype('error');
                                setMobile(values.mobile);
                                setLoading(false);
                                form.resetFields();
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function (errorInfo) {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    var handleSubmitVerified = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            form.validateFields()
                .then(function (values) { return __awaiter(void 0, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            setLoading(true);
                            return [4 /*yield*/, api.requests
                                    .post("/customer/contacts/" + ((_a = props.contact) === null || _a === void 0 ? void 0 : _a.id) + "/verify-sms/", {
                                    mobile: mobile,
                                    code: values.verifycode,
                                })
                                    .then(function (data) {
                                    form.resetFields();
                                    props.updateData && props.updateData();
                                    setReceiveSms(true);
                                    setVerify(true);
                                    setLoading(false);
                                    setNotificated(data.sms_notifications);
                                    setVerified(data.sms_verified);
                                    props.onChangeSmsNotification(true);
                                    if (data.detail === 'approved') {
                                        message.success('Your mobile was registered successfully', 3);
                                    }
                                    else {
                                        message.warning('Incorrect code, try again', 3);
                                    }
                                })
                                    .catch(function (error) {
                                    form.resetFields();
                                    message.error('Incorrect code, Please resend a new code', 3);
                                    setLoading(false);
                                })];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (errorInfo) {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                    placement: 'top',
                    maxCount: 1,
                });
            });
            return [2 /*return*/];
        });
    }); };
    var handleResend = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setResend(false);
                    return [4 /*yield*/, api.requests
                            .post("/customer/contacts/" + ((_a = props.contact) === null || _a === void 0 ? void 0 : _a.id) + "/send-sms/", { mobile: mobile === null || mobile === void 0 ? void 0 : mobile.replace('+', '') })
                            .catch(function () {
                            message.error('Error');
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDisableSms = function () { return __awaiter(void 0, void 0, void 0, function () {
        var smsNotification, _error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    smsNotification = !isSmsNotifications;
                    return [4 /*yield*/, api.requests.post("/customer/contacts/" + ((_a = props.contact) === null || _a === void 0 ? void 0 : _a.id) + "/disable-mobile/", {
                            sms: smsNotification,
                        })];
                case 1:
                    _b.sent();
                    props.onChangeSmsNotification(smsNotification);
                    props.updateData && props.updateData();
                    return [3 /*break*/, 3];
                case 2:
                    _error_1 = _b.sent();
                    message.error('Error');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleReceiveSMSNotification = function () {
        setReceiveSms(!receiveSms);
    };
    var response = React.createElement(React.Fragment, null);
    if (responsetype === 'success') {
        response = (React.createElement("span", null,
            React.createElement(CheckCircleOutlined, { style: { color: '#52c41a' } }),
            " We have sent a sms to ",
            mobile,
            " for verification."));
    }
    else if (responsetype === 'error') {
        response = (React.createElement("span", null,
            React.createElement(CloseCircleOutlined, { style: { color: 'red' } }),
            " ",
            mobile,
            " is unavailable."));
    }
    var errorMessage = null;
    var text = (React.createElement("span", { className: 'link', onClick: handleResend }, "Resend Verification"));
    if (resend) {
        text = React.createElement("span", null, "Sent");
    }
    return (React.createElement(React.Fragment, null,
        !((_e = props.contact) === null || _e === void 0 ? void 0 : _e.sms_notifications) &&
            !((_f = props.contact) === null || _f === void 0 ? void 0 : _f.sms_verified) &&
            !((_g = props.contact) === null || _g === void 0 ? void 0 : _g.mobile) &&
            !verified &&
            !noficated && (React.createElement(Checkbox, { checked: receiveSms, onChange: handleReceiveSMSNotification, className: "text-base" }, info)),
        ((((_h = props.contact) === null || _h === void 0 ? void 0 : _h.sms_notifications) && ((_j = props.contact) === null || _j === void 0 ? void 0 : _j.sms_verified)) ||
            (!((_k = props.contact) === null || _k === void 0 ? void 0 : _k.sms_notifications) && ((_l = props.contact) === null || _l === void 0 ? void 0 : _l.sms_verified)) ||
            (verified && noficated)) && (React.createElement(Checkbox, { checked: isSmsNotifications, onChange: handleDisableSms, className: "text-base" }, info)),
        !verified && !noficated && (responsetype != 'success' || verify === true) && (React.createElement(Form, { form: form, onFinish: handleSubmit, className: "mt-5" },
            React.createElement("p", { className: "text-base text-black" }, "Mobile Number"),
            React.createElement(Form.Item, { name: "mobile", rules: [
                    {
                        required: true,
                        message: 'Please input a Mobile number',
                    },
                ] },
                React.createElement(PhoneInput, { country: 'us', onlyCountries: ['us', 'ca'], disabled: !receiveSms, buttonStyle: { border: '1px solid #595959' }, inputStyle: {
                        minWidth: 300,
                        width: '26.354vw',
                        height: 50,
                        border: '1px solid #595959',
                    } })),
            React.createElement(Form.Item, null,
                React.createElement(Button, { loading: loading, htmlType: "submit", disabled: !receiveSms, size: "large", type: "primary", className: "text-lg font-normal", style: { width: '263px' } }, "Add Mobile Number")))),
        !verified && noficated && (React.createElement(React.Fragment, null,
            errorMessage,
            React.createElement(Form, { onFinish: handleSubmitVerified, form: form },
                response,
                React.createElement(Form.Item, { name: "verifycode", style: { width: 300 }, rules: [
                        {
                            required: true,
                            message: 'Please input a code verification',
                        },
                    ] },
                    React.createElement(Input, { maxLength: 4, placeholder: "Code verification" })),
                React.createElement(Form.Item, null,
                    React.createElement(Button, { loading: loading, htmlType: "submit" }, "Verify"))),
            mobile && React.createElement(React.Fragment, null, text))),
        verified && noficated && (React.createElement(Result, { status: "success", subTitle: "Congrats! Your mobile is registered and verified" }))));
});
