import React from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Modal, Typography } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PartItem, PartSlot } from '@partsbadger/library';
import PartStore from '../../stores/PartStore';
import { SortFiles } from '../SortFiles';
import { ALLOWED_FILES } from '@partsbadger/utils';
var Title = Typography.Title, Paragraph = Typography.Paragraph;
export var AssociateParts = observer(function (props) {
    var handleSubmit = function () {
        PartStore.fileParts.map(function (item) {
            var _a;
            if (item['3D File'] || item['2D File'] || ((_a = item['Additional File']) === null || _a === void 0 ? void 0 : _a.length)) {
                PartStore.uploadPart(item);
            }
            else {
                PartStore.removePart(item); // Remove empty lines
            }
        });
    };
    var handleRemove = function (item, fileType, file) {
        PartStore.removeFile(item, fileType, file);
    };
    var check3DAllowedFiles = function (item_filename) {
        var allowed_files_3d = ALLOWED_FILES.Files3D;
        var t = item_filename.split('.').pop().toLowerCase();
        var file_type = allowed_files_3d.filter(function (f) { return f === t; });
        return t === file_type[0];
    };
    var check2DAllowedFiles = function (item_filename) {
        var allowed_files_2d = ALLOWED_FILES.Files2D;
        var t = item_filename.split('.').pop().toLowerCase();
        var file_type = allowed_files_2d.filter(function (f) { return f === t; });
        return t === file_type[0];
    };
    var parts = PartStore.fileParts.map(function (part_item, index) {
        var _a;
        var file2d = part_item['2D File'] ? (React.createElement(PartItem, { index: index, file: part_item['2D File'], type: '2D File', handleRemove: function () { return handleRemove(part_item, '2D File'); } })) : null;
        var file3d = part_item['3D File'] ? (React.createElement(PartItem, { index: index, file: part_item['3D File'], type: '3D File', handleRemove: function () { return handleRemove(part_item, '3D File'); } })) : null;
        var fileOthers = ((_a = part_item['Additional File']) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? part_item['Additional File'].map(function (file, index) {
                return (React.createElement(PartItem, { key: index, index: index, file: file, type: 'Additional File', handleRemove: function () { return handleRemove(part_item, 'Additional File', file); } }));
            })
            : null;
        return (React.createElement("div", { className: 'row-associate-parts', key: part_item.uid + "-" + index, style: {
                display: 'flex',
                justifyContent: 'center',
                // backgroundColor: 'rgba(217, 217, 217, 0.19)',
                marginBottom: '5px',
                padding: '10px',
                position: 'relative',
            } },
            React.createElement(LegacyIcon, { type: 'close', style: {
                    position: 'absolute',
                    right: '5px',
                    top: '5px',
                    cursor: 'pointer',
                    color: '#d92923',
                }, onClick: function () {
                    PartStore.removePart(part_item);
                } }),
            React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: file3d, onDrop: function (item) {
                    if (props.is_staff) {
                        if (check3DAllowedFiles(item.file.name)) {
                            PartStore.swapParts(item.index, index, item.type, '3D File', item.file);
                        }
                        else {
                            message.error('The file type is not allowed for the 3D category.', 4);
                        }
                    }
                    else {
                        PartStore.swapParts(item.index, index, item.type, '3D File', item.file);
                    }
                }, onDropNew: function (files) {
                    if (files.length) {
                        var f = files.pop();
                        if (f) {
                            PartStore.addFile(index, '3D File', f);
                        }
                    }
                } }),
            React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: file2d, onDrop: function (item) {
                    if (props.is_staff) {
                        if (check2DAllowedFiles(item.file.name)) {
                            PartStore.swapParts(item.index, index, item.type, '2D File', item.file);
                        }
                        else {
                            message.error('The file type is not allowed for the 2D category.', 4);
                        }
                    }
                    else {
                        PartStore.swapParts(item.index, index, item.type, '2D File', item.file);
                    }
                }, onDropNew: function (files) {
                    var f = files.pop();
                    if (f) {
                        PartStore.addFile(index, '2D File', f);
                    }
                } }),
            React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: fileOthers, onDrop: function (item) {
                    PartStore.swapParts(item.index, index, item.type, 'Additional File', item.file);
                }, onDropNew: function (files) {
                    files.forEach(function (file) {
                        PartStore.addFile(index, 'Additional File', file);
                    });
                } })));
    });
    if (!PartStore.hasParts()) {
        return null;
    }
    var content = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "m-auto" },
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement("div", { className: "flex flex-row", style: {
                    // background: '#fafafa',
                    } },
                    React.createElement("div", { className: "flex-1 mr-auto text-center" },
                        React.createElement(SortFiles, { fileType: '3D File' })),
                    React.createElement("div", { className: "flex-1 mr-auto text-center" },
                        React.createElement(SortFiles, { fileType: '2D File' })),
                    React.createElement("div", { className: "flex-1 mr-auto text-center" },
                        React.createElement(SortFiles, { fileType: 'Additional File' }))),
                parts))));
    return (React.createElement(Modal, { open: PartStore.fileParts.length > 1, onCancel: function () {
            PartStore.fileParts.map(function (item) {
                PartStore.removePart(item);
            });
        }, centered: true, title: React.createElement(React.Fragment, null,
            React.createElement(Title, { level: 2, className: "text-center" }, "Match your 2D and 3D files"),
            React.createElement(Paragraph, { className: "text-center" }, "Drag and Drop the files between columns.")), width: '90vw', style: { maxWidth: '1350px' }, bodyStyle: { maxHeight: '50vh', overflowY: 'scroll' }, footer: React.createElement("div", { className: "text-center my-3" },
            React.createElement(Button, { id: "start-quoting-btn", className: "w-100", style: {
                    maxWidth: 300,
                }, size: 'large', type: "primary", loading: !!PartStore.fileParts.find(function (filePart) { return filePart.uploading; }), onClick: handleSubmit }, "Continue")) }, content));
});
