var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Format Line Item to local
 * @param record from APi
 * @returns {{[p: string]: *}}
 */
export var normalize_data = function (record) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    return __assign({ product__material: ((_b = (_a = record.product) === null || _a === void 0 ? void 0 : _a.material) === null || _b === void 0 ? void 0 : _b.id)
            ? {
                key: record.product.material.id,
                label: record.product.material.name,
                manufacturing_types: record.product.material.manufacturing_types,
            }
            : {}, 
        // 'product__taps': record.product.taps,
        product__sides: record.product.sides, product__design_time: record.product.design_time, product__in_house_manufacturing: record.product.product__in_house_manufacturing, product__coatings: (_e = (_d = (_c = record.product) === null || _c === void 0 ? void 0 : _c.coatings) === null || _d === void 0 ? void 0 : _d.map(function (r) {
            return {
                key: r.id,
                label: r.name,
            };
        })) === null || _e === void 0 ? void 0 : _e[0], product__finishes: (_h = (_g = (_f = record.product) === null || _f === void 0 ? void 0 : _f.finishes) === null || _g === void 0 ? void 0 : _g.map(function (r) {
            var finish_name = (r === null || r === void 0 ? void 0 : r.metric_name) ? r.name + " / " + r.metric_name : r.name;
            return {
                key: r.id,
                label: finish_name,
            };
        })) === null || _h === void 0 ? void 0 : _h[0], product__part_tolerance: {
            key: (_k = (_j = record.product) === null || _j === void 0 ? void 0 : _j.part_tolerance) === null || _k === void 0 ? void 0 : _k.id,
            label: ((_m = (_l = record.product) === null || _l === void 0 ? void 0 : _l.part_tolerance) === null || _m === void 0 ? void 0 : _m.name) + " (" + ((_p = (_o = record.product) === null || _o === void 0 ? void 0 : _o.part_tolerance) === null || _p === void 0 ? void 0 : _p.value_in_mm) + "mm)",
        }, product__hole_tolerance: {
            key: (_r = (_q = record.product) === null || _q === void 0 ? void 0 : _q.hole_tolerance) === null || _r === void 0 ? void 0 : _r.id,
            label: ((_t = (_s = record.product) === null || _s === void 0 ? void 0 : _s.hole_tolerance) === null || _t === void 0 ? void 0 : _t.name) + " (" + ((_v = (_u = record.product) === null || _u === void 0 ? void 0 : _u.hole_tolerance) === null || _v === void 0 ? void 0 : _v.value_in_mm) + "mm)",
        } }, record);
};
export var denormalize_data = function (record) {
    var _a, _b, _c, _d, _e, _f, _g;
    return __assign(__assign({}, record), { product: {
            sides: record.product__sides,
            design_time: record.product__design_time,
            in_house_manufacturing: record.product__in_house_manufacturing === true,
            material: (_a = record.product__material) === null || _a === void 0 ? void 0 : _a.key,
            part_tolerance: (_b = record.product__part_tolerance) === null || _b === void 0 ? void 0 : _b.key,
            hole_tolerance: (_c = record.product__hole_tolerance) === null || _c === void 0 ? void 0 : _c.key,
            coatings: ((_d = record.product__coatings) === null || _d === void 0 ? void 0 : _d.key) ? [(_e = record.product__coatings) === null || _e === void 0 ? void 0 : _e.key] : [],
            finishes: ((_f = record.product__finishes) === null || _f === void 0 ? void 0 : _f.key) ? [(_g = record.product__finishes) === null || _g === void 0 ? void 0 : _g.key] : [],
        } });
};
