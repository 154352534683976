import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { get, post } from '../shared';
import { User as UserDetail } from './types';
import axios from 'axios';
import { BASE_URL } from '../constants';

class User {
    isLoading = true;
    user?: UserDetail | null = null;
    errors?: any = null;

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            user: observable,
            errors: observable,

            // Actions
            loadUser: action,
            loginWithGoogle: action,
            login: action,
            logout: action,

            // Computed
            isAuthenticated: computed,
        });
    }

    loadUser() {
        this.isLoading = true;

        get(`rest-auth/user/`)
            .then((response: any) => {
                runInAction(() => {
                    this.user = response.data;
                });
            })
            .catch((err: any) => {
                runInAction(() => {
                    this.errors = err;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    get isAuthenticated() {
        return this.user?.id;
    }

    loginWithGoogle(payload: any) {
        axios
            .post(BASE_URL + '/auth/google', payload, {
                withCredentials: false,
            })
            .then((r: any) => {
                localStorage.setItem('token', r.data.token);
                runInAction(() => {
                    this.loadUser();
                });
            })
            .catch((error: any) => (this.errors = error));
    }

    login(username: string, password: string) {
        axios
            .post(
                BASE_URL + '/rest-auth/login/',
                {
                    email: username,
                    password: password,
                },
                {
                    withCredentials: false,
                }
            )
            .then((r: any) => {
                localStorage.setItem('token', r.data.token);
                this.loadUser();
            })
            .catch((error: any) => (this.errors = error));
    }

    logout() {
        post('rest-auth/logout/', {}).then(() => {
            runInAction(() => {
                this.user = undefined;
            });
        });
    }
}

export const UserStore = new User();
