import React from 'react';
export var SpecificationBox = function (_a) {
    var title = _a.title, data = _a.data;
    return (React.createElement("div", { className: "flex flex-col border border-solid border-gray-400 rounded-sm p-1 mb-1" },
        React.createElement("div", { className: "p-1 border-b border-grey-darkest border-solid" },
            React.createElement("strong", null, title)),
        React.createElement("div", { className: "flex flex-wrap px-1" }, data.map(function (_a, index) {
            var title = _a.title, description = _a.description;
            return (React.createElement("span", { key: "si-" + index, className: "mr-4 mb-1" },
                React.createElement("strong", null,
                    title,
                    ":\u00A0"), "" + (Array.isArray(description) ? description.join(', ') : description)));
        }))));
};
