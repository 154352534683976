import React, { useState } from 'react';
import { Tooltip, Button, Table, Spin, Empty, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IQualityDocumentsProps, IDocumentsProps } from '../../../../utils/types';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { DownloadOutlined } from '@ant-design/icons';

const QualityDocs: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const getAllDocuments = async () => {
        setLoading(true);

        try {
            SalesOrderStore.quality_documents.map((docs: IQualityDocumentsProps) => {
                docs.documents.map((doc: IDocumentsProps) => {
                    getDocument(doc.url, doc.file_name);
                });
            });
            setLoading(false);
            message.success('Documents downloaded');
        } catch (err) {
            setLoading(false);
            message.error(`Error - Get All Documents: ${err}`);
        }
    };

    const getDocument = async (fileUrl: any, file_name: string) => {
        setLoading(true);
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                const blobUrl = window.URL.createObjectURL(xmlHttp.response);
                const e = document.createElement('a');
                e.href = blobUrl;
                e.download = file_name;
                document.body.appendChild(e);
                e.click();
                document.body.removeChild(e);
            }
        };
        xmlHttp.responseType = 'blob';
        xmlHttp.open('GET', fileUrl, true);
        xmlHttp.send(null);
        setLoading(false);
    };

    const columns: ColumnProps<IQualityDocumentsProps>[] = [
        {
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name',
            // width: '10%',
        },
        {
            title: 'Documents',
            dataIndex: 'documents',
            key: 'documents',
            // align: 'right',
            render: (text, record) =>
                loading ? (
                    <Spin spinning={loading} className="ml-5" />
                ) : (
                    <>
                        {record.documents.length > 0 && (
                            <>
                                {record.documents.map((docs: IDocumentsProps) => {
                                    return (
                                        <div key={`Doc${docs.id}`}>
                                            <a
                                                className="underline"
                                                onClick={() => {
                                                    getDocument(docs.url, docs.file_name);
                                                }}
                                            >
                                                <Tooltip placement="right" title="Download document">
                                                    {docs.document_type + '  '}
                                                    <DownloadOutlined style={{ fontSize: '18px' }} />
                                                </Tooltip>
                                            </a>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                ),
        },
    ];

    return (
        <Table<IQualityDocumentsProps>
            rowKey={record => record.product_zid.toString()}
            pagination={false}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.quality_documents}
            scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
            footer={() => (
                <div className="flex justify-end mt-2 items-end" key="downloadAll">
                    {loading ? (
                        <Spin spinning={loading} className="mr-5" />
                    ) : (
                        <Button
                            onClick={() => {
                                getAllDocuments();
                            }}
                        >
                            Download All
                        </Button>
                    )}
                </div>
            )}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span style={{ color: '#000' }}>Sorry... This order does not have documents.</span>
                        }
                    />
                ),
            }}
        />
    );
};

export default QualityDocs;
