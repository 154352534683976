var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button, Table } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { OrderPreview } from './IntakeOuttake';
import PurchaseOrderStore from '../Stores/PurchaseOrderStore';
export var PreviousIntakePurchaseOrder = observer(function (_a) {
    var dataSource = _a.dataSource;
    var _b = useState(false), ButtonPrintOut = _b[0], setButtonPrintOut = _b[1];
    var templateName = 'reports/purchase_order_intake_printout.html';
    var columns = [
        {
            title: 'Created',
            dataIndex: 'created_time',
            render: function (text, record) { return (React.createElement("span", null, moment(record.created_time).format('MMM Do hh:mm a'))); },
        },
        {
            title: '',
            render: function (_, record) { return (React.createElement(Button, { onClick: function () {
                    typeof record.purchase_order_item === 'object' &&
                        PurchaseOrderStore.deleteIntake(record.id, record.purchase_order_item.purchase_order);
                }, danger: true, type: "primary" }, "Delete")); },
        },
        {
            title: '',
            render: function (text, intake) { return (React.createElement(Button, { type: "primary", onClick: function () {
                    PurchaseOrderStore.printPurchaseOrderIntake(intake.id);
                } }, "Print")); },
        },
        {
            title: '',
            render: function (text, intake) { return (React.createElement(Button, { onClick: function () {
                    return PurchaseOrderStore.setActivePreview("/staff/purchase-orders-intakes/" + intake.id + "/html/?template=" + templateName);
                } }, "View Printout")); },
        },
        {
            title: '',
            render: function (text, intake) { return (React.createElement(Button, { loading: ButtonPrintOut, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setButtonPrintOut(true);
                                return [4 /*yield*/, IntakeStore.downloadPdf("/staff/purchase-orders-intakes/" + intake.id + "/pdf/?template=" + templateName, 'PrintOut.pdf').finally(function () { return setButtonPrintOut(false); })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }, "Download PDF")); },
        },
        {
            title: 'Z Process?',
            render: function (text, intake) { return React.createElement("div", null, intake.z_process ? 'Yes' : 'No'); },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-around" },
            React.createElement("div", { className: "bg-white" }, dataSource && React.createElement(Table, { rowKey: "id", columns: columns, dataSource: dataSource, pagination: false })),
            PurchaseOrderStore.activePreview && React.createElement(OrderPreview, { html: PurchaseOrderStore.activePreview }))));
});
