import * as React from "react";
import { Divider, Select, Spin } from "antd";
import { useState } from "react";
import axios from "axios";
import { NOHO_API_URL } from "constants/app";

let busy = null;

export const Leads = ({...props}) => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = (params) => {

        setLoading(true);

        axios.get(`${NOHO_API_URL}/leads/?limit=10&offset=0`, {
            params: params,
            headers: {
                authorization: `Token ${props?.token}`
            }
        }).then((response) => {
            setData(response.data.results);
        }).finally(() => {
            setLoading(false);
        })
    };

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            optionKeyProp={"value"}
            size={'small'}
            style={{width: '100%'}}
            dropdownMatchSelectWidth={false}
            placeholder="Search"
            loading={loading}
            notFoundContent={loading ? <Spin size="small" spinning/> : "No results found"}
            onSearch={(name) => {

                setLoading(true);

                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    'search': name,
                };

                busy = setTimeout(() => fetchData(params), 300)
            }}
            {...props}
        >
            {data.map(entity => (
                <Select.Option key={entity.id}
                               first_name={entity.first_name}
                               last_name={entity.last_name}
                               company={entity.company}
                               phone={entity.phone || entity.mobile}
                               email={entity.email}
                               zip_code={entity.zip_code}
                >
                    <span> {entity.email} - {entity.last_name} {entity.first_name}</span>
                    <Divider type="vertical"/>
                    ({entity.company})

                </Select.Option>
            ))}
        </Select>
    )
};




