import { getCorrectValue } from '../../../utils/vendorBox';
export var buildVendorBoxSpecifications = function (item) {
    var in_house_requirements = item.in_house_requirements, additional_requirements = item.additional_requirements, shipping_options = item.shipping_options, shipping_requirements = item.shipping_requirements, shipping_name = item.shipping_name, contact = item.contact, account = item.account;
    var specifications = [
        {
            title: 'Order Requirements',
            data: [
                {
                    title: 'In House Requirements',
                    description: in_house_requirements,
                },
                {
                    title: 'Additional Requirements',
                    description: additional_requirements,
                },
                {
                    title: 'Shipping Options',
                    description: getCorrectValue(shipping_options),
                },
                {
                    title: 'Shipping Requirements',
                    description: shipping_requirements,
                },
            ],
        },
        {
            title: 'Shipping Info',
            data: [
                {
                    title: 'Shipping Info',
                    description: getCorrectValue(shipping_name),
                },
                {
                    title: 'Carrier',
                    description: getCorrectValue(account !== null ? account.carrier : ''),
                },
                {
                    title: 'Shipping method',
                    description: getCorrectValue(account !== null ? account.shipping_method : ''),
                },
                {
                    title: 'Shipping account number',
                    description: getCorrectValue(account !== null ? account.shipping_account_number : ''),
                },
            ],
        },
    ];
    if (contact) {
        var first_name = contact.first_name, last_name = contact.last_name, email = contact.email, phone = contact.phone, shipping_street = contact.shipping_street, shipping_city = contact.shipping_city, shipping_code = contact.shipping_code, shipping_country = contact.shipping_country;
        var data = [
            {
                title: 'Full name',
                description: first_name + " " + last_name,
            },
            {
                title: 'Email',
                description: email,
            },
        ];
        phone && data.push({ title: 'Phone', description: phone });
        shipping_street && data.push({ title: 'Ship. street', description: shipping_street });
        shipping_city && data.push({ title: 'Ship. city', description: shipping_city });
        shipping_code && data.push({ title: 'Ship. code', description: shipping_code });
        shipping_country && data.push({ title: 'Ship. country', description: shipping_country });
        specifications.push({ title: 'Contact', data: data });
    }
    return specifications;
};
export var buildOrderInfo = function (data) {
    var name = data.name, status = data.status, partner_ship_date = data.partner_ship_date, revised_ship_date = data.revised_ship_date, intake_status = data.intake_status, shipping_status = data.shipping_status, hardware_required = data.hardware_required, domestic_process_needed = data.domestic_process_needed, po_files = data.po_files, zoho_id = data.zoho_id, payment_terms = data.payment_terms, required_ship_date = data.required_ship_date;
    return {
        salesOrder: {
            name: name,
            status: status,
            partner_ship_date: partner_ship_date,
            intake_status: intake_status,
            shipping_status: shipping_status,
            revised_ship_date: revised_ship_date,
            domestic_process_needed: domestic_process_needed,
            hardware_required: hardware_required,
            zoho_id: zoho_id,
            payment_terms: payment_terms,
            required_ship_date: required_ship_date,
        },
        poFiles: po_files,
    };
};
