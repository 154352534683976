var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { InputNumber, Table, Checkbox, Tag, Tooltip, Button } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { Select } from 'antd/es';
import InventoryStore from '../Stores/InventoryStore';
var InputValue = observer(function (_a) {
    var record = _a.record, receiveOrShip = _a.receiveOrShip;
    var _b = useState(0), value = _b[0], setValue = _b[1];
    useEffect(function () {
        setValue(0);
        IntakeStore.updateLineItemQuantity(record.id, 0);
    }, [IntakeStore.receiveOrShip]);
    useEffect(function () {
        if (IntakeStore.lineItemQuantities[record.id]) {
            setValue(IntakeStore.lineItemQuantities[record.id]);
        }
    }, [IntakeStore.lineItemQuantities]);
    return (React.createElement("div", null,
        IntakeStore.receiveOrShip === 'ship' && (React.createElement(InputNumber, { value: value, max: record.stock, placeholder: record.stock, defaultValue: record.stock, disabled: record.stock == 0, onChange: function (value) {
                if (value != undefined) {
                    IntakeStore.updateLineItemQuantity(record.id, value);
                    setValue(value);
                }
            } })),
        IntakeStore.receiveOrShip !== 'ship' && (React.createElement(InputNumber, { value: value, 
            // max={record.quantity_remaining_to_receive}
            placeholder: "" + record.quantity_remaining_to_receive, defaultValue: record.quantity_remaining_to_receive, disabled: record.quantity_remaining_to_receive == 0, onChange: function (qty) {
                if (qty != undefined) {
                    IntakeStore.updateLineItemQuantity(record.id, qty);
                    setValue(qty);
                }
            } }))));
});
export var LineItemQuantity = observer(function (_a) {
    var isVendorNumber = _a.isVendorNumber;
    var updateDocumentStatus = 0;
    if (IntakeStore.salesOrderLineItems.length > 0) {
        updateDocumentStatus = IntakeStore.salesOrderLineItems[0].id;
    }
    useEffect(function () {
        getDocumentsStatus();
    }, [updateDocumentStatus]);
    var _b = useState(IntakeStore.salesOrderLineItems), dataSource = _b[0], setDataSource = _b[1];
    useEffect(function () {
        setDataSource(IntakeStore.salesOrderLineItems);
    }, [IntakeStore.salesOrderLineItems]);
    var getDocumentsStatus = function () {
        if (IntakeStore.salesOrderLineItems.length > 0) {
            var salesOrderId = IntakeStore.salesOrderLineItems[0].sales_order;
            IntakeStore.getDocumentsPacketStatus(salesOrderId)
                .then(function (response) {
                IntakeStore.salesOrderLineItems = IntakeStore.salesOrderLineItems.map(function (lineItem) {
                    var document_packet_status = false;
                    var product_zid = lineItem.product_zid;
                    var find_product = response.data.find(function (product) { return product.product_id === product_zid; });
                    if (find_product) {
                        document_packet_status = find_product.status;
                    }
                    return __assign(__assign({}, lineItem), { document_packet_status: document_packet_status });
                });
            })
                .catch(function () {
                // it is better not to show the user
                // message.error('Error getting documents status from Vendor App.', 6);
            });
        }
    };
    var remaining = IntakeStore.receiveOrShip === 'receive' ? 'quantity_remaining_to_receive' : 'quantity_remaining_to_ship';
    var toolTipTitle = function (processes) {
        return processes.join(', ');
    };
    function onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }
    var columns = [
        {
            title: 'Line Item',
            // dataIndex: 'name',
            render: function (record) {
                return (React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', flexDirection: 'column' } },
                    React.createElement("div", { style: { fontWeight: 'bold' } }, record.name),
                    React.createElement("div", { style: { fontSize: '11px' } }, record.product_description)));
            },
            sorter: function (a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_part_ship_date',
            sorter: function (a, b) { return Number(new Date(a.required_part_ship_date)) - Number(new Date(b.required_part_ship_date)); },
        },
        {
            title: 'Domestic Process Needed',
            render: function (record) {
                return (React.createElement("div", null, record.domestic_process_needed ? (React.createElement(Tooltip, { placement: "topLeft", title: toolTipTitle(record.domestic_processes ? record.domestic_processes : []) },
                    React.createElement(Tag, { color: "orange", className: "text-center mb-2" }, "Domestic Finish"))) : ('')));
            },
        },
        {
            title: 'Hardware Required',
            // dataIndex: 'hardware_required',
            render: function (record) {
                return (React.createElement("div", null, record.hardware_required ? (React.createElement(Tooltip, { placement: "topLeft", title: toolTipTitle(record.hardwares_required ? record.hardwares_required : []) },
                    React.createElement(Tag, { color: "volcano", className: "text-center" }, "Hardware Needed"))) : ('')));
            },
        },
        {
            title: 'Awarded Vendor',
            render: function (record) {
                return (React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', flexDirection: 'column' } },
                    React.createElement("div", { style: { fontSize: '11px' } }, record.awarded_vendor)));
            },
            sorter: function (a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Docs',
            dataIndex: 'document_packet_status',
            render: function (text, record) {
                return record.document_packet_status ? (React.createElement("h1", { className: "text-green-600 font-bold text-lg" }, "\u2713")) : (React.createElement("h1", { className: "text-red-600 font-bold" }, "\u2717"));
            },
        },
        {
            title: 'Qty Ordered',
            width: 120,
            dataIndex: 'quantity',
        },
        {
            title: 'In Z',
            width: 100,
            dataIndex: 'quantity_in_z_outtakes',
        },
        {
            title: 'In PB',
            width: 100,
            dataIndex: 'stock',
            render: function (text) {
                return React.createElement("div", null, text > 0 ? React.createElement(Tag, { color: "red" }, text) : 0);
            },
        },
        {
            title: IntakeStore.receiveOrShip === 'receive' ? 'Quantity Remaining' : 'Quantity Remaining (Customer)',
            dataIndex: remaining,
            render: function (t, record) {
                return record[remaining];
            },
        },
        {
            title: IntakeStore.receiveOrShip === 'receive' ? 'Quantity Received' : 'Quantity Shipped',
            render: function (t, record) {
                return (React.createElement("div", null,
                    React.createElement(InputValue, { record: record })));
            },
        },
    ];
    if (IntakeStore.receiveOrShip === 'receive') {
        columns = __spreadArray(__spreadArray([], columns, true), [
            {
                title: 'Storage Location',
                render: function (record) {
                    return (React.createElement("div", { className: 'flex flex-col pt-8' },
                        React.createElement(Select, { placeholder: 'Select a storage', onChange: function (storage) {
                                IntakeStore.saveStorageForIDItem(record.id, storage);
                            }, value: record.storage_location }, InventoryStore.storages.map(function (storageLocation, index) {
                            return (React.createElement(React.Fragment, null, storageLocation.numbers.map(function (number) {
                                return (React.createElement(Select.Option, { key: index, value: storageLocation.initial +
                                        '-' +
                                        number +
                                        ': ' +
                                        storageLocation.name },
                                    storageLocation.initial + '-' + number,
                                    ": ",
                                    storageLocation.name));
                            })));
                        })),
                        React.createElement(Button, { type: 'link', onClick: function () {
                                IntakeStore.saveStorageForAllItems(record.storage_location);
                            } }, "Apply to all")));
                },
            },
        ], false);
    }
    if (isVendorNumber) {
        columns = __spreadArray(__spreadArray([], columns, true), [
            {
                title: 'Select to intake',
                render: function () {
                    return (React.createElement("div", null,
                        React.createElement(Checkbox, null)));
                },
            },
        ], false);
    }
    return (React.createElement("div", null, IntakeStore.salesOrder && (React.createElement(Table, { rowKey: function (record) { return record.id; }, columns: columns, pagination: false, dataSource: dataSource, onChange: onChange }))));
});
