import React from "react";
import { Button, notification, Popconfirm } from "antd";
import { post } from "@partsbadger/utils";
import { ErrorRender } from "@partsbadger/library";

interface Props {
  module: string;
  id: number | string;
  onFinish: () => void;
}

const SendToZohoButton = (props: Props) => {

  const [isSending, setIsSending] = React.useState(false);

  const handleSendToZoho = () => {
    setIsSending(true);
    post(`/staff/${props.module}/${props.id}/send-to-zoho/`).then(() => {
      props.onFinish();
    }).catch((err) => {
      notification.error({
        duration: 10,
        message: "Error sending to Zoho",
        description: <ErrorRender error={err} />,
        placement: "top"
      });
    }).finally(() => setIsSending(false));
  };


  return (
    <Popconfirm
      placement="topLeft"
      title={"Are you sure to create this record in Zoho?"}
      onConfirm={handleSendToZoho}
      okText="Yes"
      cancelText="No"
    >
      <Button type="default" style={{
        color: "grey"
      }} loading={isSending}>Send to Zoho?</Button>
    </Popconfirm>
  );
};

export default SendToZohoButton;
