var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { get } from '../utils/api';
import IntakeStore from '../Stores/IntakeStore';
import IntakeOuttakeFilters from '../Componets/IntakeOuttake/IntakeOuttakeFilters';
import SalesOrderForm from './SalesOrderForm';
import VendorBoxOrders from './VendorBoxOrders';
import RMA from './RMA';
import { useQueryParameters } from '@partsbadger/utils';
import { Button, DatePicker, message } from 'antd';
import InventoryStore from '../Stores/InventoryStore';
import PurchaseOrderStore from '../Stores/PurchaseOrderStore';
import PurchaseOrderLineItems from './PurchaseOrders/PurchaseOrderLineItems';
import { hasPermission } from 'quotes-frontend/src/components/User';
var RangePicker = DatePicker.RangePicker;
var ParamTypes;
(function (ParamTypes) {
    ParamTypes["salesOrder"] = "sales-order";
    ParamTypes["outTake"] = "out-take";
    ParamTypes["rma"] = "rma";
    ParamTypes["purchase_orders"] = "purchase-orders";
})(ParamTypes || (ParamTypes = {}));
export var IntakeOuttake = observer(function (props) {
    var _a;
    var _b = useState(false), ButtonKPI = _b[0], setButtonKPI = _b[1];
    var _c = useState(false), isGettingKPI = _c[0], setIsGettingKPI = _c[1];
    var _d = useState(false), ButtonBox = _d[0], setButtonBox = _d[1];
    var _e = useState(null), KPIRange = _e[0], setKPIRange = _e[1];
    var _f = useState(null), KPIs = _f[0], setKPIs = _f[1];
    var _g = useState(null), rmaData = _g[0], setRmaData = _g[1];
    var query_params = useQueryParameters();
    var _h = useState('Sales order'), vendorType = _h[0], setVendorType = _h[1];
    var paramType = vendorType === 'Vendor box'
        ? ParamTypes.outTake
        : vendorType === 'RMA'
            ? ParamTypes.rma
            : vendorType === 'Purchase Orders'
                ? ParamTypes.purchase_orders
                : ParamTypes.salesOrder;
    var sales_order_id = query_params.get(paramType);
    useEffect(function () {
        function loadData() {
            return __awaiter(this, void 0, void 0, function () {
                var _a, id, res, err_1, id, err_2;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!sales_order_id) return [3 /*break*/, 11];
                            _a = vendorType;
                            switch (_a) {
                                case 'Vendor box': return [3 /*break*/, 1];
                                case 'Sales order': return [3 /*break*/, 2];
                                case 'RMA': return [3 /*break*/, 3];
                                case 'Purchase Orders': return [3 /*break*/, 7];
                            }
                            return [3 /*break*/, 11];
                        case 1:
                            {
                                IntakeStore.getOuttakeLineItems(sales_order_id);
                                return [3 /*break*/, 11];
                            }
                            _b.label = 2;
                        case 2:
                            {
                                IntakeStore.getSalesOrder(sales_order_id, IntakeStore.receiveOrShip);
                                IntakeStore.getPurchaseOrderForSalesOrder(sales_order_id);
                                return [3 /*break*/, 11];
                            }
                            _b.label = 3;
                        case 3:
                            _b.trys.push([3, 6, , 7]);
                            id = query_params.get('rma');
                            if (!id) return [3 /*break*/, 5];
                            return [4 /*yield*/, InventoryStore.get(id, 'RMA')];
                        case 4:
                            res = _b.sent();
                            setRmaData(res);
                            _b.label = 5;
                        case 5: return [3 /*break*/, 11];
                        case 6:
                            err_1 = _b.sent();
                            message.error("Error; " + err_1);
                            return [3 /*break*/, 11];
                        case 7:
                            _b.trys.push([7, 10, , 11]);
                            id = query_params.get('purchase-orders');
                            if (!id) return [3 /*break*/, 9];
                            PurchaseOrderStore.clearData();
                            return [4 /*yield*/, PurchaseOrderStore.get(id)];
                        case 8:
                            _b.sent();
                            _b.label = 9;
                        case 9: return [3 /*break*/, 11];
                        case 10:
                            err_2 = _b.sent();
                            message.error("Error; " + err_2);
                            return [3 /*break*/, 11];
                        case 11: return [2 /*return*/];
                    }
                });
            });
        }
        loadData();
    }, [sales_order_id]);
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: 'intake-outtake-filters flex flex-row items-center justify-center' },
            React.createElement(IntakeOuttakeFilters, { onChangeVendorType: setVendorType, vendorType: vendorType, receiveOrShip: IntakeStore.receiveOrShip, location: IntakeStore.location, salesOrder: IntakeStore.salesOrder, handleClickIntakeOutake: function (value) {
                    IntakeStore.setReceiveOrShip(value);
                }, handleChangeLocation: function (value) {
                    IntakeStore.setLocation(value);
                }, handleChangeRadio: function (value) {
                    console.log(value);
                } })),
        React.createElement("div", { className: "forms" },
            hasPermission('intake.view_salesorderintake') && (React.createElement(React.Fragment, null,
                vendorType === 'Vendor box' && React.createElement(VendorBoxOrders, { vendorOrderId: sales_order_id }),
                vendorType === 'Sales order' && (React.createElement(SalesOrderForm, { isTester: props.isTester, vendorType: vendorType })),
                vendorType === 'RMA' && rmaData && (React.createElement(RMA, { isTester: props.isTester, rmaItem: rmaData, onUpdate: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var res;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!rmaData.id) return [3 /*break*/, 2];
                                    return [4 /*yield*/, InventoryStore.get(rmaData.id, 'RMA')];
                                case 1:
                                    res = _a.sent();
                                    setRmaData(res);
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); } })))),
            hasPermission('finance.add_purchaseorderintake') &&
                vendorType === 'Purchase Orders' &&
                ((_a = PurchaseOrderStore.PurchaseOrder) === null || _a === void 0 ? void 0 : _a.id) && (React.createElement(PurchaseOrderLineItems, { vendorType: vendorType, onUpdate: function (po_id) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, PurchaseOrderStore.get(po_id)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }))),
        React.createElement("div", { className: 'kpis' },
            React.createElement("div", { className: "mt-4" },
                React.createElement(RangePicker, { onChange: function (dates, dateStrings) { return setKPIRange(dateStrings); } }),
                React.createElement(Button, { disabled: KPIRange === null, loading: isGettingKPI, onClick: function () {
                        setIsGettingKPI(true);
                        get("/staff/reports/sr_kpi/?from=" + KPIRange[0] + "&to=" + KPIRange[1])
                            .then(function (res) { return setKPIs(res); })
                            .finally(function () { return setIsGettingKPI(false); });
                    } }, "Get KPIs"),
                KPIs && (React.createElement("div", null,
                    React.createElement("p", null,
                        "Items In: ",
                        KPIs.data.items_in),
                    React.createElement("p", null,
                        "Items Out: ",
                        KPIs.data.items_out),
                    React.createElement("p", null,
                        "Average Out to RSD: ",
                        KPIs.data.average_out_to_rsd),
                    React.createElement("p", null,
                        "Late Shipping Cost Sum: ",
                        KPIs.data.late_shipping_cost_sum.toLocaleString())))))));
});
