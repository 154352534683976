import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Select, Spin } from "antd";


let busy = null;

export const SalesOrders = ({token, url, ...props}) => {

    const [loading, setLoading] = useState([]);
    const [orders, setOrders] = useState([]);


    const getOrders = (params = {}) => {

        setLoading(true);

        axios.get(url, {
            params: params,
            headers: {
                'Authorization': `Token ${token}`
            },
        }).then(response => {
            setOrders(response.data.results);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    };
    useEffect(() => {
        getOrders();
    }, []);


    return (
        <Select
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            optionKeyProp={"value"}
            size={'small'}
            style={{width: 300}}
            dropdownMatchSelectWidth={false}
            placeholder="Search Sales Order"
            notFoundContent={loading ? <Spin size="small"/> : null}
            onSearch={(name) => {
                if (busy) {
                    clearTimeout(busy);
                }
                let params = {
                    'search': name,
                };

                if ('in_zoho' in props) {
                    params['in_zoho'] = props.in_zoho
                }

                setLoading(true);

                busy = setTimeout(() => getOrders(params), 400);
            }}
            {...props}
        >
            {orders.map(sales_order => (
                <Select.Option key={sales_order.id}
                               entity={sales_order}>{sales_order.subject}</Select.Option>
            ))}
        </Select>
    )


};


export const SalesOrdersLineItems = ({line_items = [], ...props}) => {

    const items = line_items?.filter(item => item?.name !== "CNC Part");

    return (<div>
            <Select
                showSearch
                allowClear
                labelInValue
                filterOption={false}
                optionKeyProp={"value"}
                size={'small'}
                style={{minWidth: 120}}
                dropdownMatchSelectWidth={false}
                placeholder="Search Sales Order"
                {...props}
            >
                {items.map(item => (
                    <Select.Option key={item.id}
                                   entity={item}>{item.name}</Select.Option>
                ))}
            </Select>
            <Button style={{
                float: 'right'
            }} size={"small"} onClick={() => {
                const options = items.map(item => {
                    return {'key': item.id, 'label': item.name}
                });

                props.onChange(options);

            }}>Select All</Button>
        </div>

    )


};

