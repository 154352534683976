import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox } from 'antd';
var CheckboxChecklist = function (props) {
    var _a, _b, _c;
    var depends_on = props.question.depends_on;
    var selection = '';
    if (depends_on) {
        selection = props.form.getFieldValue("confirm-" + depends_on.toString());
    }
    if (props.question.visible || selection === 'Yes') {
        return (React.createElement(Form.Item, null, props.getFieldDecorator("confirm-" + props.question.question, {
            rules: [{ required: props.question.required, message: 'Required' }],
            initialValue: (_c = (_b = (_a = props.question) === null || _a === void 0 ? void 0 : _a.answer) === null || _b === void 0 ? void 0 : _b.confirm) !== null && _c !== void 0 ? _c : false,
        })(React.createElement(Checkbox, { checked: props.form.getFieldValue("confirm-" + props.question.question), onChange: props.onChange }))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
export default CheckboxChecklist;
