var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Checkbox, Divider, message, PageHeader, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { TableSalesOrderItems } from './components/TableSalesOrderItems';
import CreditMemoForm from './Forms/CreditMemoForm';
import SalesOrderStore from './Stores/SalesOrderStore';
import CreditMemosStore from './Stores/CreditMemosStore';
import { useHistory } from 'react-router-dom';
import { TableCreditMemos } from './components/TablesCreditMenos';
import _ from 'lodash';
var TabPane = Tabs.TabPane;
var CreditMemoTool = function (props) {
    var _a, _b;
    var history = useHistory();
    var _c = useState('1'), activeTab = _c[0], setActiveTab = _c[1];
    var _d = useState([]), selectedItems = _d[0], setSelectedItems = _d[1];
    var isIndeterminate = false;
    function onSubmitted(data) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var payload, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        payload = __assign({ sales_order: props.sales_order_id, account: (_b = (_a = SalesOrderStore.order) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.id }, data);
                        return [4 /*yield*/, CreditMemosStore.create(props.sales_order_id, payload)];
                    case 1:
                        response = _c.sent();
                        if (!response) return [3 /*break*/, 3];
                        return [4 /*yield*/, CreditMemosStore.list(props.sales_order_id)];
                    case 2:
                        _c.sent();
                        message.success('Credit memos created.');
                        setActiveTab('1');
                        setSelectedItems([]);
                        _c.label = 3;
                    case 3: return [2 /*return*/, response];
                }
            });
        });
    }
    function getOrder() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, SalesOrderStore.getOrderById(props.sales_order_id)];
                    case 1:
                        _a.sent();
                        if (SalesOrderStore.order) {
                            isIndeterminate =
                                selectedItems.length > 0 && selectedItems.length < _.size(SalesOrderStore.order.line_items);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        getOrder();
        CreditMemosStore.list(props.sales_order_id);
    }, []);
    /**
     * Add or remove items from the selected array list
     */
    var handleSelect = function (order_item_id, quantity, selected) {
        if (selected) {
            var selected_item = {
                sales_order_line_item: order_item_id,
                quantity: quantity,
            };
            setSelectedItems(__spreadArray(__spreadArray([], selectedItems, true), [selected_item], false));
        }
        else {
            setSelectedItems(selectedItems.filter(function (item) { return item.sales_order_line_item !== order_item_id; }));
        }
    };
    /**
     * Select all items
     */
    var handleSelectAll = function (e) {
        if (e.target.checked && SalesOrderStore.order) {
            var selected = _.map(SalesOrderStore.order.line_items).map(function (line_items) {
                return { sales_order_line_item: line_items.id, quantity: line_items.quantity, selected: true };
            });
            setSelectedItems(selected);
        }
        else {
            setSelectedItems([]);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { onBack: function () { return history.goBack(); }, title: React.createElement("div", { className: 'flex flex-row' },
                React.createElement("div", { className: 'font-bold mx-2' }, "Sales Order:"),
                React.createElement("div", { className: 'font-light' }, (_a = SalesOrderStore.order) === null || _a === void 0 ? void 0 :
                    _a.id,
                    " - ", (_b = SalesOrderStore.order) === null || _b === void 0 ? void 0 :
                    _b.name)) }),
        React.createElement(Tabs, { defaultActiveKey: "1", activeKey: activeTab, onTabClick: function (key) { return setActiveTab(key); } },
            React.createElement(TabPane, { tab: "List", key: "1" },
                React.createElement(TableCreditMemos, { credit_memos: CreditMemosStore.credit_memos })),
            React.createElement(TabPane, { tab: "New", key: "2" },
                React.createElement("div", { title: 'Enter the information for the new credit memo' },
                    React.createElement(CreditMemoForm, { selectedItems: selectedItems, onSubmitted: onSubmitted }),
                    React.createElement(Divider, { orientation: 'left' }, "Select Order Items"),
                    SalesOrderStore.order && (React.createElement(React.Fragment, null,
                        React.createElement(Checkbox, { checked: selectedItems.length === SalesOrderStore.order.line_items.length, className: 'p-2', indeterminate: isIndeterminate, onChange: handleSelectAll }, "Select All"),
                        React.createElement("div", { className: 'flex flex-row w-full p-4 font-bold', style: { border: '1px solid #d4d4d4' } },
                            React.createElement("div", { className: 'w-1/4' }),
                            React.createElement("div", { className: 'w-1/4' }, "Name"),
                            React.createElement("div", { className: 'w-1/4' }, "Awarded Vendor"),
                            React.createElement("div", { className: 'w-1/4' }, "Quantity")),
                        _.map(SalesOrderStore.order.line_items).map(function (order_line_item, key) {
                            var isSelected = false;
                            selectedItems.map(function (item) {
                                if (item.sales_order_line_item === order_line_item.id) {
                                    isSelected = true;
                                }
                            });
                            return (React.createElement("div", { className: 'p-3', key: key, style: {
                                    backgroundColor: isSelected ? '#F1FAFC' : '',
                                    borderBottom: '1px solid #d4d4d4',
                                    borderLeft: '1px solid #d4d4d4',
                                    borderRight: '1px solid #d4d4d4',
                                } },
                                React.createElement(TableSalesOrderItems, { order_line_item: order_line_item, isSelected: isSelected, handleSelect: function (order_item_id, quantity, selected) {
                                        handleSelect(order_item_id, quantity, selected);
                                    } })));
                        }))))))));
};
export default observer(CreditMemoTool);
