var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Radio, Row, Upload, Spin, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { QuoteStore } from '../../Stores';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
export var VendorRFQSubmissionForm = observer(function (_a) {
    var _b;
    var quote_id = _a.quote_id, loading = _a.loading, onSubmitForm = _a.onSubmitForm, selected_items = _a.selected_items;
    var _c = useState(''), notes = _c[0], setNotes = _c[1];
    var _d = useState('foreign'), rfqType = _d[0], setRFQType = _d[1];
    var _e = useState(false), loadingUpload = _e[0], setLoadingUpload = _e[1];
    var _f = useState(selected_items), selectedItems = _f[0], setSelectedItems = _f[1];
    /**
     * Add or remove items from the selected array list
     */
    var handleSelect = function (key, selected) {
        if (selected) {
            setSelectedItems(__spreadArray(__spreadArray([], selectedItems, true), [key], false));
        }
        else {
            setSelectedItems(selectedItems.filter(function (id) { return id !== key; }));
        }
    };
    /**
     * Select all for bulk update
     */
    var handleSelectAll = function (e) {
        if (e.target.checked) {
            // Get all master products
            var selected = _.map(master_products).map(function (line_items) { return line_items[0].product.master_product; });
            setSelectedItems(selected);
        }
        else {
            setSelectedItems([]);
        }
    };
    var master_products = _.groupBy(QuoteStore.lineItems, function (item) {
        return item.product.master_product;
    });
    var isIndeterminate = selectedItems.length > 0 && selectedItems.length < _.size(QuoteStore.lineItems);
    var content = QuoteStore.masterProductsByPosition.map(function (item, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var isSelected = selectedItems.includes(item.master_product.id);
        return (React.createElement("div", { className: 'fex flex-row border my-4 p-2', key: index },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("span", { style: {
                            float: 'left',
                            marginRight: 10,
                        } },
                        React.createElement(Checkbox, { checked: isSelected, onChange: function (e) {
                                handleSelect(item.master_product.id, e.target.checked);
                            } })),
                    React.createElement("span", { className: 'font-bold' }, item.master_product.name))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" }, item.master_product.step_file ? (React.createElement("div", null,
                        React.createElement("div", null, (_a = item.master_product.step_file) === null || _a === void 0 ? void 0 :
                            _a.filename,
                            " ",
                            item.master_product.step_file.id),
                        React.createElement(Upload.Dragger, { style: {
                                maxWidth: 300,
                                margin: 'auto',
                            }, showUploadList: false, beforeUpload: function (file) {
                                setLoadingUpload(true);
                                QuoteStore.upload3dRedactedFile(item.master_product.step_file.id, file).finally(function () {
                                    QuoteStore.getMasterProducts(quote_id);
                                    setLoadingUpload(false);
                                });
                                return false;
                            } }, item.master_product.step_file.redacted_file ? (React.createElement("div", { className: "flex flex-row justify-center pl-2 pr-2" },
                            React.createElement("div", { className: "flex flex-col" },
                                React.createElement("span", { style: { fontSize: '13px' } },
                                    React.createElement(LegacyIcon, { className: "mr-1", type: 'check', style: {
                                            color: 'green',
                                        } }), (_d = (_c = (_b = item.master_product.step_file.redacted_file) === null || _b === void 0 ? void 0 : _b.split('/')) === null || _c === void 0 ? void 0 : _c.pop()) === null || _d === void 0 ? void 0 :
                                    _d.split('?')[0]),
                                React.createElement("span", { className: "text-green-600 ml-1 font-extrabold" }, "Uploaded!")),
                            React.createElement("div", null,
                                React.createElement(Button, { title: 'Remove redacted file', className: 'link text-red-600', onClick: function (e) {
                                        e.stopPropagation();
                                        setLoadingUpload(true);
                                        QuoteStore.removeRedactedFile(item.master_product.drawing_file.id, {
                                            master_product: item.master_product.id,
                                            file_type: '3d',
                                        }).finally(function () {
                                            QuoteStore.getMasterProducts(quote_id);
                                            setLoadingUpload(false);
                                        });
                                    } },
                                    React.createElement(DeleteOutlined, null))))) : (React.createElement("p", { className: "ant-upload-text", style: { color: 'red' } }, "Drag a redacted 3D file"))))) : null)),
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" }, item.master_product.drawing_file ? (React.createElement(React.Fragment, null,
                        React.createElement("div", null, (_e = item.master_product.drawing_file) === null || _e === void 0 ? void 0 : _e.filename),
                        React.createElement(Upload.Dragger, { style: {
                                maxWidth: 300,
                                margin: 'auto',
                            }, showUploadList: false, beforeUpload: function (file) {
                                setLoadingUpload(true);
                                QuoteStore.upload2dRedactedFile(item.master_product.drawing_file.id, file).finally(function () {
                                    setLoadingUpload(false);
                                    QuoteStore.getMasterProducts(quote_id);
                                });
                                return false;
                            } }, item.master_product.drawing_file.redacted_file ? (React.createElement("div", { className: "flex flex-row justify-center pl-2 pr-2" },
                            React.createElement("div", { className: "flex flex-col" },
                                React.createElement("span", { style: { fontSize: '13px' } },
                                    React.createElement(LegacyIcon, { className: "mr-1", type: 'check', style: {
                                            color: 'green',
                                        } }), (_h = (_g = (_f = item.master_product.drawing_file.redacted_file) === null || _f === void 0 ? void 0 : _f.split('/')) === null || _g === void 0 ? void 0 : _g.pop()) === null || _h === void 0 ? void 0 :
                                    _h.split('?')[0]),
                                React.createElement("span", { className: "text-green-600 ml-1 font-extrabold" }, "Uploaded!")),
                            React.createElement("div", null,
                                React.createElement(Button, { title: 'Remove redacted file', className: 'link text-red-600', onClick: function (e) {
                                        e.stopPropagation();
                                        setLoadingUpload(true);
                                        QuoteStore.removeRedactedFile(item.master_product.drawing_file.id, {
                                            master_product: item.master_product.id,
                                            file_type: '2d',
                                        }).finally(function () {
                                            QuoteStore.getMasterProducts(quote_id);
                                            setLoadingUpload(false);
                                        });
                                    } },
                                    React.createElement(DeleteOutlined, null))))) : (React.createElement("p", { className: "ant-upload-text", style: { color: 'red' } }, "Drag a redacted 2D file"))))) : null)))));
    });
    return (React.createElement(Form, { className: 'm-4', layout: 'vertical' },
        React.createElement(Form.Item, { label: 'RFQ Type', className: 'my-5' },
            React.createElement(Radio.Group, { onChange: function (e) {
                    setRFQType(e.target.value);
                }, value: rfqType },
                React.createElement(Radio, { value: 'domestic' }, "Domestic Vendor RFQ"),
                React.createElement(Radio, { value: 'foreign' }, "Foreign Vendor RFQ"))),
        React.createElement(Form.Item, { label: 'Redacted Files', className: 'my-5' },
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("div", null, ((_b = QuoteStore.masterProductsByPosition) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(Checkbox, { checked: selectedItems.length > 0, style: {
                        marginLeft: 25,
                    }, indeterminate: isIndeterminate, onChange: handleSelectAll }, "Select All")))),
            React.createElement(Spin, { spinning: loadingUpload }, content)),
        React.createElement(Form.Item, { label: 'Notes for Vendor', className: 'my-5' },
            React.createElement(Input.TextArea, { placeholder: 'Notes: Optional', rows: 4, onChange: function (event) {
                    setNotes(event.target.value);
                } })),
        React.createElement("div", { className: 'my-5 text-center' },
            React.createElement(Button, { loading: loading, type: 'primary', onClick: function () {
                    onSubmitForm(rfqType, notes, selectedItems);
                } }, "Submit"))));
});
