var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, notification, Spin, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import moment from 'moment';
import { InputsInformationForm, ItemsTable } from '../components';
import { InputsTotalsForm } from '../components/InputsTotalsForm';
import { observer } from 'mobx-react';
import { ErrorRender, Users } from '@partsbadger/library';
import { hasGroup } from 'quotes-frontend/src/components/User';
var PurchaseOrderEditForm = observer(function (props) {
    var form = props.form;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), change_address = _b[0], setChangeAddress = _b[1];
    var _c = useState(null), category = _c[0], setCategory = _c[1];
    var getFieldDecorator = form.getFieldDecorator;
    var history = useHistory();
    var getPurchaseOrder = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, PurchaseOrderStore.get(id)];
                case 1:
                    _c.sent();
                    if ((_a = PurchaseOrderStore.purchase_order) === null || _a === void 0 ? void 0 : _a.line_items) {
                        PurchaseOrderStore.sync_line_items((_b = PurchaseOrderStore.purchase_order) === null || _b === void 0 ? void 0 : _b.line_items);
                        PurchaseOrderStore.recalculate_totals();
                    }
                    if (PurchaseOrderStore.purchase_order && PurchaseOrderStore.purchase_order.category) {
                        setCategory(PurchaseOrderStore.purchase_order.category);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var approved = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, PurchaseOrderStore.approve_purchase_order(props.purchase_order_id)];
                case 2:
                    _a.sent();
                    notification.success({
                        message: 'Success',
                        description: 'Order successfully approved',
                    });
                    setLoading(false);
                    return [4 /*yield*/, getPurchaseOrder(props.purchase_order_id)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    notification.error({
                        message: 'Error',
                        description: React.createElement(ErrorRender, { error: error_1 }),
                    });
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) {
        e.preventDefault();
        form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                if (PurchaseOrderStore.purchase_order && PurchaseOrderStore.line_items.length > 0) {
                    var payload = {
                        billing_city: values.billing_city,
                        billing_zipcode: values.billing_code,
                        billing_country: values.billing_country,
                        billing_state: values.billing_state,
                        billing_street: values.billing_street,
                        carrier: values.carrier,
                        category: values.category,
                        pb_point_of_contact: values.pb_point_of_contact,
                        due_date: moment(values.due_date).format('YYYY-MM-DD'),
                        line_items: PurchaseOrderStore.line_items,
                        name: 'PO-1',
                        notes: values.notes,
                        payment_terms: values.payment_terms,
                        requisition_number: values.requisition_number,
                        sales_order: values.sales_order ? values.sales_order.key : null,
                        shipping_city: values.change_address ? values.billing_city : values.shipping_city,
                        shipping_zipcode: values.change_address ? values.billing_code : values.shipping_code,
                        shipping_country: values.change_address ? values.billing_country : values.shipping_country,
                        shipping_state: values.change_address ? values.billing_state : values.shipping_state,
                        shipping_street: values.change_address ? values.billing_street : values.shipping_street,
                        ship_method: values.shipping_method,
                        vendor: values.vendor ? values.vendor.key : null,
                        terms_and_conditions: values.terms_and_conditions,
                        review_and_authorized: values.review_and_authorized,
                        discount_type: purchase_order.discount_type,
                        discount: purchase_order.discount ? purchase_order.discount : 0,
                        taxes_rate: purchase_order.taxes_rate ? purchase_order.taxes_rate.toFixed(2) : 0,
                        tax: purchase_order.tax ? purchase_order.tax.toFixed(2) : 0,
                        adjustment: purchase_order.adjustment ? purchase_order.adjustment : 0,
                        shipping_cost: purchase_order.shipping_cost ? purchase_order.shipping_cost : 0,
                        internal_reference: values.internal_reference,
                        owner: values.owner,
                        address: change_address,
                    };
                    setLoading(true);
                    PurchaseOrderStore.patch(payload, props.purchase_order_id)
                        .then(function () {
                        PurchaseOrderStore.sync_line_items();
                        props.onSubmitted();
                    })
                        .catch(function (err) {
                        notification.error({
                            message: React.createElement(ErrorRender, { error: err }),
                            placement: 'topRight',
                        });
                    })
                        .finally(function () { return setLoading(false); });
                }
                else {
                    message.error('You must first add one or more line items');
                }
            }
        });
    };
    useEffect(function () {
        if (props.purchase_order_id) {
            getPurchaseOrder(props.purchase_order_id);
        }
        PurchaseOrderStore.sync_line_items();
    }, [props.purchase_order_id]);
    useEffect(function () {
        var params = new URLSearchParams();
        if (category == 'PartsBadger Production') {
            params.append('category', 'Form31');
        }
        history.push({ search: params.toString() });
    }, [category]);
    if (!PurchaseOrderStore.purchase_order) {
        return React.createElement(Spin, { spinning: true, tip: 'Loading...' });
    }
    var purchase_order = PurchaseOrderStore.purchase_order;
    var sales_order = PurchaseOrderStore.sales_order;
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'flex flex-row pl-5' }, (purchase_order.category === 'PartsBadger Production' || category === 'PartsBadger Production') && (React.createElement("span", { className: 'font-bold' },
            React.createElement(Tag, { color: 'green' }, "Form 31 11/05/2020 Authorized by: Brandon Spenneberg, Ops. Mgr.")))),
        React.createElement("div", { className: 'flex flex-row-reverse' },
            React.createElement("span", { className: 'font-bold' },
                "Order Status:",
                ' ',
                React.createElement(Tag, { color: purchase_order.approved ? 'green' : 'red' }, purchase_order.approved ? 'Approved' : 'Not Aproved'))),
        React.createElement("div", null,
            React.createElement(Spin, { spinning: loading },
                React.createElement(Form, { onSubmit: handleSubmit },
                    React.createElement(InputsInformationForm, { getFieldDecorator: getFieldDecorator, change_address: change_address, setChangeAddress: setChangeAddress, setCategory: setCategory, purchase_order: purchase_order, sales_order: sales_order, onSalesOrderChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, PurchaseOrderStore.get_sales_order(value)];
                                    case 1:
                                        _a.sent();
                                        if (value === '') {
                                            PurchaseOrderStore.removeSalesOrder();
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); }, setZXLineItems: function () {
                            PurchaseOrderStore.set_zx_line_items();
                        } }),
                    React.createElement(ItemsTable, { form: form, line_items: PurchaseOrderStore.line_items, create: false, po_id: props.purchase_order_id, category: category }),
                    React.createElement("div", { className: "flex flex-row p-4" },
                        React.createElement("div", { className: 'w-1/3' },
                            React.createElement(Form.Item, { label: 'Terms and Conditions' }, getFieldDecorator('terms_and_conditions', {
                                initialValue: PurchaseOrderStore.purchase_order
                                    ? PurchaseOrderStore.purchase_order.terms_and_conditions
                                    : '',
                            })(React.createElement(Input.TextArea, { rows: 3 }))),
                            category === 'PartsBadger Production' && (React.createElement(React.Fragment, null,
                                React.createElement(Form.Item, { label: 'Review and Authorized' }, getFieldDecorator('review_and_authorized', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input a name!',
                                        },
                                    ],
                                    initialValue: PurchaseOrderStore.purchase_order
                                        ? PurchaseOrderStore.purchase_order.review_and_authorized
                                        : '',
                                })(React.createElement(Input, { placeholder: 'Input a name' }))))),
                            React.createElement(Form.Item, { label: 'Owner' }, getFieldDecorator('owner', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select a user!',
                                    },
                                ],
                                initialValue: {
                                    key: props.current_user.id,
                                    label: props.current_user.fullname,
                                },
                            })(React.createElement(Users, { users: props.users })))),
                        React.createElement("div", { className: 'w-2/3' },
                            React.createElement(InputsTotalsForm, null))),
                    React.createElement("div", { className: "flex justify-end pt-2" },
                        React.createElement(Button, { type: "default", className: 'mx-2', onClick: function () { return history.replace('/purchase-orders'); } }, "Cancel"),
                        React.createElement(Button, { type: "primary", className: 'mx-4', loading: loading, htmlType: "submit" }, "Save Changes"),
                        hasGroup('Purchase Order Approvers') && purchase_order.approved === false && (React.createElement(Button, { type: "primary", className: 'mx-4', loading: loading, style: { backgroundColor: 'green', borderColor: 'green' }, onClick: approved }, "Approve"))))))));
});
export default Form.create()(PurchaseOrderEditForm);
