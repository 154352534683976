var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox } from 'antd';
import TopicChecklist from './TopicChecklist';
import ChecklistQuestion from './ChecklistQuestion';
var DesignEngineerChecklistQuestion = function (_a) {
    var _b, _c;
    var getFieldDecorator = _a.getFieldDecorator, question = _a.question, value = _a.value, onChange = _a.onChange, cols = _a.cols, form = _a.form, salesRepChecklist = _a.salesRepChecklist, checklistEdit = _a.checklistEdit, checklistQuestions = _a.checklistQuestions, setChecklistQuestions = _a.setChecklistQuestions;
    var answerSalesRepChecklist = function () {
        var _a;
        if (Array.isArray(salesRepChecklist)) {
            var questionFind = salesRepChecklist.find(function (el) { return el.question === question.question; });
            if (questionFind) {
                var value_1 = (_a = questionFind === null || questionFind === void 0 ? void 0 : questionFind.answer) === null || _a === void 0 ? void 0 : _a.confirm;
                return value_1 === true ? 'Yes' : value_1 === false ? 'No' : value_1;
            }
        }
        return '';
    };
    return (React.createElement(Row, { gutter: 4 },
        React.createElement(Col, __assign({}, cols),
            React.createElement(TopicChecklist, { value: question.question })),
        React.createElement(Col, __assign({}, cols),
            React.createElement("h1", { className: "ml-4 mt-3" }, answerSalesRepChecklist())),
        React.createElement(Col, __assign({}, cols),
            React.createElement(ChecklistQuestion, { question: question, value: value, onChange: onChange, form: form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions, checklistEdit: checklistEdit, getFieldDecorator: getFieldDecorator })),
        React.createElement(Col, __assign({}, cols),
            React.createElement(Form.Item, null, getFieldDecorator("mark-" + question.question, {
                initialValue: (_c = (_b = question === null || question === void 0 ? void 0 : question.answer) === null || _b === void 0 ? void 0 : _b.mark) !== null && _c !== void 0 ? _c : false,
            })(React.createElement(Checkbox, { checked: form.getFieldValue("mark-" + question.question) }))))));
};
export default DesignEngineerChecklistQuestion;
