var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Button, Radio } from 'antd';
import { AddressForm, AddressItem } from '../Addresses';
import { observer } from 'mobx-react';
import ContactStore from './stores/ContactStore';
import { RightOutlined } from '@ant-design/icons';
var AddressEdit = observer(function (props) {
    var _a, _b;
    var _c = useState(false), isChangingOfAddress = _c[0], setIsChangingOfAddress = _c[1];
    var _d = useState(), selectedAddress = _d[0], setSelectedAddress = _d[1];
    var _e = useState(false), isAddingNewAddress = _e[0], setIsAddingNewAddress = _e[1];
    var _f = useState(), editBillingAddress = _f[0], setEditBillingAddress = _f[1];
    var updateOrderBillingAddress = function () {
        var _a, _b;
        if (selectedAddress) {
            var payload = {
                name: selectedAddress.name ? selectedAddress.name : '',
                street: selectedAddress.street ? selectedAddress.street : '',
                city: selectedAddress.city ? selectedAddress.city : '',
                state: selectedAddress.state ? selectedAddress.state : '',
                country: ((_a = selectedAddress.country) === null || _a === void 0 ? void 0 : _a.code) ? (_b = selectedAddress.country) === null || _b === void 0 ? void 0 : _b.code : '',
                zip_code: selectedAddress.zip_code ? selectedAddress.zip_code : '',
            };
            props.onSelectAddress(payload);
        }
    };
    var handleSaveAddress = function (payload, address_id) {
        if (address_id) {
            ContactStore.patchAddress(props.contact_id, address_id, payload).then(function () {
                var _a;
                ContactStore.getContactById(props.contact_id);
                props.onSelectAddress({
                    name: payload.name ? payload.name : '',
                    street: payload.street ? payload.street : '',
                    city: payload.city ? payload.city : '',
                    state: payload.state ? payload.state : '',
                    country: ((_a = payload.country) === null || _a === void 0 ? void 0 : _a.code) ? payload.country.code : '',
                    zip_code: payload.zip_code ? payload.zip_code : '',
                });
                setIsChangingOfAddress(false);
            });
        }
        else {
            ContactStore.saveAddress(props.contact_id, payload).then(function () {
                var _a;
                ContactStore.getContactById(props.contact_id);
                props.onSelectAddress({
                    name: payload.name ? payload.name : '',
                    street: payload.street ? payload.street : '',
                    city: payload.city ? payload.city : '',
                    state: payload.state ? payload.state : '',
                    country: ((_a = payload.country) === null || _a === void 0 ? void 0 : _a.code) ? payload.country.code : '',
                    zip_code: payload.zip_code ? payload.zip_code : '',
                });
                setIsChangingOfAddress(false);
            });
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", null, isChangingOfAddress ? (React.createElement("div", null,
            props.addresses && ((_a = props.addresses) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement("div", { className: "font-thin" }, "Select a an address below")), (_b = props.addresses) === null || _b === void 0 ? void 0 :
            _b.map(function (address) {
                var _a;
                return (React.createElement("div", { className: "py-2 w-full", key: address.id },
                    React.createElement("div", { className: "flex flex-col justify-center p-4 space-y-2", style: {
                            border: 'solid 1px #d9d9d9',
                        } },
                        React.createElement("div", { className: "flex flex-row content-center", onClick: function () { return setSelectedAddress(address); }, style: { cursor: 'pointer' } },
                            React.createElement("div", { className: "flex flex-wrap content-center" },
                                React.createElement(Radio, { name: "address", checked: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.id) === address.id })),
                            React.createElement("div", { className: "flex flex-col" },
                                React.createElement("div", null,
                                    React.createElement("strong", null, address.name)),
                                React.createElement("div", null,
                                    address.street,
                                    ", ",
                                    address.city,
                                    ","),
                                React.createElement("div", null,
                                    address.state,
                                    " ",
                                    address.zip_code,
                                    " ", (_a = address.country) === null || _a === void 0 ? void 0 :
                                    _a.code))),
                        (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.id) === address.id && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "text-center" },
                                React.createElement(Button, { className: "w-full lg:w-1/2", type: "primary", onClick: function () {
                                        updateOrderBillingAddress();
                                        setIsChangingOfAddress(false);
                                    } }, "Use this address")),
                            React.createElement("div", { className: "text-center" },
                                React.createElement(Button, { className: "w-full lg:w-1/2", type: "default", onClick: function () {
                                        setEditBillingAddress(address);
                                        setIsChangingOfAddress(false);
                                        setIsAddingNewAddress(true);
                                    } }, "Edit Address")))))));
            }),
            React.createElement("div", { className: "flex flex-row w-full justify-between p-2", style: {
                    border: 'solid 1px #d9d9d9',
                    cursor: 'pointer',
                }, onClick: function () {
                    setEditBillingAddress(undefined);
                    setIsChangingOfAddress(false);
                    setIsAddingNewAddress(true);
                } },
                React.createElement(Button, { type: "link" }, "Add a New Address"),
                React.createElement(RightOutlined, { className: "p-2" })))) : isAddingNewAddress ? (React.createElement("div", { className: "py-2" },
            React.createElement(AddressForm, { defaultAddressName: props.account_name, addressType: props.type, address: editBillingAddress, handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        handleSaveAddress(payload, editBillingAddress === null || editBillingAddress === void 0 ? void 0 : editBillingAddress.id);
                        return [2 /*return*/];
                    });
                }); }, handleClose: function () {
                    setIsChangingOfAddress(true);
                    setIsAddingNewAddress(false);
                } }))) : (React.createElement("div", null, props.currentAddress && props.addresses && props.addresses.length === 0 ? (React.createElement("div", { className: "flex flex-row w-full justify-between p-2", style: {
                border: 'solid 1px #d9d9d9',
                cursor: 'pointer',
            }, onClick: function () {
                setEditBillingAddress(undefined);
                setIsChangingOfAddress(false);
                setIsAddingNewAddress(true);
            } },
            React.createElement(Button, { type: "link" }, "Add a New Address"),
            React.createElement(RightOutlined, { className: "p-2" }))) : (React.createElement("div", { className: "py-2 flex flex-row" },
            props.currentAddress && React.createElement(AddressItem, { address: props.currentAddress }),
            React.createElement(Button, { type: 'link', onClick: function () {
                    setIsChangingOfAddress(true);
                    setIsChangingOfAddress(true);
                } }, "Change"))))))));
});
export default AddressEdit;
