import React from 'react';
import { Button, Col, PageHeader, Popconfirm, Row, Spin } from 'antd';
import { observer } from 'mobx-react';

import BusinessRuleForm from '../../components/BusinessRules/BusinessRuleForm';
import { BusinessRuleStore } from '../../components/BusinessRules/Stores/BusinessRuleStore';
import { useHistory, useParams } from 'react-router-dom';

const BusinessRuleEditPage = () => {
    const { id } = useParams<{ id: string }>();

    const history = useHistory();

    const load = async (id: string) => {
        await BusinessRuleStore.getById(id);
    };

    React.useEffect(() => {
        load(id);
    }, [id]);

    React.useEffect(() => {
        if (rule?.model_name) {
            BusinessRuleStore.getStructure(rule.model_name);
            BusinessRuleStore.getAllRules(rule.model_name);
        }
    }, [BusinessRuleStore.rule?.model]);

    const rule = BusinessRuleStore.rule;

    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    onBack={() => {
                        history.push('/business-rules/');
                    }}
                    title="Edit Rule"
                    extra={
                        <Popconfirm
                            placement="topLeft"
                            title={'Do you want to delete the rule permanently?'}
                            onConfirm={() => {
                                if (rule?.id) {
                                    BusinessRuleStore.delete(rule?.id);
                                    history.push('/business-rules/');
                                }
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>Delete</Button>
                        </Popconfirm>
                    }
                />
            </Col>
            <Col span={24}>{rule ? <BusinessRuleForm rule={rule} /> : <Spin />}</Col>
        </Row>
    );
};

export default observer(BusinessRuleEditPage);
