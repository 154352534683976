import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DownloadOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Collapse, Row, Col, Card, notification, Typography } from 'antd';
import { QuoteStore } from '../Stores';
import { WholeQuoteAdjustmentForm } from '../Forms';
import { ApplicationCostItems, QuoteActions, QuoteNotes, QuoteNotifications, QuoteStatus, QuoteTotals, RFQView, } from '../components';
import { MasterProductDetail } from '../components/MasterProduct/index';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
import { AdditionalRequirements, ErrorRender } from '@partsbadger/library';
var Text = Typography.Text, Paragraph = Typography.Paragraph;
var ButtonGroup = Button.Group;
var LayoutV1 = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    var quote = props.quote, selectedLineItems = props.selectedLineItems, setSelectedLineItems = props.setSelectedLineItems, setShowBulkEdit = props.setShowBulkEdit, line_items = props.line_items, account_detail = props.account_detail, setPreviewQuote = props.setPreviewQuote, additional_requirements = props.additional_requirements, setAdditionalRequirements = props.setAdditionalRequirements, setReorderingAppCost = props.setReorderingAppCost;
    return (React.createElement(React.Fragment, null,
        QuoteStore.inProgress && (React.createElement("div", { className: 'quote-notes text-center py-4 max-w-md m-auto' },
            React.createElement(WholeQuoteAdjustmentForm, { quote: quote }))),
        React.createElement("div", { className: 'text-center' },
            React.createElement(Button, { type: 'link', className: "mx-2", onClick: function () {
                    window.open(BASE_URL + "/redirect?to=" + BASE_URL + "/staff/quotes/" + quote.id + "/download-all/");
                } },
                React.createElement(DownloadOutlined, null),
                " Download All Files")),
        ((_a = quote.rfq) === null || _a === void 0 ? void 0 : _a.id) && (React.createElement("div", { className: 'm-4' },
            React.createElement(Collapse, { accordion: true },
                React.createElement(Collapse.Panel, { header: React.createElement(React.Fragment, null,
                        "RFQ ", (_b = quote.rfq) === null || _b === void 0 ? void 0 :
                        _b.id), key: "1" },
                    React.createElement("div", null,
                        React.createElement(RFQView, { rfq_id: (_c = quote.rfq) === null || _c === void 0 ? void 0 : _c.id })))),
            React.createElement("b", null, "Other Notes:"),
            " ", (_d = quote.rfq) === null || _d === void 0 ? void 0 :
            _d.other_notes)),
        React.createElement("div", { className: 'quote-actions' }, quote.editable && QuoteStore.inProgress && (React.createElement(ButtonGroup, { className: 'add-button' },
            React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'check-square' }), title: 'Shortcut + A', style: { display: selectedLineItems.length === 0 ? 'inline' : 'none' }, onClick: function () {
                    setSelectedLineItems(QuoteStore.lineItems.map(function (p) { return p.id; }));
                } }, "Select All"),
            React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'form' }), title: 'Shortcut + E', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, onClick: setShowBulkEdit }, "Bulk Edit"),
            React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'paper-clip' }), title: 'Shift + R', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, loading: QuoteStore.loading, onClick: function () {
                    var payload = {
                        quoted_products: selectedLineItems,
                    };
                    QuoteStore.recalculatePrices(payload).catch(function (err) {
                        notification.error({
                            key: 'form-errors',
                            duration: 8,
                            message: 'One of the line items has an error',
                            description: React.createElement(ErrorRender, { error: err }),
                            placement: 'top',
                            maxCount: 1,
                        });
                    });
                } }, "Recalculate Prices"),
            React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'minus-square' }), title: 'Escape', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, onClick: function () { return setSelectedLineItems([]); } }, "Clear Selection")))),
        React.createElement("div", { className: 'quote-line-items' }, line_items === null || line_items === void 0 ? void 0 : line_items.map(function (item, index) {
            return (React.createElement(Card, { key: "mp-" + item.master_product.name + "-" + index, style: {
                    padding: 0,
                }, bodyStyle: {
                    padding: 0,
                } },
                React.createElement(MasterProductDetail, { count: index, position: item.position, key: item.master_product.id, master_product: item.master_product, setSelectedLineItems: function (items) { return setSelectedLineItems(items); }, selectedLineItems: selectedLineItems })));
        })),
        React.createElement(Row, { className: 'quote-additional-cost' },
            React.createElement(Col, { xs: 24 },
                React.createElement("div", { className: 'my-2' }, account_detail && (React.createElement(ApplicationCostItems, { quote: quote, account: account_detail, setReorderingAppCost: setReorderingAppCost }))))),
        React.createElement(Row, { className: 'quote-additional-cost' },
            React.createElement(Col, { xs: 12 },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'font-bold' }, "Additional Requirements"),
                    React.createElement("div", { className: 'mt-4', style: {
                            maxWidth: 800,
                        } },
                        React.createElement(AdditionalRequirements
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            requirements: QuoteStore.additional_requirements, onChange: function (values) {
                                //@ts-ignore
                                setAdditionalRequirements(values);
                                QuoteStore.updateQuote(quote.id, {
                                    custom_requirements: values,
                                });
                            }, 
                            //@ts-ignore
                            value: additional_requirements, hide_extras: false, disabled: !quote.editable })))),
            React.createElement(Col, { xs: 12 }, ((_e = props.user) === null || _e === void 0 ? void 0 : _e.group) && ((_f = props.user) === null || _f === void 0 ? void 0 : _f.group.includes('Design Engineers')) && (React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'font-bold' }, "Internal Quoting Notes"),
                React.createElement("div", { className: 'mt-4', style: {
                        maxWidth: 800,
                    } },
                    React.createElement(Paragraph, { className: 'text-left', style: {
                            margin: 0,
                        }, editable: {
                            onChange: function (notes) {
                                var payload = {
                                    internal_quoting_notes: notes,
                                };
                                setLoading(true);
                                QuoteStore.updateQuote(quote.id, payload)
                                    .then(function () {
                                    setLoading(false);
                                })
                                    .catch(function () {
                                    setLoading(false);
                                });
                            },
                        } }, quote === null || quote === void 0 ? void 0 : quote.internal_quoting_notes)))))),
        React.createElement(Row, { className: 'quote-notes p-4' },
            React.createElement(Col, { xs: 24 },
                React.createElement(QuoteNotes, null))),
        React.createElement(Row, { className: 'flex space-between flex-wrap quote-totals' },
            React.createElement("div", { className: "md:w-3/4" },
                React.createElement(QuoteNotifications, { height: 600 })),
            React.createElement("div", { className: "md:w-1/4" },
                React.createElement(QuoteTotals, { quote: quote }))),
        React.createElement(QuoteStatus, { quote: quote, user: props.user, isQuoteLoading: QuoteStore.loading }),
        React.createElement(QuoteActions, { loading: QuoteStore.loading, quote: quote, handlePreviewQuote: setPreviewQuote })));
};
export default observer(LayoutV1);
