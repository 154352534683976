var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Button, Card, Dropdown, Empty, InputNumber, List, Menu, notification } from 'antd';
import IntakeStore from '../../Stores/IntakeStore';
import PurchaseOrderStore from '../../Stores/PurchaseOrderStore';
import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { PreviousIntakePurchaseOrder } from '../../Componets';
import { observer } from 'mobx-react';
export var PurchaseOrderLineItems = observer(function (_a) {
    var _b, _c, _d, _e;
    var onUpdate = _a.onUpdate;
    var history = useHistory();
    var _f = useState({}), selectedValue = _f[0], setSelectedValue = _f[1];
    var _g = useState(false), loading = _g[0], setIsLoading = _g[1];
    function handleChange(id, value) {
        var _selected = selectedValue;
        _selected[id] = value;
        setSelectedValue(__assign({}, _selected));
    }
    function handleSubmit(value) {
        var _a;
        setIsLoading(true);
        var payload = Object.keys(selectedValue)
            .filter(function (key) { return selectedValue[key] != undefined; })
            .map(function (key) {
            var _a;
            return {
                purchase_order_item: key,
                quantity: (_a = selectedValue[key]) !== null && _a !== void 0 ? _a : 0,
            };
        });
        if ((_a = PurchaseOrderStore.PurchaseOrder) === null || _a === void 0 ? void 0 : _a.id) {
            PurchaseOrderStore.intake(PurchaseOrderStore.PurchaseOrder.id, payload)
                .then(function () {
                var _a;
                notification.success({
                    message: 'OK',
                    description: 'Intake successfully',
                });
                if ((_a = PurchaseOrderStore.PurchaseOrder) === null || _a === void 0 ? void 0 : _a.id)
                    onUpdate(PurchaseOrderStore.PurchaseOrder.id);
                setSelectedValue({});
            })
                .finally(function () { return setIsLoading(false); });
        }
    }
    function handleInTakeOrOutTakeAll(value) {
        var _intakeAll = {};
        PurchaseOrderStore.PurchaseOrder.line_items.map(function (i) {
            if (i.id) {
                _intakeAll[i.id] = i.remaining_quantity;
            }
        });
        setSelectedValue(__assign({}, _intakeAll));
    }
    var cancelLineItem = function (po_item_id, po_id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, PurchaseOrderStore.cancel_line_item(po_id, { purchase_order_item: po_item_id })];
                case 1:
                    _a.sent();
                    onUpdate(po_id);
                    return [2 /*return*/];
            }
        });
    }); };
    return React.createElement(React.Fragment, null,
        React.createElement(Card, { title: "Purchase Order:", className: "flex flex-col w-full justify-center", bordered: false, style: { overflow: 'auto' } },
            React.createElement("div", { className: 'm-4 text-center' },
                React.createElement(Button, { onClick: handleInTakeOrOutTakeAll },
                    IntakeStore.receiveOrShip === 'receive' ? 'Intake' : 'Outtake',
                    " All Remaining"),
                React.createElement(Button, { className: 'ml-2', type: 'primary', onClick: handleSubmit, loading: loading }, "Submit")),
            React.createElement("div", { key: PurchaseOrderStore.PurchaseOrder.id, className: "flex flex-col xl:flex-row" },
                React.createElement(Card, { title: "PO Information:", className: "mr-5 mb-5", style: { width: '25vw' } },
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Name"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.name)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Vendor"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder &&
                            typeof PurchaseOrderStore.PurchaseOrder.vendor === 'object'
                            ? (_b = PurchaseOrderStore.PurchaseOrder.vendor) === null || _b === void 0 ? void 0 : _b.name
                            : '')),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Requisition number"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.requisition_number)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Carrier"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.carrier)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Ship method"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.ship_method)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Due date"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.due_date)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "PB point of contact"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.pb_point_of_contact)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Sales order"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder &&
                            typeof ((_c = PurchaseOrderStore.PurchaseOrder) === null || _c === void 0 ? void 0 : _c.sales_order) === 'object'
                            ? (_e = (_d = PurchaseOrderStore.PurchaseOrder) === null || _d === void 0 ? void 0 : _d.sales_order) === null || _e === void 0 ? void 0 : _e.name
                            : '')),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Category"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.category)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Payment terms"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.payment_terms))),
                React.createElement(List, { header: React.createElement("div", { className: "flex flex-row justify-between font-extrabold text-base" },
                        React.createElement("div", { className: "w-40 text-center" }, "Name"),
                        React.createElement("div", { className: "w-40 text-center" }, "Status"),
                        React.createElement("div", { className: "w-48 text-center" }, "Quantity"),
                        React.createElement("div", { className: "w-48 text-center" }, "Quantity Remaining"),
                        React.createElement("div", { className: "w-40 text-center" }, "Quantity Received"),
                        React.createElement("div", { className: "w-40 text-center" }, "Actions")), style: { width: '80vw' }, bordered: true, dataSource: PurchaseOrderStore.PurchaseOrder.line_items, itemLayout: "vertical", className: "mb-auto", locale: {
                        emptyText: (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement("span", { style: { color: '#000' } }, "Sorry... This purchase order doesn't have line items.") })),
                    }, renderItem: function (item) {
                        var _a;
                        return (React.createElement(List.Item, { key: item === null || item === void 0 ? void 0 : item.id, className: "flex flex-row justify-between" },
                            React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "w-40 text-left inline-grid" },
                                    React.createElement("div", null,
                                        React.createElement("strong", null, item.name)),
                                    React.createElement("div", null, item.description)),
                                React.createElement("div", { className: "w-40 text-center" }, item.status),
                                React.createElement("div", { className: "w-48 text-center" }, item.quantity),
                                React.createElement("div", { className: "w-48 text-center" }, item.remaining_quantity),
                                React.createElement("div", { className: "w-48 text-center" }, item.id && item.status !== 'Canceled' && (React.createElement(React.Fragment, null,
                                    React.createElement(InputNumber, { value: (_a = selectedValue[item.id]) !== null && _a !== void 0 ? _a : undefined, onChange: function (value) {
                                            if (item.id)
                                                handleChange(item.id, value);
                                        } })))),
                                React.createElement("div", { className: "w-48 text-center" },
                                    React.createElement("div", { className: 'flex flex-col' },
                                        React.createElement(Dropdown, { overlay: React.createElement(Menu, null,
                                                React.createElement(Menu.Item, { onClick: function () {
                                                        history.push("/purchase-orders/" + PurchaseOrderStore.PurchaseOrder.id + "/");
                                                    } },
                                                    React.createElement(EditOutlined, null),
                                                    " Edit Item"),
                                                React.createElement(Menu.Item, { onClick: function () {
                                                        if (item.id && PurchaseOrderStore.PurchaseOrder.id)
                                                            cancelLineItem(item.id, PurchaseOrderStore.PurchaseOrder.id);
                                                    } },
                                                    React.createElement(CloseOutlined, null),
                                                    " Cancel Item")) },
                                            React.createElement("span", { className: 'link' },
                                                "Actions ",
                                                React.createElement(DownOutlined, null))))))));
                    } })),
            React.createElement("div", { className: "previous-history" },
                React.createElement(PreviousIntakePurchaseOrder, { dataSource: PurchaseOrderStore.previousIntakes }))));
});
export default PurchaseOrderLineItems;
