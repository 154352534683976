import React, { useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Form, Input, notification, Select, Spin, Tag } from 'antd';
import { get, post } from '../../shared';
import { ErrorRender } from '@partsbadger/library';
import { useHistory, useParams } from 'react-router-dom';
import { OrderLineItemProps } from '@partsbadger/types/lib/SalesOrderInterfaces';

const { TextArea } = Input;

type IOrderIdParams = {
    id: string | undefined;
};

type ItemSelect = {
    key: string;
    label: string;
};

interface PropsSelectItems {
    line_items: OrderLineItemProps[];
    onChange?: (options: ItemSelect[]) => void;
}

const SalesOrdersLineItems = ({ line_items, ...props }: PropsSelectItems) => {
    return (
        <div>
            <Select
                showSearch
                allowClear
                labelInValue
                filterOption={false}
                mode="multiple"
                style={{ minWidth: 120 }}
                dropdownMatchSelectWidth={false}
                placeholder="Search and select one or more items"
                {...props}
            >
                {line_items.map((item: OrderLineItemProps) => (
                    <Select.Option key={item.id} label={item.name}>
                        <div className={'flex flex-row'}>
                            <span className={'mx-4'}>{item.name}</span>
                            {item.sample_approval_status === 'Approved' && <Tag color="green">Approved</Tag>}
                            {item.sample_approval_status === 'Rejected' && <Tag color="red">Rejected</Tag>}
                            {item.sample_approval_status === null && <Tag color="cyan">Pending</Tag>}
                        </div>
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

const OrderApprovedSample = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [salesOrdersLineItems, setSalesOrdersLineItems] = useState<Array<OrderLineItemProps>>([]);
    const { id } = useParams<IOrderIdParams>();

    const getLineItems = (sales_order_id: number) => {
        setLoading(true);
        const endpoint = `staff/sales-orders/${sales_order_id}/line-items/`;
        get(endpoint)
            .then((resp: { data: OrderLineItemProps[] }) => {
                setSalesOrdersLineItems(resp.data.filter(i => i.first_article_approval === true));
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (id) getLineItems(Number(id));
    }, [id]);

    const onFinish = (values: any) => {
        const payload: { id: number; sample_approval_status: string; sample_customer_statement: string }[] = [];
        values.selected_items.map((item: { key: number }) => {
            payload.push({
                id: item.key,
                sample_approval_status: values.sample_approval_status,
                sample_customer_statement: values.sample_customer_statement,
            });
        });

        post(`staff/sales-orders/${id}/approve-sample/`, payload)
            .then(() => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'Changes were successfully applied',
                    placement: 'topRight',
                });
                history.goBack();
                form.resetFields();
            })
            .catch((error: any) => {
                notification.error({ message: <ErrorRender error={error} /> });
                setLoading(false);
            });
    };

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Divider>
                <div className={'title'}>Submit Sample Approval/Rejection</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>You can approve or reject samples before starting production.</div>

            <Spin spinning={loading}>
                <Form form={form} name="add-edit-baptism" onFinish={onFinish} layout="vertical">
                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item label={'Sale Order items'} name="selected_items" rules={[{ required: true }]}>
                            <SalesOrdersLineItems line_items={salesOrdersLineItems} />
                        </Form.Item>
                        <Form.Item
                            label={'Select either “Approved” or “Rejected”'}
                            name="sample_approval_status"
                            rules={[{ required: true }]}
                        >
                            <Select>
                                <Select.Option value="Approved">Approved</Select.Option>
                                <Select.Option value="Rejected">Rejected</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item
                            label={'Customer Statement'}
                            name="sample_customer_statement"
                            rules={[{ required: true }]}
                        >
                            <TextArea autoSize />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className={'flex flex-row space-x-4'}>
                            <Button type="default" onClick={() => history.goBack()} className={'mx-4'}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Save Changes
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default OrderApprovedSample;
