import { Col, Row } from 'antd';
import { Price } from '@partsbadger/library';
import React from 'react';
export var OrderTotals = function (_a) {
    var subtotal = _a.subtotal, discount = _a.discount, taxes_rate = _a.taxes_rate, adjustment = _a.adjustment;
    var total = subtotal - discount;
    var tax_value = (total * taxes_rate) / 100;
    var grand_total = total + tax_value;
    if (adjustment) {
        grand_total = grand_total + adjustment;
    }
    return (React.createElement(Row, { className: "record-footer", style: {
            textAlign: 'right',
        } },
        React.createElement(Col, { sm: 24 },
            React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Subtotal: ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: subtotal }))),
            discount > 0 && (React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Discount: ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: discount })))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' },
                        "Sales Tax (",
                        taxes_rate,
                        "%): ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: tax_value }))),
            adjustment > 0 && (React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Adjustment: ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: adjustment })))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Grand Total: ")),
                React.createElement(Col, { md: 6 }, grand_total && React.createElement(Price, { value: grand_total }))))));
};
