import React from 'react';
import { Button, Table } from 'antd';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { IRule } from './types';
import { ColumnsType } from 'antd/es/table';

const TableBusinessRule = () => {
    const history = useHistory();
    const dataSource = BusinessRuleStore.rules;

    const columns: ColumnsType<IRule> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'model',
            dataIndex: 'model_name',
            key: 'model_name',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (t, r) => (
                <Button
                    onClick={e => {
                        BusinessRuleStore.setRule(r);
                        history.push(`/business-rules/${r.id}/`);
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];

    return <Table dataSource={dataSource} columns={columns} />;
};

export default observer(TableBusinessRule);
