import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, InputNumber } from 'antd';
import SalesOrderBatchStore from '../Stores/SalesOrderBatchStore';
import { observer } from 'mobx-react';
var SalesOrderAddBatchForm = observer(function (props) {
    var getFieldDecorator = props.form.getFieldDecorator;
    var handleSubmitBatch = function (e) {
        e.preventDefault();
        props.form.validateFields(['batch_ship_part_date', 'batch_quantity'], function (err, values) {
            if (!err) {
                var batch_ship_part_date = values.batch_ship_part_date.format('MM-DD-YYYY');
                var batch_quantity = values.batch_quantity;
                props.onSubmitted({ batch_ship_part_date: batch_ship_part_date, batch_quantity: batch_quantity });
                props.form.resetFields();
                SalesOrderBatchStore.getQuantity(props.order_item_id);
                SalesOrderBatchStore.getMissingQuantity(SalesOrderBatchStore.original_quantity, SalesOrderBatchStore.total_quantity);
            }
        });
    };
    return (React.createElement("div", { style: { border: '1px solid #E7E6E6' }, className: 'p-4 mt-8' },
        React.createElement(Form, { layout: "inline", onSubmit: handleSubmitBatch },
            React.createElement(Form.Item, { label: "Estimated Ship Date" }, getFieldDecorator('batch_ship_part_date', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a date!',
                    },
                ],
            })(React.createElement(DatePicker, null))),
            React.createElement(Form.Item, { label: "Quantity" }, getFieldDecorator('batch_quantity', {
                rules: [
                    {
                        required: true,
                        message: 'Please input a quantity!',
                    },
                ],
            })(React.createElement(InputNumber, { placeholder: String(SalesOrderBatchStore.missing_quantity), style: { width: '100px' } }))),
            React.createElement(Form.Item, null,
                React.createElement(Button, { type: "primary", htmlType: "submit" }, "Add")))));
});
export default Form.create()(SalesOrderAddBatchForm);
