import React from 'react';

import { observer } from 'mobx-react';
import { QuoteStore } from '@partsbadger/quote-tool/lib/Stores';
import { Materials } from '@partsbadger/quote-tool/lib/components/Select/Materials';

interface Props {
    getFieldDecorator: any;
}

const MaterialsSelect = (props: Props) => {
    return (
        <Materials
            materials={QuoteStore.materials.map(m => {
                return {
                    id: m.id,
                    name: m.name,
                };
            })}
            handleSearch={params => QuoteStore.getMaterials(params)}
            {...props}
        />
    );
};

export default observer(MaterialsSelect);
