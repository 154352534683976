var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { API_ROOT } from '@partsbadger/utils';
//@ts-ignore
var qs = require('qs');
/**
 * @deprecated Use @partsbadger/utils instead
 */
function getHeaders() {
    var token = localStorage.getItem('token');
    if (token) {
        return {
            'Content-Type': 'application/json',
            Authorization: "Token " + token,
        };
    }
    else {
        return {
            'Content-Type': 'application/json',
        };
    }
}
/**
 * @deprecated Use @partsbadger/utils instead
 */
export var get = function (url, params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    return axios.get(API_ROOT + url, __assign({ params: params, paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }, headers: getHeaders() }, options));
};
/**
 * @deprecated Use @partsbadger/utils instead
 */
export var post = function (url, payload, options) {
    if (payload === void 0) { payload = {}; }
    if (options === void 0) { options = {}; }
    return axios.post(API_ROOT + url, payload, __assign({ headers: getHeaders() }, options));
};
export var patch = function (url, payload) {
    if (payload === void 0) { payload = {}; }
    return axios.patch(API_ROOT + url, payload, {
        headers: getHeaders(),
    });
};
/**
 * @deprecated Use @partsbadger/utils instead
 */
export var remove = function (url) {
    return axios.delete(API_ROOT + url, {
        headers: getHeaders(),
    });
};
export var getFile = function (url) {
    return fetch(API_ROOT + url, {
        method: 'GET',
        //@ts-ignore
        headers: getHeaders(),
    });
};
export var printSalesOrder = function (url) {
    return fetch(API_ROOT + url, {
        method: 'POST',
        //@ts-ignore
        headers: getHeaders(),
    });
};
export var get_order_documents = function (url, params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    var headers = {
        Authorization: "Token " + localStorage.getItem('token'),
    };
    return axios.get(API_ROOT + url, { headers: headers });
};
export var update_domestic_order_status = function (url, params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    var headers = {
        Authorization: "Token " + localStorage.getItem('token'),
    };
    return axios.get(API_ROOT + url, { headers: headers });
};
