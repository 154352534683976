var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import api from '../utils/api';
import { TOTAL_PRICE_SHOW_CHECKLIST } from '../utils/utils';
import FileDownload from 'js-file-download';
import { get } from '@partsbadger/utils';
var SalesOrderStore = /** @class */ (function () {
    function SalesOrderStore() {
        var _this = this;
        this.loading = false;
        this.loading_quote_items = false;
        this.isUpdatingLineItem = false;
        this.account = null;
        this.contact = null;
        this.selectedContact = null;
        this.order = null;
        this.order_id = 0;
        this.quoted_line_items = []; // Line Items for the selected quotes
        this.applicationCostItems = [];
        this.order_items = []; // Local Line Items assigned to the order
        this.users = [];
        this.account_manager = [];
        this.addresses = null;
        this.default_shipping_address = null;
        this.default_billing_address = null;
        this.requirements_list = [];
        this.sales_order_notifications = [];
        this.error = null;
        this.quote = null;
        this.industry_use = null;
        this.taxes_rate = 0;
        this.default_requirements = [];
        this.item = [];
        this.poUnacceptableKeywords = [];
        this.afUnacceptableKeywords = [];
        this.creditCard = null;
        this.getAccountById = function (account_id) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/customer/accounts/" + account_id + "/")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        this.getContactById = function (contact_id) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/customer/contacts/" + contact_id + "/").then(function (data) {
                            var _a, _b, _c;
                            _this.contact = data;
                            _this.account = data.account;
                            _this.selectedContact = data;
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            _this.default_shipping_address = _this.contact.default_addresses.shipping_address;
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            _this.default_billing_address = _this.contact.default_addresses.billing_address;
                            if ((_b = (_a = _this.contact) === null || _a === void 0 ? void 0 : _a.industry) === null || _b === void 0 ? void 0 : _b.industry) {
                                _this.industry_use = {
                                    industry: {
                                        key: _this.contact.industry.industry.id.toString(),
                                        label: _this.contact.industry.industry.name,
                                    },
                                    industry_use: {
                                        key: _this.contact.industry.id.toString(),
                                        label: _this.contact.industry.use.name,
                                    },
                                    end_use: {
                                        key: _this.contact.industry.end_use,
                                        label: _this.contact.industry.end_use,
                                    },
                                };
                            }
                            _this.addresses = (_c = _this.contact) === null || _c === void 0 ? void 0 : _c.addresses;
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, api.requests.get("/customer/contacts/" + contact_id + "/additional-requirements-documents/").then(function (data) {
                                _this.default_requirements = data.requirements
                                    ? data.requirements.map(function (r) {
                                        return {
                                            key: r.requirement.id,
                                            label: r.requirement.name,
                                            value: r.value,
                                            files: r.files,
                                            type: r.requirement.type,
                                        };
                                    })
                                    : [];
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getTaxesByContactAndZipCode = function (contact_id, zip_code) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/customer/contacts/" + contact_id + "/tax-rate/?zip_code=" + zip_code).then(function (data) {
                            _this.taxes_rate = (data === null || data === void 0 ? void 0 : data.estimated_combined_rate) || 0;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getAdditionalRequirements = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/customer/additional-requirements/").then(function (data) {
                            _this.requirements_list = data.results;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        makeObservable(this, {
            // Observables
            loading: observable,
            loading_quote_items: observable,
            isUpdatingLineItem: observable,
            account: observable,
            contact: observable,
            selectedContact: observable,
            order: observable,
            order_id: observable,
            quoted_line_items: observable,
            applicationCostItems: observable,
            order_items: observable,
            users: observable,
            account_manager: observable,
            addresses: observable,
            default_shipping_address: observable,
            default_billing_address: observable,
            requirements_list: observable,
            sales_order_notifications: observable,
            error: observable,
            quote: observable,
            industry_use: observable,
            taxes_rate: observable,
            default_requirements: observable,
            item: observable,
            poUnacceptableKeywords: observable,
            afUnacceptableKeywords: observable,
            creditCard: observable,
            // Actions
            setLoading: action,
            setOrder: action,
            setAccount: action,
            setQuote: action,
            setOrderItems: action,
            setLineItemSampleQuantity: action,
            refactoringOfQuoteItems: action,
            addMultipleOrderItem: action,
            addOrderItem: action,
            updateOrderItem: action,
            updateOrderItem3D: action,
            updateOrderItemStepFile: action,
            updateOrderItem2D: action,
            removeOrderItem: action,
            getQuotes: action,
            getQuote: action,
            getUsers: action,
            setQuotedLineItems: action,
            appendApplicationCostItems: action,
            setApplicationCostItems: action,
            getQuoteLineItems: action,
            RemoveQuoteLineItemsFromSpecificQuote: action,
            sendToReview: action,
            saveOrCompleteSalesOrder: action,
            getOrCreateSalesOrder: action,
            getAccountById: action,
            getContactByEmail: action,
            getContactById: action,
            getTaxesByContactAndZipCode: action,
            getAdditionalRequirements: action,
            saveAddress: action,
            updateAddress: action,
            getOrderById: action,
            addRemoteOrderItem: action,
            addMultipleRemoteOrderItem: action,
            addRemoteOrderItemFormAdditionalRequirement: action,
            updateRemoteOrderItem: action,
            updateRemoteOrderItemWithOutPartnerShipDate: action,
            updateOrderLineItemsTagsLocal: action,
            updateOrderProcessesLocal: action,
            updateLineItemHardwareDomesticLocal: action,
            updateRemoteMultipleItems: action,
            updateRemoteOrderItemLocal: action,
            removeRemoteOrderItem: action,
            updateRemoteOrder: action,
            downloadAllFiles: action,
            uploadRedactedFile: action,
            update: action,
            sendToProduction: action,
            getQuotePDF: action,
            sendQuoteToZoho: action,
            analyze_3d_file: action,
            upload2dOr3dFile: action,
            getNotifications: action,
            sendNotification: action,
            getMarginLifetimeQuote: action,
            updateSalesOrderChecklist: action,
            updateSalesOrderSteps: action,
            getOrderProcessingChecklist: action,
            getChecklistType: action,
            getChecklistsOrder: action,
            getSessionUser: action,
            updateAccountManager: action,
            getTotalPriceOrder: action,
            isBigOrder: action,
            getUnacceptableKeywords: action,
            getVendorQualificationsFromVendorApp: action,
            sendCreditCard: action,
            patchOrderDraft: action,
            sendToReviewStaff: action,
            update_order_item: action,
            sendToSalesRepresentative: action,
            update_sales_order_field: action,
            // Computed
            available_quoted_line_items: computed,
            available_application_costs_items: computed,
            availableItemsCount: computed,
            pendingItemsToAddCount: computed,
            doWeNeed3DFiles: computed,
            doWeNeed2DFiles: computed,
            doWeNeedRedactedFiles: computed,
            getLeadItem: computed,
        });
    }
    SalesOrderStore.prototype.setLoading = function (loading) {
        this.loading = loading;
    };
    SalesOrderStore.prototype.setOrder = function (data) {
        this.order = data;
        if (data) {
            this.order_id = data.id;
            this.setOrderItems(data.line_items);
        }
        this.loading = false;
    };
    SalesOrderStore.prototype.setAccount = function (account) {
        this.account = account;
    };
    SalesOrderStore.prototype.setQuote = function (quote) {
        this.quote = quote;
    };
    SalesOrderStore.prototype.setOrderItems = function (order_items) {
        this.order_items = order_items.sort(function (a, b) { return a.id - b.id; });
    };
    SalesOrderStore.prototype.setBatchShipments = function (record, batch_shipments) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.order_items = this.order_items.map(function (current) {
                            if (record.id === current.id) {
                                return __assign(__assign({}, current), { batch_shipments: batch_shipments });
                            }
                            return current;
                        });
                        payload = __assign(__assign({}, record), { batch_shipments: batch_shipments, split_into_batch_shipments: false });
                        return [4 /*yield*/, this.updateRemoteOrderItem(payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.setLineItemSampleQuantity = function (record, sample) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.order_items = this.order_items.map(function (current) {
                            if (record.id === current.id) {
                                return __assign(__assign({}, current), { quantity_sample_required: sample });
                            }
                            return current;
                        });
                        payload = __assign(__assign({}, record), { quantity_sample_required: sample });
                        return [4 /*yield*/, this.updateRemoteOrderItem(payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.refactoringOfQuoteItems = function (_quote_items) {
        var new_items = [];
        _quote_items.map(function (new_item) {
            var _a;
            var _quote_has_product_id = undefined;
            if (new_item.quote_has_product && new_item.quote_has_product._parent_id) {
                _quote_has_product_id = new_item.quote_has_product._parent_id;
            }
            else if (new_item.quote_has_product) {
                _quote_has_product_id = (_a = new_item.quote_has_product) === null || _a === void 0 ? void 0 : _a.id;
            }
            var _item = {
                name: new_item.name,
                product_description: new_item.product_description,
                quote_has_product: _quote_has_product_id,
                quantity: new_item.quantity,
                unit_price: new_item.unit_price,
                total_price: Number(new_item.quantity) * Number(new_item.unit_price),
                required_part_ship_date: new_item.required_part_ship_date || null,
                required_partner_ship_date: new_item.required_partner_ship_date || null,
            };
            new_items.push(_item);
        });
        return new_items;
    };
    SalesOrderStore.prototype.addMultipleOrderItem = function (order_items) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.addMultipleRemoteOrderItem(this.refactoringOfQuoteItems(order_items))];
                    case 1:
                        _b.sent();
                        if (this.order) {
                            this.order_items = [];
                            (_a = this.order) === null || _a === void 0 ? void 0 : _a.line_items.map(function (order_item) {
                                _this.order_items = __spreadArray([order_item], _this.order_items, true);
                            });
                        }
                        this.loading = false;
                        return [2 /*return*/, true];
                }
            });
        });
    };
    SalesOrderStore.prototype.addOrderItem = function (new_item) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _quote_has_product_id, item;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.order_items = [];
                        _quote_has_product_id = undefined;
                        if (new_item.quote_has_product && new_item.quote_has_product._parent_id) {
                            _quote_has_product_id = new_item.quote_has_product._parent_id;
                        }
                        else if (new_item.quote_has_product) {
                            _quote_has_product_id = (_a = new_item.quote_has_product) === null || _a === void 0 ? void 0 : _a.id;
                        }
                        item = {
                            name: new_item.name,
                            product_description: new_item.product_description,
                            quote_has_product: _quote_has_product_id,
                            quantity: new_item.quantity,
                            unit_price: new_item.unit_price,
                            total_price: Number(new_item.quantity) * Number(new_item.unit_price),
                            required_part_ship_date: new_item.required_part_ship_date || null,
                            required_partner_ship_date: new_item.required_partner_ship_date || null,
                        };
                        return [4 /*yield*/, this.addRemoteOrderItem(item)];
                    case 1:
                        _c.sent();
                        if (this.order) {
                            (_b = this.order) === null || _b === void 0 ? void 0 : _b.line_items.map(function (order_item) {
                                _this.order_items = __spreadArray([order_item], _this.order_items, true);
                            });
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    /**
     * Update Line Item in local
     * @param record
     * @param quantity
     */
    SalesOrderStore.prototype.updateOrderItem = function (record, quantity) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.order_items = this.order_items.map(function (current) {
                            if (record.id === current.id) {
                                return __assign(__assign({}, current), { quantity: quantity, total: quantity * current.unit_price });
                            }
                            return current;
                        });
                        payload = __assign(__assign({}, record), { quantity: quantity });
                        return [4 /*yield*/, this.updateRemoteOrderItem(payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateOrderItemBatch = function (record, batches, split) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.order_items = this.order_items.map(function (current) {
                            if (record.id === current.id) {
                                return __assign({}, current);
                            }
                            return current;
                        });
                        payload = __assign(__assign({}, record), { batch_shipments: batches, split_into_batch_shipments: split });
                        return [4 /*yield*/, this.updateRemoteOrderItem(payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update Line Item in local
     * @param record
     * @param production_3d_file_id
     * @param name_3d_file
     */
    SalesOrderStore.prototype.updateOrderItem3D = function (record, production_3d_file_id, name_3d_file) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var payload, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.order_items = this.order_items.map(function (current) {
                            if (record.id === current.id) {
                                return __assign(__assign({}, current), { production_3d_file_id: production_3d_file_id, name_3d_file: name_3d_file });
                            }
                            return current;
                        });
                        payload = {
                            id: record.id,
                            production_3d_file: production_3d_file_id,
                            quantity: record.quantity,
                            unit_price: record.unit_price,
                        };
                        _b = this;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + ((_a = this.order) === null || _a === void 0 ? void 0 : _a.id) + "/update-item/", payload)];
                    case 1:
                        _b.order = _c.sent();
                        if (this.order)
                            this.setOrderItems(this.order.line_items);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update Line Item in local
     * @param order_item_id
     * @param production_3d_file
     */
    SalesOrderStore.prototype.updateOrderItemStepFile = function (order_item_id, production_3d_file) {
        this.order_items = this.order_items.map(function (current) {
            if (order_item_id === current.id) {
                return __assign(__assign({}, current), { production_3d_file: production_3d_file });
            }
            return current;
        });
    };
    /**
     * Update Line Item in local
     * @param record
     * @param production_2d_file_id
     * @param name_2d_file
     */
    SalesOrderStore.prototype.updateOrderItem2D = function (record, production_2d_file_id, name_2d_file) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var payload, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.order_items = this.order_items.map(function (current) {
                            if (record.id === current.id) {
                                return __assign(__assign({}, current), { production_2d_file_id: production_2d_file_id, name_2d_file: name_2d_file });
                            }
                            return current;
                        });
                        payload = {
                            id: record.id,
                            production_2d_file: production_2d_file_id,
                            quantity: record.quantity,
                            unit_price: record.unit_price,
                        };
                        _b = this;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + ((_a = this.order) === null || _a === void 0 ? void 0 : _a.id) + "/update-item/", payload)];
                    case 1:
                        _b.order = _c.sent();
                        if (this.order)
                            this.setOrderItems(this.order.line_items);
                        return [2 /*return*/];
                }
            });
        });
    };
    /***
     * Remove line item from local state
     * @param item
     */
    SalesOrderStore.prototype.removeOrderItem = function (item, i) {
        return __awaiter(this, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.order_items.splice(i, 1);
                        items = this.order_items.map(function (item) {
                            return item;
                        });
                        this.order_items = [];
                        this.setOrderItems(items);
                        return [4 /*yield*/, this.removeRemoteOrderItem(item)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.getQuotes = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests
                            .get("/staff/quotes/", {
                            params: {
                                search: search,
                            },
                        })
                            .then(function (data) {
                            _this.order = data.results;
                        })
                            .finally(function () { return (_this.loading = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.getQuote = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/quotes/" + quote_id + "/").finally(function () { return (_this.loading = false); })];
                    case 1:
                        response = _a.sent();
                        this.appendApplicationCostItems(quote_id, response.additional_requirements);
                        this.quote = response;
                        return [2 /*return*/, response];
                }
            });
        });
    };
    SalesOrderStore.prototype.getUsers = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests
                            .get("/staff/users/", {
                            params: params,
                        })
                            .then(function (data) {
                            runInAction(function () {
                                params.search === 'Account Managers'
                                    ? (_this.account_manager = data.results)
                                    : (_this.users = data.results);
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.setQuotedLineItems = function (data) {
        var _this = this;
        if (!data.length) {
            this.quoted_line_items = [];
            return;
        }
        // By Quote Has Product (Old Method)
        var new_items = data
            .filter(function (item) { return !item.has_dynamic_quantities; })
            .filter(function (item) {
            return !_this.quoted_line_items.find(function (li_item) { return li_item.id === item.id; });
        });
        // Items by Quantity item (New Method)
        // Map Quantity Items as QuoteLineItems to keep sales order tool working
        var new_items_2 = [];
        data.filter(function (item) { return item.has_dynamic_quantities; }).forEach(function (quoted_item) {
            var formatted_items = quoted_item.quote_item_quantities
                .filter(function (item) { return item.displayed; })
                .map(function (quantity_item) {
                return __assign(__assign({}, quoted_item), { id: 'quote_item_' + quantity_item.id, _parent_id: quoted_item.id, quantity: quantity_item.quantity, unit_price: quantity_item.unit_price, total_price: quantity_item.total_price });
            });
            new_items_2 = __spreadArray(__spreadArray([], new_items_2, true), formatted_items, true);
        });
        //remove existing items
        new_items_2 = new_items_2.filter(function (item) {
            return !_this.quoted_line_items.find(function (li_item) { return li_item.id === item.id; });
        });
        this.quoted_line_items = __spreadArray(__spreadArray(__spreadArray([], this.quoted_line_items, true), new_items, true), new_items_2, true);
    };
    SalesOrderStore.prototype.appendApplicationCostItems = function (quote_id, additional_requirements) {
        var _this = this;
        var new_items = additional_requirements.map(function (item) {
            return {
                id: item.id,
                name: item.additional_requirement,
                price: item.price,
                quote: quote_id,
            };
        });
        var filtered__items = new_items.filter(function (new_item) {
            return !_this.applicationCostItems.find(function (current) { return current.id === new_item.id; });
        });
        this.setApplicationCostItems(__spreadArray(__spreadArray([], this.applicationCostItems, true), filtered__items, true));
    };
    /**
     *
     * @param new_items
     */
    SalesOrderStore.prototype.setApplicationCostItems = function (new_items) {
        this.applicationCostItems = new_items;
    };
    /**
     * Add Quoted line items of a specific quote
     */
    SalesOrderStore.prototype.getQuoteLineItems = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading_quote_items = true;
                        return [4 /*yield*/, api.requests
                                .get("/customer/quotes/" + quote_id + "/line-items/")
                                .then(function (data) {
                                _this.setQuotedLineItems(data.results);
                            })
                                .finally(function () { return (_this.loading_quote_items = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Remove line items of a specific quote
     */
    SalesOrderStore.prototype.RemoveQuoteLineItemsFromSpecificQuote = function (quote_id) {
        // Using == instead of === because sometimes is string and other number, yep is crazy
        this.quoted_line_items = this.quoted_line_items.filter(function (item) { return item.quote != quote_id; });
        this.applicationCostItems = this.applicationCostItems.filter(function (item) { return item.quote != quote_id; });
    };
    Object.defineProperty(SalesOrderStore.prototype, "available_quoted_line_items", {
        /**
         * Line items that are in the order yet
         */
        get: function () {
            var _this = this;
            return this.quoted_line_items.filter(function (item) {
                return !_this.order_items.some(function (current) {
                    var _a;
                    return item.id === ((_a = current.quote_has_product) === null || _a === void 0 ? void 0 : _a.id);
                });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesOrderStore.prototype, "available_application_costs_items", {
        /**
         * Line items that are in the order yet
         */
        get: function () {
            var _this = this;
            return this.applicationCostItems.filter(function (item) {
                return !_this.order_items.some(function (current) {
                    return item.name === current.product_description && Number(item.price) === Number(current.unit_price);
                });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesOrderStore.prototype, "availableItemsCount", {
        get: function () {
            return this.quoted_line_items.length + this.applicationCostItems.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesOrderStore.prototype, "pendingItemsToAddCount", {
        get: function () {
            return this.availableItemsCount - this.order_items.length;
        },
        enumerable: false,
        configurable: true
    });
    SalesOrderStore.prototype.sendToReview = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Used only by the customer front-end
                        this.loading = true;
                        return [4 /*yield*/, api.requests.post("/customer/sales-orders/", payload).finally(function () {
                                _this.loading = false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.saveOrCompleteSalesOrder = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Used only by the staff front-end
                        this.loading = true;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + this.order_id + "/", payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.sendToReviewStaff = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Used only by the staff front-end
                        this.loading = true;
                        return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + this.order_id + "/send-to-review/", payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.getOrCreateSalesOrder = function (contact_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/contacts/" + contact_id + "/get-orders-draft/").then(function (response) {
                            _this.order_id = response.id;
                            _this.order = response;
                            _this.setOrderItems(response.line_items);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.getContactByEmail = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests
                            .get("/customer/contacts/?email=" + email + "&exclude_account_payable=true")
                            .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (!((_a = response.results[0]) === null || _a === void 0 ? void 0 : _a.id)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.getContactById((_b = response.results[0]) === null || _b === void 0 ? void 0 : _b.id)];
                                    case 1:
                                        _c.sent();
                                        _c.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.saveAddress = function (contact_id, payload) {
        if (payload === void 0) { payload = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.post("/customer/contacts/" + contact_id + "/addresses/", payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateAddress = function (contact_id, address_id, payload) {
        if (payload === void 0) { payload = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.patch("/customer/contacts/" + contact_id + "/addresses/" + address_id + "/", payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Object.defineProperty(SalesOrderStore.prototype, "doWeNeed3DFiles", {
        get: function () {
            return this.order_items.filter(function (item) { return item.quote_has_product && !item.production_3d_file; }).length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesOrderStore.prototype, "doWeNeed2DFiles", {
        get: function () {
            return this.order_items.filter(function (item) { return item.quote_has_product && !item.production_2d_file; }).length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SalesOrderStore.prototype, "doWeNeedRedactedFiles", {
        get: function () {
            if (!this.order) {
                return false;
            }
            return (this.order.master_products.filter(function (m) {
                var _a, _b;
                return !((_a = m.drawing_file) === null || _a === void 0 ? void 0 : _a.redacted_file) || !((_b = m.step_file) === null || _b === void 0 ? void 0 : _b.redacted_file);
            }).length > 0);
        },
        enumerable: false,
        configurable: true
    });
    SalesOrderStore.prototype.setBatchBlanketReleases = function () {
        if (!this.order) {
            return false;
        }
        if (this.order.shipping_requirements === null) {
            this.order.shipping_requirements = ['Batch/Blanket Releases'];
        }
        else {
            var new_shipping_requirements = __spreadArray([], this.order.shipping_requirements, true);
            new_shipping_requirements.push('Batch/Blanket Releases');
            this.order.shipping_requirements = new_shipping_requirements;
        }
    };
    SalesOrderStore.prototype.removeBatchBlanketReleases = function () {
        var _a;
        if (!this.order) {
            return false;
        }
        if (((_a = this.order.shipping_requirements) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var new_shipping_requirements = __spreadArray([], this.order.shipping_requirements, true);
            this.order.shipping_requirements = new_shipping_requirements.filter(function (value) {
                return value !== 'Batch/Blanket Releases';
            });
        }
    };
    SalesOrderStore.prototype.getOrderById = function (sales_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, api.requests
                                .get("/staff/sales-orders/" + sales_order_id + "/")
                                .then(function (data) {
                                var _a;
                                _this.setOrder(data);
                                if ((_a = data.contact) === null || _a === void 0 ? void 0 : _a.id) {
                                    _this.getContactById(data === null || data === void 0 ? void 0 : data.contact.id);
                                }
                            })
                                .finally(function () { return (_this.loading = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Add remote item based on quoted line item
     * @param payload
     */
    SalesOrderStore.prototype.addRemoteOrderItem = function (payload) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
                            return [2 /*return*/];
                        return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + ((_b = this.order) === null || _b === void 0 ? void 0 : _b.id) + "/add-item/", payload).then(function (data) {
                                _this.order = data;
                            })];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.patchOrderDraft = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = false;
                        return [4 /*yield*/, api.requests
                                .patch("/staff/sales-orders/" + order_id + "/", payload)
                                .then(function (response) {
                                _this.order = response;
                                // this.order_items = response.line_items;
                            })
                                .finally(function () {
                                _this.loading = false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.addMultipleRemoteOrderItem = function (payload) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
                            return [2 /*return*/];
                        return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + ((_b = this.order) === null || _b === void 0 ? void 0 : _b.id) + "/add-multiple-items/", payload).then(function (data) {
                                _this.order = data;
                            })];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Add additional requirement to the sales orders as CNC Part
     * @param payload
     */
    SalesOrderStore.prototype.addRemoteOrderItemFormAdditionalRequirement = function (payload) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = this;
                        return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + ((_a = this.order) === null || _a === void 0 ? void 0 : _a.id) + "/add-item/", __assign({ name: 'CNC Part' }, payload))];
                    case 1:
                        _b.order = _c.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    /**
     * Update a item of a existing Sales Order
     */
    SalesOrderStore.prototype.updateRemoteOrderItem = function (order_line_item) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var payload, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
                            return [2 /*return*/];
                        payload = {
                            id: order_line_item.id,
                            quantity: order_line_item.quantity,
                            unit_price: order_line_item.unit_price,
                            required_part_ship_date: order_line_item.required_part_ship_date,
                            required_partner_ship_date: order_line_item.required_partner_ship_date,
                            vendor_qualifications: order_line_item.vendor_qualifications,
                            hardware_required: order_line_item.hardware_required,
                            domestic_process_needed: order_line_item.domestic_process_needed,
                            domestic_processes: order_line_item.domestic_processes,
                            hardwares_required: order_line_item.hardwares_required,
                            batch_shipments: order_line_item.batch_shipments,
                            quantity_sample_required: order_line_item.quantity_sample_required,
                            split_into_batch_shipments: order_line_item.split_into_batch_shipments,
                        };
                        this.order.line_items = this.order.line_items.map(function (current) {
                            if (order_line_item.id === current.id) {
                                return order_line_item;
                            }
                            return current;
                        });
                        _c = this;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + ((_b = this.order) === null || _b === void 0 ? void 0 : _b.id) + "/update-item/", payload)];
                    case 1:
                        _c.order = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateRemoteOrderItemWithOutPartnerShipDate = function (order_line_item) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var index, payload, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
                            return [2 /*return*/];
                        index = this.order.line_items.findIndex(function (obj) {
                            return obj.id === order_line_item.id;
                        });
                        payload = {
                            id: order_line_item.id,
                            quantity: order_line_item.quantity,
                            unit_price: order_line_item.unit_price,
                            required_part_ship_date: order_line_item.required_part_ship_date,
                            required_partner_ship_date: this.order.line_items[index].required_partner_ship_date,
                            hardware_required: order_line_item.hardware_required,
                            domestic_process_needed: order_line_item.domestic_process_needed,
                            domestic_processes: order_line_item.domestic_processes,
                            hardwares_required: order_line_item.hardwares_required,
                            // vendor_requirements: order_line_item.vendor_requirements,             //Todo Allow to update requirements one by one
                            // vendor_requirement_notes: order_line_item.vendor_requirement_notes,
                        };
                        // this.order.line_items = this.order.line_items.map(current => {
                        //     if (order_line_item.id === current.id) {
                        //         return order_line_item;
                        //     }
                        //     return current;
                        // });
                        _c = this;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + ((_b = this.order) === null || _b === void 0 ? void 0 : _b.id) + "/update-item/", payload)];
                    case 1:
                        // this.order.line_items = this.order.line_items.map(current => {
                        //     if (order_line_item.id === current.id) {
                        //         return order_line_item;
                        //     }
                        //     return current;
                        // });
                        _c.order = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateOrderLineItemsTagsLocal = function (order_line_item, tags, type) {
        var _a;
        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
            return;
        var itemIndex = this.order.line_items.findIndex(function (object) {
            return object.id === (order_line_item === null || order_line_item === void 0 ? void 0 : order_line_item.id);
        });
        if (type === 'domestic') {
            this.order.line_items[itemIndex].domestic_processes = tags;
        }
        if (type === 'hardware') {
            this.order.line_items[itemIndex].hardwares_required = tags;
        }
    };
    SalesOrderStore.prototype.updateOrderProcessesLocal = function () {
        var _a;
        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
            return;
        var indexDomesticProcessNeeded = this.order.line_items.findIndex(function (object) {
            return object.domestic_process_needed === true;
        });
        if (indexDomesticProcessNeeded > -1)
            this.order.domestic_process_needed = true;
        var indexHardwareRequired = this.order.line_items.findIndex(function (object) {
            return object.hardware_required === true;
        });
        if (indexHardwareRequired > -1)
            this.order.hardware_required = true;
    };
    SalesOrderStore.prototype.updateLineItemHardwareDomesticLocal = function (order_line_item, type, value) {
        var _a;
        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
            return;
        var itemIndex = this.order.line_items.findIndex(function (object) {
            return object.id === (order_line_item === null || order_line_item === void 0 ? void 0 : order_line_item.id);
        });
        if (type === 'domestic') {
            this.order.line_items[itemIndex].domestic_process_needed = value;
            return;
        }
        if (type === 'hardware') {
            this.order.line_items[itemIndex].hardware_required = value;
            return;
        }
    };
    /**
     * Update a item of a existing Sales Order
     */
    SalesOrderStore.prototype.updateRemoteMultipleItems = function (order_line_items) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var payload, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
                            return [2 /*return*/];
                        payload = order_line_items.map(function (order_line_item) {
                            return {
                                id: order_line_item.id,
                                quantity: order_line_item.quantity,
                                unit_price: order_line_item.unit_price,
                                required_part_ship_date: order_line_item.required_part_ship_date,
                                required_partner_ship_date: order_line_item.required_partner_ship_date,
                                vendor_qualifications: order_line_item.vendor_qualifications,
                                hardware_required: order_line_item.hardware_required,
                                domestic_process_needed: order_line_item.domestic_process_needed,
                                domestic_processes: order_line_item.domestic_processes,
                                hardwares_required: order_line_item.hardwares_required,
                                // vendor_requirements: order_line_item.vendor_requirements,             //Todo Allow to update requirements one by one
                                // vendor_requirement_notes: order_line_item.vendor_requirement_notes,
                            };
                        });
                        this.order.line_items = order_line_items;
                        _c = this;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + ((_b = this.order) === null || _b === void 0 ? void 0 : _b.id) + "/update-multiple-items/", payload)];
                    case 1:
                        _c.order = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.update_multiple_items = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + order_id + "/update-multiple-items/", payload)];
                    case 1:
                        _a.order = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateRemoteOrderItemLocal = function (order_line_item) {
        var _a;
        if (!((_a = this.order) === null || _a === void 0 ? void 0 : _a.id))
            return;
        this.order.line_items = this.order.line_items.map(function (current) {
            if (order_line_item.id === current.id) {
                return order_line_item;
            }
            return current;
        });
    };
    /**
     * Remove line item of a existing Sales Order
     * @param item
     */
    SalesOrderStore.prototype.removeRemoteOrderItem = function (item) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.order)
                            return [2 /*return*/];
                        this.order.line_items = this.order.line_items.filter(function (current) { return item.id !== current.id; });
                        _c = this;
                        return [4 /*yield*/, api.requests.delete("/staff/sales-orders/" + ((_a = this.order) === null || _a === void 0 ? void 0 : _a.id) + "/remove-item/" + item.id + "/")];
                    case 1:
                        _c.order = _d.sent();
                        if (this.order) {
                            this.order_items = [];
                            (_b = this.order) === null || _b === void 0 ? void 0 : _b.line_items.map(function (order_item) {
                                _this.order_items = __spreadArray([order_item], _this.order_items, true);
                            });
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateRemoteOrder = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + order_id + "/", payload).finally(function () {
                                _this.loading = false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.update_sales_order_field = function (key, value) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.order) return [3 /*break*/, 2];
                        this.order = __assign(__assign({}, this.order), (_a = {}, _a[key] = value, _a));
                        payload = (_b = {},
                            _b[key] = value,
                            _b);
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + this.order.id + "/", payload).finally(function () {
                                _this.loading = false;
                            })];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.downloadAllFiles = function (order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests
                            .get("/staff/sales-orders/" + order_id + "/download-all/", { responseType: 'blob' })
                            .then(function (data) {
                            FileDownload(data, "sales-order-" + order_id + "-files.zip");
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.uploadRedactedFile = function (order_id, line_item_id, file_type, file) {
        return __awaiter(this, void 0, void 0, function () {
            var formData, filename;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        filename = file_type === '2d' ? 'redacted_2d_file' : 'redacted_3d_file';
                        formData.append('line_item', line_item_id);
                        formData.append(filename, file);
                        return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + order_id + "/redacted-files/", formData, {}, true)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.update = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + order_id + "/", payload).finally(function () {
                                _this.loading = false;
                                _this.getOrderById(order_id);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.sendToProduction = function (order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, api.requests
                                .patch("/staff/sales-orders/" + order_id + "/?send-to-production=true", payload)
                                .finally(function () {
                                _this.loading = false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Object.defineProperty(SalesOrderStore.prototype, "getLeadItem", {
        get: function () {
            if (!this.order)
                return null;
            var lead_items = this.order.line_items
                .filter(function (item) { return item.suggested_lead_time; })
                .map(function (item) { return item.suggested_lead_time; })
                .sort(function (a, b) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                return b - a;
            });
            if (lead_items.length > 0) {
                return lead_items[0];
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    SalesOrderStore.prototype.getQuotePDF = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/quotes/" + quote_id + "/export/", { responseType: 'blob' })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Send pending quote to zoho
     * @param quote_id
     */
    SalesOrderStore.prototype.sendQuoteToZoho = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.post("/staff/quotes/" + quote_id + "/send-to-zoho/", {}).finally(function () {
                            _this.loading = false;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.analyze_3d_file = function (file_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.post("/staff/step-files/" + file_id + "/analyze/", '').then(function (response) {
                            console.log(response);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.upload2dOr3dFile = function (file, type) {
        return __awaiter(this, void 0, void 0, function () {
            var url, formData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = type == '3D' ? '/customer/step-files/' : '/customer/drawing-files/';
                        formData = new FormData();
                        formData.append('file', file);
                        return [4 /*yield*/, api.requests.post(url, formData, {}, true)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.getNotifications = function (sales_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/sales-orders/" + sales_order_id + "/notifications/").then(function (response) {
                            _this.sales_order_notifications = response;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.sendNotification = function (sales_order_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        if (payload.body) {
                            formData.append('body', payload.body);
                        }
                        if (payload.attachments) {
                            payload.attachments.forEach(function (item) {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                formData.append('attachments_files', item);
                            });
                        }
                        if (payload === null || payload === void 0 ? void 0 : payload.notify_owner) {
                            formData.append('notify_owner', payload.notify_owner);
                        }
                        return [4 /*yield*/, api.requests
                                .post("/staff/sales-orders/" + sales_order_id + "/notifications/", formData, 1, true)
                                .then(function (response) {
                                _this.sales_order_notifications = response;
                            })
                                .catch(function (error) { return (_this.error = error); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.getMarginLifetimeQuote = function (sales_order_id, payload) {
        if (payload === void 0) { payload = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/sales-orders/" + sales_order_id + "/margin-lifetime-quote/", payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateSalesOrderChecklist = function (sales_order_id, params) {
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + sales_order_id + "/update-sales-order-checklist/", params)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateSalesOrderSteps = function (sales_order_id, params) {
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.patch("/staff/sales-orders/" + sales_order_id + "/", params)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.getOrderProcessingChecklist = function (sales_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/sales-orders/" + sales_order_id + "/get-order-processing-checklist/")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.getChecklistType = function (sales_order_id, name) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/sales-orders/" + sales_order_id + "/get-checklist-type/?name=" + name)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.getChecklistsOrder = function (sales_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/sales-orders/" + sales_order_id + "/get-checklists-order/")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.getSessionUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/rest-auth/user/")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateAccountManager = function (sales_order_id, params) {
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + sales_order_id + "/update-account-manager/", params)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesOrderStore.prototype.updateMeetingTime = function (sales_order_id, params) {
        if (params === void 0) { params = {}; }
        return api.requests.post("/staff/sales-orders/" + sales_order_id + "/update-meeting-time/", params);
    };
    /**
     * Get total price sales order
     */
    SalesOrderStore.prototype.getTotalPriceOrder = function () {
        var initialValue = 0;
        var total = this.order_items.reduce(function (accumulator, item) {
            return accumulator + parseFloat(item.total.toString());
        }, initialValue);
        return total;
    };
    SalesOrderStore.prototype.isBigOrder = function () {
        var _a, _b;
        return ((_b = (_a = this.order) === null || _a === void 0 ? void 0 : _a.subtotal) !== null && _b !== void 0 ? _b : 0) > TOTAL_PRICE_SHOW_CHECKLIST;
    };
    SalesOrderStore.prototype.getUnacceptableKeywords = function (file_id, UnacceptableKeywords) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests
                            .post("/staff/request-for-orders/get_unacceptable_keywords/", {
                            file_id: file_id,
                        })
                            .then(function (data) {
                            data.unacceptable_keywords.map(function (k) {
                                if (UnacceptableKeywords.indexOf(k) === -1) {
                                    UnacceptableKeywords.push(k);
                                }
                            });
                        })
                            .finally(function () { return (_this.loading = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // get qualifications from vendor app
    SalesOrderStore.prototype.getVendorQualificationsFromVendorApp = function (sales_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.get("/staff/sales-orders/" + sales_order_id + "/vendor-qualifications/")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    //Credit card Submision store
    SalesOrderStore.prototype.sendCreditCard = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        if (payload.amount) {
                            formData.append('amount', payload.amount);
                        }
                        if (payload.customer_email) {
                            formData.append('customer_email', payload.customer_email);
                        }
                        if (payload.notes) {
                            formData.append('notes', payload.notes);
                        }
                        if (payload.representative_email) {
                            formData.append('representative_email', payload.representative_email);
                        }
                        formData.append('ship_cost', payload.ship_cost);
                        formData.append('tax_cost', payload.tax_cost);
                        formData.append('zip_code', payload.zip_code);
                        if (payload.quotes) {
                            payload.quotes.forEach(function (item) {
                                formData.append('quotes', item === null || item === void 0 ? void 0 : item.key);
                            });
                        }
                        if (payload.files) {
                            payload.files.forEach(function (item) {
                                formData.append('files', item);
                            });
                        }
                        return [4 /*yield*/, api.requests
                                .post("/staff/sales-orders/card/", formData, 1, true)
                                .then(function (response) {
                                _this.creditCard = response;
                            })
                                .catch(function (error) { return (_this.error = error); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.update_order_item = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.patch("/staff/sales-order-line-items/" + id + "/", payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesOrderStore.prototype.sendToSalesRepresentative = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.requests.post("/staff/sales-orders/" + id + "/send-to-sales-representative/", payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return SalesOrderStore;
}());
export default new SalesOrderStore();
