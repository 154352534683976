import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Badge, Button, Card, Checkbox, Col, Modal, notification, Row, Typography } from 'antd';
import Stages from './Stages';
import { get, useTitle, ZOHO_BASE_URL } from '@partsbadger/utils';
import { RFQPreview } from './RFQPreview';
import { QuoteStore } from '../Stores';
import QuoteToolVersionSwitcher from './QuoteToolVersionSwitcher';
import QuoteSocket from '../Services/QuoteSocket';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
var Paragraph = Typography.Paragraph;
export var QuoteHeader = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var quote = props.quote, handleShowQuoteEdit = props.handleShowQuoteEdit;
    useTitle(((_b = (_a = quote === null || quote === void 0 ? void 0 : quote.rfq) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '') + " - " + ((_d = (_c = quote.account) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : quote.name));
    var history = useHistory();
    var _m = useState(false), previewRFQ = _m[0], setPreviewRFQ = _m[1];
    var _o = useState(false), applyBatch = _o[0], setApplyBatch = _o[1];
    var goBack = function () {
        if (history.length > 1) {
            history.goBack();
        }
        else {
            history.push('/quotes/');
        }
    };
    return (React.createElement(Card, { style: {
            padding: 0,
        }, bodyStyle: {
            padding: 0,
        } },
        React.createElement(Row, { className: 'shadow p-2' },
            React.createElement(Col, { xs: 24, sm: 24, md: 24, lg: 5, xl: 4, xxl: 3 },
                React.createElement("div", { className: 'flex flex-row item-center justify-left' },
                    React.createElement(ArrowLeftOutlined, { style: {
                            fontSize: 22,
                        }, onClick: goBack }),
                    React.createElement("div", { className: 'ml-2' },
                        React.createElement(Paragraph, { copyable: true, style: { fontWeight: 'bold', display: 'initial' } }, quote.name))),
                React.createElement("div", null,
                    React.createElement(Stages, { stage: quote.stage }),
                    quote.safe_to_autocomplete && (React.createElement(Badge, { count: 'Auto Green', style: { backgroundColor: '#27AE60' } })),
                    quote.is_dynamic && (React.createElement(React.Fragment, null,
                        React.createElement(Badge, { count: 'Dynamic', style: { backgroundColor: '#7576c4' } }))),
                    quote.zoho_id && (React.createElement("a", { style: {
                            fontSize: 12,
                            marginLeft: 4,
                        }, target: '_blank', rel: "noreferrer", href: ZOHO_BASE_URL + "Quotes/" + quote.zoho_id }, "View In Zoho")),
                    quote.editable && (React.createElement(Button, { disabled: !quote.editable, type: "link", icon: React.createElement(LegacyIcon, { type: 'edit' }), onClick: function () {
                            handleShowQuoteEdit();
                        } }, "Edit")),
                    React.createElement("div", { className: 'flex flex-row', style: {
                            paddingLeft: '10px',
                            marginLeft: '9px',
                            maxWidth: 100,
                        } },
                        React.createElement("div", { className: 'font-bold' }, "Layout: "),
                        React.createElement(QuoteToolVersionSwitcher, { version: '1', handleChange: function (version) {
                                props.handleQuoteToolLayoutVersionChange(version);
                            } })))),
            React.createElement(Col, { xs: 24, sm: 24, md: 24, lg: 19, xl: 20, xxl: 21 },
                React.createElement(Row, { className: 'px-0 lg:px-8' },
                    React.createElement(Col, { md: 24, xxl: 12 },
                        React.createElement("div", { className: 'flex flex-row' },
                            React.createElement("div", { className: 'flex flex-col' },
                                React.createElement("div", { className: "account" }, quote.account ? (React.createElement("div", { className: ((_e = quote === null || quote === void 0 ? void 0 : quote.account) === null || _e === void 0 ? void 0 : _e.deleted) ? 'line-through' : '' }, (_f = quote.account) === null || _f === void 0 ? void 0 : _f.name)) : (React.createElement("div", { style: {
                                        color: 'red',
                                    } }, "Account Required"))),
                                quote.contact ? (React.createElement("div", { className: 'contact flex flex-row', style: {
                                        marginLeft: 2,
                                    } },
                                    React.createElement("div", { title: (_g = quote.contact) === null || _g === void 0 ? void 0 : _g.email, className: ((_h = quote === null || quote === void 0 ? void 0 : quote.contact) === null || _h === void 0 ? void 0 : _h.deleted) ? 'line-through' : '' }, (_j = quote.contact) === null || _j === void 0 ? void 0 : _j.fullname),
                                    React.createElement(Paragraph, { className: "ml-1", copyable: true, style: { display: 'initial' } }, (_k = quote.contact) === null || _k === void 0 ? void 0 : _k.email))) : (React.createElement("div", { style: { color: 'red' } }, "Contact Required"))),
                            React.createElement("div", { className: 'flex flex-col', style: {
                                    paddingLeft: '10px',
                                    marginLeft: '9px',
                                } },
                                React.createElement("div", { className: 'font-bold' }, "RFQ:"),
                                quote.rfq ? (React.createElement("div", { title: 'View RFQ', className: 'link', onClick: function () {
                                        get("/staff/quotes/" + quote.id + "/preview-rfq/?type=html")
                                            .then(function (data) {
                                            var file = new Blob([data], {
                                                type: 'text/html; charset=utf-8',
                                            });
                                            var fileURL = URL.createObjectURL(file);
                                            window.open(fileURL, '', 'width=800,height=800,left=200,top=200');
                                        })
                                            .catch(function (error) { return notification.error(error); });
                                    } }, quote.rfq.id)) : (React.createElement("div", null, "---"))),
                            React.createElement("div", { className: 'flex flex-col', style: {
                                    paddingLeft: '10px',
                                    marginLeft: '9px',
                                } },
                                React.createElement("div", { className: 'font-bold' }, "Type: "),
                                quote.type),
                            React.createElement("div", { className: 'flex flex-col', style: {
                                    paddingLeft: '10px',
                                    marginLeft: '9px',
                                } },
                                React.createElement("div", { className: 'font-bold' }, "Batch Release/Blanket: "),
                                React.createElement(Checkbox, { checked: applyBatch, onChange: function () { return setApplyBatch(!applyBatch); } })),
                            React.createElement("div", { className: 'flex flex-col', style: {
                                    paddingLeft: '10px',
                                    marginLeft: '9px',
                                } },
                                React.createElement("div", { className: 'font-bold' }, "Sales Rep: "),
                                React.createElement("div", null, quote.owner.fullname)),
                            React.createElement("div", { className: 'flex flex-col', style: {
                                    paddingLeft: '10px',
                                    marginLeft: '9px',
                                } },
                                React.createElement("div", { className: 'font-bold w-full' }, " Design Engineer: "),
                                quote.design_engineer ? ((_l = quote.design_engineer) === null || _l === void 0 ? void 0 : _l.fullname) : (React.createElement("div", { style: { color: 'red' } }, "Required"))))),
                    React.createElement(Col, { md: 24, xxl: 12 },
                        React.createElement(AccountInfoHeader, null))))),
        React.createElement(QuoteSocket, { quote_id: props.quote.id, user: {
                id: props.user.id,
                email: props.user.email,
                display_name: props.user.display_name,
            } }),
        previewRFQ && React.createElement(RFQPreview, { quote_id: quote.id, handleClose: function () { return setPreviewRFQ(false); } }),
        React.createElement(Modal, { title: 'Apply Batch to All', open: applyBatch, onCancel: function () { return setApplyBatch(false); }, footer: [] }, "If checkbox is selected, a pop up for more information - \u201CApply to all line items\u201D Checkbox - Qty. for each line item and est. release dates. Feature to be able to add more lines for date/quantity selection if needed.")));
};
var AccountInfoHeader = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g;
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement("div", null,
            React.createElement("strong", null, "Whole Quote Adjustment:"),
            " ", (_a = QuoteStore.quote) === null || _a === void 0 ? void 0 :
            _a.whole_quote_adjust,
            "%"),
        React.createElement("div", null, ((_b = QuoteStore.quoteAccountOverview) === null || _b === void 0 ? void 0 : _b.is_first_quote_for_contact) && React.createElement("div", null, "\uD83C\uDFC6 Contact's first Quote \uD83C\uDFC6")),
        React.createElement("div", { style: { display: 'flex', marginLeft: '1erm' } },
            React.createElement("div", { style: { marginRight: '1rem', marginLeft: '1rem' } }, "Account Overview: "),
            React.createElement("div", { style: { marginRight: '1rem', marginLeft: '1erm' } },
                React.createElement("strong", null, " Close Rate:"),
                " ", (_c = QuoteStore.quoteAccountOverview) === null || _c === void 0 ? void 0 :
                _c.close_rate,
                "%"),
            React.createElement("div", { style: { marginRight: '1rem', marginLeft: '1erm' } },
                React.createElement("strong", null, "Average Margin:"),
                " ", (_d = QuoteStore.quoteAccountOverview) === null || _d === void 0 ? void 0 :
                _d.average_margin,
                "% (\u03C3:",
                ' ', (_e = QuoteStore.quoteAccountOverview) === null || _e === void 0 ? void 0 :
                _e.margin_stddev,
                ")"),
            React.createElement("div", { style: { marginRight: '1rem', marginLeft: '1erm' } },
                React.createElement("strong", null, " Lifetime Value:"),
                " $", (_g = (_f = QuoteStore.quoteAccountOverview) === null || _f === void 0 ? void 0 : _f.lifetime_value) === null || _g === void 0 ? void 0 :
                _g.toLocaleString()))));
});
