var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { ALLOWED_FILES } from './FileExtensions';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
var UploadFile = React.forwardRef(function (props, ref) {
    var _a, _b;
    var multiple = props.multiple, onChange = props.onChange, onUpload = props.onUpload, onRemove = props.onRemove, value = props.value, fileType = props.fileType, renderAs = props.renderAs;
    var token = localStorage.getItem('token');
    var handleChange = function (info) {
        var file = info.file;
        var fileList = info.fileList;
        if (!multiple) {
            fileList = info.fileList.slice(-1);
        }
        if (onChange) {
            onChange(fileList);
        }
        if (info.file.status === 'done' && !!onUpload) {
            onUpload(info.file);
        }
        if (info.file.status === 'removed' && !!onRemove) {
            onRemove(file);
        }
    };
    var url = "customer/files/";
    var allowed_files = '';
    if (!props.accept) {
        switch (fileType) {
            case '3D':
                url = "customer/step-files/";
                allowed_files = ALLOWED_FILES.allowed_3d.map(function (r) { return "." + r.toLowerCase(); }).toString();
                break;
            case '2D':
                url = "customer/drawing-files/";
                allowed_files = ALLOWED_FILES.allowed_2d.map(function (r) { return "." + r.toLowerCase(); }).toString();
                break;
        }
    }
    else {
        switch (fileType) {
            case '3D':
                url = "customer/step-files/";
                allowed_files = props.accept.map(function (r) { return "." + r.toLowerCase(); }).toString();
                break;
            case '2D':
                url = "customer/drawing-files/";
                allowed_files = props.accept.map(function (r) { return "." + r.toLowerCase(); }).toString();
                break;
            default:
                allowed_files = props.accept.map(function (r) { return "." + r.toLowerCase(); }).toString();
                break;
        }
    }
    var otherProps = {};
    if (value) {
        otherProps = {
            fileList: value,
        };
    }
    var headers = {};
    if (token) {
        headers['Authorization'] = "Token " + token;
    }
    var showUploadList = props.showUploadList;
    if (renderAs == 'button') {
        return (React.createElement(Upload, __assign({ className: props.className, name: "file", action: BASE_URL + '/' + url, headers: headers, accept: allowed_files }, otherProps, { showUploadList: showUploadList, onChange: handleChange, style: props.styles, beforeUpload: function (file) {
                if (props.accept) {
                    var type_1 = file.name.split('.').pop();
                    var file_type = props.accept.filter(function (file) { return file === (type_1 === null || type_1 === void 0 ? void 0 : type_1.toLowerCase()); });
                    if ((type_1 === null || type_1 === void 0 ? void 0 : type_1.toLowerCase()) === file_type[0])
                        return true;
                    message.error('The file type is not allowed, try again.', 3);
                    return false;
                }
                return true;
            } }), (_a = props.children) !== null && _a !== void 0 ? _a : (React.createElement(Button, { size: 'small' },
            React.createElement(UploadOutlined, null),
            " Upload"))));
    }
    return (React.createElement(Upload.Dragger, __assign({ className: props.className, name: "file", action: BASE_URL + '/' + url, headers: headers, multiple: true, accept: allowed_files }, otherProps, { showUploadList: showUploadList, onChange: handleChange, style: props.styles, beforeUpload: function (file) {
            if (props.accept) {
                var type_2 = file.name.split('.').pop();
                var file_type = props.accept.filter(function (file) { return file === (type_2 === null || type_2 === void 0 ? void 0 : type_2.toLowerCase()); });
                if ((type_2 === null || type_2 === void 0 ? void 0 : type_2.toLowerCase()) === file_type[0])
                    return true;
                message.error('The file type is not allowed, try again.', 3);
                return false;
            }
            return true;
        } }), (_b = props.children) !== null && _b !== void 0 ? _b : (fileType === '3D' ? (React.createElement("div", { className: "flex flex-col justify-center items-center", style: { height: '309px' } },
        React.createElement(UploadOutlined, { style: { fontSize: '96px', color: '#405764' } }),
        React.createElement("p", { className: "font-bold text-2xl  text-center pt-6", style: { width: '227px' } }, "Select STEP, STP or IGES File"),
        React.createElement("p", { className: "text-base font-bold ml-1  pt-10", style: { color: '#595959' } },
            "Drop your files here or ",
            React.createElement("span", { className: "text-red-600 font-bold" }, "Browse")))) : (React.createElement("div", { className: "flex justify-center items-center align-middle", style: { height: '87px' } },
        React.createElement(UploadOutlined, { style: { fontSize: '34px', color: '#405764' } }),
        React.createElement("span", { className: "text-base ml-1" }, "Click or drag files"))))));
});
/**
 * Map the response of the api to a local format to be used in UploadFile component
 * @param file
 * @returns {{uid: *, response: {id: *, url: *}, name: *, status: string}}
 */
export var normalize_files = function (file) {
    if (!file) {
        return;
    }
    return {
        uid: file.id,
        name: file.filename,
        status: 'done',
        response: {
            id: file.id,
            url: file.file,
        },
    };
};
UploadFile.displayName = 'UploadFile';
export default UploadFile;
