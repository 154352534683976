var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { Button, Input, InputNumber, notification, Form } from 'antd';
import { observer } from 'mobx-react';
import { Coatings, Finishes, InputQuantities, Materials, Processes } from './DropDowns';
import QuoteStore from '../stores/QuoteStore';
import { ErrorRender, FormErrorParser } from '@partsbadger/library';
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(function (field) { return fieldsError[field]; });
}
var BulkUpdateItems = function (_a) {
    var master_products = _a.master_products, _b = _a.for_staff, for_staff = _b === void 0 ? false : _b, props = __rest(_a, ["master_products", "for_staff"]);
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), showQuantity = _d[0], setShowQuantity = _d[1];
    var form = Form.useForm()[0];
    var getFieldValue = form.getFieldValue;
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, data_1, payload, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    values = _b.sent();
                    setLoading(true);
                    data_1 = {
                        product: {
                            customs: {},
                        },
                    };
                    if (values.manufacturing_type) {
                        data_1['manufacturing_type'] = values.manufacturing_type;
                    }
                    if (values === null || values === void 0 ? void 0 : values.material) {
                        data_1['product']['material'] = values.material.key;
                    }
                    if (values.custom_material) {
                        data_1['product']['custom_material'] = values.custom_material;
                    }
                    if (values === null || values === void 0 ? void 0 : values.finish) {
                        data_1['product']['finishes'] = [values.finish.key];
                    }
                    if (values.custom_finish) {
                        data_1['product']['custom_finish'] = values.custom_finish;
                    }
                    if (values === null || values === void 0 ? void 0 : values.coating) {
                        data_1['product']['coatings'] = [values.coating.key];
                    }
                    if (values.custom_coating) {
                        data_1['product']['custom_coating'] = values.custom_coating;
                    }
                    if (values.quantity) {
                        data_1['quantity'] = values.quantity;
                        data_1['quantities'] = [];
                    }
                    if (values.quantities) {
                        data_1['quantity'] = 1;
                        data_1['quantities'] = values.quantities;
                    }
                    if (!QuoteStore.quote) return [3 /*break*/, 3];
                    payload = (_a = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.line_items.filter(function (item) { return master_products.includes(item.product.master_product); }).map(function (item) {
                        return __assign(__assign({}, data_1), { id: item.id });
                    });
                    return [4 /*yield*/, QuoteStore.updateMultipleLineItems(payload)
                            .then(function () {
                            form.resetFields();
                            props.handleClose();
                        })
                            .catch(function (err) {
                            notification.error({
                                message: React.createElement(ErrorRender, { error: err }),
                                placement: 'topRight',
                            });
                        })];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _b.sent();
                    notification.error({
                        message: React.createElement(ErrorRender, { error: error_1 }),
                        placement: 'topRight',
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Form, { form: form, className: "form-bulk-update", layout: 'vertical', onFinish: function () { return handleSubmit(); }, onFinishFailed: function (errorInfo) {
            notification.error({
                message: 'Error',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
            });
        } },
        React.createElement(Form.Item, { label: 'Process' },
            React.createElement(Form.Item, { name: "manufacturing_type", rules: [{ required: false, message: 'Please select a process!' }], noStyle: true },
                React.createElement(Processes, { placeholder: "Select", style: {
                        width: '100%',
                        minWidth: 100,
                    } }))),
        React.createElement(Form.Item, { label: 'Material' },
            React.createElement(Form.Item, { name: "material", rules: [{ required: false, message: 'Please select a material!' }], noStyle: true },
                React.createElement(Materials, { materials: QuoteStore.materials })),
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: function (prevValues, curValues) { return prevValues.material !== curValues.material; } }, function (_a) {
                var _b;
                var getFieldValue = _a.getFieldValue;
                return ((_b = getFieldValue('material')) === null || _b === void 0 ? void 0 : _b.label) === 'Custom' ? (React.createElement(Form.Item, { name: "custom_material", label: 'Material Name', rules: [{ required: true, message: 'Please insert a value' }] },
                    React.createElement(Input, { size: "small" }))) : null;
            })),
        React.createElement(Form.Item, { label: 'Finish' },
            React.createElement(Form.Item, { name: "finish", rules: [{ required: false, message: 'Please select a finish!' }], noStyle: true },
                React.createElement(Finishes, { finishes: QuoteStore.finishes })),
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: function (prevValues, curValues) { return prevValues.finish !== curValues.finish; } }, function (_a) {
                var _b;
                var getFieldValue = _a.getFieldValue;
                return ((_b = getFieldValue('finish')) === null || _b === void 0 ? void 0 : _b.label) === 'Custom' ? (React.createElement(Form.Item, { name: "custom_finish", label: 'Finish Name', rules: [{ required: true, message: 'Please insert a value' }] },
                    React.createElement(Input, { size: "small" }))) : null;
            })),
        React.createElement(Form.Item, { label: 'Coating' },
            React.createElement(Form.Item, { name: "coating", rules: [{ required: false, message: 'Please select a coating!' }], noStyle: true },
                React.createElement(Coatings, { coatings: QuoteStore.coatings })),
            React.createElement(Form.Item, { noStyle: true, shouldUpdate: function (prevValues, curValues) { return prevValues.coating !== curValues.coating; } }, function (_a) {
                var _b;
                var getFieldValue = _a.getFieldValue;
                return ((_b = getFieldValue('coating')) === null || _b === void 0 ? void 0 : _b.label) === 'Custom' ? (React.createElement(Form.Item, { name: "custom_coating", label: 'Coating Name', rules: [{ required: true, message: 'Please insert a value' }] },
                    React.createElement(Input, { size: "small" }))) : null;
            })),
        for_staff && (React.createElement(React.Fragment, null, showQuantity ? (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Single Quantity' },
            React.createElement(Form.Item, { name: 'quantity', noStyle: true },
                React.createElement(InputNumber, { step: 1, controls: false, style: { width: '100%' } })),
            React.createElement(Button, { type: 'link', onClick: function () { return setShowQuantity(false); } }, "or Quote Multiple Quantities?"))) : (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Multiple Quantities' },
            React.createElement(Form.Item, { name: 'quantities', noStyle: true },
                React.createElement(InputQuantities, { style: { width: '100%' } })),
            React.createElement(Button, { type: 'link', onClick: function () { return setShowQuantity(true); } }, "or Quote Single Quantity?"))))),
        !for_staff && !QuoteStore.isRequote && (React.createElement(Form.Item, { name: "quantity", label: 'Quantity', rules: [{ required: false, message: 'Please input a quantity!' }] },
            React.createElement(InputNumber, null))),
        React.createElement(Form.Item, null,
            React.createElement(Button, { type: "primary", block: true, loading: loading, htmlType: "submit" }, "Update All"))));
};
export default observer(BulkUpdateItems);
