var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { message, Modal, Spin } from 'antd';
import { UploadFileInfo } from './UploadFileInfo';
import { ItemsCreateForm } from './ItemsCreateForm';
import { ItemsEditForm } from './ItemsEditForm';
import api from './utils/api';
import SalesOrderStore from './Stores/SalesOrderStore';
import { ALLOWED_FILES } from '@partsbadger/utils';
export var PartFiles = function (props) {
    var product = props.product;
    var record = props.record;
    var action = props.action;
    var pollingInterval = null;
    var _a = useState(false), showAddStepFile = _a[0], setShowAddStepFile = _a[1];
    var _b = useState(false), showAddDrawingFile = _b[0], setShowAddDrawingFile = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    useEffect(function () {
        if (record.production_3d_file_id) {
            api.requests.get("/staff/step-files/" + record.production_3d_file_id + "/").then(function (response) {
                if (response.analyzed_status !== 'completed' && action === 'edit') {
                    if (record.production_3d_file_id) {
                        startPolling(record.production_3d_file_id);
                    }
                }
            });
        }
    }, [record]);
    var renderStepFile = function (id) {
        var url = "/viewer/" + id + "/";
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    var renderDrawingFile = function (id) {
        var width = 960;
        var height = 720;
        var left = window.screen.width - width;
        var external_window = window.open("/drawing-viewer/" + id + "/", '', "width=" + width + ",height=" + height + ",left=" + left);
        external_window.document.title = 'Drawing File Viewer';
    };
    var startPolling = function (id) {
        setLoading(true);
        var url = "/staff/step-files/" + id + "/";
        pollingInterval = setInterval(function () {
            api.requests
                .get(url)
                .then(function (response) {
                if (response.analyzed_status === 'completed') {
                    SalesOrderStore.updateOrderItemStepFile(record.id, response);
                    clearInterval(pollingInterval);
                    setLoading(false);
                }
            })
                .catch(function () {
                setLoading(false);
                clearInterval(pollingInterval);
            });
        }, 5000);
    };
    return (React.createElement(Spin, { spinning: loading, tip: 'Analyzing file, please wait.' },
        React.createElement("div", { className: 'flex flex-col itemsCenter' },
            React.createElement("div", { className: 'flex flex lg:flex-row md:flex-col sm:flex-col itemsCenter' }, (props === null || props === void 0 ? void 0 : props.show_pending_quoted_files) && (React.createElement("div", { className: 'w-full' },
                action === 'create' ? (React.createElement(React.Fragment, null,
                    React.createElement(ItemsCreateForm, { record: record, product: product, setShowAddDrawingFile: setShowAddDrawingFile, setShowAddStepFile: setShowAddStepFile, renderStepFile: renderStepFile, renderDrawingFile: renderDrawingFile, onDropFile: function (file, type) { return __awaiter(void 0, void 0, void 0, function () {
                            var allowed_files, t, file_type, data;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        allowed_files = ALLOWED_FILES.Files3D;
                                        t = (_a = file.name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
                                        if (type === '2D')
                                            allowed_files = ALLOWED_FILES.Files2D;
                                        file_type = allowed_files.filter(function (f) { return f === t; });
                                        if (!(t === file_type[0])) return [3 /*break*/, 2];
                                        return [4 /*yield*/, SalesOrderStore.upload2dOr3dFile(file, type)];
                                    case 1:
                                        data = _b.sent();
                                        if (type === '2D') {
                                            SalesOrderStore.updateOrderItem2D(record, data.id, file.name);
                                        }
                                        else {
                                            SalesOrderStore.analyze_3d_file(data.id);
                                            SalesOrderStore.updateOrderItem3D(record, data.id, file.name);
                                        }
                                        return [3 /*break*/, 3];
                                    case 2:
                                        message.error('The file type is not allowed, try again.', 3);
                                        _b.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } }))) : (React.createElement(React.Fragment, null,
                    React.createElement(ItemsEditForm, { record: record, setShowAddDrawingFile: setShowAddDrawingFile, setShowAddStepFile: setShowAddStepFile, renderStepFile: renderStepFile, renderDrawingFile: renderDrawingFile }))),
                React.createElement(Modal, { visible: showAddStepFile, centered: true, title: "Add Step File", onCancel: function () { return setShowAddStepFile(false); }, footer: false },
                    React.createElement("div", { className: 'flex justify-center' }, ALLOWED_FILES.Files3D.map(function (file) {
                        return file.toUpperCase() + ' | ';
                    })),
                    React.createElement(UploadFileInfo, { typeFile: '3D', accept: ALLOWED_FILES.Files3D, setShowDragger: setShowAddStepFile, record: record })),
                React.createElement(Modal, { visible: showAddDrawingFile, centered: true, title: "Add Drawing File", onCancel: function () { return setShowAddDrawingFile(false); }, footer: false },
                    React.createElement("div", { className: 'flex justify-center' }, ALLOWED_FILES.Files2D.map(function (file) {
                        return file.toUpperCase() + ' | ';
                    })),
                    React.createElement(UploadFileInfo, { typeFile: '2D', accept: ALLOWED_FILES.Files2D, setShowDragger: setShowAddDrawingFile, record: record }))))))));
};
