import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Divider, Dropdown, Menu, Popconfirm, Row, Table } from 'antd';
import { CheckCircleOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';

//Types
import { PaginationProps } from 'antd/es/pagination';
import { IInventory } from '@partsbadger/types';

import { remove, useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import { DateRender } from '@partsbadger/library';

import { get } from '../../shared';
import { hasPermission } from '../../components/User';

import Stages from '../../components/Stages';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import InventoryFilterForm from '../../components/Inventory/InventoryFilterForm';

const { Column } = Table;

const InventoryList = () => {
    const searchParams = useQueryParameters();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [inventory, setInventory] = useState([]);

    const [pagination, setPagination] = useState<PaginationProps>({});

    const getInventory = (params = {}) => {
        setLoading(true);
        get('staff/inventory/', params)
            .then((response: any) => {
                setInventory(response.data.results);
                setPagination({
                    pageSize: 30,
                    total: response.data.count,
                });
            })
            .finally(() => setLoading(false));
    };

    const deleteInventory = (id: number) => {
        setLoading(true);
        remove(`/staff/inventory/${id}/`)
            .then((response: any) => {
                getInventory();
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getInventory(searchParams);
    }, [history.location.search]);

    return (
        <Fragment>
            <div>
                <InventoryFilterForm />
            </div>
            <Divider>
                <div className={'title'}> Inventory</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IInventory>
                        rowKey={'id'}
                        dataSource={inventory}
                        loading={loading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...pagination} />;
                        }}
                        scroll={{ x: 'auto' }}
                    >
                        <Column title="ID" dataIndex="id" key="id" />
                        <Column title="Name" dataIndex="name" key="name" />
                        <Column<IInventory>
                            title="Created Time"
                            dataIndex="created_time"
                            key="created_time"
                            render={(text, record) => {
                                return <DateRender value={record.created_time} />;
                            }}
                        />

                        <Column<IInventory>
                            title="Sales Order"
                            dataIndex="sales_order.name"
                            key="sales_order.name"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div>{typeof record.sales_order === 'object' && record.sales_order?.name}</div>
                                        <div className="text-sm">{record.account_related?.name}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column<IInventory>
                            title="In Zoho"
                            dataIndex="zoho_id"
                            key="zoho_id"
                            render={(text, record) => {
                                if (record.zoho_id) {
                                    return (
                                        <a
                                            target={'_blank'}
                                            rel="noreferrer"
                                            href={`${ZOHO_BASE_URL}CustomModule15/${record.zoho_id}`}
                                        >
                                            View Zoho
                                        </a>
                                    );
                                }
                                return null;
                            }}
                        />

                        <Column<IInventory>
                            title={'Actions'}
                            key={'actions'}
                            render={(text, record) => {
                                const menu = (
                                    <Menu>
                                        <Menu.Item
                                            onClick={() => {
                                                history.push(`/inventory/${record.id}/`);
                                            }}
                                        >
                                            <CheckCircleOutlined /> View
                                        </Menu.Item>

                                        {!record.zoho_id && (
                                            <Menu.Item>
                                                <Popconfirm
                                                    placement="topLeft"
                                                    title={'Do you want to remove this item?'}
                                                    onConfirm={() => {
                                                        if (record.id) deleteInventory(record.id);
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <CloseOutlined /> Delete
                                                </Popconfirm>
                                            </Menu.Item>
                                        )}
                                    </Menu>
                                );

                                return (
                                    <Dropdown overlay={menu}>
                                        <span className={'link'}>
                                            Actions <DownOutlined />
                                        </span>
                                    </Dropdown>
                                );
                            }}
                        />
                    </Table>
                </Col>
            </Row>
        </Fragment>
    );
};

export default InventoryList;
