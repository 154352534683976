import React, { useState } from 'react';
import { Card, Table, Button, notification } from 'antd';
import moment from 'moment';

import { DateRangePicker } from '@partsbadger/library';
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useQueryParameters } from '@partsbadger/utils';
import { post } from '@partsbadger/utils';
import MaterialsSelect from './MaterialsSelect';
import CoatingsSelect from './CoatingsSelect';
import { Formulas } from '@partsbadger/quote-tool/lib/components/Select';
import { LeadTimeTracking } from '../../../stores/types';

const FormItem = Form.Item;

export interface Params {
    start_date: string;
    end_date: string;
    material: string;
    coating: string;
    formula: string;
}

const LeadTimeTrackingReport = (props: FormComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<LeadTimeTracking[]>([]);

    const query = useQueryParameters();
    const { form } = props;
    const { getFieldDecorator } = form;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const dates = values.dates;
                const material = values?.material?.label;
                const coating = values?.coating?.label;
                const formula = values?.formula;

                const start_date = moment(dates[0]).format('YYYY-MM-DD');
                const end_date = moment(dates[1]).format('YYYY-MM-DD');
                const params = {
                    start_date,
                    end_date,
                    material,
                    coating,
                    formula,
                };
                getLeadTimeTracking(params);
            }
        });
    };

    const getLeadTimeTracking = (params: Params) => {
        setLoading(true);
        post(`/staff/report-line-items-only-intake/lead-time-tracking/`, params)
            .then((response: LeadTimeTracking[]) => {
                setData(response);
            })
            .catch(error => {
                notification.error({ message: error.message, duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns = [
        {
            title: 'Quoting LT',
            children: [
                {
                    title: 'Absolute Minimum',
                    dataIndex: 'quoting_min',
                    key: 'quoting_min',
                },
                {
                    title: 'Absolute Maximum',
                    dataIndex: 'quoting_max',
                    key: 'quoting_max',
                },
                {
                    title: 'Average',
                    dataIndex: 'quoting_avg',
                    key: 'quoting_avg',
                },
            ],
        },
        {
            title: 'Processing LT',
            children: [
                {
                    title: 'Absolute Minimum',
                    dataIndex: 'processing_min',
                    key: 'processing_min',
                },
                {
                    title: 'Absolute Maximum',
                    dataIndex: 'processing_max',
                    key: 'processing_max',
                },
                {
                    title: 'Average',
                    dataIndex: 'processing_avg',
                    key: 'processing_avg',
                },
            ],
        },
        {
            title: 'Observed LT',
            children: [
                {
                    title: 'Absolute Minimum',
                    dataIndex: 'observed_min',
                    key: 'observed_min',
                },
                {
                    title: 'Absolute Maximum',
                    dataIndex: 'observed_max',
                    key: 'observed_max',
                },
                {
                    title: 'Average',
                    dataIndex: 'observed_avg',
                    key: 'observed_avg',
                },
            ],
        },
    ];

    return (
        <div className={'flex flex-col'}>
            <Card title={'Quoted and Processed Lead Time Tracking'}>
                <Form onSubmit={handleSubmit}>
                    <div className="flex lg:flex-row flex-col lg:ml-4 ml-0">
                        <FormItem label="" required>
                            {getFieldDecorator('dates', {
                                rules: [{ required: true, message: 'Dates is required' }],
                            })(<DateRangePicker />)}
                        </FormItem>

                        <FormItem label="Materials" className="w-64 lg:ml-4 ml-0">
                            {getFieldDecorator(
                                'material',
                                {}
                            )(<MaterialsSelect getFieldDecorator={getFieldDecorator} />)}
                        </FormItem>

                        <FormItem label="Coatings" className="w-64 lg:ml-4 ml-0">
                            {getFieldDecorator('coating', {})(<CoatingsSelect getFieldDecorator={getFieldDecorator} />)}
                        </FormItem>

                        <FormItem label="Formula" className="w-64 lg:ml-4 ml-0">
                            {getFieldDecorator(
                                'formula',
                                {}
                            )(
                                <Formulas
                                    placeholder="Select"
                                    item={{
                                        partVol: null,
                                        totalVol: null,
                                        has_file_3d: undefined,
                                    }}
                                    selectedMaterialName={null}
                                />
                            )}
                        </FormItem>

                        <FormItem>
                            <Button type="default" htmlType={'submit'} size="small" className="lg:ml-4 ml-0">
                                Filter
                            </Button>
                        </FormItem>
                    </div>
                </Form>
            </Card>

            <div className={'w-full overflow-auto'}>
                <Table
                    bordered
                    rowKey={'id'}
                    columns={columns}
                    loading={loading}
                    dataSource={data}
                    pagination={false}
                ></Table>
            </div>
        </div>
    );
};

export const LeadTimeTrackingReportForm = Form.create()(LeadTimeTrackingReport);
