import React from 'react';
import { CodeSandboxOutlined, DropboxOutlined } from '@ant-design/icons';
import { Card, Radio } from 'antd';
import { useHistory } from 'react-router';
import { AutoCompletedRecord } from '../AutoCompleteRecord';
import { hasPermission } from 'quotes-frontend/src/components/User';
var ParamTypes;
(function (ParamTypes) {
    ParamTypes["salesOrder"] = "sales-order";
    ParamTypes["outTake"] = "out-take";
    ParamTypes["rma"] = "rma";
    ParamTypes["purchase_orders"] = "purchase-orders";
})(ParamTypes || (ParamTypes = {}));
var IntakeOuttakeFilters = function (props) {
    var history = useHistory();
    var paramType = props.vendorType === 'Vendor box'
        ? ParamTypes.outTake
        : props.vendorType === 'RMA'
            ? ParamTypes.rma
            : props.vendorType === 'Purchase Orders'
                ? ParamTypes.purchase_orders
                : ParamTypes.salesOrder;
    var isReceive = props.receiveOrShip === 'receive';
    return (React.createElement("div", { className: "flex flex-row justify-center mb-4" },
        React.createElement("div", { className: 'active-card mx-2' },
            React.createElement(Card, { hoverable: true, onClick: function () {
                    props.handleClickIntakeOutake('receive');
                }, style: {
                    width: 150,
                    height: 100,
                    fontSize: '2rem',
                    padding: '10px 10px 0',
                    backgroundColor: isReceive ? 'rgb(217, 41, 35)' : 'inherit',
                    color: isReceive ? '#fff' : '',
                }, cover: React.createElement(DropboxOutlined, null) },
                React.createElement(Card.Meta, { style: { textAlign: 'center' }, title: React.createElement("div", { style: isReceive ? { color: 'white' } : {} }, "Intake") }))),
        React.createElement("div", { className: !isReceive ? 'active-card mx-2' : 'mx-2' },
            React.createElement(Card, { hoverable: true, onClick: function () {
                    // setVendorType(false)
                    props.handleClickIntakeOutake('ship');
                }, style: {
                    width: 150,
                    height: 100,
                    fontSize: '2rem',
                    padding: '10px 10px 0',
                    backgroundColor: !isReceive ? 'rgb(217, 41, 35)' : 'inherit',
                    color: !isReceive ? 'white' : '',
                }, cover: React.createElement(CodeSandboxOutlined, null) },
                React.createElement(Card.Meta, { style: { textAlign: 'center' }, title: React.createElement("div", { style: !isReceive ? { color: 'white' } : {} }, "Outtake") }))),
        React.createElement("div", { className: "mx-4", style: { minWidth: 150 } },
            isReceive && (React.createElement("div", { className: "flex flex-row" },
                React.createElement("strong", { className: "mx-4" }, "Filters:"),
                React.createElement(Radio.Group, { className: "flex flex-row", value: props.vendorType, onChange: function (e) {
                        props.onChangeVendorType(e.target.value);
                    } },
                    hasPermission('intake.view_salesorderintake') && (React.createElement(React.Fragment, null,
                        React.createElement(Radio, { value: "Sales order" }, "Sales order"),
                        React.createElement(Radio, { value: "Vendor box" }, "Vendor box"),
                        React.createElement(Radio, { value: "RMA" }, "RMA"))),
                    hasPermission('finance.add_purchaseorderintake') && (React.createElement(Radio, { value: "Purchase Orders" }, "Purchase Orders"))))),
            React.createElement(AutoCompletedRecord, { isVendorNumber: props.vendorType, handleSelect: function (sales) {
                    history.push("?" + paramType + "=" + sales.key);
                } }))));
};
export default IntakeOuttakeFilters;
