import React, {Fragment} from 'react';
import { FileAddOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { AutoComplete, Button, Col, Divider, Input, Row, Select, Spin, Table } from 'antd';
import {showAddDealForm} from "../../redux/actions/dealActions";
import {fetchAccounts, fetchContacts} from "../../redux/actions/accountActions";

import {connect} from "react-redux";
import {ErrorRender} from "@partsbadger/library";
import {fetchUsers} from "../../redux/actions/userActions";

const {Column} = Table;
const Search = Input.Search;
const FormItem = Form.Item;
const AutoCompleteOption = AutoComplete.Option;

let busy = null;
const ButtonGroup = Button.Group;

class ContactsListComponent extends React.Component {


    state = {
        contact: {},
        account: {},
        owner: {},
        search: "",
        visible: false,
        childrenDrawer: false,
    }


    componentDidMount() {
        // let url = this.props.location.search;
        this.props.fetchContacts([]);

    }


    handleSearchOwner = (name) => {
        if (busy) {
            clearTimeout(busy);
        }
        const params = {
            search: name,
            is_staff: true
        }

        busy = setTimeout(() => this.props.fetchUsers(params), 500);
        this.setState({
            owner: ''
        })

    }

    handleSelectOwner = (event) => {
        this.setState({
            owner: event
        })
    }

    handleSearchAccounts = (name) => {
        if (busy) {
            clearTimeout(busy);
        }
        const params = {
            'search': name,
        }
        busy = setTimeout(() => this.props.fetchAccounts(params), 500);
        this.setState({
            account: ''
        })

    }


    handleSearchChange = (event) => {
        this.setState({
            search: event.target.value
        })
    }


    handleContactSelect = (event) => {
        this.setState({
            contact: event
        })
    }

    handleAccountSelect = (event) => {
        this.setState({
            account: event
        })
    }


    handleApplyFilter = () => {

        const params = {
            'search': this.state.search,
            'account': this.state.account.key
        }

        this.props.fetchContacts(params);
    }


    render() {

        let dataSource = this.props.contacts.entities


        const accounts = this.props.accounts.entities.map(entity => {
            const label = entity.name;
            return (<AutoCompleteOption
                key={entity.id}>{label}</AutoCompleteOption>);
        });


        return (
            <Fragment>

                <ErrorRender error={this.props.deals.error}/>
                <Divider>
                    <div className={"title"}> Deals</div>
                </Divider>


                <Form layout="horizontal" style={{maxWidth: "100%"}}>
                    <Row>
                        <Col
                            lg={{span: 8}} sm={24}
                            style={
                                {
                                    textAlign: 'center'
                                }
                            }>

                            <FormItem
                                labelCol={{span: 6}}
                                wrapperCol={{span: 16}}
                                label="Search"
                            >
                                <Search
                                    placeholder="Search.."
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleSearchChange}
                                    onPressEnter={() => this.handleApplyFilter()}

                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            lg={{span: 8}} sm={24}
                            style={
                                {
                                    textAlign: 'center'
                                }
                            }>
                            <FormItem
                                labelCol={{span: 6}}
                                wrapperCol={{span: 16}}
                                label="Account"
                            >


                                <AutoComplete
                                    dropdownMatchSelectWidth={false}
                                    dataSource={accounts}
                                    optionKeyProp={"value"}
                                    labelInValue={true}
                                    onSearch={this.handleSearchAccounts}
                                    onSelect={this.handleAccountSelect}
                                >
                                    <Input suffix={<SearchOutlined className="certain-category-icon" />}/>
                                </AutoComplete>

                            </FormItem>

                        </Col>

                    </Row>
                    <Row>
                        <Col
                            lg={{span: 8}} sm={24}
                            style={
                                {
                                    textAlign: 'center'
                                }
                            }>
                            <FormItem
                                labelCol={{span: 6}}
                                wrapperCol={{span: 16}}
                                label="Sales Representative"
                            >


                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Search contact by name or email"
                                    notFoundContent={this.props.users.isLoading ?
                                        <Spin size="small"/> : null}
                                    filterOption={false}
                                    optionKeyProp={"value"}
                                    onSearch={this.handleSearchOwner}
                                    onSelect={this.handleSelectOwner}
                                    style={{width: '100%'}}
                                >
                                    {this.props.users.entities.map(d => (
                                        <Select.Option key={d.id}>{d.fullname} {d.email}</Select.Option>
                                    ))}
                                </Select>


                            </FormItem>
                        </Col>
                    </Row>

                    <Button type="primary" onClick={this.handleApplyFilter}>
                        Submit
                    </Button>
                </Form>


                <Row>
                    <ButtonGroup className={"add-button"}>
                        <Button type="primary" icon={<FileAddOutlined />} onClick={() => {
                            this.props.showAddDealForm();
                        }}>
                            Add
                            Deal
                        </Button>
                    </ButtonGroup>
                </Row>

                <Divider>
                    <div className={"title"}> Deals</div>
                </Divider>

                <Row>
                    <Col xs={24}>

                        <Table rowKey={'id'} dataSource={dataSource} loading={this.props.deals.isLoading}>
                            <Column
                                title="First Name"
                                dataIndex="first_name"
                                key="first_name"
                            />

                            <Column
                                title="Last Name"
                                dataIndex="last_name"
                                key="last_name"
                            />

                            <Column
                                title="Account"
                                dataIndex="account"
                                key="account"
                                render={(text, record) => (
                                    <span>
                                        {record.account.name}
                                        </span>
                                )}
                            />

                            <Column
                                title="Email"
                                dataIndex="email"
                                key="email"
                            />

                            <Column
                                title="Owner"
                                dataIndex="owner"
                                key="owner"
                                render={(text, record) => (
                                    <span>
                                        {record.owner.fullname}
                                        </span>
                                )}
                            />

                            <Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <span>
                                             Create Deal
                                        </span>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>


            </Fragment>
        );

    }

}


const mapStateToProps = state => {
    return {
        accounts: state.accounts,
        contacts: state.contacts,
        user: state.user,
        deals: state.deals
    };
}


const mapDispatchToProps = dispatch => {
    return {
        fetchContacts: (name, account) => dispatch(fetchContacts(name, account)),
        fetchAccounts: (name) => dispatch(fetchAccounts(name)),
        fetchUsers: (search) => dispatch(fetchUsers(search)),
        showAddDealForm: () => dispatch(showAddDealForm()),
    };
}

export const ContactsList = connect(mapStateToProps, mapDispatchToProps)(ContactsListComponent);

