import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import { NOHO_API_URL } from 'constants/index';

export const GOOGLE_CLIENT_ID = '936736108436-06m75u4bvu4qrgfa9u9p8m8ehs2oh771.apps.googleusercontent.com';

interface IResponseProps {
    accessToken: string;
}

export const useNohoLogin = () => {
    const [loading, setLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState(localStorage.getItem(NOHO_API_URL) || null);

    /**
     * Check if the token is valid
     */
    useEffect(() => {
        if (token) {
            setLoading(true);
            axios
                .get(NOHO_API_URL + '/api/auth/user/', {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                .then(() => {
                    setLoggedIn(true);
                })
                .catch(() => {
                    setLoggedIn(false);
                })
                .finally(() => setLoading(false));
        }
    }, []);

    let GoogleButton = null;

    if (!loading && !loggedIn) {
        GoogleButton = (
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Log In with Google"
                onSuccess={response => {
                    setLoading(true);
                    axios
                        .post(NOHO_API_URL + '/rest-auth/google/', {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            access_token: response?.accessToken,
                        })
                        .then(response => {
                            setLoggedIn(true);
                            setToken(response.data.key);
                            window.localStorage.setItem(NOHO_API_URL, response.data.key);
                        })
                        .finally(() => setLoading(false));
                }}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
            />
        );
    }

    return {
        loading: loading,
        loggedIn: loggedIn,
        noho_token: token,
        GoogleButton: GoogleButton,
    };
};
