var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Upload } from 'antd';
import _ from 'lodash';
import { API_ROOT } from '@partsbadger/utils';
var FILE_URL = API_ROOT + "/customer/files/";
var Option = Select.Option, OptGroup = Select.OptGroup;
var TextArea = Input.TextArea;
export var AdditionalRequirements = function (_a) {
    var value = _a.value, requirements = _a.requirements, onChange = _a.onChange, hide_extras = _a.hide_extras, disabled = _a.disabled;
    var _b = useState(), selectedValue = _b[0], setSelectedValue = _b[1];
    /**
     * Update local state
     */
    useEffect(function () {
        setSelectedValue(value);
    }, [value]);
    /**
     * Update parent and local state
     * @param items
     */
    var handleChange = function (items) {
        //If there onChange is defined set the state to parent
        if (onChange) {
            onChange(items);
        }
        else {
            setSelectedValue(items);
        }
    };
    //Group by Type
    var options = _.groupBy(requirements, function (item) {
        return item.type;
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Select, { allowClear: true, mode: "multiple", placeholder: "Search", style: { width: '100%' }, labelInValue: true, optionFilterProp: "children", 
                // @ts-ignore
                value: selectedValue, onChange: function (items) {
                    handleChange(items);
                }, disabled: disabled }, Object.keys(options).map(function (key) {
                return (React.createElement(OptGroup, { key: key, label: key }, options[key].map(function (item) { return (React.createElement(Option, { key: item.id, value: item.id }, item.name)); })));
            }))),
        React.createElement("div", { style: { padding: '10px 21px' } }, value && !hide_extras && (React.createElement(ExtraInputs, { requirements: requirements, value: value, handleChange: handleChange })))));
};
var ExtraInputs = function (_a) {
    var value = _a.value, requirements = _a.requirements, handleChange = _a.handleChange;
    if (!value) {
        return React.createElement("div", null);
    }
    return (React.createElement(React.Fragment, null, value.map(function (item) {
        var _a;
        var option = requirements.find(function (record) { return Number(record.id) === Number(item.key); });
        if (!option) {
            return null;
        }
        var field_type = option.field_type;
        if (field_type === 'input') {
            return (React.createElement(Form.Item, { key: "input-" + item.key, label: item.label },
                React.createElement(Input, { style: { maxWidth: 300 }, size: 'small', required: true, defaultValue: item.value, onBlur: function (event) {
                        var new_data = value.map(function (old_record) {
                            return old_record.key === item.key
                                ? __assign(__assign({}, old_record), { value: event.target.value }) : old_record;
                        });
                        handleChange(new_data);
                    } })));
        }
        if (field_type === 'inputtext') {
            return (React.createElement(Form.Item, { key: "input-" + item.key, label: item.label },
                React.createElement(TextArea, { style: { maxWidth: 300 }, size: 'small', required: true, defaultValue: item.value, onBlur: function (event) {
                        var new_data = value.map(function (old_record) {
                            return old_record.key === item.key
                                ? __assign(__assign({}, old_record), { value: event.target.value }) : old_record;
                        });
                        handleChange(new_data);
                    } })));
        }
        if (field_type === 'file') {
            var defaultFileList = (_a = item === null || item === void 0 ? void 0 : item.files) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return f; }).map(function (file) {
                return {
                    uid: file.id,
                    name: file.filename,
                    status: 'done',
                    url: file.file,
                    response: {
                        id: file.id,
                    },
                };
            });
            return (React.createElement(Form.Item, { key: "upload-" + item.key, label: item.label },
                React.createElement(Upload.Dragger, { name: "file", action: FILE_URL, headers: {
                        Authorization: "Token " + localStorage.getItem('token'),
                    }, 
                    // @ts-ignore
                    defaultFileList: defaultFileList, onChange: function (info) {
                        var fileList = info.fileList;
                        var files = fileList === null || fileList === void 0 ? void 0 : fileList.filter(function (f) { return (f === null || f === void 0 ? void 0 : f.status) === 'done'; }).map(function (file) {
                            return {
                                id: file.response.id,
                                filename: file.response.filename,
                                file: file.response.file,
                            };
                        });
                        if (!files.length) {
                            return;
                        }
                        var items = value === null || value === void 0 ? void 0 : value.map(function (old_record) {
                            return old_record.key === item.key
                                ? __assign(__assign({}, old_record), { files: files }) : old_record;
                        });
                        handleChange(items);
                    }, multiple: true },
                    React.createElement(Button, null,
                        React.createElement(UploadOutlined, null),
                        " Click to upload"))));
        }
        return null;
    })));
};
