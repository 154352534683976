var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useState } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
var busy = null;
export var Contacts = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), contacts = _b[0], setContacts = _b[1];
    var token = localStorage.getItem('token');
    var account = props.account;
    var fetchContacts = function (params) {
        axios
            .get(BASE_URL + "/staff/contacts/", {
            headers: {
                Authorization: "Token " + token,
            },
            params: params,
        })
            .then(function (response) {
            setContacts(response.data.results);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    return (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search contact by  name or email", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, onSearch: function (name) {
            if (busy) {
                clearTimeout(busy);
            }
            var params = {
                search: name,
                exclude_account_payable: true,
            };
            if (account) {
                params['account'] = props.account;
            }
            if (props.exclude_account_payable) {
                params['exclude_account_payable'] = props.exclude_account_payable;
            }
            setLoading(true);
            busy = setTimeout(function () { return fetchContacts(params); }, 400);
        } }, props), contacts.map(function (entity) { return (React.createElement(Select.Option, { key: entity.id, 
        //@ts-ignore
        contact: entity, account: entity.account, first_name: entity.first_name, last_name: entity.last_name, company: entity === null || entity === void 0 ? void 0 : entity.account, phone: entity.phone, email: entity.email, zip_code: entity.zip_code },
        entity.fullname,
        " || ",
        entity.email)); })));
};
