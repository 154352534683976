import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Badge, message, Spin } from 'antd';
// import './RFQView.css';
import { get } from '@partsbadger/utils';
//@ts-ignore
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
var EMPTY_DATA_VALUE = ' - ';
export var RFQView = observer(function (_a) {
    var _b, _c, _d, _e, _f;
    var rfq_id = _a.rfq_id;
    var _g = useState(), rfq = _g[0], setRFQ = _g[1];
    useEffect(function () {
        get("/staff/rfq/" + rfq_id + "/")
            .then(function (response) {
            setRFQ(response);
        })
            .catch(function (error) { return message.error(error); });
    }, [rfq_id]);
    if (!(rfq === null || rfq === void 0 ? void 0 : rfq.id)) {
        return React.createElement(Spin, { spinning: true, tip: 'Loading RFQ' });
    }
    return (React.createElement("div", { className: 'w-full' },
        React.createElement("table", { className: "rfq-table-bordered w-full" },
            React.createElement("tr", null,
                React.createElement("th", null, "Submission Method:"),
                React.createElement("td", null,
                    " ",
                    rfq.submission_method)),
            React.createElement("tr", null,
                React.createElement("th", null, "Date:"),
                React.createElement("td", null,
                    " ",
                    dayjs(rfq.created_time).format('LLL'))),
            React.createElement("tr", null,
                React.createElement("th", null, "Account"),
                React.createElement("td", null, rfq.account ? React.createElement("div", null, rfq.account.name) : rfq.account_name)),
            rfq.custom_additional_requirements && (React.createElement("tr", null,
                React.createElement("th", null, "Custom Additional Requirements"),
                React.createElement("td", null,
                    React.createElement("ul", null, rfq.custom_additional_requirements)))),
            React.createElement("tr", null,
                React.createElement("th", null, "Itar"),
                React.createElement("td", null, rfq.itar ? 'Yes' : 'No')),
            React.createElement("tr", null,
                React.createElement("th", null, "PartsBadger creating 3D Files?"),
                React.createElement("td", null, rfq.needs_to_create_3d_files ? 'Yes' : 'No')),
            React.createElement("tr", null,
                React.createElement("th", null, "PartsBadger creating 2D Files?"),
                React.createElement("td", null, rfq.needs_to_create_2d_files ? 'Yes' : 'No')),
            React.createElement("tr", null,
                React.createElement("th", null, "Shipping Address"),
                React.createElement("td", null,
                    React.createElement("address", null,
                        rfq.shipping_name,
                        " \u00A0",
                        rfq.shipping_street,
                        " \u00A0",
                        rfq.shipping_state,
                        " \u00A0",
                        rfq.shipping_country,
                        " \u00A0",
                        rfq.shipping_zip_code,
                        " \u00A0"))),
            rfq.target_lead_time && (React.createElement("tr", null,
                React.createElement("th", null, "Target Lead Time"),
                React.createElement("td", null,
                    React.createElement("address", null, rfq.target_lead_time)))),
            rfq.other_notes && (React.createElement("tr", null,
                React.createElement("th", null, "Other Notes"),
                React.createElement("td", null,
                    React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: rfq.other_notes,
                        } })))),
            rfq.quote.customer_statement && (React.createElement("tr", null,
                React.createElement("th", null, "Customer Statement"),
                React.createElement("td", null,
                    React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: rfq.quote.customer_statement,
                        } })))),
            rfq.quote.requote_changes && (React.createElement("tr", null,
                React.createElement("th", null, "Requote Changes"),
                React.createElement("td", null,
                    React.createElement("ul", null, rfq.quote.requote_changes.map(function (r) { return (React.createElement("li", { key: r }, r)); }))))),
            rfq.quote.domestic_quoting && (React.createElement("tr", null,
                React.createElement("th", null, "Domestic Quoting"),
                React.createElement("td", null,
                    React.createElement(Badge, { count: 'Yes', style: { backgroundColor: '#5cad0d' } })))),
            rfq.quote.customer_supplied_material && (React.createElement("tr", null,
                React.createElement("th", null, "Customer Supplied Material"),
                React.createElement("td", null,
                    React.createElement(Badge, { count: 'Yes', style: { backgroundColor: '#18adaa' } })))),
            React.createElement("tr", null,
                React.createElement("th", null, "Additional Requirements"),
                React.createElement("td", null,
                    React.createElement("ul", null,
                        React.createElement("table", null,
                            React.createElement("tbody", null,
                                React.createElement("tr", null,
                                    React.createElement("td", null,
                                        React.createElement("ul", null, (_b = rfq.quote.custom_requirements) === null || _b === void 0 ? void 0 : _b.map(function (r) {
                                            var _a;
                                            return (React.createElement("li", { key: r.id },
                                                React.createElement("span", { className: "font-weight bold" }, r.label),
                                                r.value && r.value, (_a = r.files) === null || _a === void 0 ? void 0 :
                                                _a.map(function (f) {
                                                    return (React.createElement("a", { key: f.id, href: f.filename }, f.filename));
                                                })));
                                        }))))))))),
            React.createElement("tr", null,
                React.createElement("th", null, "Customer RFQ Reference"),
                React.createElement("td", null, (_d = (_c = rfq.quote) === null || _c === void 0 ? void 0 : _c.customer_rfq_reference) !== null && _d !== void 0 ? _d : ''))),
        React.createElement("div", { className: 'py-4' },
            React.createElement("h1", { className: 'font-bold' }, "Products")),
        rfq.rfq_items.map(function (r, index) {
            var _a, _b, _c;
            return (React.createElement("div", { key: "p-" + (index + 1), className: "product-container", style: {
                    border: '1px solid #dee2e6',
                    borderRadius: '10px',
                    margin: '0px 4px 5px',
                    padding: '5px',
                    display: 'flex',
                    flexWrap: 'wrap',
                } }, [
                { label: '3D File:', value: ((_a = r.file3d) === null || _a === void 0 ? void 0 : _a.filename) || r.file3d_name || EMPTY_DATA_VALUE },
                { label: '2D File:', value: ((_b = r.file2d) === null || _b === void 0 ? void 0 : _b.filename) || r.file2d_name || EMPTY_DATA_VALUE },
                { label: 'Other Files:', value: r.other_files_name || EMPTY_DATA_VALUE },
                { label: 'Quantities:', value: ((_c = r.quantities) === null || _c === void 0 ? void 0 : _c.toString()) || EMPTY_DATA_VALUE },
                { label: 'Materials:', value: r.materials || EMPTY_DATA_VALUE },
                { label: 'Coating:', value: r.coating || EMPTY_DATA_VALUE },
                { label: 'Finish:', value: r.finish || EMPTY_DATA_VALUE },
                { label: 'Notes:', value: r.notes || EMPTY_DATA_VALUE },
                { label: 'Lead Time:', value: r.lead_time || EMPTY_DATA_VALUE },
                { label: 'Target Price:', value: r.target_price || EMPTY_DATA_VALUE },
                { label: 'Unit Price:', value: r.unit_price || EMPTY_DATA_VALUE },
            ].map(function (_a, index) {
                var label = _a.label, value = _a.value;
                return (React.createElement("div", { key: "pi-" + (index + 1), className: "product-item", style: {
                        margin: 2,
                        flex: '1 1 140px',
                        fontSize: '12px',
                    } },
                    React.createElement("strong", { className: "product-item-label", style: {
                            marginRight: 2,
                        } }, label),
                    React.createElement("span", null, value)));
            })));
        }),
        React.createElement("div", { className: 'py-4' },
            React.createElement("h1", { className: 'font-bold' }, "Additional Files")),
        React.createElement("ul", null,
            React.createElement("li", null, ((_e = rfq.quote.additional_files) === null || _e === void 0 ? void 0 : _e.length) ? ((_f = rfq.quote.additional_files) === null || _f === void 0 ? void 0 : _f.map(function (f) { return (React.createElement("div", { key: f.id },
                React.createElement("a", { target: "_blank", rel: "noreferrer", href: f.file }, f.filename))); })) : (React.createElement("div", null, "None")))),
        React.createElement("br", null)));
});
