import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, TimePicker, Button, message } from 'antd';
import moment from 'moment';
import SalesOrderStore from '../../Stores/SalesOrderStore';
var ModalMeeting = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = props.form, getFieldDecorator = _b.getFieldDecorator, getFieldValue = _b.getFieldValue, setFieldsValue = _b.setFieldsValue;
    var handleSubmit = function (e) {
        e.preventDefault();
        props.form.validateFields(function (err, values) {
            if (!err) {
                var date = moment(values.date).format('YYYY-MM-DD');
                var time = moment(values.time).format('HH:mm');
                var datetime = date + " " + time;
                var params = {
                    datetime: datetime,
                    type: props.type_review,
                };
                setLoading(true);
                SalesOrderStore.updateMeetingTime(props.sales_order_id, params)
                    .then(function (response) {
                    message.success('Meeting notification sent', 3);
                    props.handleClose();
                    props.handleSubmit();
                })
                    .catch(function (error) {
                    message.error(error.message);
                })
                    .finally(function () {
                    setLoading(false);
                });
            }
        });
    };
    var disabledDate = function (current) {
        // Can not select days before today
        return current && current < moment().add('-1', 'days').endOf('day');
    };
    return (React.createElement(Modal, { title: "Set Meeting Datetime", visible: true, onCancel: props.handleClose, footer: null },
        React.createElement(Form, { className: "text-center", layout: "inline", onSubmit: handleSubmit },
            React.createElement(Form.Item, { label: "Date" }, getFieldDecorator('date', {
                rules: [{ required: true, message: 'Please select a date!' }],
                initialValue: '',
            })(React.createElement(DatePicker, { disabledDate: disabledDate }))),
            React.createElement(Form.Item, { label: "Time" }, getFieldDecorator('time', {
                rules: [{ required: true, message: 'Please select a hour!' }],
                initialValue: '',
            })(React.createElement(TimePicker, { format: "HH:mm" }))),
            React.createElement("div", { className: "mt-4" },
                React.createElement(Button, { type: "primary", htmlType: "submit", loading: loading }, "Send Notification")))));
};
export default Form.create()(ModalMeeting);
