// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 *
 * HTTP URL and WEB SOCKETS URL
 */
var _a, _b, _c, _d, _e;
export var QUICKTURN_API_ROOT = (_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_QUICKTURN_API_URL;
export var API_ROOT = ((_b = process === null || process === void 0 ? void 0 : process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_API_URL)
    ? process.env.REACT_APP_API_URL
    : 'https://api.parts-badger.com';
export var BASE_URL_WS = API_ROOT.replace('http', 'ws');
export var GOOGLE_CLIENT_ID = '936736108436-06m75u4bvu4qrgfa9u9p8m8ehs2oh771.apps.googleusercontent.com';
export var GOOGLE_MAPS_API_KEY = 'AIzaSyDZavt2tEQruj9qmuOUUAvzjjI4sdXopTk';
export var ZOHO_BASE_URL = process.env.REACT_APP_ZOHO_URL
    ? process.env.REACT_APP_ZOHO_URL
    : 'https://crm.zoho.com/crm/org633043268/tab/';
export var NOHO_API_URL = process.env.REACT_APP_NOHO_API_URL
    ? process.env.REACT_APP_NOHO_API_URL
    : 'https://zsync.parts-badger.com';
/**
 * Square Payment
 */
export var SQUARE_APP_ID = ((_c = process === null || process === void 0 ? void 0 : process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_SQUARE_APP_ID)
    ? process.env.REACT_APP_SQUARE_APP_ID
    : 'sq0idp-uB3It19uQ4b4DZ72Q33f6A';
export var SQUARE_LOCATION_ID = ((_d = process === null || process === void 0 ? void 0 : process.env) === null || _d === void 0 ? void 0 : _d.REACT_APP_SQUARE_LOCATION_ID)
    ? process.env.REACT_APP_SQUARE_LOCATION_ID
    : 'B2YZK1TKA3VKQ';
export var SQUARE_ENVIRONMENT_URL = ((_e = process === null || process === void 0 ? void 0 : process.env) === null || _e === void 0 ? void 0 : _e.REACT_APP_SQUARE_ENVIRONMENT_URL)
    ? process.env.REACT_APP_SQUARE_ENVIRONMENT_URL
    : 'https://web.squarecdn.com/v1/square.js';
