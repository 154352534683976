var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, InputNumber, Row, Select } from 'antd';
import { Coatings, Finishes, Formulas, SelectReinforcement } from '../../components/Select';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { Materials } from '../../components/Select/Materials';
var Option = Select.Option;
var smallLayout = {
    xs: 8,
    sm: 2,
    md: 2,
    style: {
        textAlign: 'center',
    },
};
var largeLayout = {
    xs: 8,
    sm: 2,
    md: 2,
    style: {
        textAlign: 'center',
    },
};
var QuoteLineItemForm = function (_a) {
    var _b, _c, _d, _e;
    var item = _a.item, handleSubmit = _a.handleSubmit, isReadOnly = _a.isReadOnly, form = _a.form;
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue;
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var onSubmit = function () {
        form.validateFields(function (err, values) {
            if (!err) {
                setIsLoading(true);
                handleSubmit(values)
                    .then(function () {
                    form.resetFields();
                })
                    .finally(function () { return setIsLoading(false); });
            }
        });
    };
    var manufacturing_type = getFieldValue('manufacturing_type');
    var save_changes = form.isFieldsTouched() ? 'Save Changes' : 'No Changes';
    var selectedMaterialName = (_b = getFieldValue('product__material')) === null || _b === void 0 ? void 0 : _b.label;
    var materials = QuoteStore.materials.map(function (m) {
        return {
            id: m.id,
            name: m.name,
        };
    });
    var coatings = QuoteStore.coatings.map(function (m) {
        return {
            id: m.id,
            name: m.name,
            needs_manual_adjust: m.needs_manual_adjust,
        };
    });
    var finishes = QuoteStore.finishes.map(function (m) {
        return {
            id: m.id,
            name: m.name,
            metric_name: m.metric_name,
            needs_manual_adjust: m.needs_manual_adjust,
        };
    });
    return (React.createElement(Form, { layout: 'inline' },
        React.createElement(Row, null,
            React.createElement(Col, __assign({}, smallLayout), "Manufacturing"),
            React.createElement(Col, __assign({}, smallLayout), "Formula"),
            React.createElement(Col, __assign({}, largeLayout), "Material"),
            React.createElement(Col, __assign({}, smallLayout), "FAI Required"),
            manufacturing_type === '3D PRINTING' && (React.createElement(React.Fragment, null,
                React.createElement(Col, __assign({}, largeLayout), "Reinforcement"),
                React.createElement(Col, __assign({}, largeLayout), "Reinforcement %"),
                React.createElement(Col, __assign({}, smallLayout), "Design Time"))),
            React.createElement(Col, __assign({}, smallLayout), "Sides"),
            React.createElement(Col, __assign({}, largeLayout), "Finish"),
            React.createElement(Col, __assign({}, largeLayout), "Coating"),
            React.createElement(Col, __assign({}, smallLayout), "Adjust"),
            React.createElement(Col, __assign({}, smallLayout), "Vendor Adjust")),
        React.createElement(Row, null,
            React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('manufacturing_type', {
                initialValue: item.manufacturing_type,
            })(React.createElement(Select, { disabled: isReadOnly, size: "small" },
                React.createElement(Option, { value: "CNC" }, "CNC"),
                React.createElement(Option, { value: "3D PRINTING" }, "3D")))),
            React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('formula', {
                initialValue: item.formula,
            })(React.createElement(Formulas, { disabled: isReadOnly, selectedMaterialName: selectedMaterialName, item: item }))),
            React.createElement(Col, __assign({}, largeLayout), getFieldDecorator('product__material', __assign({}, (item.product__material && { initialValue: item.product__material })))(React.createElement(Materials, { materials: materials, handleSearch: function (params) { return QuoteStore.getMaterials(params); }, disabled: isReadOnly, suggestions: ((_c = item.drawing_file) === null || _c === void 0 ? void 0 : _c.suggestions)
                    ? item.drawing_file.suggestions
                        .filter(function (s) { var _a; return (_a = s.suggested_material) === null || _a === void 0 ? void 0 : _a.id; })
                        .map(function (s) {
                        var _a, _b, _c, _d, _e, _f;
                        return {
                            id: (_b = (_a = s.suggested_material) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                            name: (_d = (_c = s.suggested_material) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                            thumbnail: (_f = (_e = s.cropped_image) === null || _e === void 0 ? void 0 : _e.file) !== null && _f !== void 0 ? _f : '',
                        };
                    })
                    : [] }))),
            React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('is_fai_required', {
                initialValue: item.is_fai_required,
                valuePropName: 'checked',
            })(React.createElement(Checkbox, { disabled: isReadOnly }))),
            manufacturing_type === '3D PRINTING' && (React.createElement(React.Fragment, null,
                React.createElement(Col, __assign({}, largeLayout), getFieldDecorator('reinforcement', {
                    initialValue: item.reinforcement ? item.reinforcement : 'carbon_fiber',
                })(React.createElement(SelectReinforcement, { disabled: isReadOnly }))),
                React.createElement(Col, __assign({}, largeLayout), getFieldDecorator('reinforcement_percent', {
                    initialValue: item.reinforcement_percent ? item.reinforcement_percent : '',
                })(React.createElement(Input, { size: "small", style: { width: '100%' }, disabled: isReadOnly }))),
                React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('design_time', {
                    initialValue: item.design_time,
                })(React.createElement(InputNumber, { size: "small", disabled: isReadOnly }))))),
            React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('product__sides', {
                initialValue: item.product__sides,
            })(React.createElement(Input, { size: "small", style: { width: '100%' }, disabled: isReadOnly }))),
            React.createElement(Col, __assign({}, largeLayout), getFieldDecorator('product__finishes', __assign({}, (item.product__finish && { initialValue: item.product__finish })))(React.createElement(Finishes, { finishes: finishes, handleSearch: function (params) { return QuoteStore.getFinishes(params); }, disabled: isReadOnly, suggestions: ((_d = item.drawing_file) === null || _d === void 0 ? void 0 : _d.suggestions)
                    ? item.drawing_file.suggestions
                        .filter(function (s) { var _a; return (_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.id; })
                        .map(function (s) {
                        var _a, _b, _c, _d, _e, _f;
                        return {
                            id: (_b = (_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                            name: (_d = (_c = s.suggested_finish) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                            thumbnail: (_f = (_e = s.cropped_image) === null || _e === void 0 ? void 0 : _e.file) !== null && _f !== void 0 ? _f : '',
                        };
                    })
                    : [] }))),
            React.createElement(Col, __assign({}, largeLayout), getFieldDecorator('product__coatings', __assign({}, (item.product__coating && { initialValue: item.product__coating })))(React.createElement(Coatings, { coatings: coatings, handleSearch: function (params) { return QuoteStore.getCoatings(params); }, disabled: isReadOnly, suggestions: ((_e = item.drawing_file) === null || _e === void 0 ? void 0 : _e.suggestions)
                    ? item.drawing_file.suggestions
                        .filter(function (s) { var _a; return (_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.id; })
                        .map(function (s) {
                        var _a, _b, _c, _d, _e, _f;
                        return {
                            id: (_b = (_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
                            name: (_d = (_c = s.suggested_coating) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                            thumbnail: (_f = (_e = s.cropped_image) === null || _e === void 0 ? void 0 : _e.file) !== null && _f !== void 0 ? _f : '',
                        };
                    })
                    : [] }))),
            React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('manual_adjustment', {
                initialValue: item.manual_adjustment,
            })(React.createElement(InputNumber, { disabled: isReadOnly, size: "small" }))),
            React.createElement(Col, __assign({}, smallLayout), getFieldDecorator('vendor_price_adjust', {
                initialValue: item.vendor_price_adjust,
            })(React.createElement(InputNumber, { disabled: isReadOnly, size: "small" }))),
            !isReadOnly && (React.createElement(Col, __assign({}, smallLayout, { className: "ml-2" }),
                React.createElement(Button, { disabled: !form.isFieldsTouched(), size: "small", loading: isLoading, type: 'primary', htmlType: 'submit', onClick: onSubmit },
                    React.createElement(SaveOutlined, null),
                    " ",
                    isLoading ? 'Saving' : save_changes))))));
};
export default Form.create()(observer(QuoteLineItemForm));
