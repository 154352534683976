import React, { Fragment, useEffect, useState } from 'react';
import { DownOutlined, LogoutOutlined, UserOutlined, BgColorsOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Menu } from 'antd';
import MenuBar from './Menu';
import { useHistory } from 'react-router';

import { INotification } from '../../utils/types';
import { hasPermission } from './User';
import Notifications from './Notifications';
import { NotificationStore } from '../stores';

interface Props {
    user: any;
    handleLogout: () => void;
}

const DARK_MODE = 'dark-mode';

const Header: React.FC<Props> = ({ user, handleLogout }) => {
    const [data, setData] = useState<INotification[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    const username = user.fullname;
    const auth = !!user?.id;
    const darkMode = window.localStorage.getItem(DARK_MODE) == 'yes';

    const handleSwitchTheme = () => {
        window.localStorage.setItem(DARK_MODE, darkMode ? 'no' : 'yes');
        window.location.reload();
    };

    const menu = (
        <Menu style={{ width: '200px' }}>
            <Menu.Item key="theme-switcher" onClick={handleSwitchTheme}>
                <BgColorsOutlined /> Switch to {darkMode ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item>
            <Menu.Divider />
            {auth && (
                <Menu.Item key="logout" onClick={handleLogout}>
                    <LogoutOutlined /> <span>Log Out</span>
                </Menu.Item>
            )}
        </Menu>
    );

    useEffect(() => {
        async function getNotifications() {
            setLoading(true);

            try {
                const response = await NotificationStore.getNotifications();
                setData(response);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }

        getNotifications();
    }, []);

    async function makeRead(id: number) {
        const response = await NotificationStore.makeNotificationRead(id);

        setData(
            data.map(item => {
                if (item.id === response.id) {
                    return { ...item, read: true };
                }
                return item;
            })
        );
    }

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxHeight: '65px',
                }}
            >
                <div
                    className="row-logo"
                    style={{
                        width: '10%',
                    }}
                    onClick={() => history.push('/')}
                >
                    <img
                        style={{
                            width: '100%',
                        }}
                        src="https://partsbadger-static-files.s3.us-east-2.amazonaws.com/static/images/logo_full.svg"
                        className={'logo'}
                        alt=""
                    />
                </div>
                <div
                    className={'menu-header '}
                    style={{
                        width: '68%',
                    }}
                >
                    <MenuBar user={user} mode={'horizontal'} />
                </div>

                <div
                    className={'row-user'}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                        width: '22%',
                        marginRight: 15,
                    }}
                >
                    {hasPermission('quotes.add_quote') && (
                        <div className={'hidden xl:inline'}>
                            <Button
                                className={'step-one-true-form'}
                                onClick={() => {
                                    history.push('/get-quote');
                                }}
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#d92923',
                                    borderColor: '#d92923',
                                    marginLeft: 'auto',
                                }}
                            >
                                One True Form
                            </Button>
                        </div>
                    )}
                    <Notifications data={data} loading={loading} makeRead={makeRead} />
                    <span style={{ padding: ' 0 10px' }}>
                        <Avatar icon={<UserOutlined />} />
                    </span>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link" href={'#' + username} style={{ color: '#737373' }}>
                            {username} <DownOutlined />
                        </a>
                    </Dropdown>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
