var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Checkbox, Col, Collapse, DatePicker, Divider, Form, Input, InputNumber, message, Modal, notification, Row, Typography, Upload, } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { observer } from 'mobx-react';
import { AdditionalRequirements, FormErrorParser, Mobile, Price } from '@partsbadger/library';
import { CartItem } from './CartItem';
import QuoteStore from '../../stores/QuoteStore';
import { Accounts, AcquisitionSource, Purchasing, Users } from '../DropDowns';
import { API_ROOT, DownloadFile, get } from '@partsbadger/utils';
import { QuestionMark } from '../QuestionMark';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { CheckCircleOutlined } from '@ant-design/icons';
var Text = Typography.Text;
var TextArea = Input.TextArea;
export var CheckoutForm = observer(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    var history = useHistory();
    var _2 = useState(false), loading = _2[0], setLoading = _2[1];
    var _3 = useState(''), needByAnswerDate = _3[0], setNeedByAnswerDate = _3[1];
    var _4 = useState(null), formErrors = _4[0], setFormErrors = _4[1];
    var onQuoteSubmitted = props.onQuoteSubmitted;
    var form = Form.useForm()[0];
    var getFieldValue = form.getFieldValue;
    var line_items = (QuoteStore.quote || {}).line_items;
    var _5 = useState(false), quote_completed = _5[0], setQuoteCompleted = _5[1];
    var _6 = useState(''), quote_name = _6[0], setQuoteName = _6[1];
    var _7 = useState(0), quote_id = _7[0], setQuoteID = _7[1];
    var getFirstName = QuoteStore.getFirstName, getLastName = QuoteStore.getLastName, getEmail = QuoteStore.getEmail, getPhone = QuoteStore.getPhone, getCompany = QuoteStore.getCompany, getZip = QuoteStore.getZip, getTitle = QuoteStore.getTitle, for_staff = QuoteStore.for_staff, additional_requirements = QuoteStore.additional_requirements, getRelatedContact = QuoteStore.getRelatedContact, getAdditionalFiles = QuoteStore.getAdditionalFiles, contact = QuoteStore.contact;
    var quote = QuoteStore.quote;
    // If there are error fields in the line items, scroll to them on form submit and expand product if it is collapsed
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (((_a = QuoteStore.errorFields) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            if (QuoteStore.collapsedLineItems.includes(Number((_b = QuoteStore.errorFields[0]) === null || _b === void 0 ? void 0 : _b.lineItem))) {
                QuoteStore.setCollapsed((_c = QuoteStore.errorFields[0]) === null || _c === void 0 ? void 0 : _c.lineItem);
            }
            (_e = document.getElementById((_d = QuoteStore.errorFields[0]) === null || _d === void 0 ? void 0 : _d.lineItem)) === null || _e === void 0 ? void 0 : _e.scrollIntoView(false);
            setLoading(false);
        }
    }, [loading]);
    var handleSubmitRFQ = function (save_for_later) { return __awaiter(void 0, void 0, void 0, function () {
        var values_1, file_2d_required_1, payload, err_1;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    setLoading(true);
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, form.validateFields()];
                case 2:
                    values_1 = _g.sent();
                    if (((_a = QuoteStore.errorFields) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                        if (!((_b = quote === null || quote === void 0 ? void 0 : quote.line_items) === null || _b === void 0 ? void 0 : _b.length)) {
                            message.error('Please select your parts files');
                            return [2 /*return*/, null];
                        }
                        file_2d_required_1 = '';
                        line_items === null || line_items === void 0 ? void 0 : line_items.forEach(function (item) {
                            var _a, _b, _c, _d, _e;
                            if ((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.material.includes('Customer')) {
                                values_1.customer_supplied_material = true;
                            }
                            if (((_b = item === null || item === void 0 ? void 0 : item.product) === null || _b === void 0 ? void 0 : _b.material.includes('Review 2D')) && !((_c = item === null || item === void 0 ? void 0 : item.product) === null || _c === void 0 ? void 0 : _c.has_file_2d)) {
                                file_2d_required_1 = item.name;
                            }
                            (_d = item === null || item === void 0 ? void 0 : item.product) === null || _d === void 0 ? void 0 : _d.finishes.forEach(function (finish) {
                                var _a;
                                if (finish == 'Review 2D' && !((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.has_file_2d)) {
                                    file_2d_required_1 = item.name;
                                }
                            });
                            (_e = item === null || item === void 0 ? void 0 : item.product) === null || _e === void 0 ? void 0 : _e.coatings.forEach(function (coating) {
                                var _a;
                                if (coating == 'Review 2D' && !((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.has_file_2d)) {
                                    file_2d_required_1 = item.name;
                                }
                            });
                        });
                        payload = {
                            first_name: values_1.first_name,
                            last_name: values_1.last_name,
                            email: values_1.email,
                            phone: values_1.phone,
                            company: values_1.company,
                            other_notes: values_1.other_notes,
                            customer_statement: values_1.customer_statement,
                            requote_changes: values_1.requote_changes,
                            shipping_zip_code: values_1.zip_code,
                            customer_rfq_reference: values_1.customer_rfq_reference,
                            acquisition_source: values_1.acquisition_source,
                            source: QuoteStore.for_staff ? 'One True Form - Staff' : 'One True Form - Customer',
                            create_3d_files: values_1.create_3d_files || false,
                            create_2d_files: values_1.create_2d_files || false,
                            additional_files: (_d = (_c = values_1.additional_files) === null || _c === void 0 ? void 0 : _c.fileList) === null || _d === void 0 ? void 0 : _d.map(function (f) { var _a, _b; return (_b = (_a = f.response) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : Number(f.uid); }),
                            lead_time: values_1.lead_time,
                            is_a_referral: values_1.is_a_referral,
                            initiate_go_team: values_1.initiate_go_team,
                            domestic_quoting: values_1.domestic_quoting,
                            customer_supplied_material: values_1.customer_supplied_material,
                            purchasing_type: values_1.purchasing_type,
                            related_contact: values_1.related_contact,
                            title: values_1.title,
                            go_team_info: {
                                why: values_1.why || null,
                                need_by_answer: needByAnswerDate || null,
                            },
                        };
                        if ((_e = values_1.account) === null || _e === void 0 ? void 0 : _e.key) {
                            payload['account'] = values_1.account.key;
                            payload['company'] = values_1.account.label;
                        }
                        if ((_f = values_1 === null || values_1 === void 0 ? void 0 : values_1.owner) === null || _f === void 0 ? void 0 : _f.key) {
                            payload['owner'] = values_1.owner.key;
                        }
                        if (values_1.additional_requirements) {
                            payload['custom_requirements'] = values_1.additional_requirements;
                        }
                        if (save_for_later) {
                            payload['notes'] = values_1.other_notes;
                            QuoteStore.updateQuote(payload).finally(function () { return setLoading(false); });
                        }
                        else {
                            QuoteStore.sendToReview(payload)
                                .then(function (data) {
                                var _a;
                                setLoading(false);
                                if (data.quote_stage === 'Completed') {
                                    setQuoteCompleted(true);
                                    setQuoteID(data.quote_id);
                                    setQuoteName(data.quote_name);
                                }
                                else {
                                    QuoteStore.setQuote(null);
                                    if (onQuoteSubmitted) {
                                        onQuoteSubmitted(__assign({ user: data.user, first_name: values_1.first_name, last_name: values_1.last_name, email: values_1.email, company: values_1.company }, data));
                                    }
                                    var date = new Date();
                                    // Push google tracking event to window.dataLayer
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    (_a = window === null || window === void 0 ? void 0 : window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
                                        event: 'submitted-rfq',
                                        url: window.location.toString(),
                                        time: date.getTime(),
                                    });
                                }
                            })
                                .catch(function (err) {
                                message.error('Error');
                                setLoading(false);
                            });
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _g.sent();
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmitSaveForLater = function () {
        var _a, _b, _c, _d;
        line_items === null || line_items === void 0 ? void 0 : line_items.filter(function (item) { return !item.has_dynamic_quantities; });
        var file_2d_required = '';
        var customer_supplied_material = false;
        line_items === null || line_items === void 0 ? void 0 : line_items.forEach(function (item) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if ((_b = (_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.material) === null || _b === void 0 ? void 0 : _b.includes('Customer')) {
                customer_supplied_material = true;
            }
            if (((_d = (_c = item === null || item === void 0 ? void 0 : item.product) === null || _c === void 0 ? void 0 : _c.material) === null || _d === void 0 ? void 0 : _d.includes('Review 2D')) && !((_e = item === null || item === void 0 ? void 0 : item.product) === null || _e === void 0 ? void 0 : _e.has_file_2d)) {
                file_2d_required = item.name;
            }
            (_g = (_f = item === null || item === void 0 ? void 0 : item.product) === null || _f === void 0 ? void 0 : _f.finishes) === null || _g === void 0 ? void 0 : _g.forEach(function (finish) {
                var _a;
                if (finish == 'Review 2D' && !((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.has_file_2d)) {
                    file_2d_required = item.name;
                }
            });
            (_j = (_h = item === null || item === void 0 ? void 0 : item.product) === null || _h === void 0 ? void 0 : _h.coatings) === null || _j === void 0 ? void 0 : _j.forEach(function (coating) {
                var _a;
                if (coating == 'Review 2D' && !((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.has_file_2d)) {
                    file_2d_required = item.name;
                }
            });
        });
        if (file_2d_required != '') {
            message.error('2D file is required in ' + file_2d_required, 5);
            return null;
        }
        var payload = {
            first_name: form.getFieldValue('first_name'),
            last_name: form.getFieldValue('last_name'),
            email: form.getFieldValue('values.email'),
            phone: form.getFieldValue('values.phone'),
            company: form.getFieldValue('company'),
            other_notes: form.getFieldValue('other_notes'),
            customer_statement: form.getFieldValue('customer_statement'),
            requote_changes: form.getFieldValue('requote_changes'),
            shipping_zip_code: form.getFieldValue('zip_code'),
            customer_rfq_reference: form.getFieldValue('customer_rfq_reference'),
            acquisition_source: form.getFieldValue('acquisition_source'),
            source: QuoteStore.for_staff ? 'One True Form - Staff' : 'One True Form - Customer',
            create_3d_files: form.getFieldValue('create_3d_files') || false,
            create_2d_files: form.getFieldValue('create_2d_files') || false,
            additional_files: (_b = (_a = form
                .getFieldValue('additional_files')) === null || _a === void 0 ? void 0 : _a.fileList) === null || _b === void 0 ? void 0 : _b.map(function (f) { var _a, _b; return (_b = (_a = f.response) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : Number(f.uid); }),
            lead_time: form.getFieldValue('lead_time'),
            is_a_referral: form.getFieldValue('is_a_referral'),
            initiate_go_team: form.getFieldValue('initiate_go_team') || false,
            domestic_quoting: form.getFieldValue('domestic_quoting'),
            customer_supplied_material: customer_supplied_material,
            purchasing_type: form.getFieldValue('purchasing_type'),
            related_contact: form.getFieldValue('related_contact'),
            title: form.getFieldValue('title'),
            go_team_info: {
                why: form.getFieldValue('why') || null,
                need_by_answer: needByAnswerDate || null,
            },
        };
        if ((_c = form.getFieldValue('account')) === null || _c === void 0 ? void 0 : _c.key) {
            payload['account'] = form.getFieldValue('account').key;
            payload['company'] = form.getFieldValue('account').label;
        }
        if ((_d = form.getFieldValue('owner')) === null || _d === void 0 ? void 0 : _d.key) {
            payload['owner'] = form.getFieldValue('owner').key;
        }
        if (form.getFieldValue('additional_requirements')) {
            payload['custom_requirements'] = form.getFieldValue('additional_requirements');
        }
        payload['notes'] = form.getFieldValue('other_notes');
        setLoading(true);
        QuoteStore.updateQuote(payload).finally(function () { return setLoading(false); });
        notification.success({ message: 'Saved it for later!' });
    };
    var downloadQuote = function (quote_id, quote_name) {
        get("/staff/quotes/" + quote_id + "/export/", { responseType: 'blob' }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, DownloadFile(response, "quote-" + quote_name + ".pdf")];
                    case 1:
                        _a.sent();
                        notification.success({ message: 'Quote PDF Downloaded' });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    var debouncedFormValidation = React.useCallback(_.debounce(function () {
        form.validateFields()
            .then(function (values) {
            setFormErrors(null);
        })
            .catch(function (data) {
            setFormErrors(data.errorFields);
        });
    }, 500), []);
    var onValuesChange = function () {
        debouncedFormValidation();
    };
    var user = QuoteStore.current_user;
    var popup = function (url) {
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, colon: false, onValuesChange: onValuesChange, onFinish: function () { return handleSubmitRFQ(false); }, onFinishFailed: function (errorInfo) {
                setFormErrors(errorInfo.errorFields);
            }, layout: "vertical", scrollToFirstError: true, initialValues: {
                lead_time: quote === null || quote === void 0 ? void 0 : quote.lead_time,
            }, className: 'checkout' },
            React.createElement("input", { type: "hidden", id: "zc_gad", name: "zc_gad", value: "" }),
            React.createElement(Row, { gutter: [24, 0] },
                React.createElement(Col, { md: 12, span: 24 },
                    React.createElement("h2", { className: "my-4 font-bold", style: { fontSize: '1.5rem' } }, "Delivery & Requirements"),
                    React.createElement(Card, null,
                        React.createElement(Row, { gutter: [12, 0] },
                            React.createElement(Col, { md: 24 },
                                React.createElement(Form.Item, { name: "lead_time", rules: [{ required: false, message: 'Please select a value!' }], initialValue: quote === null || quote === void 0 ? void 0 : quote.lead_time, label: React.createElement("span", null,
                                        "Target Lead Time",
                                        React.createElement(QuestionMark, null, "If you have a required lead time that you would like to meet, please enter it in business days.")) },
                                    React.createElement(InputNumber, { min: 1, size: "large", className: "w-full", style: { width: '100%' }, onBlur: function (e) {
                                            var value = e.target.value ? parseInt(e.target.value) : null;
                                            QuoteStore.update_quote_field('lead_time', value);
                                        } }))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "customer_supplied_material", rules: [
                                        {
                                            required: false,
                                        },
                                    ], initialValue: quote === null || quote === void 0 ? void 0 : quote.customer_supplied_material, valuePropName: 'checked', className: "mb-0" },
                                    React.createElement(Checkbox, { onChange: function (e) {
                                            QuoteStore.update_quote_field('customer_supplied_material', e.target.checked);
                                        } }, "Customer Supplied Material"))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "domestic_quoting", rules: [
                                        {
                                            required: false,
                                        },
                                    ], initialValue: quote === null || quote === void 0 ? void 0 : quote.domestic_quoting, valuePropName: 'checked' },
                                    React.createElement(Checkbox, { onChange: function (e) {
                                            QuoteStore.update_quote_field('domestic_quoting', e.target.checked);
                                        } }, "Domestic Production Required"))),
                            QuoteStore.needs2DFiles() ? (React.createElement(Col, { xl: 24, span: 24 },
                                React.createElement(Form.Item, { className: "mb-0", name: "create_2d_files", valuePropName: "checked", initialValue: quote === null || quote === void 0 ? void 0 : quote.create_2d_files },
                                    React.createElement(Checkbox, { onChange: function (e) {
                                            QuoteStore.update_quote_field('create_2d_files', e.target.checked);
                                        } },
                                        React.createElement(Text, { strong: true }, "Create 2D Files"))))) : null,
                            QuoteStore.needs3DFiles() ? (React.createElement(Col, { xl: 24, span: 24 },
                                React.createElement(Form.Item, { className: "mb-0", name: "create_3d_files", valuePropName: "checked", initialValue: quote === null || quote === void 0 ? void 0 : quote.create_3d_files },
                                    React.createElement(Checkbox, { defaultChecked: false, onChange: function (e) {
                                            QuoteStore.update_quote_field('create_3d_files', e.target.checked);
                                        } },
                                        React.createElement(Text, { strong: true }, "Create 3D Files"))))) : null,
                            QuoteStore.needs3DFiles() || QuoteStore.needs2DFiles() ? React.createElement(Divider, null) : null,
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "additional_requirements", rules: [{ required: false, message: 'Please select a value!' }], initialValue: (_a = quote === null || quote === void 0 ? void 0 : quote.custom_requirements) !== null && _a !== void 0 ? _a : [], label: React.createElement(React.Fragment, null,
                                        "Additional Requirements",
                                        React.createElement(QuestionMark, null, "Please select any additional requirements like material certificate, First Article Inspection, Hardness Testing, etc. that you would like to be included with this quote.")), className: "mb-0" },
                                    React.createElement(AdditionalRequirements, { requirements: additional_requirements, onChange: function (values) {
                                            QuoteStore.update_quote_field('custom_requirements', values.map(function (v) {
                                                var _a;
                                                return {
                                                    key: v.key.toString(),
                                                    label: v.label,
                                                    value: (_a = v === null || v === void 0 ? void 0 : v.value) !== null && _a !== void 0 ? _a : '',
                                                    // files: v.files,
                                                };
                                            }));
                                        } }))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "additional_files", label: React.createElement("span", null,
                                        "Additional Files",
                                        React.createElement(QuestionMark, null, "Please upload any additional files like NDA, Tax Exempt Form, Photos, etc.")), rules: [{ required: false, message: 'Please select a value!' }] },
                                    React.createElement(Upload.Dragger, { multiple: true, name: "file", showUploadList: true, action: API_ROOT + "/customer/files/", defaultFileList: getAdditionalFiles, "data-testid": "upload-file" },
                                        React.createElement("div", { className: "d-flex align-items-center align-middle justify-content-center mb-2 text-lg" },
                                            React.createElement(FeatherIcon, { icon: "upload-cloud" }),
                                            React.createElement("span", { className: "ml-2" }, "Click or drag files")),
                                        React.createElement("p", null, "Add any additional files like NDAs, Tax Exempt Forms, Photos, etc.")))),
                            QuoteStore.isRequote && (React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "requote_changes", label: "ReQuote Changes", help: 'What changes did you make to ReQuote?', rules: [{ required: true, message: 'Please select a value!' }] },
                                    React.createElement(Checkbox.Group, { options: [
                                            { label: 'Quantity', value: 'Quantity' },
                                            { label: 'Lead Time', value: 'Lead Time' },
                                            { label: 'Material', value: 'Material' },
                                            { label: 'Coating or Finish', value: 'Coating or Finish' },
                                            { label: 'New File Revision', value: 'New File Revision' },
                                            { label: 'Add/Remove Lines', value: 'Add/Remove Lines' },
                                            { label: 'Pricing', value: 'Pricing' },
                                            {
                                                label: 'Additional Requirements',
                                                value: 'Additional Requirements',
                                            },
                                            { label: 'Refresh', value: 'Refresh' },
                                        ] })))),
                            for_staff && (React.createElement(Col, { span: 24 },
                                React.createElement(Form.Item, { name: "initiate_go_team", initialValue: quote === null || quote === void 0 ? void 0 : quote.initiate_go_team, valuePropName: 'checked' },
                                    React.createElement(Checkbox, { onChange: function (e) {
                                            QuoteStore.update_quote_field('initiate_go_team', e.target.checked);
                                        } },
                                        React.createElement(Text, { strong: true }, "Submit to the initiate Go team?"),
                                        React.createElement("br", null),
                                        React.createElement(Text, { type: "secondary" }, "Select to submit the RFQ/ReQuote to the initiate Go team"))),
                                getFieldValue('initiate_go_team') && (React.createElement(Col, { md: 24 },
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 12 },
                                            React.createElement(Form.Item, { name: "why", rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select what do you need the Go Team for',
                                                    },
                                                ], initialValue: (_d = (_c = (_b = QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.go_team_info) === null || _c === void 0 ? void 0 : _c.why) !== null && _d !== void 0 ? _d : [], label: "Why?", help: 'What do you need the Go Team for?' },
                                                React.createElement(Checkbox.Group, { options: [
                                                        { label: 'Pricing', value: 'Pricing' },
                                                        { label: 'Lead Time', value: 'Lead Time' },
                                                        { label: 'Fast Lane', value: 'Fast Lane' },
                                                    ] }))),
                                        React.createElement(Col, { md: 12 },
                                            React.createElement(Form.Item, { name: "need_by_answer", label: "Need by Answer:", rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please insert a date!',
                                                    },
                                                ], initialValue: ((_f = (_e = QuoteStore.quote) === null || _e === void 0 ? void 0 : _e.go_team_info) === null || _f === void 0 ? void 0 : _f.need_by_answer)
                                                    ? moment(QuoteStore.quote.go_team_info.need_by_answer)
                                                    : null },
                                                React.createElement(DatePicker, { onChange: function (date, dateString) {
                                                        return setNeedByAnswerDate(dateString);
                                                    } })))))))),
                            QuoteStore.isRequote && (React.createElement(Col, { md: 24 },
                                React.createElement(Form.Item, { name: "customer_statement", label: React.createElement("span", null, props.for_staff === false
                                        ? 'Please note why you would like to make these changes'
                                        : 'Customer Statement and Additional Notes'), rules: [
                                        {
                                            required: true,
                                            message: 'Please insert the customer statement!',
                                        },
                                    ], help: 'Copy and paste the customer statement', initialValue: (_h = (_g = QuoteStore.quote) === null || _g === void 0 ? void 0 : _g.notes) !== null && _h !== void 0 ? _h : '' },
                                    React.createElement(TextArea, null)))),
                            !QuoteStore.isRequote && (React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "other_notes", label: "Additional Notes", initialValue: (_k = (_j = QuoteStore.quote) === null || _j === void 0 ? void 0 : _j.notes) !== null && _k !== void 0 ? _k : '', className: "mb-0" },
                                    React.createElement(TextArea, { style: { height: '100px' }, onBlur: function (e) {
                                            QuoteStore.update_quote_field('notes', e.target.value);
                                        } })))),
                            QuoteStore.isRequote && QuoteStore.for_staff && (React.createElement(Col, { md: 24 },
                                React.createElement(Form.Item, { name: "owner", label: "Owner", initialValue: {
                                        key: user === null || user === void 0 ? void 0 : user.id,
                                        label: user === null || user === void 0 ? void 0 : user.fullname,
                                    }, help: 'If empty the account owner will be used' },
                                    React.createElement(Users, null))))),
                        !QuoteStore.isRequote && QuoteStore.for_staff ? (React.createElement(Row, { gutter: [8, 0] },
                            !getFirstName && (React.createElement(Col, { xl: 12, span: 24 },
                                React.createElement(Form.Item, { name: "first_name", label: "First Name", rules: [
                                        {
                                            required: true,
                                            message: 'Please input the first name',
                                        },
                                    ], initialValue: getFirstName, hasFeedback: true },
                                    React.createElement(Input, null)))),
                            !getLastName && (React.createElement(Col, { xl: 12, span: 24 },
                                React.createElement(Form.Item, { name: "last_name", label: "Last Name", rules: [
                                        {
                                            required: true,
                                            message: 'Please input the last name',
                                        },
                                    ], initialValue: getLastName, hasFeedback: true },
                                    React.createElement(Input, null)))),
                            React.createElement(Col, { xl: 12, span: 24 },
                                React.createElement(Form.Item, { name: "account", label: "Account", initialValue: ((_l = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _l === void 0 ? void 0 : _l.account_related)
                                        ? {
                                            key: (_o = (_m = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _m === void 0 ? void 0 : _m.account_related) === null || _o === void 0 ? void 0 : _o.id,
                                            label: (_q = (_p = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _p === void 0 ? void 0 : _p.account_related) === null || _q === void 0 ? void 0 : _q.name,
                                        }
                                        : null, rules: [
                                        {
                                            required: true,
                                            message: 'Please select an account',
                                        },
                                    ], hasFeedback: true, help: React.createElement("div", null,
                                        "If you don't see the account?",
                                        React.createElement("a", { target: "_blank", href: 'https://crm.zoho.com/crm/org633043268/tab/Accounts/create', rel: "noreferrer" }, "create here")) },
                                    React.createElement(Accounts, null))),
                            QuoteStore.isNewContact && (React.createElement(Col, { md: 12, span: 24 },
                                React.createElement(Form.Item, { name: "is_a_referral", valuePropName: 'checked' },
                                    React.createElement(Checkbox, null, "Is this a new referral from an Existing Contact or Lead?")))),
                            React.createElement(React.Fragment, null,
                                React.createElement(Col, { xl: 12, span: 24 },
                                    React.createElement(Form.Item, { name: "acquisition_source", label: "Acquisition Source", rules: [
                                            {
                                                required: true,
                                                message: 'Please input an Acquisition Source',
                                            },
                                        ], initialValue: (_s = (_r = QuoteStore.quote) === null || _r === void 0 ? void 0 : _r.acquisition_source) !== null && _s !== void 0 ? _s : '' },
                                        React.createElement(AcquisitionSource, { is_new_contact: QuoteStore.isNewContact }))),
                                React.createElement(React.Fragment, null,
                                    React.createElement(Col, { xl: 12, span: 24 },
                                        React.createElement(Form.Item, { name: "related_contact", label: "Related Contact", rules: [{ type: 'email' }], initialValue: getRelatedContact, hasFeedback: true },
                                            React.createElement(Input, { style: { maxWidth: 300 } }))),
                                    React.createElement(Col, { xl: 12, span: 24 },
                                        React.createElement(Form.Item, { name: "purchasing_type", label: "Purchasing type", rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select Purchasing Type',
                                                },
                                            ], initialValue: (_v = (_u = (_t = QuoteStore.quote) === null || _t === void 0 ? void 0 : _t.account_related) === null || _u === void 0 ? void 0 : _u.purchasing_type) !== null && _v !== void 0 ? _v : null },
                                            React.createElement(Purchasing, null)))),
                                React.createElement(Col, { md: 24 },
                                    React.createElement(Form.Item, { name: "owner", label: "Owner", initialValue: {
                                            key: user === null || user === void 0 ? void 0 : user.id,
                                            label: user === null || user === void 0 ? void 0 : user.fullname,
                                        }, help: 'If empty the account owner will be used' },
                                        React.createElement(Users, null)))),
                            ((_w = QuoteStore.default_documents) === null || _w === void 0 ? void 0 : _w.length) > 0 && (React.createElement(Col, { md: 24, className: 'py-4' },
                                React.createElement(Collapse, { accordion: true },
                                    React.createElement(Collapse.Panel, { header: "Review the uploaded documents for the contact and account.", key: "1" }, QuoteStore.default_documents.map(function (document) {
                                        return (React.createElement(React.Fragment, null,
                                            React.createElement("a", { onClick: function () { return popup(document.file); }, rel: "noreferrer" }, document.filename),
                                            React.createElement("br", null)));
                                    }))))),
                            React.createElement(Col, { md: 24 },
                                React.createElement(Form.Item, { name: "customer_rfq_reference", label: "Customer RFQ reference", initialValue: quote === null || quote === void 0 ? void 0 : quote.customer_rfq_reference, hasFeedback: true },
                                    React.createElement(TextArea, { onBlur: function (e) {
                                            QuoteStore.update_quote_field('customer_rfq_reference', e.target.value);
                                        } }))))) : null)),
                React.createElement(Col, { md: 12, span: 24 },
                    React.createElement("h2", { className: "my-4 font-bold", style: { fontSize: '1.5rem' } }, "Quote Summary"),
                    React.createElement(Card, null,
                        React.createElement("div", null,
                            QuoteStore.quote && QuoteStore.quote.line_items.length >= 1 && (React.createElement(Row, null, (_y = (_x = QuoteStore.quote) === null || _x === void 0 ? void 0 : _x.line_items) === null || _y === void 0 ? void 0 :
                                _y.map(function (item) {
                                    return (React.createElement(Col, { sm: 24, key: item.id, style: {
                                            marginBottom: 10,
                                        } },
                                        React.createElement(CartItem, { key: item.id, item: item })));
                                }),
                                React.createElement(Divider, { type: "horizontal" }))),
                            !QuoteStore.needsRFQ() && (React.createElement(Row, null,
                                React.createElement(Col, { md: 16 },
                                    React.createElement(Text, { className: "font-size-lg" }, "Total")),
                                React.createElement(Col, { md: 8, className: "text-right" },
                                    React.createElement(Text, { strong: true, className: "font-size-lg" },
                                        React.createElement(Price, { value: (_z = quote === null || quote === void 0 ? void 0 : quote.grand_total) !== null && _z !== void 0 ? _z : 0 }))))),
                            React.createElement(React.Fragment, null,
                                !for_staff && ((_0 = QuoteStore.quote) === null || _0 === void 0 ? void 0 : _0.contact) && props.isReceiveSms !== undefined && (React.createElement(Mobile, { contact: (_1 = QuoteStore.quote) === null || _1 === void 0 ? void 0 : _1.contact, onChangeSmsNotification: function (value) { return QuoteStore.updateContactInfo(value); } })),
                                React.createElement(React.Fragment, null,
                                    React.createElement(Row, { gutter: [8, 0] },
                                        React.createElement(Col, { span: 24 },
                                            React.createElement(Text, { strong: true, className: "d-block font-size-md" }, getFirstName + " " + getLastName),
                                            React.createElement(Text, { className: "d-block font-size-md" }, getEmail)),
                                        !getFirstName && (React.createElement(Col, { xl: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "first_name", label: "First Name", rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input the first name',
                                                    },
                                                ], initialValue: getFirstName, hasFeedback: true },
                                                React.createElement(Input, null)))),
                                        !getLastName && (React.createElement(Col, { xl: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "last_name", label: "Last Name", rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input the last name',
                                                    },
                                                ], initialValue: getLastName, hasFeedback: true },
                                                React.createElement(Input, null)))),
                                        !getFieldValue('account') ? (React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "company", label: "Company", hasFeedback: true, rules: [{ required: true, message: 'Please input a Company' }], initialValue: getCompany },
                                                React.createElement(Input, null)))) : null,
                                        getTitle === '' ? (React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "title", label: "Title", initialValue: getTitle, hasFeedback: true },
                                                React.createElement(Input, null)))) : null,
                                        getPhone === '' ? (React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "phone", label: "Phone", rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please input a phone number',
                                                    },
                                                ], initialValue: getPhone, hasFeedback: true },
                                                React.createElement(Input, null)))) : null,
                                        React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "zip_code", label: React.createElement(React.Fragment, null,
                                                    "Zip/Postal Code",
                                                    ' ',
                                                    React.createElement(QuestionMark, null, "Zip code required for estimating sales tax")), rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please insert a Zip/Postal Code',
                                                    },
                                                ], initialValue: getZip, hasFeedback: true },
                                                React.createElement(Input, { min: 1 })))),
                                    !for_staff && (React.createElement(Form.Item, { name: "accepted_terms_conditions", rules: [
                                            {
                                                validator: function (_, value) {
                                                    return value
                                                        ? Promise.resolve()
                                                        : Promise.reject(new Error('Please accept the terms and conditions'));
                                                },
                                            },
                                        ], valuePropName: "checked", initialValue: false },
                                        React.createElement("div", { className: "d-flex flex-row justify-content-start align-items-center mt-4", "data-testid": "checkbox-terms" },
                                            React.createElement(Checkbox, null,
                                                "I have read and accept the",
                                                ' ',
                                                React.createElement("a", { href: 'https://parts-badger.com/terms-and-conditions', rel: "noopener noreferrer", target: "_blank", className: "font-weight-bold underline" }, "terms and conditions.")))))),
                                !!formErrors && (React.createElement("div", { className: 'mt-5 mb-4' },
                                    React.createElement(Alert, { type: 'error', description: React.createElement(FormErrorParser, { isAntd4: true, errors: formErrors }) }))),
                                QuoteStore.for_staff && (quote === null || quote === void 0 ? void 0 : quote.safe_to_autocomplete) && props.is_tester && (React.createElement("div", null,
                                    React.createElement("div", { className: 'mt-5 mb-4' },
                                        React.createElement(Alert, { type: 'success', icon: React.createElement(CheckCircleOutlined, null), description: 'This quote meets all specifications for "Auto Green", press submit to get an approval from one of our engineers', showIcon: true })))),
                                React.createElement(Button, { id: "submit-for-engineer-review", block: true, type: 'primary', loading: loading !== null && loading !== void 0 ? loading : QuoteStore.loading, size: "large", className: "mb-2", htmlType: "submit" }, "Submit for Engineer Review"),
                                React.createElement("div", { className: "d-flex justify-center w-100" },
                                    React.createElement(Button, { type: 'default', htmlType: "button", onClick: function () { return handleSubmitSaveForLater(); }, loading: loading !== null && loading !== void 0 ? loading : QuoteStore.loading, size: "small", block: true }, "Save for Later")))))))),
        for_staff && props.is_tester && quote_completed && (React.createElement(Modal, { open: true, onCancel: function () {
                QuoteStore.setQuote(null);
                notification.success({
                    message: 'Quote sent!',
                });
                history.push('/rfq');
            }, closable: false, okText: 'Download Quote PDF', onOk: function () {
                downloadQuote(quote_id, quote_name);
                QuoteStore.setQuote(null);
                notification.success({
                    message: 'Downloading file...',
                });
                history.push('/rfq');
            } },
            React.createElement("span", null, "This quote is \"Auto Green\", and was autocompleted, you can download the PDF file.")))));
});
