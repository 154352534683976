var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import Suggestion from '../Suggestion';
import { CaretDownOutlined } from '@ant-design/icons';
export var Coatings = observer(function (props) {
    var _a, _b;
    var _c = useState(false), isDropDownMenuOpen = _c[0], setIsDropDownMenuOpen = _c[1];
    var items = props.coatings;
    // Get Custom Option
    var custom = items.find(function (item) { return item.name === 'Custom'; });
    var menu = items
        .slice()
        .sort(function (a) { return (a.name === 'None' ? -1 : 1); })
        .map(function (item) {
        return (React.createElement(Select.Option, { key: item.id, value: item.id }, item.name));
    });
    var EmptyTag = (React.createElement("div", { className: "flex flex-no-wrap p-2 cursor-pointer", onClick: function () {
            if (custom && props.onChange) {
                props.onChange({
                    key: custom.id.toString(),
                    label: custom.name,
                }, []);
                setIsDropDownMenuOpen(false);
            }
        } },
        React.createElement("span", { className: "flex flex-row items-center cursor-pointer justify-center" },
            "Can't find your Coating? please select ",
            React.createElement("div", { className: "ml-2 link" }, "Custom"))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, __assign({ showSearch: true, labelInValue: true, bordered: true, size: (props === null || props === void 0 ? void 0 : props.for_staff) ? 'middle' : 'small', dropdownMatchSelectWidth: false, placeholder: "Select Coating", open: isDropDownMenuOpen, onDropdownVisibleChange: function (visible) { return setIsDropDownMenuOpen(visible); } }, props, { notFoundContent: EmptyTag, allowClear: false, suffixIcon: React.createElement(CaretDownOutlined, null), "data-testid": "coatings-test", filterOption: function (input, option) {
                var _a, _b, _c, _d, _e, _f;
                try {
                    if (!option.key) {
                        // Filter by group label
                        return ((_c = (_b = (_a = option.props) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === null || _c === void 0 ? void 0 : _c.indexOf(input === null || input === void 0 ? void 0 : input.toLowerCase())) >= 0;
                    }
                    return ((_f = (_e = (_d = option.props) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === null || _f === void 0 ? void 0 : _f.indexOf(input === null || input === void 0 ? void 0 : input.toLowerCase())) >= 0;
                }
                catch (e) {
                    return false;
                }
            }, dropdownRender: function (menu) { return React.createElement("div", null, menu); } }),
            custom && (custom === null || custom === void 0 ? void 0 : custom.id) > 0 && (React.createElement(Select.OptGroup, { label: 'Custom' },
                React.createElement(Select.Option, { key: custom.id }, custom.name))),
            React.createElement(Select.OptGroup, { label: 'Options' }, menu)),
        !((_a = props.value) === null || _a === void 0 ? void 0 : _a.label) && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, (_b = props.suggestions) === null || _b === void 0 ? void 0 : _b.map(function (suggestion) { return (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: function () {
                var val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    props.onSelect(val, val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })); })))));
});
