import React, { useEffect, useState } from 'react';
import { get } from '../../shared';
import { SalesOrderProps } from '@partsbadger/sales_order_tool/lib/types/types';
import { Card, DatePicker, Table } from 'antd';
import moment, { Moment } from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';

const OrderReports = () => {
    const [loading, setLoading] = useState(false);

    const [salesOrdersMustToShip, setSalesOrdersMustToShip] = useState<Array<SalesOrderProps>>([]);
    const [yesterdayIntakes, setYesterdayIntakes] = useState<Array<any>>([]);
    const [yesterdayOuttakes, setYesterdayOuttakes] = useState<Array<any>>([]);
    const [salesordersBatchShipment, setSalesOrdersBatchShipment] = useState<Array<SalesOrderProps>>([]);

    const [selectedDate, setSelectedDate] = useState<Moment | null>();

    useEffect(() => {
        setLoading(true);
        setSelectedDate(moment());
        loadData().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (selectedDate) {
            getSalesOrderMusToShip().finally(() => setLoading(false));
            getSalesOrdersBatchShipment().finally(() => setLoading(false));
        }
    }, [selectedDate]);

    const loadData = async () => {
        if (selectedDate) {
            await getSalesOrderMusToShip();
        }
        await getSalesOrderOuttakesYesterday();
        await getSalesOrderIntakesYesterday();
        await getSalesOrdersBatchShipment();
    };

    const getSalesOrderMusToShip = async () => {
        const response = await get(`staff/report-must-to-ship/`, {
            must_to_ship_date: selectedDate?.format('YYYY-MM-DD'),
        });
        setSalesOrdersMustToShip(response.data.results);
    };

    const getSalesOrdersBatchShipment = async () => {
        const response = await get(`staff/batch-shipment/`, {
            must_to_ship_date: selectedDate?.format('YYYY-MM-DD'),
        });
        setSalesOrdersBatchShipment(response.data.results);
    };

    const getSalesOrderIntakesYesterday = async () => {
        const response = await get(`staff/report-intakes-yesterday/`);
        setYesterdayIntakes(response.data.results);
    };

    const getSalesOrderOuttakesYesterday = async () => {
        const response = await get(`staff/report-outtakes-yesterday/`);
        setYesterdayOuttakes(response.data.results);
    };

    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_ship_date',
            key: 'required_ship_date',
        },
        {
            title: 'Hot Order',
            dataIndex: 'hot_order',
            key: 'hot_order',
            render: (t: string, r: any) => {
                return r.hot_order ? <CheckCircleOutlined /> : '';
            },
        },
    ];

    const columnsShipment = [
        {
            title: 'Subject',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Next Batch Ship Date',
            dataIndex: 'revised_ship_date',
            key: 'revised_ship_date',
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    return (
        <>
            <div className={'row'}>
                <DatePicker allowClear={false} defaultValue={moment()} onChange={date => setSelectedDate(date)} />
            </div>

            <Card title={'Must Ship Today'}>
                <div className={'w-full'}>
                    <Table
                        key={'today'}
                        columns={[
                            ...columns,
                            {
                                title: 'Days on List',
                                dataIndex: 'days_in_must_ship',
                                key: 'days_in_must_ship',
                            },
                        ]}
                        loading={loading}
                        dataSource={salesOrdersMustToShip}
                        pagination={false}
                    />
                </div>

                <div className={'flex flex-row justify-between items-center'}>
                    <div className={'w-1/2'}>
                        <Card title={'Yesterday Intakes'}>
                            <Table
                                key={'yesterday-intakes'}
                                loading={loading}
                                columns={columns}
                                dataSource={yesterdayIntakes}
                                pagination={false}
                            />
                        </Card>
                    </div>

                    <div className={'w-1/2'}>
                        <Card title={'Yesterday Outtakes'}>
                            <Table
                                key={'yesterday-outtake'}
                                loading={loading}
                                columns={columns}
                                dataSource={yesterdayOuttakes}
                                pagination={false}
                            />
                        </Card>
                    </div>
                </div>
            </Card>
            <>
                <Card title={'Batch Shipment'}>
                    <div className={'w-full'}>
                        <Table
                            key={'must-ship-today'}
                            columns={columnsShipment}
                            loading={loading}
                            dataSource={salesordersBatchShipment}
                            pagination={false}
                        />
                    </div>
                </Card>
            </>
        </>
    );
};

export default OrderReports;
