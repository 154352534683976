import React from 'react';
var AddressItem = React.forwardRef(function (props, ref) {
    var _a = props.address, name = _a.name, street = _a.street, city = _a.city, state = _a.state, country = _a.country, zip_code = _a.zip_code;
    return (React.createElement("div", { style: { lineHeight: 'initial' } },
        React.createElement("div", { style: { fontWeight: 'bold' } },
            name,
            " "),
        React.createElement("div", null,
            street,
            " "),
        React.createElement("div", null,
            city,
            ", ",
            state,
            " ",
            zip_code),
        React.createElement("div", null, country === null || country === void 0 ? void 0 : country.code)));
});
AddressItem.displayName = 'AddressItem';
export default AddressItem;
