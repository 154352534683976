import React from 'react';
import { Button, Col, PageHeader, Popconfirm, Row } from 'antd';
import { observer } from 'mobx-react';

import BusinessRuleForm from '../../components/BusinessRules/BusinessRuleForm';
import { BusinessRuleStore } from '../../components/BusinessRules/Stores/BusinessRuleStore';
import { IRule } from '../../components/BusinessRules/types';
import { useHistory } from 'react-router-dom';

const BusinessRuleNewPage = () => {
    const history = useHistory();

    React.useEffect(() => {
        BusinessRuleStore.getAllContentTypes();
    }, []);
    React.useEffect(() => {
        const new_rule: IRule = {
            id: null,
            name: '',
            model: '',
            model_name: '',
            configuration: {
                conditions: {
                    all: [
                        {
                            name: '',
                            operator: '',
                            value: '',
                        },
                    ],
                },
                actions: [{ name: '', params: [] }],
            },
        };
        BusinessRuleStore.setRule(new_rule);
    }, []);
    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    onBack={() => {
                        history.push('/business-rules/');
                    }}
                    title="New Rule"
                />
            </Col>
            <Col span={24}>{BusinessRuleStore.rule && <BusinessRuleForm rule={BusinessRuleStore.rule} />}</Col>
        </Row>
    );
};

export default observer(BusinessRuleNewPage);
