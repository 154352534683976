import { action, computed, observable, makeObservable } from 'mobx';
import { get, patch, post, remove } from '../shared/api';
import { message, notification } from 'antd';
import {
    AccountOverview,
    AdditionalRequirement,
    MasterProduct,
    MasterProductPosition,
    Quote as QuoteProps,
    QuoteLineItem,
    QuoteNotification,
    Rfq,
} from './types';
import { RcFile } from 'antd/lib/upload';

const STATUS_IN_PROGRESS = ['In Progress', 'In Review'];

class Quote {
    quote?: QuoteProps | null = null;
    quoteAccountOverview?: AccountOverview | null = null;
    rfq?: Rfq | null = null;
    loading = false;
    masterProducts: MasterProductPosition[] = [];
    lineItems: QuoteLineItem[] = [];
    item: any = null; // Dynamic Quantities
    quote_notifications: QuoteNotification[] = [];
    error: any = null;
    mcMasterProductResponse: any[] = [];
    additional_requirements: AdditionalRequirement[] = [];
    selected_master_product?: MasterProduct | null = null;

    constructor() {
        makeObservable(this, {
            // Observables
            loading: observable,
            quote: observable,
            quoteAccountOverview: observable,
            rfq: observable,

            masterProducts: observable,
            lineItems: observable,
            item: observable, // Dynamic Quantities
            quote_notifications: observable,
            error: observable,
            mcMasterProductResponse: observable,
            additional_requirements: observable,
            selected_master_product: observable,

            // Actions
            setQuote: action,
            setSelectedMasterProduct: action,
            getQuote: action,
            getRFQ: action,
            getQuoteAccountOverview: action,
            updateQuote: action,
            sendToPartners: action,
            duplicateQuote: action,
            deleteQuote: action,
            getMasterProducts: action,
            deleteMasterProduct: action,
            getAdditionalRequirements: action,
            deleteLineItem: action,
            sendToSalesRepresentative: action,
            getNotifications: action,
            sendNotification: action,
            getRequote: action,
            upload3dRedactedFile: action,
            upload2dRedactedFile: action,
            updateQuoteLineQuantityItem: action,
            getDynamicPricesList: action,
            addQuoteItemQuantity: action,
            replaceFilesMasterProduct: action,

            // Computed
            inProgress: computed,
        });
    }

    setQuote(data: any) {
        this.quote = data;
    }

    setSelectedMasterProduct(master_product: MasterProduct) {
        this.selected_master_product = master_product;
    }

    get inProgress() {
        if (this.quote) {
            return STATUS_IN_PROGRESS.includes(this.quote.stage);
        }
        return false;
    }

    getQuote(id: number | undefined) {
        const quote_id = id ? id : this.quote?.id;
        this.loading = true;
        get(`staff/quotes/${quote_id}/`)
            .then((response: any) => {
                this.quote = response.data;
                this.loading = false;
            })
            .catch((error: any) => (this.error = error));
    }

    getRFQ(id: number) {
        get(`staff/rfq/${id}/`)
            .then((response: any) => {
                this.rfq = response.data;
            })
            .catch((error: any) => (this.error = error));
    }

    getQuoteAccountOverview(id: number) {
        this.loading = true;
        get(`staff/quotes/${id}/account-overview/`).then((response: any) => {
            this.quoteAccountOverview = response.data;
            this.loading = false;
        });
    }

    updateQuote(id: number, payload: any) {
        this.loading = true;
        this.error = null;
        return patch(`staff/quotes/${id}/`, payload)
            .then(() => {
                this.loading = false;
                this.getQuote(id);
                this.getMasterProducts(id);
            })
            .catch((error: any) => (this.error = error));
    }

    sendToPartners(id: number, payload: any) {
        this.loading = true;
        this.error = null;
        return patch(`staff/quotes/${id}/sent-to-partners/`, payload)
            .then(() => {
                this.loading = false;
                this.getQuote(id);
                this.getMasterProducts(id);
            })
            .catch((error: any) => (this.error = error));
    }

    duplicateQuote(id: number) {
        this.loading = true;
        return post(`staff/quotes/${id}/duplicate/`);
    }

    deleteQuote(id: number, history: any) {
        return post(`staff/quotes/${id}/delete/`)
            .then((response: any) => {
                history.push(`/quotes/`);
            })
            .catch((error: any) => console.log(error));
    }

    getMasterProducts(id: number) {
        return get(`staff/quotes/${id}/master-products/`).then((response: any) => {
            this.masterProducts = response.data;
        });
    }

    deleteMasterProduct(master_product_id: number) {
        this.masterProducts = this.masterProducts.filter(item => item.master_product.id !== master_product_id);

        post(`staff/quotes/${this.quote?.id}/delete-master-product/`, {
            master_product: master_product_id,
        })
            .then(() => this.getQuote(this.quote?.id))
            .catch((error: any) =>
                notification.error({
                    message: 'Error',
                })
            );
    }

    getAdditionalRequirements() {
        get(`customer/additional-requirements/`).then((response: any) => {
            this.additional_requirements = response.data.results;
        });
    }

    deleteLineItem(id: number) {
        this.lineItems = this.lineItems.filter(r => r.id !== id);
        remove(`staff/quoted-products/${id}/`)
            .then(() => this.getQuote(this.quote?.id))
            .catch((error: any) => notification.error({ message: 'Error' }));
    }

    sendToSalesRepresentative(id: number, payload: any) {
        return post(`staff/quotes/${id}/send-to-sales-representative/`, payload).catch(() =>
            notification.error({ message: 'Error' })
        );
    }

    getNotifications() {
        if (this.quote?.id) {
            get(`staff/quotes/${this.quote?.id}/notifications/`).then((response: any) => {
                this.quote_notifications = response.data;
            });
        }
    }

    sendNotification(payload: { body?: any; attachments: []; notify_owner?: string }) {
        const formData = new FormData();

        if (payload.body) {
            formData.append('body', payload.body);
        }

        payload.attachments.forEach(item => {
            formData.append('attachments_files', item);
        });

        if (payload?.notify_owner) {
            formData.append('notify_owner', payload.notify_owner);
        }

        post(`staff/quotes/${this.quote?.id}/notifications/`, formData, 1, true)
            .then((response: any) => {
                this.quote_notifications = response.data;
            })
            .catch((error: any) => (this.error = error));
    }

    getRequote(id: number) {
        return post(`staff/quotes/${id}/requote/`);
    }

    upload3dRedactedFile(step_id: number, file: RcFile) {
        const formData = new FormData();

        formData.append('redacted_file', file);

        return patch(`staff/step-files/${step_id}/`, formData, 1);
    }

    upload2dRedactedFile(drawing_id: number, file: RcFile) {
        const formData = new FormData();

        formData.append('redacted_file', file);

        return patch(`staff/drawing-files/${drawing_id}/`, formData, 1);
    }

    async updateQuoteLineQuantityItem(
        quote_item_id: number,
        quote_item_quantity_id: number,
        payload: { quantities: number[] }
    ) {
        const response = await patch(
            `staff/quoted-products/${quote_item_id}/quote-item-quantity/${quote_item_quantity_id}/`,
            payload
        );

        this.lineItems = this.lineItems.map(item => {
            if (item.id === quote_item_id) {
                item.quote_item_quantities = item.quote_item_quantities.map(item_quantity => {
                    if (item_quantity.id === response.data.id) {
                        return response.data;
                    }
                    return item_quantity;
                });
                return item;
            }
            return item;
        });
    }

    async getDynamicPricesList(quote_item_id: number, page = 1) {
        return await get(`staff/quoted-products/${quote_item_id}/all-dynamic-quantities/`, {
            page: page,
        });
    }

    async addQuoteItemQuantity(quote_item_id: number, quantity: number) {
        const response = await post(`staff/quoted-products/${quote_item_id}/add_quantity/`, {
            quantity: quantity,
        });

        this.lineItems = this.lineItems.map(item => {
            if (item.id === quote_item_id) {
                item.quote_item_quantities.push(response.data);
                item.quote_item_quantities = item.quote_item_quantities.sort((a, b) =>
                    a.quantity > b.quantity ? 1 : -1
                );
                return item;
            }
            return item;
        });
    }

    replaceFilesMasterProduct(quote_id: number, payload: any) {
        return post(`staff/quotes/${quote_id}/replace-files-master-product/`, payload);
    }
}

export const QuoteStore = new Quote();
