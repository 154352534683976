var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Button, Upload } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { API_ROOT } from '@partsbadger/utils';
import QuoteStore from '../../stores/QuoteStore';
export var Upload3dFile = function (_a) {
    var item = _a.item;
    var props = {
        name: 'file',
        multiple: false,
        // accept: '.step, .dxf',
        action: API_ROOT + "/customer/quote/" + QuoteStore.signed_uid + "/3d-file/",
        data: { part_name: item === null || item === void 0 ? void 0 : item.name },
        onChange: function (info) {
            var status = info.file.status;
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_3d: info.file.response.id },
                });
            }
            if (status === 'removed') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_3d: null },
                });
            }
        },
    };
    if (!(item === null || item === void 0 ? void 0 : item.uid)) {
        return null;
    }
    return (
    // @ts-ignore
    React.createElement(Upload, __assign({}, props),
        React.createElement(Button, { className: "d-flex align-items-center", size: "small", style: {
                fontSize: '13px',
                lineHeight: '2rem',
                height: '2rem',
            }, type: "primary", icon: React.createElement(FeatherIcon, { icon: "upload-cloud", size: "18", className: "mr-2" }) }, "Upload 3D File")));
};
