//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Select,
    Input,
    Button,
    Divider,
    message,
    InputNumber,
    Checkbox,
    notification,
} from 'antd';
import { get, post } from 'shared';
import { UploadFile } from '@partsbadger/library';
import { number } from 'prop-types';

const { Option } = Select;

const Reason = (props: any) => {
    const reasons = [];
    const reasonlist: string[] = [
        'Wrong quantity received',
        'Wrong merchandise received',
        'Damaged in shipping',
        'Duplicate order',
        'Product defective',
        'Customer not satisfied',
        'Incorrect item ordered',
        'Incorrect quantity ordered',
        'Other (Write the reason in this text box, please)',
    ];

    for (let i = 0; i < reasonlist.length; i++) {
        if (reasonlist.length - 1 === i) {
            reasons.push(
                <Option value={reasonlist[i]} disabled>
                    {reasonlist[i]}
                </Option>
            );
        } else {
            reasons.push(<Select.Option value={reasonlist[i]}>{reasonlist[i]}</Select.Option>);
        }
    }

    return (
        <Select onChange={props.onChange} mode="tags" style={{ width: '100%' }} {...props}>
            {reasons}
        </Select>
    );
};

const SalesOrders = (props: any) => {
    const [loading, setLoading] = useState([]);
    const [orders, setOrders] = useState([]);

    const getOrders = (params = {}) => {
        setLoading(true);

        get('staff/sales-orders', params)
            .then(response => {
                setOrders(response.data.results);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };
    useEffect(() => {
        getOrders();
    }, []);

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            optionKeyProp={'value'}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder="Search Sales Order"
            onChange={props.onChange}
            onSearch={name => {
                let busy = null;
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    search: name,
                };

                if ('in_zoho' in props) {
                    params['in_zoho'] = props.in_zoho;
                }

                setLoading(true);

                busy = setTimeout(() => getOrders(params), 400);
            }}
            {...props}
        >
            {orders.map(sales_order => (
                <Select.Option key={sales_order.id}>{sales_order.name}</Select.Option>
            ))}
        </Select>
    );
};

interface PropsSelectItems {
    line_items: any;
    onSelect: (value: any) => void;
    onDeselect: (value: any) => void;
    selectAll?: (options: any) => void;
}

const SalesOrdersLineItems = ({ line_items, onSelect, onDeselect, selectAll, ...props }: PropsSelectItems) => {
    const items = line_items?.filter(item => item.name !== 'CNC Part');

    return (
        <div>
            <Select
                showSearch
                allowClear
                labelInValue
                filterOption={false}
                optionKeyProp={'value'}
                mode="multiple"
                style={{ minWidth: 120 }}
                dropdownMatchSelectWidth={false}
                placeholder="Search Sales Order Items"
                onSelect={(id: number, item: any) => {
                    onSelect({
                        key: item.props.entity.id,
                        label: item.props.entity.name,
                        value: item.props.entity.quantity,
                    });
                }}
                onDeselect={item => {
                    onDeselect({ key: item.key, label: item.label, value: item.quantity });
                }}
                {...props}
            >
                {items.map(item => (
                    <Select.Option key={item.id} entity={item}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
            <Button
                type={'link'}
                style={{
                    float: 'right',
                }}
                size={'small'}
                onClick={() => {
                    const options = items.map(item => {
                        return { key: item.id, label: item.name };
                    });
                    selectAll(options);
                    // eslint-disable-next-line react/prop-types
                    props.onChange(options);
                }}
            >
                Select All
            </Button>
        </div>
    );
};

export const NewPartQualityIssueReport = Form.create()(props => {
    const { form } = props;
    const [loading, setLoading] = useState(false);
    const [salesOrdersLineItems, setSalesOrdersLineItems] = useState([]);
    const [selectSalesOrdersLineItems, setSelectSalesOrdersLineItems] = useState<any>([]);
    const { getFieldDecorator } = form;

    const handleSubmit = e => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);
                const keys = values.item ? Object.keys(values.item) : [];
                const filter_items = selectSalesOrdersLineItems.map(lineItem => {
                    return keys.filter(key => {
                        if (Number(key) === Number(lineItem.key)) return key;
                    });
                });

                try {
                    const payload = {
                        sales_order: values.sales_order.key,
                        reporting_contact_information: values?.reporting_contact_information,
                        rma_required: values?.rma_required,
                        redo_required: values?.redo_required,
                        items: filter_items.map(key => {
                            return {
                                id: key[0],
                                name: values.item[key],
                                quantity: values.quantity[key],
                                reason: values.reason[key].map(r => {
                                    return r;
                                }),
                            };
                        }),
                        issues: values?.issues,
                        documents: values?.documents ? values.documents.map(doc => doc.response.id) : [],
                    };
                    if (keys.length > 0) {
                        post(`staff/sales-orders/quality-issue/`, payload)
                            .then(() => {
                                setLoading(false);
                                notification.success({
                                    message: 'Success',
                                    description: 'Report Submitted',
                                    placement: 'topRight',
                                });
                                setSalesOrdersLineItems([]);
                                setSelectSalesOrdersLineItems([]);
                                form.resetFields();
                            })
                            .catch(error => {
                                notification.error(error);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                        notification.info({
                            message: 'Incomplete information.',
                            description: 'You must select a sales order and its items.',
                            placement: 'topRight',
                        });
                    }
                } catch (err: any) {
                    message.error('Please input all required fields');
                    setLoading(false);
                }
            } else {
                message.error('Please input all required fields');
            }
        });
    };

    const handleSalesOrderChange = value => {
        const endpoint = `staff/sales-orders/${value.key}/line-items/`;
        get(endpoint).then(resp => {
            setSalesOrdersLineItems(resp.data);
        });
    };

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Divider>
                <div className={'title'}>Issues with Parts</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>To report any quality issues shared by customers.</div>
            <Form layout={'vertical'}>
                <Form.Item label={'Sales Order'}>
                    {getFieldDecorator('sales_order', {
                        rules: [{ required: true, message: 'Required' }],
                    })(<SalesOrders onChange={handleSalesOrderChange} in_zoho={true} />)}
                </Form.Item>

                {salesOrdersLineItems.length > 0 && (
                    <Form.Item label={'Sales Order Items'}>
                        {getFieldDecorator('sales_order_line_items')(
                            <SalesOrdersLineItems
                                line_items={salesOrdersLineItems}
                                onSelect={sales_order_item => {
                                    setSelectSalesOrdersLineItems([]);
                                    setSelectSalesOrdersLineItems([...selectSalesOrdersLineItems, sales_order_item]);
                                }}
                                onDeselect={sales_order_item => {
                                    const filter_items = selectSalesOrdersLineItems.filter(
                                        item => item.key !== Number(sales_order_item.key)
                                    );
                                    setSelectSalesOrdersLineItems([]);
                                    setSelectSalesOrdersLineItems([...filter_items]);
                                }}
                                selectAll={options => setSelectSalesOrdersLineItems(options)}
                            />
                        )}
                    </Form.Item>
                )}

                {selectSalesOrdersLineItems.length > 0 && (
                    <div className={'flex flex-col'}>
                        <div>
                            {selectSalesOrdersLineItems.map((item, index: number) => {
                                return (
                                    <div className={'flex flex-row'} key={index}>
                                        <div className={'w-1/3'}>
                                            <Form.Item label={index === 0 ? 'Sales Order Items' : ''}>
                                                {getFieldDecorator(`item[${item.key}]`, {
                                                    initialValue: item.label,
                                                })(<Input readOnly={true} />)}
                                            </Form.Item>
                                        </div>
                                        <div className={'px-4 w-1/2'}>
                                            <Form.Item label={index === 0 ? 'Quantity' : ''}>
                                                {getFieldDecorator(`quantity[${item.key}]`, {
                                                    initialValue: item.value,
                                                    rules: [{ required: true, message: 'Field Required' }],
                                                })(<InputNumber />)}
                                            </Form.Item>
                                        </div>
                                        <div className={'px-4 w-2/3'}>
                                            <Form.Item label={index === 0 ? 'Select Reason' : ''}>
                                                {getFieldDecorator(`reason[${item.key}]`, {
                                                    rules: [{ required: true, message: 'Field Required' }],
                                                })(<Reason />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <Button
                                type="link"
                                size={'small'}
                                onClick={() => {
                                    setSelectSalesOrdersLineItems([]);
                                }}
                            >
                                Remove All
                            </Button>
                        </div>
                    </div>
                )}

                <Form.Item label={'Customer Contact(s) Name and Email'}>
                    {getFieldDecorator('reporting_contact_information', {
                        rules: [{ required: true, message: 'Required' }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('rma_required', { initialValue: false, valuePropName: 'checked' })(
                        <Checkbox>RMA Required</Checkbox>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('redo_required', { initialValue: false, valuePropName: 'checked' })(
                        <Checkbox>REDO Required</Checkbox>
                    )}
                </Form.Item>

                <Form.Item label={'Issue(s) - can be copy/pasted from customer email.'}>
                    {getFieldDecorator('issues', {
                        rules: [{ required: true, message: 'Required' }],
                    })(<Input.TextArea />)}
                </Form.Item>

                <Form.Item label={'Pertinent Files, Such as Pictures From Customer.'}>
                    <p>
                        Please note that if the customer has NOT marked up the drawings, we do not need drawings/SO/PO
                        documents.
                    </p>
                    {getFieldDecorator('documents')(<UploadFile multiple={true} />)}
                </Form.Item>

                <div
                    style={{
                        marginTop: 20,
                        textAlign: 'center',
                    }}
                >
                    <Button type={'primary'} loading={loading} onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    );
});
