import React, { Fragment, useEffect, useState } from 'react';
import { Col, Divider, Row, Table, Typography, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { get } from '../../shared';

import { PaginationComponent } from '../../components/Inputs/Pagination';

import { Price } from '@partsbadger/library';
import { QuickTurnSalesOrder } from '@partsbadger/types';
import { PaginationProps } from 'antd/es/pagination';

const { Text } = Typography;

export const QuickTurnOrdersList = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    const [pagination, setPagination] = useState<PaginationProps>({});

    const columns: ColumnsType<QuickTurnSalesOrder> = [
        {
            title: 'Created',
            key: 'created_time',
            width: 150,
            render: (record: QuickTurnSalesOrder) => moment(record?.created_time).format('YYYY-MM-DD'),
        },
        {
            title: 'Order Number',
            key: 'name',
            width: 250,
            render: (record: QuickTurnSalesOrder) => (
                <Link to={`/quickturn/sales-orders/${record.id}`}>{record.name}</Link>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: QuickTurnSalesOrder) => (
                <Tag color={record?.status === 'Order Received' ? 'blue' : 'default'}>{record?.status}</Tag>
            ),
        },
        {
            title: 'Billing Name',
            key: 'billing_name',
            dataIndex: 'billing_name',
        },
        {
            title: 'Line Items',
            key: 'line_items',
            render: (record: QuickTurnSalesOrder) => record?.line_items?.length,
        },
        {
            title: 'Subtotal',
            key: 'subtotal',
            width: 150,
            align: 'center',
            render: (record: QuickTurnSalesOrder) => <Price value={Number(record?.subtotal)} />,
        },
        {
            title: 'Shipping',
            key: 'shipping',
            width: 150,
            align: 'center',
            render: (record: QuickTurnSalesOrder) => <Price value={Number(record?.shipping)} />,
        },
        {
            title: 'Total',
            key: 'grant_total',
            width: 150,
            align: 'right',
            render: (record: QuickTurnSalesOrder) => (
                <Text strong>
                    <Price value={Number(record?.grand_total)} />
                </Text>
            ),
        },
    ];

    const getOrders = () => {
        setLoading(true);
        get('quickturn/core/staff/sales-order')
            .then((response: any) => {
                setOrders(response.data);
                setPagination({
                    pageSize: 30,
                    total: response.data.count,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getOrders();
    }, [props.location.search]);

    return (
        <Fragment>
            <Divider>
                <div className={'title'}>QuickTurn Orders</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table
                        rowKey={'id'}
                        columns={columns}
                        dataSource={orders}
                        loading={loading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...pagination} />;
                        }}
                        scroll={{ x: 'auto' }}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};
