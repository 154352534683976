import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { observer } from 'mobx-react';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { IFieldTypes } from './types';
import { toJS } from 'mobx';

const Option = Select.Option;

interface IBusinessActionsForm {
    row: number;
    name: string;
    params: object;
    handleChange: (values: { name?: string; params?: object }) => void;
    handleClickDelete: () => void;
}

const BusinessActionsForm = (props: IBusinessActionsForm) => {
    const { name, params } = props;
    const [paramsOptions, setParamOptions] = useState<{ label: string; name: string; fieldType: any }[]>([]);
    const { actions } = BusinessRuleStore;

    useEffect(() => {
        const variable = actions.find(v => v.name == props.name);
        if (variable) {
            setParamOptions(variable.params);
        }
    }, [props.name, actions]);

    return (
        <Row>
            <Form.Item
                label="Action"
                name={`action-name-${props.row}`}
                initialValue={name}
                rules={[{ required: true }]}
            >
                <Select
                    placeholder="Select a option"
                    allowClear
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                        props.handleChange({
                            name: value,
                        });
                    }}
                >
                    {actions.map(action => (
                        <Option key={action.label} value={action.name}>
                            {action.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Col span={22}>
                {paramsOptions.map(paramOption => {
                    const defaultValue = _.get(params, paramOption.name, '');

                    return (
                        <Form.Item
                            key={paramOption.name}
                            label={paramOption.label}
                            name={`param-${paramOption.name}-${props.row}`}
                            initialValue={defaultValue}
                            rules={[{ required: true }]}
                        >
                            {paramOption.fieldType == 'text' && (
                                <Input
                                    onChange={e => {
                                        props.handleChange({
                                            params: {
                                                ...params,
                                                [paramOption.name]: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            )}
                        </Form.Item>
                    );
                })}
            </Col>
            <Col span={2}>
                <DeleteOutlined
                    style={{
                        fontSize: 20,
                        color: 'red',
                    }}
                    onClick={props.handleClickDelete}
                />
            </Col>
        </Row>
    );
};

export default observer(BusinessActionsForm);
