import { Popover, Tag } from 'antd';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
// import { Container } from './styles';
var SuggestionField = function (props) {
    var _a;
    return (React.createElement("div", { className: "d-flex flex-row align-items-start rounded-sm text-green mb-2", style: { padding: '4px', border: '3px solid #a3dab673' } },
        React.createElement("span", { style: { width: '150px', color: '#008041' }, className: "text-xs font-weight-bold" }, "Suggestions:"),
        React.createElement(Scrollbars, { style: { width: '100%' }, autoHide: true, autoHeight: true, autoHeightMin: 50, autoHeightMax: 100 }, (_a = props.suggestions) === null || _a === void 0 ? void 0 : _a.map(function (item, index) { return (React.createElement("div", { key: index, className: 'suggestion', style: {
                display: 'inline-block',
                cursor: 'pointer',
            } },
            React.createElement(Popover, { title: React.createElement("div", { className: 'flex flex-row justify-start' },
                    React.createElement("div", { className: 'font-bold' }, "Suggested Option: "),
                    React.createElement("div", { className: 'font-normal ml-2' }, item.name)), placement: "top", content: item.thumbnail && (React.createElement("div", { className: 'border', style: {
                        maxWidth: 500,
                        background: '#d4d4d4',
                        textAlign: 'center',
                    } },
                    React.createElement("img", { style: {
                            maxWidth: '90%',
                            margin: 'auto',
                        }, src: item.thumbnail }))) },
                React.createElement(Tag, { className: "border border-dashed border-green", style: { maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }, color: "green", onClick: function () {
                        props.handleClick(item.id.toString(), item.name);
                    } }, item.name)))); }))));
};
export default SuggestionField;
