import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { get, patch, post } from '../../shared';
import { Price } from '@partsbadger/library';
import { Button, Card, Col, Row, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuickTurnSalesOrder } from '@partsbadger/types';
import { observer } from 'mobx-react';
import moment from 'moment';
import { QuickTurnSalesOrderLineItem } from '@partsbadger/types/lib/QuickTurnInterfaces';
import { ArrowLeftOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const QuickTurnOrderDetail = observer(() => {
    let quickturn_sales_order_id = '';
    ({ quickturn_sales_order_id } = useParams());
    const [loading, setLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<QuickTurnSalesOrder | null>(null);

    const getOrder = () => {
        get(`quickturn/core/staff/sales-order/${quickturn_sales_order_id}/`)
            .then(({ data }: { data: QuickTurnSalesOrder }) => {
                setOrder(data);
            })
            .finally(() => setLoading(false));
    };

    const rebuildStepFile = (part_id: string) => {
        get(`quickturn/core/staff/parts/${part_id}/rebuild-step-file/`)
            .then(() => {
                getOrder();
            })
            .finally(() => setLoading(false));
    };

    const columns: ColumnsType<QuickTurnSalesOrderLineItem> = [
        {
            title: 'Part',
            key: 'part',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => <>{record?.part?.name}</>,
        },
        {
            title: 'Material',
            key: 'material',
            width: 250,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <>{`${record?.part?.material.type} ${record?.part?.material.alloy}`}</>
            ),
        },
        {
            title: 'STEP File',
            key: 'step-file',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <div>
                    {record?.part?.step_file?.file ? (
                        <a href={record.part.step_file.file} className="ant-btn ant-btn-default mr-2 mb-2">
                            <DownloadOutlined /> Download
                        </a>
                    ) : null}

                    <Button type="default" icon={<ReloadOutlined />} onClick={() => rebuildStepFile(record.part.id)}>
                        Rebuild
                    </Button>
                </div>
            ),
        },
        {
            title: 'Qty',
            key: 'quantity',
            width: 100,
            render: (record: QuickTurnSalesOrderLineItem) => record?.part?.quantity,
        },
        {
            title: 'Unit',
            key: 'unit_price',
            width: 125,
            align: 'right',
            render: (record: QuickTurnSalesOrderLineItem) => <Price value={Number(record?.part?.price)} />,
        },
        {
            title: 'Total',
            key: 'total_price',
            width: 150,
            align: 'right',
            render: (record: QuickTurnSalesOrderLineItem) => <Price value={Number(record?.total_price)} />,
        },
    ];

    useEffect(() => {
        setLoading(true);
        getOrder();
    }, [quickturn_sales_order_id]);

    const editOrder = (payload: QuickTurnSalesOrder) => {
        patch(`quickturn/core/staff/sales-orders/${quickturn_sales_order_id}/`, payload).then((response: any) => {
            getOrder();
        });
    };

    return (
        <>
            {loading && (
                <div
                    style={{
                        margin: '20px 0',
                        marginBottom: '20px',
                        padding: '30px 50px',
                        textAlign: 'center',
                    }}
                >
                    <Spin tip={'Loading Sales Order...'} />
                </div>
            )}

            {order && (
                <Row gutter={[12, 12]} className="p-3">
                    <Col span={24}>
                        <Link to="/quickturn/sales-orders" className="block mb-2">
                            <ArrowLeftOutlined /> Back to Order List
                        </Link>
                        <Title level={3}>{order.name}</Title>
                        <span>
                            Placed {moment(order?.created_time).format('YYYY-MM-DD')} at{' '}
                            {moment(order?.created_time).format('hh:mm a')}
                        </span>
                    </Col>
                    <Col md={16} span={24}>
                        <Card title="Line Items">
                            <Table
                                rowKey={'id'}
                                columns={columns}
                                dataSource={order?.line_items}
                                loading={loading}
                                pagination={false}
                                scroll={{ x: 'auto' }}
                            />
                        </Card>
                    </Col>
                    <Col md={8} span={24}>
                        <Card title="Billing Details" className="mb-3">
                            <div className="flex flex-column">
                                <span>{order?.billing_name}</span>
                                <span>{`${order?.billing_street}`}</span>
                                <span>
                                    {`${order?.billing_city}, ${order?.billing_state}, ${order?.billing_zip_code}`}
                                </span>
                            </div>
                        </Card>
                        <Card title="Shipping Details">
                            <div className="flex flex-column">
                                <span>{order?.shipping_name}</span>
                                <span>{`${order?.shipping_street}`}</span>
                                <span>
                                    {`${order?.shipping_city}, ${order?.shipping_state}, ${order?.shipping_zip_code}`}
                                </span>
                            </div>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
});
