var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Button, InputNumber, notification } from 'antd';
import { isNumber } from 'lodash';
import { QuoteStore } from '../Stores';
import { ErrorRender } from '@partsbadger/library';
export var AddLeadTime = function (props) {
    var _a = useState(1), days = _a[0], setDays = _a[1];
    return (React.createElement("div", { style: { minWidth: '200px', padding: 20 } },
        React.createElement("div", { className: 'mb-2' },
            React.createElement("label", { className: 'mx-2' }, props.master_product !== undefined
                ? "Add lead time for the product."
                : 'Number of days that are added or subtracted from all products:'),
            React.createElement(InputNumber, { precision: 0, className: 'mx-2', autoFocus: true, style: { width: '60px' }, size: "small", defaultValue: 1, onChange: function (number) {
                    if (isNumber(number)) {
                        setDays(number);
                    }
                    else {
                        setDays(1);
                    }
                } }),
            React.createElement(Button, { type: 'primary', size: 'small', htmlType: "submit", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var payload, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                payload = {
                                    days: days,
                                };
                                if (props.master_product !== undefined) {
                                    payload.master_product_id = props.master_product;
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, QuoteStore.addLeadTine(payload)];
                            case 2:
                                _a.sent();
                                notification.success({ message: 'Lead time updated' });
                                props.setAddLeadTime(false);
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                notification.error({
                                    message: 'Error',
                                    description: React.createElement(ErrorRender, { error: e_1 }),
                                });
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); } }, "Save Days")),
        React.createElement("span", { className: 'text-sm mx-2' }, "Negative values are subtracted: -1, -5")));
};
