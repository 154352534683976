import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tooltip } from 'antd';
import { DroppableFileZone } from '@partsbadger/library';
import { allowed_2d, allowed_3d } from '@partsbadger/utils';
import { EyeOutlined } from '@ant-design/icons';
var styles = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 10,
    alignItems: 'center',
};
export var ItemsCreateForm = function (_a) {
    var _b;
    var product = _a.product, record = _a.record, setShowAddStepFile = _a.setShowAddStepFile, setShowAddDrawingFile = _a.setShowAddDrawingFile, renderDrawingFile = _a.renderDrawingFile, onDropFile = _a.onDropFile;
    return (React.createElement("div", { className: 'flex flex-row w-full' },
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "3D File:"),
            " \u00A0",
            (record === null || record === void 0 ? void 0 : record.name_3d_file) ? (React.createElement(DroppableFileZone, { onDrop: function (file) {
                    return onDropFile(file, '3D');
                } },
                React.createElement(Tooltip, { title: "Accepted files: " + allowed_3d.replace(/,/g, ' ') },
                    React.createElement("a", null, record.name_3d_file),
                    React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: function () { return setShowAddStepFile(true); } })))) : (React.createElement(DroppableFileZone, { onDrop: function (files) {
                    return onDropFile(files, '3D');
                } }, record.production_3d_file ? (React.createElement(Tooltip, { title: "Accepted files: " + allowed_3d.replace(/,/g, ' ') },
                record.production_3d_file.filename,
                React.createElement(LegacyIcon, { title: 'Change 3D file s', className: 'link', type: 'edit', onClick: function () { return setShowAddStepFile(true); } }))) : (React.createElement(Tooltip, { title: "Accepted files: " + allowed_3d.replace(/,/g, ' ') },
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: function () { return setShowAddStepFile(true); } })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "2D File:"),
            " \u00A0",
            (record === null || record === void 0 ? void 0 : record.name_2d_file) ? (React.createElement(DroppableFileZone, { onDrop: function (files) {
                    return onDropFile(files, '2D');
                } }, (record === null || record === void 0 ? void 0 : record.name_2d_file) && (React.createElement(Tooltip, { title: "Accepted files: " + allowed_2d.replace(/,/g, ' ') },
                React.createElement("span", { title: 'Preview 2D file', className: 'link', onClick: function () {
                        if (record.production_2d_file_id) {
                            renderDrawingFile(record.production_2d_file_id);
                        }
                    } },
                    React.createElement("a", null,
                        " ",
                        record.name_2d_file)),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: function () { return setShowAddDrawingFile(true); } }))))) : (React.createElement(DroppableFileZone, { onDrop: function (file) {
                    return onDropFile(file, '2D');
                } }, record.production_2d_file ? (React.createElement(Tooltip, { title: "Accepted files: " + allowed_2d.replace(/,/g, ' ') },
                React.createElement("span", { title: 'Preview 2D file', className: 'link', onClick: function () {
                        return record.production_2d_file && renderDrawingFile(record.production_2d_file.id);
                    } }, record.production_2d_file.filename),
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: function () { return setShowAddDrawingFile(true); } }))) : (React.createElement(Tooltip, { title: "Accepted files: " + allowed_2d.replace(/,/g, ' ') },
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 2D file', className: 'link', type: 'plus', onClick: function () { return setShowAddDrawingFile(true); } })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "Other Files:"),
            " \u00A0",
            React.createElement("div", { className: 'flex flex-col' }, (_b = record.production_other_files) === null || _b === void 0 ? void 0 : _b.map(function (f) { return (React.createElement("div", { key: f.id, className: 'flex flex-row', title: 'Preview 2D file' }, f === null || f === void 0 ? void 0 :
                f.filename,
                React.createElement("div", { className: 'ml-2 link', onClick: function () { return window.open(f.file, '', "width=960,height=1200,left=0"); } },
                    React.createElement(EyeOutlined, null)))); })))));
};
