var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { SAFE_MATERIALS } from './utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import Suggestion from '../Suggestions';
var Materials = observer(function (props) {
    var _a, _b;
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var items = props.materials;
    useEffect(function () {
        // fetchData('');
    }, []);
    var debouncedSearch = useCallback(_.debounce(function (search) { return fetchData(search); }, 400), []);
    var fetchData = function (search) { return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    params = {
                        search: search,
                    };
                    if (props.manufacturing_type) {
                        params['manufacturing_type'] = props.manufacturing_type;
                    }
                    return [4 /*yield*/, props.handleSearch(params)];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Group by Material Type
    var materials_grouped = _.groupBy(items, function (item) { return item.material_type; });
    var menu = _.map(materials_grouped, function (items, key) {
        return (React.createElement(Select.OptGroup, { key: key, label: 'Uncategorized' }, items.map(function (material) { return (React.createElement(Select.Option, { style: {
                backgroundColor: SAFE_MATERIALS.includes(material.name)
                    ? 'rgba(169, 255, 169, 0.3)'
                    : 'inherit',
            }, key: material.id }, material.name)); })));
    });
    return (React.createElement("div", null,
        React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, size: 'small', placeholder: "Search", filterOption: false, dropdownMatchSelectWidth: false, style: { width: '100%' }, notFoundContent: isLoading ? React.createElement(Spin, { size: "small", spinning: true }) : null, onSearch: debouncedSearch }, props), menu),
        //@ts-ignore
        ((_a = props.value) === null || _a === void 0 ? void 0 : _a.label) !== 'Custom' && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, (_b = props.suggestions) === null || _b === void 0 ? void 0 : _b.map(function (suggestion) { return (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: function () {
                var val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    // @ts-ignore
                    props.onSelect(val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })); })))));
});
export default Materials;
