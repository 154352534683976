var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Popconfirm, Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { QuoteStore } from '../Stores';
import { RichTextEditor } from '@partsbadger/library';
import { API_ROOT as BASE_URL, DownloadFile, get, post } from '@partsbadger/utils';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
export var QuoteActions = function (_a) {
    var _b;
    var loading = _a.loading, quote = _a.quote, handlePreviewQuote = _a.handlePreviewQuote;
    var history = useHistory();
    var _c = useState(false), downloading = _c[0], setDownloading = _c[1];
    var _d = useState(false), downloadingDynamic = _d[0], setDownloadingDynamic = _d[1];
    var _e = useState(false), sending = _e[0], setSending = _e[1];
    var _f = useState(false), send = _f[0], sendQuote = _f[1];
    var _g = useState(''), notes = _g[0], setNotes = _g[1];
    var _h = useState(false), sendingToZoho = _h[0], setSendingToZoho = _h[1];
    var _j = useState(false), loadingCsv = _j[0], setLoadingCsv = _j[1];
    var download = function () {
        var url = '/staff/quotes/' + (quote === null || quote === void 0 ? void 0 : quote.id) + '/export';
        setDownloading(true);
        get(url, { responseType: 'blob' }).then(function (response) {
            DownloadFile(response, quote.name + '.pdf');
            setDownloading(false);
        });
    };
    var downloadDynamic = function () {
        var url = '/staff/quotes/' + (quote === null || quote === void 0 ? void 0 : quote.id) + '/export-dynamic';
        setDownloadingDynamic(true);
        get(url, { responseType: 'blob' }).then(function (response) {
            DownloadFile(response, quote.name + '.pdf');
            setDownloadingDynamic(false);
        });
    };
    return (React.createElement(Row, { className: 'quote-actions', style: { textAlign: 'center', padding: 5 } },
        React.createElement(Col, { style: { margin: 'auto' } },
            !quote.completed && (React.createElement(Popconfirm, { title: "Do you want to delete this quote?", onConfirm: function () { return QuoteStore.deleteQuote(quote.id, history); } },
                React.createElement(Button, { className: "mx-2", type: 'primary', danger: true },
                    "Delete ",
                    React.createElement(LegacyIcon, { type: 'delete' })))),
            React.createElement(Button, { className: "mx-2", onClick: function () {
                    handlePreviewQuote();
                }, loading: loading },
                React.createElement(FilePdfOutlined, null),
                " Preview"),
            React.createElement(Popconfirm, { title: "Do you want to duplicate this quote?", onConfirm: function () {
                    QuoteStore.duplicateQuote(quote.id).then(function (response) {
                        window.location.href = "/quotes/" + response.id;
                    });
                } },
                React.createElement(Button, { className: "mx-2" },
                    "Duplicate ",
                    React.createElement(LegacyIcon, { type: 'file-pdf' }))),
            quote.completed && (React.createElement(Button, { className: "mx-2", onClick: download, loading: downloading },
                React.createElement(FilePdfOutlined, null),
                " Download")),
            quote.completed && (React.createElement(Button, { className: "mx-2", onClick: downloadDynamic, loading: downloadingDynamic },
                React.createElement(FilePdfOutlined, null),
                " Download Dynamic PDF")),
            React.createElement(Button, { className: "mx-2", onClick: function () {
                    setLoadingCsv(true);
                    QuoteStore.exportQuotedProductsAsCSV(quote.id)
                        .then(function (response) {
                        if (response.message) {
                            message.warning(response.message, 5);
                        }
                        else {
                            FileDownload(response, "products.csv");
                        }
                    })
                        .catch(function (error) {
                        message.error('There was an error downloading the csv file.', 6);
                    })
                        .finally(function () {
                        setLoadingCsv(false);
                    });
                }, loading: loadingCsv },
                React.createElement(DownloadOutlined, null),
                " CSV Quoted Products"),
            React.createElement(Button, { className: "mx-2", onClick: function () {
                    window.open(BASE_URL + "/redirect?to=" + BASE_URL + "/staff/quotes/" + quote.id + "/download-all/");
                }, loading: loading },
                React.createElement(DownloadOutlined, null),
                " Download All Files"),
            quote.completed && (React.createElement(React.Fragment, null,
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'file-pdf' }), className: "mx-2", onClick: function () { return sendQuote(true); } }, "Send Quote to Rep"),
                React.createElement(Popconfirm, { placement: "topLeft", title: "Do you want to convert this quote to an order?", onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, SalesOrderStore.getOrCreateSalesOrder(quote.contact.id).then(function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, SalesOrderStore.update(SalesOrderStore.order_id, {
                                                        quotes: [],
                                                    })];
                                                case 1:
                                                    _a.sent();
                                                    return [4 /*yield*/, SalesOrderStore.update(SalesOrderStore.order_id, {
                                                            contact: quote.contact.id,
                                                            quotes: [quote.id],
                                                        })];
                                                case 2:
                                                    _a.sent();
                                                    history.push('/sales-order/new/');
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); })];
                                case 1:
                                    _a.sent();
                                    history.push('/sales-order/new');
                                    return [2 /*return*/];
                            }
                        });
                    }); }, okText: "Yes", cancelText: "No" },
                    React.createElement(Button, { className: "mx-2", type: "primary" }, "Convert to Sales Order")),
                !quote.zoho_id && quote.stage === 'Completed' && (React.createElement(Button, { className: "mx-2", title: 'Completed quotes are sending automatically to zoho', loading: sendingToZoho, onClick: function () {
                        setSendingToZoho(true);
                        post("/staff/quotes/" + quote.id + "/send-to-zoho/")
                            .then(function (response) {
                            QuoteStore.setQuote(response);
                            setSendingToZoho(false);
                        })
                            .catch(function (error) {
                            // console.log(error);
                            setSendingToZoho(false);
                        });
                    } }, "Send to Zoho")))),
            send && (React.createElement(Modal, { visible: true, centered: true, onCancel: function () { return sendQuote(false); }, footer: [
                    React.createElement(Button, { key: "1", className: "mx-2", loading: sending, onClick: function () {
                            setSending(true);
                            QuoteStore.sendToSalesRepresentative(quote.id, {
                                notes: notes,
                            }).then(function () {
                                notification.success({
                                    message: 'Quote was sent to the sales representative',
                                });
                                sendQuote(false);
                                setSending(false);
                            });
                            QuoteStore.getNotifications();
                        } }, "Submit"),
                ] },
                React.createElement(RichTextEditor, { initialValue: "<p>\n                                    Hi " + quote.owner.first_name + ", attached is your " + ((quote === null || quote === void 0 ? void 0 : quote.type) === 'Requote' ? 'requote' : 'quote') + ". " + (((_b = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quoteAccountOverview) === null || _b === void 0 ? void 0 : _b.is_first_quote_for_contact)
                        ? ' 🏆<b>This is the customers first quote</b>🏆'
                        : '') + "\n                                    <p style=\"font-weight: bold;\">Quote Notes:</->\n                                    " + ((quote === null || quote === void 0 ? void 0 : quote.notes) ? quote.notes : '') + "\n                                    <br />\n                                    - " + quote.design_engineer.first_name + "\n                                    </p>", onChange: function (html, text) { return setNotes(html); } })))),
        React.createElement("br", null)));
};
