var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Button, Upload } from 'antd';
import { API_ROOT } from '@partsbadger/utils';
import QuoteStore from '../../stores/QuoteStore';
import { UploadOutlined } from '@ant-design/icons';
export var UploadOtherFiles = function (_a) {
    var _b;
    var item = _a.item;
    var other_files = ((_b = item.product) === null || _b === void 0 ? void 0 : _b.attachments).other_files;
    var defaultFileList = other_files === null || other_files === void 0 ? void 0 : other_files.map(function (row) {
        return {
            uid: row.id,
            name: row.filename,
            status: 'done',
            response: {
                id: row.id,
                file: row.file,
                filename: row.filename,
            },
        };
    });
    var headers = QuoteStore.token ? { Authorization: "Token " + QuoteStore.token } : {};
    var props = {
        name: 'file',
        multiple: true,
        action: API_ROOT + "/customer/files/",
        headers: headers,
        defaultFileList: defaultFileList,
        onChange: function (info) {
            var status = info.file.status;
            if (status === 'done') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_others: info.fileList.map(function (f) { return f.response.id; }) },
                });
            }
            if (status === 'removed') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: {
                        file_others: info.fileList.filter(function (f) { return f.uid !== info.file.uid; }).map(function (f) { return f.response.id; }),
                    },
                });
            }
        },
    };
    if (!(item === null || item === void 0 ? void 0 : item.uid)) {
        return null;
    }
    return (
    // @ts-ignore
    React.createElement(Upload, __assign({ className: 'files-2d mb-3 px-3' }, props),
        React.createElement(Button, { className: "ant-btn-xs", icon: React.createElement(UploadOutlined, null) }, "Add Additional File")));
};
