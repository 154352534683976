var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var buildLineItems = function (salesOrderItems) {
    return salesOrderItems.map(function (obj) { return ({
        lineItemOuttake: obj.outtake,
        lineItemOutTakeId: obj.id,
        salesOrderLineItemId: obj.sales_order_lineitem.id,
        quantityRemainingToReceive: obj.sales_order_lineitem.quantity_remaining_to_receive,
        awardedVendor: obj.sales_order_lineitem.awarded_vendor,
        productZid: obj.sales_order_lineitem.product_zid,
        name: obj.sales_order_lineitem.name,
        intakeStatus: obj.sales_order_lineitem.intake_status,
        salesOrderLineItemQuantity: obj.sales_order_lineitem.quantity,
        lineItemOuttakeQuantity: obj.quantity,
        shippingStatus: obj.sales_order_lineitem.shipping_status,
        zohoId: obj.zoho_id,
        salesOrderId: obj.sales_order_lineitem.sales_order,
    }); });
};
export var getOrderLineItemQuantities = function (salesOrderId, selectedLineItems, vendorOrders) {
    var orderItems = vendorOrders.find(function (_a) {
        var order = _a.order;
        return order === salesOrderId;
    }).items;
    return orderItems.reduce(function (acc, order) {
        var _a;
        if (selectedLineItems.includes(order.id)) {
            return __assign(__assign({}, acc), (_a = {}, _a[order.sales_order_lineitem.id] = order.quantity, _a));
        }
        return acc;
    }, {});
};
