import React, { useEffect, useState } from 'react';
import { Drawer, notification, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import { DownloadFile, get } from '@partsbadger/utils';
export var RFQPreview = function (_a) {
    var quote_id = _a.quote_id, handleClose = _a.handleClose;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(''), fileURL = _c[0], setFileURL = _c[1];
    useEffect(function () {
        setLoading(true);
        get("/staff/quotes/" + quote_id + "/preview-rfq/?type=html").then(function (response) {
            var data = response;
            if (isMobile) {
                DownloadFile(data, "quote-" + quote_id + ".pdf");
                handleClose();
                notification.success({ message: 'Document downloaded' });
            }
            else {
                var file = new Blob([data], { type: 'text/html; charset=utf-8' });
                var fileURL_1 = URL.createObjectURL(file);
                window.open(fileURL_1, '', 'width=800,height=800,left=200,top=200');
                setLoading(false);
                setFileURL(fileURL_1);
            }
        });
    }, [quote_id]);
    if (isMobile) {
        return null;
    }
    return (React.createElement(Drawer, { title: "Preview", width: '80%', closable: true, open: false, placement: 'left', onClose: handleClose },
        React.createElement(Spin, { spinning: loading },
            React.createElement("iframe", { title: 'Quote Preview', src: fileURL, frameBorder: "0", height: window.innerHeight, width: "100%" }))));
};
