var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Button, Modal, Table, message } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { post } from '@partsbadger/utils';
import { QuoteStore } from '../../Stores';
import { SortAscendingOutlined } from '@ant-design/icons';
var dragingIndex = -1;
var BodyRow = /** @class */ (function (_super) {
    __extends(BodyRow, _super);
    function BodyRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BodyRow.prototype.render = function () {
        var _a = this.props, isOver = _a.isOver, connectDragSource = _a.connectDragSource, connectDropTarget = _a.connectDropTarget, moveRow = _a.moveRow, restProps = __rest(_a, ["isOver", "connectDragSource", "connectDropTarget", "moveRow"]);
        var style = __assign(__assign({}, restProps.style), { cursor: 'move' });
        var className = restProps.className;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }
        return connectDragSource(connectDropTarget(React.createElement("tr", __assign({}, restProps, { className: className, style: style }))));
    };
    return BodyRow;
}(React.Component));
var rowSource = {
    beginDrag: function (props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};
var rowTarget = {
    drop: function (props, monitor) {
        var dragIndex = monitor.getItem().index;
        var hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};
var DragableBodyRow = DropTarget('row', rowTarget, function (connect, monitor) { return ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}); })(DragSource('row', rowSource, function (connect) { return ({
    connectDragSource: connect.dragSource(),
}); })(BodyRow));
var columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
];
var ReorderMasterProducts = /** @class */ (function (_super) {
    __extends(ReorderMasterProducts, _super);
    function ReorderMasterProducts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            data: QuoteStore.masterProductsByPosition.map(function (item) {
                return {
                    id: item.master_product.id,
                    name: item.master_product.name,
                    position: item.position,
                };
            }),
        };
        _this.components = {
            body: {
                row: DragableBodyRow,
            },
        };
        _this.moveRow = function (dragIndex, hoverIndex) {
            var data = _this.state.data;
            var dragRow = data[dragIndex];
            _this.setState(update(_this.state, {
                data: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            }));
        };
        _this.sortRows = function () {
            var data = _this.state.data;
            data.sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.setState({ data: data });
        };
        return _this;
    }
    ReorderMasterProducts.prototype.render = function () {
        var _this = this;
        var quote_id = this.props.quote_id;
        return (React.createElement(Modal, { title: 'Sort Products', visible: true, onCancel: function () {
                _this.props.handleClose();
            }, footer: [
                React.createElement(Button, { key: "close", type: "default", onClick: this.props.handleClose }, "Cancel"),
                React.createElement(Button, { key: "save", type: "primary", onClick: function () {
                        var payload = _this.state.data.map(function (item, index) {
                            return {
                                master_product: item.id,
                                position: index,
                            };
                        });
                        post("/staff/quotes/" + quote_id + "/master-products/", payload)
                            .then(function () {
                            QuoteStore.getMasterProducts(quote_id);
                            _this.props.handleClose();
                        })
                            .catch(function () {
                            message.error('Error, please try again');
                        });
                    } }, "Apply"),
            ] },
            React.createElement("div", { className: "text-right" },
                React.createElement(Button, { onClick: function () {
                        _this.sortRows();
                    } },
                    React.createElement(SortAscendingOutlined, { className: "text-2xl" }))),
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(Table, { className: 'components-table-drag-sorting', rowKey: 'id', size: 'small', pagination: false, columns: columns, dataSource: this.state.data, components: this.components, 
                    //@ts-ignore
                    onRow: function (record, index) { return ({
                        index: index,
                        moveRow: _this.moveRow,
                    }); } }))));
    };
    return ReorderMasterProducts;
}(React.Component));
export default ReorderMasterProducts;
