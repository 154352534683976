var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PillSelect from '../../common/PillSelect';
export var VendorQualifications = function (props) {
    var qualifications = [
        { label: 'Tight Tolerance', value: 'tighttolerance' },
        { label: 'Lathe', value: 'lathe' },
        { label: 'Wireedm', value: 'wireedm' },
        { label: 'Sinkeredm', value: 'sinkeredm' },
        { label: 'Grinding', value: 'grinding' },
        { label: 'Stamping', value: 'stamping' },
        { label: 'Laser', value: 'laser' },
        { label: 'Extrusions', value: 'extrusions' },
        { label: 'Axis3', value: 'axis3' },
        { label: 'Axis4', value: 'axis4' },
        { label: 'Axis5', value: 'Axis5' },
        { label: 'Uniquecoating', value: 'uniquecoating' },
        { label: 'Uniquematerial', value: 'uniquematerial' },
        { label: 'Toptier', value: 'toptier' },
        { label: 'Lowtier', value: 'lowtier' },
        { label: 'Swiss', value: 'swiss' },
        { label: 'Inhouse', value: 'inhouse' },
    ];
    var data = props.data ? props.data : qualifications;
    return React.createElement(PillSelect, __assign({}, props, { data: data }));
};
