import React, { useState } from 'react';
import { message, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { QuoteStore } from '../../Stores';
import { post } from '@partsbadger/utils';
var loadingIcon = React.createElement(SyncOutlined, { style: { fontSize: 16 }, spin: true });
var ConvertPngToPdf = function (props) {
    var _a = useState(false), stateLoading = _a[0], setStateLoading = _a[1];
    var checkImagePng = function () {
        var filename = props.file.filename;
        var array = filename.split('.');
        var extension = '';
        var size = array.length;
        if (size > 1) {
            extension = array[size - 1].trim().toLowerCase();
            if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "flex flex-row " }, checkImagePng() && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: stateLoading ? 'link text-red-600 text-xs pointer-events-none' : 'link text-red-600 text-xs', onClick: function (e) {
                var payload = {
                    master_product_id: props.master_product.id,
                    file_id: props.file.id,
                };
                e.stopPropagation();
                setStateLoading(true);
                post("/staff/quotes/" + props.quote_id + "/set-2d-file/", payload)
                    .then(function (response) {
                    setStateLoading(false);
                    var master_product = response.master_product;
                    if (master_product) {
                        var id_1 = master_product.id;
                        var index = QuoteStore.masterProductsByPosition.findIndex(function (product) { return product.master_product.id === id_1; });
                        if (index > -1) {
                            QuoteStore.masterProductsByPosition[index].master_product = master_product;
                        }
                    }
                })
                    .catch(function (error) {
                    setStateLoading(false);
                    message.error(error.message || 'Internal server error.');
                });
            } }, "Set as 2D file"),
        React.createElement(Spin, { spinning: stateLoading, size: "small", indicator: loadingIcon }, ' ')))));
};
export default ConvertPngToPdf;
