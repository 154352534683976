var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { message } from 'antd';
import { get, post, requests, patch } from '@partsbadger/utils';
function normalize_requirements(r) {
    return {
        key: r.requirement.id,
        label: r.requirement.name,
        value: r.value,
        files: r.files,
    };
}
var QuoteStore = /** @class */ (function () {
    function QuoteStore() {
        var _this = this;
        this.signed_uid = null;
        this.loading = false;
        this.loading_accounts = false;
        this.quote = null;
        this.is_new_contact = false;
        // All information of contact related to the current quote
        this.contact = null;
        this.partTolerances = [];
        this.holeTolerances = [];
        this.finishes = [];
        this.coatings = [];
        this.materials = [];
        this.accounts = [];
        this.selectedLineItems = [];
        this.collapsedLineItems = [];
        this.default_requirements = [];
        this.users = [];
        this.token = '';
        this.for_staff = false;
        this.current_user = {};
        this.isConnectedToSocket = false;
        this.previous_quoted_parts = [];
        this.loading_quote_items = false;
        this.additional_requirements = [];
        this.default_documents = [];
        this.getAdditionalRequirements = function () {
            requests.get("/customer/additional-requirements/").then(function (data) {
                runInAction(function () {
                    _this.additional_requirements = data.results;
                });
            });
        };
        this.createLineItemFromExistingLineItems = function (line_items) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/customer/quote/" + this.quote_uid + "/add-existing-quoted-product/", {
                            quoted_products: line_items,
                        }).finally(function () { return _this.getQuote(); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        this.searchPreviousParts = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading_quote_items = true;
                        return [4 /*yield*/, post("/staff/master-products/parts-by-account/", payload)
                                .then(function (response) {
                                _this.previous_quoted_parts = response;
                            })
                                .finally(function () { return (_this.loading_quote_items = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        makeObservable(this, {
            // Observables
            signed_uid: observable,
            loading: observable,
            loading_accounts: observable,
            quote: observable,
            is_new_contact: observable,
            contact: observable,
            partTolerances: observable,
            holeTolerances: observable,
            finishes: observable,
            coatings: observable,
            materials: observable,
            accounts: observable,
            selectedLineItems: observable,
            collapsedLineItems: observable,
            default_requirements: observable,
            users: observable,
            token: observable,
            for_staff: observable,
            current_user: observable,
            isConnectedToSocket: observable,
            previous_quoted_parts: observable,
            loading_quote_items: observable,
            additional_requirements: observable,
            default_documents: observable,
            // Actions
            setQuote: action,
            setLineItem: action,
            setCollapsed: action,
            collapseAllLineItems: action,
            expandAllLineItems: action,
            setConnectedSocket: action,
            setSignedUID: action,
            setToken: action,
            setLoading: action,
            newQuote: action,
            getQuote: action,
            updateQuote: action,
            getRequote: action,
            newLineItemWithFiles: action,
            duplicateItem: action,
            updateLineItem: action,
            updateMultipleLineItems: action,
            getBulkPrices: action,
            deleteLineItem: action,
            deleteMasterProduct: action,
            deleteAll: action,
            newLineItem: action,
            exportToPdf: action,
            sendToReview: action,
            getTolerances: action,
            getFinishes: action,
            getCoatings: action,
            getMaterials: action,
            getAdditionalRequirements: action,
            getAccounts: action,
            getUsers: action,
            lineItems: action,
            safeLineItems: action,
            hasErrorFiles: action,
            needsRFQ: action,
            needs3DFiles: action,
            needs2DFiles: action,
            setForStaff: action,
            uploadAdditionalFile: action,
            setIsNewContact: action,
            setCurrentUser: action,
            updateContactInfo: action.bound,
            getContactDetails: action,
            getAdditionalRequirementsContactAccount: action,
            updateRequiredQuantities: action,
            getQuoteLineItems: action,
            createLineItemFromExistingLineItems: action,
            searchPreviousParts: action,
            clean_previous_items: action,
            update_quote_field: action,
            // Computed
            quote_uid: computed,
            needsToUsePolling: computed,
            canBeOrdered: computed,
            errorFields: computed,
            isRequote: computed,
            isNewContact: computed,
            getFirstName: computed,
            getLastName: computed,
            getEmail: computed,
            getCompany: computed,
            getPhone: computed,
            getZip: computed,
            getTitle: computed,
            getRelatedContact: computed,
            getAdditionalFiles: computed,
        });
    }
    QuoteStore.prototype.setQuote = function (data) {
        this.quote = data;
        this.loading = false;
    };
    QuoteStore.prototype.setLineItem = function (data) {
        var _this = this;
        runInAction(function () {
            if (_this.quote) {
                _this.quote.line_items = _this.quote.line_items.map(function (item) {
                    if (item.id == data.id) {
                        return data;
                    }
                    return item;
                });
            }
        });
    };
    QuoteStore.prototype.setCollapsed = function (id) {
        var _this = this;
        runInAction(function () {
            if (_this.collapsedLineItems.includes(Number(id))) {
                _this.collapsedLineItems = _this.collapsedLineItems.filter(function (x) { return Number(x) != Number(id); });
            }
            else {
                _this.collapsedLineItems.push(Number(id));
            }
        });
    };
    QuoteStore.prototype.collapseAllLineItems = function () {
        var _a, _b;
        this.collapsedLineItems = ((_b = (_a = this === null || this === void 0 ? void 0 : this.quote) === null || _a === void 0 ? void 0 : _a.line_items) === null || _b === void 0 ? void 0 : _b.map(function (x) { return Number(x.id); })) || [];
    };
    QuoteStore.prototype.expandAllLineItems = function () {
        this.collapsedLineItems = [];
    };
    QuoteStore.prototype.setConnectedSocket = function (connected) {
        this.isConnectedToSocket = connected;
    };
    QuoteStore.prototype.setSignedUID = function (signed_id) {
        this.signed_uid = signed_id;
    };
    Object.defineProperty(QuoteStore.prototype, "quote_uid", {
        get: function () {
            var _a;
            if (!this.token && this.signed_uid) {
                return this.signed_uid;
            }
            return (_a = this.quote) === null || _a === void 0 ? void 0 : _a.uid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "needsToUsePolling", {
        /**
         * If is not authenticated (no token) and has pending files to analyze use polling because web sockets is only for
         * authenticated users
         * @returns {boolean}
         */
        get: function () {
            var _a, _b, _c, _d;
            return (this.quote &&
                (((_b = (_a = this.quote.line_items) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !item.product.analysis_status.file_3d.completed; })) === null || _b === void 0 ? void 0 : _b.length) > 0 ||
                    ((_d = (_c = this.quote.line_items) === null || _c === void 0 ? void 0 : _c.filter(function (item) { return !item.product.analysis_status.file_2d.completed; })) === null || _d === void 0 ? void 0 : _d.length) > 0));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "canBeOrdered", {
        /**
         * Items safe to quote can be ordered
         * */
        get: function () {
            return (this.quote &&
                this.quote.line_items.length > 0 &&
                this.quote.line_items.filter(function (item) { return item.needs_manual_quote; }).length === 0);
        },
        enumerable: false,
        configurable: true
    });
    QuoteStore.prototype.setToken = function (token) {
        var _this = this;
        runInAction(function () {
            _this.token = token;
        });
    };
    QuoteStore.prototype.setLoading = function (loading) {
        var _this = this;
        if (loading === void 0) { loading = false; }
        runInAction(function () {
            _this.loading = loading;
        });
    };
    QuoteStore.prototype.newQuote = function (payload) {
        var _this = this;
        if (payload === void 0) { payload = {}; }
        return requests
            .post("/customer/quote/new/", payload)
            .then(function (data) {
            _this.setQuote(data);
        })
            .catch(function (err) {
            console.log(err);
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.getQuote = function () {
        var _this = this;
        if (this.quote_uid) {
            this.loading = true;
            requests
                .get("/customer/quote/" + this.quote_uid + "/")
                .then(function (data) {
                _this.setQuote(data);
            })
                .catch(function (error) {
                var _a;
                if (((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                    _this.setQuote(null);
                }
            })
                .finally(function () {
                runInAction(function () {
                    _this.loading = false;
                });
            });
        }
        else {
            this.loading = false;
        }
    };
    QuoteStore.prototype.updateQuote = function (payload) {
        var _this = this;
        if (payload === void 0) { payload = {}; }
        return requests
            .patch("/customer/quote/" + this.quote_uid + "/", payload)
            .then(function () {
            _this.getQuote();
        })
            .catch(function (err) {
            console.log(err);
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.getRequote = function (id) {
        return requests.post("/staff/quotes/" + id + "/requote/");
    };
    QuoteStore.prototype.newLineItemWithFiles = function (payload, onUploadProgress) {
        var _this = this;
        if (payload === void 0) { payload = {}; }
        var authorization = this.token ? { authorization: "Token " + this.token } : {};
        var options = {
            headers: __assign({ 'Content-Type': 'multipart/form-data' }, authorization),
            onUploadProgress: onUploadProgress && onUploadProgress,
        };
        return requests
            .post("/customer/quote/" + this.quote_uid + "/new-item/", payload, options)
            .then(function (data) {
            runInAction(function () {
                var _a, _b;
                (_b = (_a = _this.quote) === null || _a === void 0 ? void 0 : _a.line_items) === null || _b === void 0 ? void 0 : _b.push(data);
            });
        })
            .catch(function (err) {
            console.log(err);
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.duplicateItem = function (master_product) {
        var _this = this;
        return requests
            .post("/customer/quote/" + this.quote_uid + "/duplicate-item/" + master_product + "/")
            .then(function (data) {
            runInAction(function () {
                if (_this.quote) {
                    _this.quote.line_items.push(data);
                }
                _this.getQuote();
            });
        })
            .catch(function (err) {
            console.log(err);
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.updateLineItem = function (payload) {
        var _this = this;
        if (payload === void 0) { payload = {}; }
        this.loading = true;
        return requests
            .patch("/customer/quote/" + this.quote_uid + "/update-item/", payload)
            .then(function (data) {
            runInAction(function () {
                if (_this.quote) {
                    _this.quote.line_items = _this.quote.line_items.map(function (item) {
                        if (item.uid === data.uid) {
                            return data;
                        }
                        return item;
                    });
                }
                _this.getQuote();
            });
        })
            .catch(function (err) {
            message.error('Error, please try again');
        })
            .finally(function () {
            _this.setLoading(false);
        });
    };
    QuoteStore.prototype.updateMultipleLineItems = function (payload) {
        if (payload === void 0) { payload = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, requests
                                .patch("/customer/quote/" + this.quote_uid + "/update-multiple-items/", payload)
                                .then(function (data) {
                                _this.setQuote(data);
                            })
                                .catch(function (err) {
                                message.error('Error, please try again');
                            })
                                .finally(function () {
                                _this.setLoading(false);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    QuoteStore.prototype.getBulkPrices = function (line_item, quantities) {
        var options = {
            params: {
                quantities: quantities.join(','),
            },
        };
        return requests.get("/customer/quote/" + this.quote_uid + "/bulk-prices/" + line_item + "/", options);
    };
    QuoteStore.prototype.deleteLineItem = function (item_uid) {
        var _this = this;
        this.loading = true;
        if (this.quote) {
            this.quote.line_items = this.quote.line_items.filter(function (item) { return item.uid !== item_uid; });
        }
        requests
            .delete("/customer/quote/" + this.quote_uid + "/remove-item/" + item_uid + "/")
            .then(function (data) {
            _this.setQuote(data);
        })
            .catch(function (err) {
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.deleteMasterProduct = function (master_product_id) {
        var _this = this;
        requests
            .delete("/customer/quote/" + this.quote_uid + "/remove-part/" + master_product_id + "/")
            .then(function (data) {
            _this.setQuote(data);
        })
            .catch(function (err) {
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.deleteAll = function () {
        var _this = this;
        this.loading = true;
        requests
            .delete("/customer/quote/" + this.quote_uid + "/remove-all/")
            .then(function (data) {
            _this.setQuote(data);
        })
            .catch(function (err) {
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.newLineItem = function () {
        var _this = this;
        this.loading = true;
        var id = this.quote_uid;
        requests
            .post("/customer/quote/" + id + "/new-item/")
            .then(function (data) {
            runInAction(function () {
                if (_this.quote) {
                    _this.quote.line_items.push(data);
                }
                _this.getQuote();
            });
        })
            .catch(function (err) {
            message.error('Error, please try again');
        });
    };
    QuoteStore.prototype.exportToPdf = function () {
        return requests.get("/customer/quote/" + this.quote_uid + "/export/", {
            responseType: 'blob',
        });
    };
    QuoteStore.prototype.sendToReview = function (payload) {
        if (payload === void 0) { payload = {}; }
        this.addBatchRequirement(payload, 'Batch/Blanket Releases');
        return requests.post("/customer/quote/" + this.quote_uid + "/send-to-review/", payload);
    };
    QuoteStore.prototype.getTolerances = function () {
        var _this = this;
        requests
            .get("/customer/part-tolerances")
            .then(function (data) {
            runInAction(function () {
                _this.partTolerances = data.results;
            });
        })
            .catch(function (error) {
            console.error(error);
        });
        requests
            .get("/customer/hole-tolerances")
            .then(function (data) {
            runInAction(function () {
                _this.holeTolerances = data.results;
            });
        })
            .catch(function (error) {
            console.error(error);
        });
    };
    QuoteStore.prototype.getFinishes = function () {
        var _this = this;
        requests
            .get("/customer/finishes/")
            .then(function (data) {
            runInAction(function () {
                _this.finishes = data.results;
            });
        })
            .catch(function (error) {
            console.error(error);
        });
    };
    QuoteStore.prototype.getCoatings = function () {
        var _this = this;
        requests
            .get("/customer/coatings/")
            .then(function (data) {
            runInAction(function () {
                _this.coatings = data.results;
            });
        })
            .catch(function (error) {
            console.error(error);
        });
    };
    QuoteStore.prototype.getMaterials = function () {
        var _this = this;
        requests
            .get("/customer/materials/")
            .then(function (data) {
            runInAction(function () {
                _this.materials = data.results;
            });
        })
            .catch(function (error) {
            console.error(error);
        });
    };
    QuoteStore.prototype.getAccounts = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.loading_accounts = true;
        return requests
            .get("/staff/accounts/", {
            params: params,
        })
            .then(function (data) {
            runInAction(function () {
                _this.accounts = data.results;
            });
        })
            .finally(function () { return (_this.loading_accounts = false); });
    };
    QuoteStore.prototype.getUsers = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        requests
            .get("/staff/users/", {
            params: params,
        })
            .then(function (data) {
            runInAction(function () {
                _this.users = data.results;
            });
        });
    };
    QuoteStore.prototype.lineItems = function () {
        var _a, _b;
        return (((_b = (_a = this.quote) === null || _a === void 0 ? void 0 : _a.line_items) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
            var _a = item.product.analysis_status.file_3d, completed = _a.completed, status = _a.status;
            return status == 'SUCCESS' && completed;
        })) || []);
    };
    QuoteStore.prototype.safeLineItems = function () {
        var _a, _b;
        return (((_b = (_a = this.quote) === null || _a === void 0 ? void 0 : _a.line_items) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
            var _a = item.product.analysis_status.file_3d, completed = _a.completed, status = _a.status;
            return status == 'SUCCESS' && completed && !item.needs_manual_quote;
        })) || []);
    };
    QuoteStore.prototype.hasErrorFiles = function () {
        var _this = this;
        var _a, _b;
        return (this.quote &&
            ((_b = (_a = this.quote.line_items) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
                var _a, _b, _c, _d, _e;
                return ((!((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.material) && !((_b = item === null || item === void 0 ? void 0 : item.product) === null || _b === void 0 ? void 0 : _b.has_file_2d)) ||
                    !((_d = (_c = item === null || item === void 0 ? void 0 : item.product) === null || _c === void 0 ? void 0 : _c.finishes) === null || _d === void 0 ? void 0 : _d.length) ||
                    !((_e = item === null || item === void 0 ? void 0 : item.product) === null || _e === void 0 ? void 0 : _e.coatings) ||
                    (!(item === null || item === void 0 ? void 0 : item.quantities) && !_this.isRequote));
            })) === null || _b === void 0 ? void 0 : _b.length) > 0);
    };
    QuoteStore.prototype.needsRFQ = function () {
        var _a, _b;
        return this.quote && ((_b = (_a = this.quote.line_items) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item === null || item === void 0 ? void 0 : item.needs_manual_quote; })) === null || _b === void 0 ? void 0 : _b.length) > 0;
    };
    QuoteStore.prototype.needs3DFiles = function () {
        var _a, _b;
        return (this.quote &&
            ((_b = (_a = this.quote.line_items) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
                return !item.product.has_file_3d;
            })) === null || _b === void 0 ? void 0 : _b.length) > 0);
    };
    QuoteStore.prototype.needs2DFiles = function () {
        var _a, _b, _c;
        return (this.quote &&
            ((_c = (_b = (_a = this.quote) === null || _a === void 0 ? void 0 : _a.line_items) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
                return !item.product.has_file_2d;
            })) === null || _c === void 0 ? void 0 : _c.length) > 0);
    };
    QuoteStore.prototype.setForStaff = function (for_staff) {
        this.for_staff = for_staff;
    };
    QuoteStore.prototype.uploadAdditionalFile = function (file) {
        var _this = this;
        var formData = new FormData();
        formData.append('file', file);
        this.loading = true;
        return requests.post("/customer/quote/" + this.quote_uid + "/upload-additional-file/", formData).finally(function () {
            runInAction(function () {
                _this.loading = false;
            });
        });
    };
    Object.defineProperty(QuoteStore.prototype, "errorFields", {
        get: function () {
            var _a;
            if (this.quote && this.quote.line_items) {
                return (_a = this.quote.line_items) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, item) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    var fieldName = function (name) {
                        return name + '__' + item.id;
                    };
                    if (!((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.material)) {
                        acc.push({
                            fieldName: fieldName('material'),
                            lineItem: String(item.id),
                        });
                    }
                    else {
                        if (((_b = item === null || item === void 0 ? void 0 : item.product) === null || _b === void 0 ? void 0 : _b.material) === 'Custom' && !((_c = item === null || item === void 0 ? void 0 : item.product) === null || _c === void 0 ? void 0 : _c.custom_material)) {
                            acc.push({
                                fieldName: fieldName('custom_material'),
                                lineItem: String(item.id),
                            });
                        }
                    }
                    if (((_e = (_d = item === null || item === void 0 ? void 0 : item.product) === null || _d === void 0 ? void 0 : _d.finishes) === null || _e === void 0 ? void 0 : _e.length) === 0) {
                        acc.push({
                            fieldName: fieldName('finishes'),
                            lineItem: String(item.id),
                        });
                    }
                    else {
                        // @ts-ignore
                        if (((_f = item === null || item === void 0 ? void 0 : item.product) === null || _f === void 0 ? void 0 : _f.finishes[0]) === 'Custom' && !((_g = item === null || item === void 0 ? void 0 : item.product) === null || _g === void 0 ? void 0 : _g.custom_finish)) {
                            acc.push({
                                fieldName: fieldName('custom_finish'),
                                lineItem: String(item.id),
                            });
                        }
                    }
                    if (((_j = (_h = item === null || item === void 0 ? void 0 : item.product) === null || _h === void 0 ? void 0 : _h.coatings) === null || _j === void 0 ? void 0 : _j.length) === 0) {
                        acc.push({
                            fieldName: fieldName('coatings'),
                            lineItem: String(item.id),
                        });
                    }
                    else {
                        // @ts-ignore
                        if (((_k = item === null || item === void 0 ? void 0 : item.product) === null || _k === void 0 ? void 0 : _k.coatings[0]) === 'Custom' && !((_l = item === null || item === void 0 ? void 0 : item.product) === null || _l === void 0 ? void 0 : _l.custom_coating)) {
                            acc.push({
                                fieldName: fieldName('custom_coating'),
                                lineItem: String(item.id),
                            });
                        }
                    }
                    return acc;
                }, []);
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "isRequote", {
        get: function () {
            var _a;
            return ((_a = this.quote) === null || _a === void 0 ? void 0 : _a.type) === 'Requote';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "isNewContact", {
        get: function () {
            return this.is_new_contact;
        },
        enumerable: false,
        configurable: true
    });
    QuoteStore.prototype.setIsNewContact = function (is_new) {
        return (this.is_new_contact = is_new);
    };
    QuoteStore.prototype.setCurrentUser = function (user) {
        return (this.current_user = user);
    };
    Object.defineProperty(QuoteStore.prototype, "getFirstName", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.first_name)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.first_name) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getLastName", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.last_name)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.last_name) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getEmail", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.email)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.email) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getCompany", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.account)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.company) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getPhone", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.phone)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.phone) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getZip", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.zip_code)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.shipping_zip_code) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getTitle", {
        get: function () {
            var _a;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.title)) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getRelatedContact", {
        get: function () {
            var _a, _b;
            return (this.quote && ((_a = this.quote.contact) === null || _a === void 0 ? void 0 : _a.related_contact)) || ((_b = this.quote) === null || _b === void 0 ? void 0 : _b.related_contact) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuoteStore.prototype, "getAdditionalFiles", {
        get: function () {
            if (this.quote && this.quote.additional_files.length > 0) {
                var additional_files_1 = [];
                this.quote.additional_files.map(function (file) {
                    additional_files_1.push({
                        uid: file.id.toString(),
                        name: file.filename,
                        size: 0,
                        type: '',
                    });
                });
                return additional_files_1;
            }
            return [];
        },
        enumerable: false,
        configurable: true
    });
    QuoteStore.prototype.updateContactInfo = function (value) {
        if (this.quote && this.quote.contact) {
            this.quote.contact = __assign(__assign({}, this.quote.contact), { sms_notifications: value });
        }
    };
    QuoteStore.prototype.getContactDetails = function (contact_id) {
        var _this = this;
        requests.get("/customer/contacts/" + contact_id + "/").then(function (data) {
            runInAction(function () {
                _this.contact = data;
            });
        });
    };
    QuoteStore.prototype.getAdditionalRequirementsContactAccount = function (contact_id) {
        var _this = this;
        requests
            .get("/customer/contacts/" + contact_id + "/additional-requirements-documents/")
            .then(function (data) {
            runInAction(function () {
                var _a;
                _this.default_documents = data.documents;
                var default_requirements = data.requirements
                    ? data.requirements.map(function (r) {
                        return {
                            key: r.requirement.id,
                            label: r.requirement.name,
                            value: r.value,
                            files: r.files,
                        };
                    })
                    : [];
                if ((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.custom_requirements) {
                    if (typeof _this.quote.custom_requirements !== 'string') {
                        var custom_requirements_saved = _this.quote.custom_requirements
                            .map(function (r) {
                            return {
                                key: r.key,
                                label: r.label,
                                value: r.label,
                                files: null,
                            };
                        })
                            .filter(function (item) { return !default_requirements.some(function (dcr) { return dcr.key === item.key; }); });
                        _this.default_requirements = __spreadArray(__spreadArray([], default_requirements, true), custom_requirements_saved, true);
                    }
                }
                else {
                    _this.default_requirements = __spreadArray([], default_requirements, true);
                }
            });
        });
    };
    QuoteStore.prototype.updateRequiredQuantities = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quoted-products/" + id + "/get_dynamic_quantities/", payload)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            runInAction(function () {
                                _this.getQuote();
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Add Quoted line items of a specific quote
     */
    QuoteStore.prototype.getQuoteLineItems = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading_quote_items = true;
                        return [4 /*yield*/, get("/customer/quotes/" + quote_id + "/line-items/")
                                .then(function (response) {
                                _this.previous_quoted_parts = response.results;
                            })
                                .finally(function () { return (_this.loading_quote_items = false); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuoteStore.prototype.clean_previous_items = function () {
        this.loading_quote_items = false;
        this.previous_quoted_parts = [];
    };
    /***
     * Use this option to update a field in the quote
     * @param key
     * @param value
     */
    QuoteStore.prototype.update_quote_field = function (key, value) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.quote != undefined)) return [3 /*break*/, 3];
                        this.quote = __assign(__assign({}, this.quote), (_a = {}, _a[key] = value, _a));
                        payload = (_b = {},
                            _b[key] = value,
                            _b);
                        this.loading = true;
                        return [4 /*yield*/, patch("/customer/quote/" + this.quote_uid + "/", payload).finally(function () {
                                _this.loading = false;
                            })];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, this.getQuote()];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuoteStore.prototype.addBatchRequirement = function (payload, requirement_name) {
        return __awaiter(this, void 0, void 0, function () {
            var requirement, line_items_with_batch, batch_requirement;
            return __generator(this, function (_a) {
                if (this.quote && this.additional_requirements.length > 0) {
                    requirement = this.additional_requirements.filter(function (requirement) { return requirement.name === requirement_name; })[0];
                    line_items_with_batch = this.quote.line_items.filter(function (item) { return item.batch_shipment; });
                    batch_requirement = payload['custom_requirements'].filter(function (requirement) { return requirement.label === requirement_name; });
                    if (batch_requirement.length === 0 && line_items_with_batch.length > 0) {
                        payload['custom_requirements'].push({
                            label: requirement.name,
                            value: requirement.id,
                            key: requirement.id.toString(),
                        });
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    return QuoteStore;
}());
var quoteStore = new QuoteStore();
export default quoteStore;
