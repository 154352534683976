var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Form, Button, Descriptions, notification } from 'antd';
import { ShippingCarriersPurchasesOrders, ShippingMethodsPurchasesOrders } from '@partsbadger/library';
import IntakeStore from '../../Stores/IntakeStore';
import { FormErrorParser } from '@partsbadger/library';
import dayjs from 'dayjs';
import ShippingDestination from './ShippingDestination';
var CustomerOuttake = function (props) {
    var _a, _b, _c, _d;
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var form = Form.useForm()[0];
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(false);
                    props.acknowledgeVisible(false);
                    props.isSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    payload = {
                        shipping_designation: values.shipping_designation,
                    };
                    return [4 /*yield*/, IntakeStore.outtakeSalesOrder(false, payload).finally(function () { return props.isSubmitting(false); })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    notification.error({
                        message: 'Error',
                        description: 'An error has occured!',
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCancel = function (e) {
        e.preventDefault();
        props.acknowledgeVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onFinish: function (values) { return onSubmit(values); }, onFinishFailed: function (errorInfo) {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                    placement: 'top',
                    maxCount: 1,
                });
            }, layout: "horizontal" },
            React.createElement("p", null, "Confirm the shipment method for this outtake based on the Required Ship Date and Shipping information."),
            React.createElement(Descriptions, { bordered: true, size: "small", column: 1 },
                React.createElement(Descriptions.Item, { label: "* Shipping Designation" },
                    React.createElement(Form.Item, { name: "shipping_designation", noStyle: true, rules: [
                            {
                                required: true,
                                message: 'Please enter a shipping destination',
                            },
                        ] },
                        React.createElement(ShippingDestination, null))),
                React.createElement(Descriptions.Item, { label: "Shipping Address" },
                    IntakeStore.salesOrder.shipping_street,
                    ", ",
                    IntakeStore.salesOrder.shipping_city,
                    ",",
                    ' ',
                    IntakeStore.salesOrder.shipping_state,
                    ", ",
                    IntakeStore.salesOrder.shipping_code,
                    ",",
                    IntakeStore.salesOrder.shipping_country,
                    IntakeStore.salesOrder.shipping_zip_code),
                React.createElement(Descriptions.Item, { label: "Required Ship Date" }, dayjs((_a = IntakeStore.salesOrder) === null || _a === void 0 ? void 0 : _a.required_ship_date).format('YYYY-MM-DD')),
                React.createElement(Descriptions.Item, { label: "Carrier" },
                    React.createElement(ShippingCarriersPurchasesOrders, { disabled: true, defaultValue: (_b = IntakeStore.salesOrder) === null || _b === void 0 ? void 0 : _b.carrier })),
                React.createElement(Descriptions.Item, { label: "Shipping Method" },
                    React.createElement(ShippingMethodsPurchasesOrders, { disabled: true, defaultValue: (_c = IntakeStore.salesOrder) === null || _c === void 0 ? void 0 : _c.shipping_method })),
                React.createElement(Descriptions.Item, { label: "Shipping Account" }, (_d = IntakeStore.salesOrder) === null || _d === void 0 ? void 0 : _d.shipping_account_number)),
            React.createElement(Form.Item, { noStyle: true },
                React.createElement("div", { className: "flex w-full justify-between mt-10" },
                    React.createElement(Button, { key: "back", onClick: handleCancel }, "Go Back to Outtake"),
                    React.createElement(Button, { type: "primary", htmlType: "submit" }, "Submit"))))));
};
export default CustomerOuttake;
