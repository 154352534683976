var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { message } from 'antd';
import axios from 'axios';
import { API_ROOT } from '@partsbadger/utils';
var handleErrors = function (error) {
    //need good error handling
    // Need throw the error to handle error events on the page were is called
    var _a;
    if (!(error === null || error === void 0 ? void 0 : error.response)) {
        message.error((error === null || error === void 0 ? void 0 : error.message) ? error === null || error === void 0 ? void 0 : error.message : 'Error');
    }
    else if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
        message.error((error === null || error === void 0 ? void 0 : error.message) ? error === null || error === void 0 ? void 0 : error.message : 'Error');
    }
    throw error;
};
var responseBody = function (resp) {
    // right now both successful/unsuccessful calls go to the caller
    // routing them to a single component would be ideal to show errors
    // to the user
    return resp.data;
};
function getHeader(has_files) {
    if (has_files === void 0) { has_files = false; }
    var token = localStorage.getItem('token');
    if (token) {
        return {
            'Content-Type': has_files ? 'multipart/form-data' : 'application/json',
            Authorization: "Token " + token,
        };
    }
    else {
        return {
            'Content-Type': has_files ? 'multipart/form-data' : 'application/json',
        };
    }
}
var requests = {
    get: function (url, options) {
        if (options === void 0) { options = {}; }
        return axios
            .get("" + API_ROOT + url, __assign({ headers: getHeader() }, options))
            .then(responseBody)
            .catch(handleErrors);
    },
    post: function (url, data, options, has_files) {
        if (options === void 0) { options = {}; }
        if (has_files === void 0) { has_files = false; }
        return axios
            .post("" + API_ROOT + url, data, __assign({ headers: getHeader(has_files) }, options))
            .then(responseBody)
            .catch(handleErrors);
    },
    patch: function (url, data, options, has_files) {
        if (options === void 0) { options = {}; }
        if (has_files === void 0) { has_files = false; }
        return axios
            .patch("" + API_ROOT + url, data, {
            headers: getHeader(has_files),
        })
            .then(responseBody)
            .catch(handleErrors);
    },
    delete: function (url) {
        return axios
            .delete("" + API_ROOT + url, {
            headers: getHeader(),
        })
            .then(responseBody)
            .catch(handleErrors);
    },
};
export default {
    API_ROOT: API_ROOT,
    requests: requests,
};
