import { useEffect, useState } from 'react';
import { BASE_URL_WS } from '@partsbadger/utils';
import ReconnectingWebSocket from 'reconnecting-websocket';
var useSocketQuoteConnection = function (quote_id, token) {
    var _a = useState(false), connectedSocket = _a[0], setConnectedSocket = _a[1];
    var _b = useState(), new_notification = _b[0], setNewNotification = _b[1];
    useEffect(function () {
        var url = BASE_URL_WS + "/ws/quotes/" + quote_id + "/?token=" + token;
        var chatSocket = new ReconnectingWebSocket(url, [], {
            maxRetries: 50,
        });
        chatSocket.onopen = function (m) {
            setConnectedSocket(true);
        };
        chatSocket.onmessage = function (message) {
            setNewNotification(JSON.parse(message.data));
        };
        chatSocket.onclose = function (e) {
            setConnectedSocket(false);
        };
        chatSocket.onerror = function (e) {
            console.log('Error');
        };
        return function () {
            if (chatSocket) {
                chatSocket.close();
            }
        };
    }, []);
    return { new_notification: new_notification, connectedSocket: connectedSocket };
};
export default useSocketQuoteConnection;
