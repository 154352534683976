import React from 'react';
import { Popover, Tag } from 'antd';
var Property = function (props) {
    var label = props.label, value = props.value;
    return (React.createElement("div", { className: 'flex flex-row' },
        React.createElement("div", { className: 'font-bold' },
            label,
            ":"),
        React.createElement("div", { className: 'font' }, value)));
};
var Part = function (props) {
    var _a = props.part, name = _a.name, dimensions = _a.dimensions, material = _a.material, finish = _a.finish, coating = _a.coating, customer_notes = _a.customer_notes, first_article_approval = _a.first_article_approval, is_batch_shipment = _a.is_batch_shipment;
    return (React.createElement("div", { className: 'flex flex-col itemsCenter' },
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: "font-bold" }, name),
            React.createElement("div", { className: "italic text-xs" }, dimensions),
            customer_notes && (React.createElement("div", { className: "italic text-xs xl:ml-2" },
                React.createElement(Popover, { overlayStyle: { maxWidth: '500px' }, content: customer_notes },
                    React.createElement("a", null, " (Notes) ")),
                ' '))),
        React.createElement("div", { className: 'flex flex flex-col' },
            material && React.createElement(Property, { label: "Material", value: material }),
            React.createElement("div", { className: 'flex  flex-col' },
                coating && (React.createElement("div", null,
                    React.createElement(Property, { label: "Coating", value: coating }))),
                finish && (React.createElement("div", null,
                    React.createElement(Property, { label: "Finish", value: finish })))),
            first_article_approval !== undefined && (React.createElement("div", { className: 'flex flex-row' }, first_article_approval && React.createElement(Tag, { color: "green" }, "First Article Inspection"))),
            is_batch_shipment !== undefined && (React.createElement("div", { className: 'flex flex-row' }, is_batch_shipment && React.createElement(Tag, { color: "blue" }, "Batch Shipment"))))));
};
export default Part;
