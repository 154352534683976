import { action, observable, makeObservable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
import {
    IAction,
    IBusinessRulesStructure,
    IContentType,
    IRule,
    IRuleConfiguration,
    IVariable,
    IVariableTypeOperators,
} from '../types';

class BusinessRule {
    rules: IRule[] = [];
    rule: IRule | null = null;
    actions: IAction[] = [];
    variables: IVariable[] = [];
    variable_type_operators: IVariableTypeOperators | null = null;

    ContentTypes: IContentType[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            rules: observable,
            rule: observable,
            actions: observable,
            variables: observable,
            variable_type_operators: observable,

            ContentTypes: observable,

            // Actions
            getAllRules: action,
            getById: action,
            saveRule: action,
            delete: action,
            getStructure: action,
            getAllContentTypes: action,
            setRule: action,
            updateRuleField: action,
        });
    }

    // Api Actions
    getAllRules = async (module_name: string) => {
        this.rules = [];
        const data = await get(`/staff/business-rules/`, {
            params: {
                model: module_name,
            },
        });
        this.rules = data.results;
    };

    getById = async (rule_id: string) => {
        this.rule = await get(`/staff/business-rules/${rule_id}/`);
    };

    saveRule = async (payload: {
        id: number | null;
        name: string;
        model: string;
        configuration: IRuleConfiguration;
    }) => {
        if (payload.id) {
            const data = await patch(`/staff/business-rules/${payload.id}/`, payload);
            this.rule = null;
            // this.rules = [...this.rules, data];
        } else {
            const data = await post(`/staff/business-rules/`, payload);
            this.rule = null;
            this.rules = [...this.rules, data];
        }
    };

    delete = async (rule_id: number) => {
        await remove(`/staff/business-rules/${rule_id}/`);
        this.rules = this.rules.filter(r => r.id != rule_id);
    };

    getStructure = async (module_name: string) => {
        this.variable_type_operators = null;
        const data: IBusinessRulesStructure = await get(`/staff/business-rules/describe/${module_name}/`);
        this.variables = data.variables;
        this.actions = data.actions;
        this.variable_type_operators = data.variable_type_operators;
    };

    getAllContentTypes = async () => {
        this.ContentTypes = await get(`/staff/business-rules/content-types/`);
    };

    // Module Store Actions

    setRule(rule: IRule) {
        this.rule = rule;
    }

    updateRuleField<K extends keyof IRuleConfiguration, V extends IRuleConfiguration[K]>(key: K, value: V) {
        if (this.rule != undefined) {
            const _configuration = {
                ...this.rule.configuration,
                [key]: value,
            };
            this.rule.configuration = _configuration;
        }
    }
}

export const BusinessRuleStore = new BusinessRule();
