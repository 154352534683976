var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import { InputCalculator, PartDimensionsNewLayout } from '../index';
import { normalize_number, patch } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Divider, Input, message, Modal, notification, Select, Tooltip, } from 'antd';
import { QuoteStore } from '../../../Stores';
var PartDetails = function (_a) {
    var _b;
    var row = _a.row, form = _a.form, editable = _a.editable, handleChange = _a.handleChange, handleCreate = _a.handleCreate, editNotes = _a.editNotes, closedNotes = _a.closedNotes;
    var _c = useState(false), edit = _c[0], setEdit = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), showNotes = _e[0], setShowNotes = _e[1];
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue, setFieldsValue = form.setFieldsValue;
    var Option = Select.Option;
    var outsideClickRef = useRef(null);
    useEffect(function () {
        document.addEventListener('keydown', function (event) {
            if (event.code === 'Escape')
                setEdit(false), editable(false);
        });
    }, []);
    useEffect(function () {
        function handleClick(event) {
            var _a, _b, _c;
            if (outsideClickRef === null || outsideClickRef === void 0 ? void 0 : outsideClickRef.current) {
                try {
                    // ANTD Select Option are render outside the component
                    if (((_a = event.target) === null || _a === void 0 ? void 0 : _a.className) && event.target.className.includes('ant-select-dropdown-menu-item')) {
                        return;
                    }
                    //for antd4
                    if ((_c = (_b = event.target) === null || _b === void 0 ? void 0 : _b.className) === null || _c === void 0 ? void 0 : _c.includes('ant-select-item-option-content')) {
                        return;
                    }
                    if (!outsideClickRef.current.contains(event.target)) {
                        if (edit && row.editable) {
                            handleSubmit();
                        }
                        else {
                            setEdit(false);
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        }
        if (edit) {
            // Bind the event listener
            document.addEventListener('mousedown', handleClick);
            return function () {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClick);
            };
        }
    }, [edit]);
    var validateNumberGreaterThan0 = function (rule, value, callback) {
        if (isNaN(value) || value == 0 || !value) {
            callback('Required');
        }
        callback();
    };
    var handleSubmit = function () {
        form.validateFieldsAndScroll(function (err, values) { return __awaiter(void 0, void 0, void 0, function () {
            var payload, payload, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(values.notes !== null && values.no_quote_category !== null && row.id && showNotes)) return [3 /*break*/, 2];
                        setLoading(true);
                        payload = {
                            quotable: values.quotable,
                            no_quote_category: values.no_quote_category,
                            notes: values.notes,
                        };
                        return [4 /*yield*/, submitPatch(payload, row.id)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 2:
                        if (!!err) return [3 /*break*/, 6];
                        setLoading(true);
                        if (!row.id) return [3 /*break*/, 4];
                        payload = __assign(__assign({}, values), { volume: values.volume >= 0 ? values.volume : 0, part_volume_percentage: values.part_volume_percentage ? values.part_volume_percentage : 0 });
                        return [4 /*yield*/, submitPatch(payload, row.id)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        payload = {
                            master_product: __assign(__assign({}, values), { volume: values.volume ? values.volume : 0, part_volume_percentage: values.part_volume_percentage ? values.part_volume_percentage : 0 }),
                            sides: values.sides,
                            taps: values.taps,
                        };
                        setLoading(false);
                        if (handleCreate) {
                            handleCreate(payload);
                            setShowNotes(false);
                            closedNotes(false);
                        }
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        message.error('Please fill all required values');
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    var submitPatch = function (payload, id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, patch('/staff/master-products/' + id + '/', payload)
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setLoading(false);
                                    if (handleChange) {
                                        handleChange(response.id);
                                    }
                                    setEdit(false);
                                    setShowNotes(false);
                                    closedNotes(false);
                                    return [4 /*yield*/, QuoteStore.getQuote(undefined)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })
                        .catch(function (error) {
                        notification.error({ message: React.createElement(ErrorRender, { error: error }) });
                        setLoading(false);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { onClick: function () { return (setEdit(true), editable(true)); }, ref: outsideClickRef },
            React.createElement(Tooltip, { title: !edit && 'Click to edit' },
                React.createElement("div", { className: "flex flex-col lg:flex-row" },
                    !row.editable && !edit && (React.createElement(LegacyIcon, { title: 'This master product is used in other quotes', type: 'lock', style: { fontSize: '10px' }, className: "link mr-2 mt-1" })),
                    !edit ? (React.createElement("div", { className: "flex flex-col xl:flex-row" },
                        React.createElement("div", { className: "flex flex-col xl:flex-row" },
                            React.createElement("div", { className: "align-middle font-bold" },
                                row.name,
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement(PartDimensionsNewLayout, { length: parseFloat(row.length), width: parseFloat(row.width), height: parseFloat(row.height), unit: row.unit, edit: edit, 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                form: form })),
                        React.createElement("div", { className: "flex flex-col xl:flex-row" },
                            React.createElement("div", { className: "align-middle xl:m-0" },
                                React.createElement("span", { title: row.total_volume.toString() },
                                    React.createElement("strong", null, "Total Vol."),
                                    " ",
                                    row.total_volume_inches.toFixed(3),
                                    " IN"),
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement("div", { className: "align-middle xl:ml-2 xl:m-0" },
                                React.createElement("span", { title: row.part_volume.toString() },
                                    React.createElement("strong", null, "Part Vol. "),
                                    row.part_volume_inches.toFixed(3),
                                    " IN"),
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement("div", { className: "align-middle xl:ml-2 xl:m-0" },
                                React.createElement("span", { title: row.removed_volume.toFixed() },
                                    React.createElement("strong", null, "Removed Vol."),
                                    ' ',
                                    React.createElement("span", { style: {
                                            color: row.removed_volume_inches == 0 ? 'red' : 'inherit',
                                        } },
                                        row.removed_volume_inches.toFixed(3),
                                        " IN")),
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement("div", { className: "align-middle xl:ml-2 xl:m-0" },
                                React.createElement("span", { className: "xl:ml-2 xl:m-0" },
                                    React.createElement("strong", null, "3d Print Vol. "),
                                    row.volume_3d_printing
                                        ? parseFloat(row.volume_3d_printing).toFixed(3)
                                        : 'Analyzing...',
                                    ' ',
                                    "MM",
                                    React.createElement(Divider, { type: 'vertical' })))))) : (React.createElement("div", { className: 'flex flex-row' },
                        React.createElement(Form.Item, { label: 'Name:', className: "font-bold ml-2 m-0" }, getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Please input a value!' }],
                            initialValue: row.name,
                        })(React.createElement(Input, { size: "small", style: { width: '15vw' } }))),
                        React.createElement(PartDimensionsNewLayout, { length: parseFloat(row.length), width: parseFloat(row.width), height: parseFloat(row.height), unit: row.unit, edit: edit, 
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            form: form }),
                        React.createElement(Form.Item, { label: 'Shape', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('shape', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: row.shape,
                        })(React.createElement(Select, { showSearch: true, size: "small" },
                            React.createElement(Option, { value: "REC" }, "REC"),
                            React.createElement(Option, { value: "ROD" }, "ROD"),
                            React.createElement(Option, { value: "TUBE" }, "TUBE")))),
                        getFieldValue('shape') === 'TUBE' && (React.createElement(Form.Item, { label: 'Inner Diam.', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('inner_diameter', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ],
                            initialValue: normalize_number(row.inner_diameter),
                        })(React.createElement(InputCalculator, null)))),
                        React.createElement(Form.Item, { label: 'Part Vol.', className: "flex flex-row font-bold ml-3 m-0" }, getFieldDecorator('volume', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: normalize_number(row.volume),
                        })(React.createElement(InputCalculator, null))),
                        getFieldValue('volume') == 0 && (React.createElement(Tooltip, { title: "% of Bounding Box volume. Use this field only when part volume is unknown" },
                            React.createElement(Form.Item, { label: '(%) Vol.', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('part_volume_percentage', {
                                rules: [
                                    {
                                        required: getFieldValue('volume') == 0,
                                        message: 'Required!',
                                    },
                                    getFieldValue('volume') == 0
                                        ? {
                                            validator: validateNumberGreaterThan0,
                                        }
                                        : {},
                                ],
                                initialValue: row.part_volume_percentage,
                            })(React.createElement(InputCalculator, null))))),
                        React.createElement(Form.Item, { label: 'Itar', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('itar', {
                            initialValue: row.itar,
                            valuePropName: 'checked',
                        })(React.createElement(Checkbox, null))))),
                    edit &&
                        ((row === null || row === void 0 ? void 0 : row.id) ? (React.createElement("div", { className: 'flex flex-row mx-2' },
                            React.createElement(Form.Item, { label: 'Quotable', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('quotable', {
                                valuePropName: 'checked',
                                initialValue: row.quotable,
                            })(React.createElement(Checkbox, { onChange: function (e) { return setShowNotes(!e.target.checked); } }))))) : (React.createElement("div", { className: "flex flex-row justify-center align-middle", style: { width: '370px' } },
                            React.createElement(Form.Item, { label: 'Taps', className: "flex flex-row font-bold" }, getFieldDecorator('taps', {
                                rules: [{ required: true, message: 'Required!' }],
                                initialValue: 0,
                            })(React.createElement(Input, { placeholder: "", type: 'number', size: "small", className: "w-16" }))),
                            React.createElement(Form.Item, { label: 'Sides', className: "flex flex-row font-bold" }, getFieldDecorator('sides', {
                                rules: [{ required: true, message: 'Required!' }],
                            })(React.createElement(Input, { placeholder: "", type: 'number', size: "small", className: "w-16" }))),
                            React.createElement("div", { style: { textAlign: 'center' } },
                                React.createElement(Button, { type: "primary", htmlType: "submit", size: "small", loading: loading, className: "mt-2" }, "Save as New")))))),
                edit && !row.editable && ((_b = QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.editable) && (React.createElement("div", { className: "flex flex-row justify-center m-auto mb-2" },
                    React.createElement("div", { className: "flex flex-col justify-center align-center" },
                        React.createElement(Alert, { style: { margin: 5, width: '350px' }, type: 'warning', className: "text-center", message: 'This master product is used in other quotes.' }),
                        React.createElement("div", { className: "flex flex-row justify-center align-center w-48 m-auto" },
                            React.createElement(Button, { danger: true, type: 'primary', className: "m-auto", icon: React.createElement(LegacyIcon, { type: 'check' }), onClick: function () {
                                    QuoteStore.duplicateMasterProduct(row.id);
                                    setEdit(false);
                                    editable(false);
                                }, loading: loading, style: { width: '150px' } }, "Duplicate"),
                            React.createElement("div", null,
                                React.createElement(Button, { className: "ml-2", style: { width: '100px' } }, "Cancel")))))))),
        !getFieldValue('quotable') && (React.createElement(Modal, { visible: showNotes || editNotes, centered: true, title: "Why this product is not quotable?", onCancel: function () {
                setShowNotes(false);
                closedNotes(false);
            }, footer: [
                React.createElement(Button, { loading: loading, key: "ButtonAccept", type: "primary", htmlType: "submit", onClick: function () {
                        handleSubmit();
                    } }, "Accept"),
            ], width: 230 },
            React.createElement(Form.Item, { className: "font-bold", label: 'Reason' }, getFieldDecorator('notes', {
                initialValue: row.notes,
                rules: [
                    {
                        required: true,
                    },
                ],
            })(React.createElement(Input.TextArea, { rows: 2, className: "w-48", placeholder: "Why this product is not quotable?" }))),
            React.createElement(Form.Item, { className: "font-bold", label: 'Category' }, getFieldDecorator('no_quote_category', {
                initialValue: row.no_quote_category,
                rules: [
                    {
                        required: true,
                        message: 'Select a Category',
                    },
                ],
            })(React.createElement(Select, { showSearch: true, placeholder: "Select a Category", className: "w-48", dropdownMatchSelectWidth: false, style: {
                    width: '100%',
                } },
                React.createElement(Select.Option, { value: "Assembly" }, "Assembly"),
                React.createElement(Select.Option, { value: "Bending - Vendor" }, "Bending - Vendor"),
                React.createElement(Select.Option, { value: "Capabilities" }, "Capabilities"),
                React.createElement(Select.Option, { value: "Certifications" }, "Certifications"),
                React.createElement(Select.Option, { value: "Coatings" }, "Coatings"),
                React.createElement(Select.Option, { value: "Company Culture" }, "Company Culture"),
                React.createElement(Select.Option, { value: "Features" }, "Features"),
                React.createElement(Select.Option, { value: "Files" }, "Files"),
                React.createElement(Select.Option, { value: "Finished Size" }, "Finished Size"),
                React.createElement(Select.Option, { value: "Installation" }, "Installation"),
                React.createElement(Select.Option, { value: "Lead Time" }, "Lead Time"),
                React.createElement(Select.Option, { value: "Machinability" }, "Machinability"),
                React.createElement(Select.Option, { value: "Machining Size" }, "Machining Size"),
                React.createElement(Select.Option, { value: "Material" }, "Material"),
                React.createElement(Select.Option, { value: "Measurement" }, "Measurement"),
                React.createElement(Select.Option, { value: "RFQ" }, "RFQ"),
                React.createElement(Select.Option, { value: "Secondary Process" }, "Secondary Process"),
                React.createElement(Select.Option, { value: "Tolerance" }, "Tolerance"),
                React.createElement(Select.Option, { value: "Wall Thickness" }, "Wall Thickness"),
                React.createElement(Select.Option, { value: "New Category" }, "New Category"))))))));
};
export default Form.create()(PartDetails);
