import { get, post } from '@partsbadger/utils';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography } from 'antd';
import React from 'react';
import ConvertPngToPdf from './ConvertPngToPdf';
import { QuoteStore } from '../../Stores/QuoteStore';
var Paragraph = Typography.Paragraph;
var OtherFiles = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var master_product = _a.master_product, showAddOtherFile = _a.showAddOtherFile, quote_id = _a.quote_id;
    var other_files = master_product === null || master_product === void 0 ? void 0 : master_product.other_files.map(function (file) {
        return (React.createElement("div", { className: "", key: file.id },
            React.createElement("div", { className: 'link inline-block', onClick: function (e) {
                    e.stopPropagation();
                    get("/staff/files/" + file.id + "/signed-url/").then(function (response) {
                        window.open(response.url, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800');
                    });
                } },
                file.filename,
                "\u00A0"),
            React.createElement(LegacyIcon, { title: 'Remove file', type: 'cross', className: 'link text-red-600', onClick: function (e) {
                    e.stopPropagation();
                    post("/staff/files/" + file.id + "/remove-internal-file/").then(function () {
                        QuoteStore.getMasterProducts(quote_id);
                    });
                } }),
            React.createElement(ConvertPngToPdf, { quote_id: quote_id, master_product: master_product, file: file })));
    });
    var converted_2d_file = (_d = (_b = master_product.converted_drawing_file) !== null && _b !== void 0 ? _b : (_c = master_product.drawing_file) === null || _c === void 0 ? void 0 : _c.convertable_file) !== null && _d !== void 0 ? _d : null;
    var converted_3d_file = (_g = (_e = master_product.converted_3d_file) !== null && _e !== void 0 ? _e : (_f = master_product.step_file) === null || _f === void 0 ? void 0 : _f.convertable_file) !== null && _g !== void 0 ? _g : null;
    return (React.createElement("div", { style: {
            display: 'flex',
            justifyItems: 'flex-start',
            alignContent: 'center',
            alignItems: 'center',
        } },
        React.createElement("strong", null, "Other Files:"),
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'flex-start',
                alignContent: 'center',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            } },
            other_files,
            React.createElement(RenderConverted2dFile, { url: converted_2d_file }),
            React.createElement(RenderConverted2dFile, { url: converted_3d_file })),
        master_product.editable && (React.createElement(LegacyIcon, { className: 'link ml-1', title: 'Add or Remove files', type: 'plus', onClick: showAddOtherFile }))));
};
var RenderConverted2dFile = function (_a) {
    var url = _a.url;
    if (url) {
        var filename = url.split('/');
        return (React.createElement("span", { style: { display: 'inline' }, title: 'Original File' },
            React.createElement("a", { href: url, className: 'link' },
                React.createElement("span", null,
                    decodeURI(filename[filename.length - 1].split('?')[0]),
                    ", \u00A0"))));
    }
    return null;
};
export default OtherFiles;
