var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, Card, Empty, InputNumber, List, notification } from 'antd';
import IntakeStore from '../../Stores/IntakeStore';
import InventoryStore from '../../Stores/InventoryStore';
var RMA = function (_a) {
    var rmaItem = _a.rmaItem, isTester = _a.isTester, onUpdate = _a.onUpdate;
    var _b = useState({}), selectedValue = _b[0], setSelectedValue = _b[1];
    var _c = useState(false), loading = _c[0], setIsLoading = _c[1];
    function handleChange(id, value) {
        var _selected = selectedValue;
        _selected[id] = value;
        setSelectedValue(__assign({}, _selected));
    }
    function handleSubmit(value) {
        setIsLoading(true);
        var payload = Object.keys(selectedValue)
            .filter(function (key) { return selectedValue[key] != undefined; })
            .map(function (key) {
            var _a;
            return {
                inventory_line_item: key,
                quantity: (_a = selectedValue[key]) !== null && _a !== void 0 ? _a : 0,
            };
        });
        if (rmaItem.id) {
            InventoryStore.intake(rmaItem.id, payload)
                .then(function () {
                notification.success({
                    message: 'OK',
                    description: 'Intake successfully',
                });
                onUpdate();
                setSelectedValue({});
            })
                .finally(function () { return setIsLoading(false); });
        }
    }
    function handleInTakeOrOutTakeAll(value) {
        var _intakeAll = {};
        if (typeof rmaItem.line_items === 'object') {
            rmaItem.line_items.map(function (i) {
                if (i.id)
                    _intakeAll[i.id] = i.remaining_quantity;
            });
        }
        setSelectedValue(__assign({}, _intakeAll));
    }
    return (React.createElement(Card, { title: "RMA:", className: "flex flex-col w-full justify-center", bordered: false, style: { overflow: 'auto' } },
        React.createElement("div", { className: 'm-4 text-center' },
            React.createElement(Button, { onClick: handleInTakeOrOutTakeAll },
                IntakeStore.receiveOrShip === 'receive' ? 'Intake' : 'Outtake',
                " All Remaining"),
            React.createElement(Button, { className: 'ml-2', type: 'primary', onClick: handleSubmit, loading: loading }, "Submit")),
        React.createElement("div", { key: rmaItem.id, className: "flex flex-col xl:flex-row" },
            React.createElement(Card, { title: "RMA Information:", className: "mr-5 mb-5", style: { width: '15vw' } },
                React.createElement("div", null,
                    React.createElement("span", { className: "font-bold" }, "Name"),
                    React.createElement("span", { className: "ml-1" }, rmaItem.name)),
                React.createElement("div", null,
                    React.createElement("span", { className: "font-bold" }, "Reason:"),
                    React.createElement("span", { className: "ml-1" }, rmaItem.reason ? rmaItem.reason[0] : '--')),
                React.createElement("div", null,
                    React.createElement("span", { className: "font-bold" }, "Status:"),
                    React.createElement("span", null, rmaItem.intake_status)),
                React.createElement("div", null,
                    React.createElement("span", { className: "font-bold" }, "Request date: "),
                    React.createElement("span", { className: "ml-1" }, rmaItem.request_date ? rmaItem.request_date : '--')),
                React.createElement("div", null,
                    React.createElement("span", { className: "font-bold" }, "Complete date:"),
                    React.createElement("span", { className: "ml-1" }, rmaItem.complete_date ? rmaItem.complete_date : '--'))),
            React.createElement(List, { header: React.createElement("div", { className: "flex flex-row justify-between ml-2 mr-10 font-extrabold text-base" },
                    React.createElement("span", null, "Name"),
                    React.createElement("span", null, "Quantity"),
                    React.createElement("span", null, "Quantity Remaining"),
                    React.createElement("span", null, "Quantity Received")), 
                // footer={<div>Footer</div>}
                style: { width: '90vw' }, bordered: true, dataSource: rmaItem.line_items, itemLayout: "vertical", className: "mb-auto", locale: {
                    emptyText: (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement("span", { style: { color: '#000' } }, "Sorry... This invoice doesn't have parts.") })),
                }, renderItem: function (item) {
                    var _a;
                    return (React.createElement(List.Item, { key: item === null || item === void 0 ? void 0 : item.id, className: "flex flex-row justify-between" },
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "w-40 text-center" },
                                React.createElement("span", { className: "ml-2" }, typeof item.sales_order_line_item === 'object' &&
                                    item.sales_order_line_item.name)),
                            React.createElement("div", { className: "w-48 text-center" },
                                React.createElement("span", { className: "ml-10" }, item.quantity)),
                            React.createElement("div", { className: "w-48 text-center" },
                                React.createElement("span", { className: "ml-10" }, item.remaining_quantity)),
                            item.id && (React.createElement("div", { className: "w-48 text-center" },
                                React.createElement(InputNumber, { value: (_a = selectedValue[item.id]) !== null && _a !== void 0 ? _a : undefined, onChange: function (value) {
                                        if (item.id) {
                                            handleChange(item.id, value);
                                        }
                                    } }))))));
                } }))));
};
export default RMA;
