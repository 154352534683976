var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Checkbox, Modal, notification, Select } from 'antd';
import { LineItemQuantity, PreviousHistory } from '../Componets';
import SalesOrderInfo from '../Componets/SalesOrderInfo';
import SalesOrderData from './SalesOrderData';
import IntakeStore from '../Stores/IntakeStore';
import { QuadrantSelector } from '../Componets/IntakeOuttake';
import InventoryStore from '../Stores/InventoryStore';
import { CreateInventoryForm } from './Inventory';
import { ErrorRender } from '@partsbadger/library';
import CustomerOuttake from '../Componets/IntakeOuttake/CustomerOuttake';
var SalesOrderForm = observer(function (_a) {
    var _b, _c;
    var vendorType = _a.vendorType, props = __rest(_a, ["vendorType"]);
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var handleInTakeOrOutTakeAll = function () { return IntakeStore.IntakeOrOuttakeAllItems(); };
    var handleOuttakeAllIntakeItems = function () { return IntakeStore.OuttakeAllIntakeItems(); };
    var _f = useState(false), domesticFinishModalVisible = _f[0], setDomesticFinishVisible = _f[1];
    var _g = useState([]), extraOrderItem = _g[0], setExtraOrderItem = _g[1];
    var _h = useState(false), createInventory = _h[0], setCreateInventory = _h[1];
    var _j = useState(false), showCustomerOuttakeModal = _j[0], setShowCustomerOuttakeModal = _j[1];
    var _k = useState(false), purchaseOrdersModal = _k[0], setPurchaseOrdersModal = _k[1];
    var _l = useState(null), selectedPo = _l[0], setSelectedPo = _l[1];
    var purchase_orders = IntakeStore.purchase_orders;
    var closeDomesticModal = function () {
        setDomesticFinishVisible(false);
    };
    var handleUpdateOrderItem = function (order_item) { return __awaiter(void 0, void 0, void 0, function () {
        var refactor_line_items;
        return __generator(this, function (_a) {
            refactor_line_items = extraOrderItem.map(function (line_item) {
                if (line_item.id === order_item.id) {
                    return {
                        id: order_item.id,
                        name: order_item.name,
                        quantity_remaining: order_item.quantity_remaining,
                        quantity_received: order_item.quantity_received,
                        extra_quantity: order_item.extra_quantity,
                        storage: order_item.storage,
                        save_in_inventory: order_item.save_in_inventory,
                        reason: order_item.reason,
                    };
                }
                else {
                    return line_item;
                }
            });
            if (refactor_line_items.length > 0) {
                setCreateInventory(true);
                setExtraOrderItem(refactor_line_items.sort(function (x, y) { return x.name.localeCompare(y.name); }));
            }
            return [2 /*return*/];
        });
    }); };
    var intakeNormal = function (zIntake) { return __awaiter(void 0, void 0, void 0, function () {
        var refactor_line_items, extra_quantities;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    if (!(IntakeStore.receiveOrShip === 'receive')) return [3 /*break*/, 4];
                    return [4 /*yield*/, IntakeStore.refactorLineItems()];
                case 1:
                    refactor_line_items = _a.sent();
                    extra_quantities = refactor_line_items.filter(function (lineItem) { return lineItem.extra_quantity > 0; });
                    if (!(extra_quantities.length > 0)) return [3 /*break*/, 2];
                    setCreateInventory(true);
                    setExtraOrderItem(extra_quantities.sort(function (x, y) { return x.name.localeCompare(y.name); }));
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, IntakeStore.intakeSalesOrder(true, zIntake)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Intake Sales Order normally (No Extra Domestic work needed)
     */
    var handleIntakeSalesOrder = function () {
        setIsSubmitting(true);
        intakeNormal();
        setIsSubmitting(false);
        // IntakeStore.intakeSalesOrder().finally(() => setIsSubmitting(false));
    };
    /**
     * Outtake Sales Order normally (No Extra Domestic work needed)
     */
    var handleOuttakeSalesOrder = function (zOuttake, poId) {
        setIsSubmitting(true);
        IntakeStore.outtakeSalesOrder(zOuttake, {}, poId).finally(function () { return setIsSubmitting(false); });
    };
    var handleZIntakeDisable = function () {
        if (IntakeStore.salesOrder) {
            var z = IntakeStore.salesOrder.line_items.filter(function (obj) { return obj.quantity_in_z_outtakes > 0; });
            if (z.length > 0) {
                return false;
            }
        }
        return true;
    };
    // const handleSubmitOrCustomerOuttake = () => {
    //     if (
    //         IntakeStore?.salesOrder?.in_house_requirements ||
    //         IntakeStore?.salesOrder?.additional_requirements?.length ||
    //         IntakeStore?.salesOrder?.shipping_requirements?.length
    //     ) {
    //         setShowCustomerOuttakeModal(true);
    //     } else {
    //         handleOuttakeSalesOrder();
    //     }
    // };
    useEffect(function () {
        InventoryStore.getStorageLocations();
    }, []);
    return (React.createElement("div", { className: "border border-gray-300 py-3 px-6 mb-3" },
        IntakeStore.salesOrder && IntakeStore.salesOrder.status.includes('Received - Under Review') && (React.createElement(Alert, { message: "Place parts on the appropriate shelf. Do not ship until review is completed.", type: "warning", className: "mb-2" })),
        React.createElement("div", { className: "flex justify-center" },
            IntakeStore.salesOrder && IntakeStore.receiveOrShip === 'receive' && (React.createElement(QuadrantSelector, { selectedQuadrant: IntakeStore.salesOrder.quadrant !== null ? IntakeStore.salesOrder.quadrant : 'A', onQuadrantChange: function (val) { return IntakeStore.setQuadrant(val); } })),
            React.createElement("div", { className: "flex" },
                React.createElement("div", { className: "mx-4" },
                    React.createElement("span", { className: "mr-2" }, "Customer Return?"),
                    React.createElement(Checkbox, { checked: IntakeStore.location === 'PB-CUSTOMER-RETURN', onChange: function (e) {
                            if (e.target.checked === true) {
                                IntakeStore.setLocation('PB-CUSTOMER-RETURN');
                            }
                            else {
                                IntakeStore.setLocation('PB');
                            }
                        } })))),
        React.createElement("div", { className: "m-4 text-center" },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { onClick: handleInTakeOrOutTakeAll },
                        IntakeStore.receiveOrShip === 'receive' ? 'Intake' : 'Outtake',
                        " All Remaining")),
                IntakeStore.receiveOrShip === 'ship' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { onClick: handleOuttakeAllIntakeItems }, "Outtake All Intake Parts"))),
                IntakeStore.receiveOrShip === 'ship' && (React.createElement(Modal, { title: "Confirm the information below...", closable: false, open: showCustomerOuttakeModal, footer: [], width: 550 },
                    React.createElement(CustomerOuttake, { acknowledgeVisible: function (value) { return setShowCustomerOuttakeModal(value); }, isSubmitting: function (value) { return setIsSubmitting(value); } }))),
                IntakeStore.receiveOrShip == 'ship' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { danger: true, loading: isSubmitting, disabled: Object.values(IntakeStore.lineItemQuantities).filter(function (amt) { return amt !== 0; }).length === 0, onClick: function () {
                            var items = IntakeStore.lineItemQuantities;
                            InventoryStore.create({
                                type: 'SCRAP',
                                sales_order: IntakeStore.salesOrder.id,
                                line_items: Object.keys(items).map(function (key) {
                                    return {
                                        sales_order_line_item: Number(key),
                                        quantity: items[key],
                                    };
                                }),
                            }).then(function () {
                                notification.success({
                                    message: 'OK',
                                    description: 'Parts scrapped!',
                                });
                            });
                        } }, "Scrap Outtake"))),
                !((_b = IntakeStore.salesOrder) === null || _b === void 0 ? void 0 : _b.domestic_process_needed) && !((_c = IntakeStore.salesOrder) === null || _c === void 0 ? void 0 : _c.hardware_required) && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { loading: isSubmitting, disabled: Object.values(IntakeStore.lineItemQuantities).filter(function (amt) { return amt !== 0; }).length === 0, onClick: function () {
                            // Update variable print
                            IntakeStore.setPrintIntakeOuttake(true);
                            if (IntakeStore.receiveOrShip === 'receive') {
                                handleIntakeSalesOrder();
                            }
                            else {
                                handleOuttakeSalesOrder();
                            }
                        } }, "Submit and Print"))),
                IntakeStore.receiveOrShip === 'ship' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { className: "font-bold", type: "primary", loading: isSubmitting, disabled: Object.values(IntakeStore.lineItemQuantities).filter(function (amt) { return amt !== 0; }).length === 0, onClick: function () {
                            if (purchase_orders === null || purchase_orders === void 0 ? void 0 : purchase_orders.length) {
                                setPurchaseOrdersModal(true);
                            }
                            else {
                                handleOuttakeSalesOrder(true);
                                // // Todo: what is this?
                                IntakeStore.setDefaultValue();
                            }
                        } }, "Z Outtake"))),
                IntakeStore.receiveOrShip === 'receive' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { disabled: handleZIntakeDisable(), key: 'btn-m-2', className: "font-bold", type: "primary", onClick: function () {
                            intakeNormal(true);
                        } }, "Z Intake"))),
                React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { className: "font-bold", type: "primary", loading: loading, disabled: Object.values(IntakeStore.lineItemQuantities).filter(function (amt) { return amt !== 0; }).length === 0, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(IntakeStore.receiveOrShip === 'receive')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, intakeNormal(false)];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        setShowCustomerOuttakeModal(true);
                                        _a.label = 3;
                                    case 3:
                                        // TODO: what is this?
                                        IntakeStore.setDefaultValue();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, IntakeStore.receiveOrShip == 'ship' ? 'Customer Outtake' : 'Intake Normal')))),
        IntakeStore.salesOrder !== null && (React.createElement("div", { className: "my-4 flex flex-col" },
            React.createElement("div", { className: "flex flex-row mb-4 justify-center" },
                React.createElement(SalesOrderData, null),
                React.createElement(LineItemQuantity, { isVendorNumber: vendorType })),
            React.createElement(SalesOrderInfo, { salesOrder: IntakeStore.salesOrder, contact: IntakeStore.salesOrder, pdSalesOrder: IntakeStore.pdSalesOrder }))),
        React.createElement("div", { className: "previous-history" },
            React.createElement(PreviousHistory, { isTester: props.isTester, 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                dataSource: IntakeStore.receiveOrShip === 'receive'
                    ? IntakeStore.previousIntakes
                    : IntakeStore.previousOuttakes, 
                // disabledBox={IntakeStore.salesOrder?.name?.includes('REDO')}
                historyType: IntakeStore.receiveOrShip === 'receive' ? 'intakes' : 'outtakes' })),
        React.createElement(Modal, { title: "Domestic Process", visible: domesticFinishModalVisible, onOk: closeDomesticModal, onCancel: closeDomesticModal, footer: [
                React.createElement(Button, { key: 'btn-m-1', onClick: closeDomesticModal }, "Cancel"),
                React.createElement(Button, { key: 'btn-m-2', type: "primary", onClick: function () {
                        intakeNormal(true);
                        closeDomesticModal();
                    } }, "Z Intake"),
                React.createElement(Button, { key: 'btn-m-3', type: "primary", onClick: function () {
                        intakeNormal(false);
                        closeDomesticModal();
                    } }, "Intake Normal"),
            ] },
            React.createElement("p", null, "This order has domestic processes required. Click submit to intake this order as Received in Full - Domestic Finish. If Domestic processes have been completed, please click Intake Normal.")),
        React.createElement(Modal, { title: 'Select a purchase order for this z outtake', closable: true, visible: purchaseOrdersModal, footer: [
                React.createElement(Button, { key: 'btn-m-1', onClick: function () { return setPurchaseOrdersModal(false); } }, "Cancel"),
                React.createElement(Button, { key: 'btn-m-2', type: "primary", onClick: function () {
                        if (selectedPo) {
                            handleOuttakeSalesOrder(true, selectedPo);
                            IntakeStore.setDefaultValue();
                            setPurchaseOrdersModal(false);
                        }
                    } }, "Z Outtake"),
            ] },
            React.createElement(Select, { allowClear: true, style: { width: '100%' }, placeholder: "Please select a part*", onChange: function (value) {
                    setSelectedPo(value);
                } }, purchase_orders === null || purchase_orders === void 0 ? void 0 : purchase_orders.map(function (entity) { return (React.createElement(Select.Option, { key: entity.id, name: entity.name }, entity.name)); }))),
        createInventory && (React.createElement(Modal, { title: 'The quantities entered for these items appear to be incorrect, please correct them or they were saved in inventory.', open: createInventory, closable: true, footer: false, onOk: function () {
                setExtraOrderItem([]);
                setCreateInventory(false);
            }, onCancel: function () {
                setExtraOrderItem([]);
                setCreateInventory(false);
            }, width: 850 }, createInventory && (React.createElement(React.Fragment, null,
            extraOrderItem.map(function (order_item, index) {
                return (React.createElement("div", { key: index, className: 'pt-4 border-b-2 ' },
                    React.createElement("div", { className: 'pb-2 ' },
                        React.createElement("strong", null, "PRODUCT: "),
                        " ",
                        React.createElement("strong", null, order_item.name)),
                    React.createElement(CreateInventoryForm, { key: order_item.id, storage_locations: InventoryStore.storages, extra_order_item: order_item, handleUpdateItem: function (oi) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, handleUpdateOrderItem(oi)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })));
            }),
            React.createElement("div", { className: 'flex justify-center mt-4' },
                React.createElement(Button, { loading: loading, type: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var error, err_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setLoading(true);
                                    error = [];
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 5, , 6]);
                                    extraOrderItem.map(function (item) {
                                        if (item.save_in_inventory && item.storage === undefined) {
                                            error.push(item);
                                        }
                                    });
                                    if (!(error.length > 0)) return [3 /*break*/, 2];
                                    notification.error({
                                        message: 'Make sure you have selected a storage location to create the inventory items.',
                                        placement: 'topRight',
                                    });
                                    setLoading(false);
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, IntakeStore.intakeSalesOrder(true, false, extraOrderItem).then(function () {
                                        setCreateInventory(false);
                                        setLoading(false);
                                    })];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [3 /*break*/, 6];
                                case 5:
                                    err_1 = _a.sent();
                                    notification.error({
                                        message: React.createElement(ErrorRender, { error: err_1 }),
                                        placement: 'topRight',
                                    });
                                    setLoading(false);
                                    return [3 /*break*/, 6];
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); } }, "Proceed, create a separate Conforming Spare inventory record"))))))));
});
export default SalesOrderForm;
