var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Button, Divider, Row, Col } from 'antd';
import { QuoteStore } from '../Stores';
import { RichTextEditor } from '@partsbadger/library';
var QuoteNotesComponent = /** @class */ (function (_super) {
    __extends(QuoteNotesComponent, _super);
    function QuoteNotesComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            notes: '',
            showNotes: false,
        };
        return _this;
    }
    QuoteNotesComponent.prototype.componentDidMount = function () {
        var _this = this;
        document.addEventListener('keydown', function (event) {
            if (event.code === 'Escape') {
                _this.setState({
                    showNotes: false,
                });
                event.preventDefault();
            }
        });
    };
    QuoteNotesComponent.prototype.render = function () {
        var _this = this;
        var quote = QuoteStore.quote;
        return (React.createElement("div", null,
            React.createElement(Divider, null,
                React.createElement("div", { className: 'title' }, this.state.showNotes ? (React.createElement(Button, { type: 'link', onClick: function () {
                        var payload = {
                            notes: _this.state.notes,
                        };
                        if (quote === null || quote === void 0 ? void 0 : quote.id) {
                            QuoteStore.updateQuote(quote.id, payload);
                        }
                        _this.setState({
                            showNotes: false,
                        });
                    } }, "Save Notes")) : (React.createElement("div", null, "Notes")))),
            this.state.showNotes ? (React.createElement(Row, null,
                React.createElement(Col, { xs: 24 },
                    React.createElement(RichTextEditor, { value: this.state.notes, placeholder: 'Add Quote Notes', onChange: function (html) {
                            _this.setState({
                                notes: html,
                            });
                        } })))) : (React.createElement(Row, { style: { width: '100%', minHeight: '40px', textAlign: 'left', border: '1px solid #d4d4d4' }, className: 'editable-row bordered', onClick: function () {
                    _this.setState({
                        showNotes: true,
                        notes: (quote === null || quote === void 0 ? void 0 : quote.notes) || '',
                    });
                } },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: (quote === null || quote === void 0 ? void 0 : quote.notes) || 'Click here to add notes..' } })))));
    };
    return QuoteNotesComponent;
}(React.Component));
export var QuoteNotes = QuoteNotesComponent;
