var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef, useLayoutEffect } from 'react';
import { Spin, Modal, Tag, Typography } from 'antd';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import QuoteStore from '../stores/QuoteStore';
import * as api from '@partsbadger/utils';
import { Upload3dFile } from './UploadParts';
import { observer } from 'mobx-react';
import { ThreeDViewer as Viewer } from '@partsbadger/threedviewer';
import FeatherIcon from 'feather-icons-react';
var Text = Typography.Text;
var IconPreview = function () {
    return (React.createElement("div", { className: "d-flex flex-column align-items-center text-center w-100" },
        React.createElement(FeatherIcon, { icon: "file", size: "72", strokeWidth: "0.5" }),
        React.createElement("div", null, "2D Drawing")));
};
export var PartPreview = observer(function (_a) {
    var _b, _c;
    var item = _a.item;
    var product = item.product;
    var _d = useState(null), url = _d[0], setUrl = _d[1];
    var _e = useState(false), modelLoading = _e[0], setModelLoading = _e[1];
    var _f = useState(false), modalVisible = _f[0], setModalVisible = _f[1];
    var _g = useState(null), width = _g[0], setWidth = _g[1];
    var _h = useState(null), height = _h[0], setHeight = _h[1];
    var modalRef = useRef();
    var open3dViewer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setModalVisible(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.get("/customer/quote/" + QuoteStore.quote_uid + "/threed-signed-url/" + item.uid)];
                case 2:
                    data = _a.sent();
                    setUrl(data.url);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var open2dViewer = function (url) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
            return [2 /*return*/];
        });
    }); };
    var analysis_status = product.analysis_status, has_file_3d = product.has_file_3d, has_file_2d = product.has_file_2d, thumbnail = product.thumbnail, attachments = product.attachments;
    var analyzer_3d_finished = analysis_status.file_3d.completed;
    var analyzer_3d_success = ((_b = analysis_status === null || analysis_status === void 0 ? void 0 : analysis_status.file_3d) === null || _b === void 0 ? void 0 : _b.status) === 'SUCCESS';
    useLayoutEffect(function () {
        if (modalVisible) {
            setModelLoading(true);
            // set height and width in setTimeout so the modal can load before the container is measured
            setTimeout(function () {
                var _a, _b;
                setWidth((_a = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth);
                setHeight((_b = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight);
                setModelLoading(false);
            }, 1000);
        }
    }, [modalVisible]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "part-preview-box" }, !analyzer_3d_finished ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, null), spinning: !analyzer_3d_finished, tip: "Analyzing Geometry..." })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "d-flex flex-column align-items-center justify-content-center text-center" }, thumbnail ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "p-2 w-100" }, item.product.has_file_3d ? (React.createElement("img", { className: "thumbnail-3d-file", alt: (_c = attachments === null || attachments === void 0 ? void 0 : attachments.file3d) === null || _c === void 0 ? void 0 : _c.filename, src: thumbnail, onClick: function () {
                        open3dViewer();
                    } })) : item.product.has_file_2d ? (React.createElement("img", { className: "thumbnail-2d-file", src: thumbnail, onClick: function () {
                        open2dViewer(attachments.files_2d.file);
                    } })) : null))) : null),
            !thumbnail && item.product.has_file_3d && (React.createElement("div", null,
                React.createElement(Tag, { color: "blue", className: "my-4 mr-auto ml-auto", icon: React.createElement(WarningOutlined, null) }, "3D Preview Unavailable"))),
            analyzer_3d_finished && !analyzer_3d_success && product.has_file_3d ? (React.createElement("div", { className: "d-flex flex-column align-items-center justify-content-center" },
                React.createElement(Text, { type: "danger", strong: true, className: "mr-auto ml-auto mt-3 font-size-sm" },
                    React.createElement(WarningOutlined, null),
                    " 3D file could not be analyzed"),
                React.createElement("div", { className: "mb-3" },
                    React.createElement(Upload3dFile, { item: item })))) : null,
            !has_file_3d && !has_file_2d && (React.createElement(Text, { type: "danger", strong: true, className: "mr-auto ml-auto mt-3 font-size-sm" },
                React.createElement(WarningOutlined, null),
                " No Part Files"))))),
        !has_file_3d && !QuoteStore.isRequote && (React.createElement("div", { className: "py-3 text-center" },
            React.createElement(Upload3dFile, { item: item }))),
        React.createElement(Modal, { open: modalVisible, footer: null, centered: true, width: '90vw', maskClosable: true, onCancel: function () {
                setModalVisible(false);
                setUrl(null);
            }, bodyStyle: { height: '720px', margin: 0, padding: 0 }, style: { maxWidth: '960px' } },
            React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center", ref: modalRef }, modelLoading ? null : (React.createElement(Viewer, { url: url || '', canvasStyle: { width: width + "px", height: height + "px" } }))))));
});
