import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { InputNumber, Table } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import { Price } from '@partsbadger/library';
import { PartFiles } from '../../PartFiles';
import Part from '../../Part';
var LineItems = function () {
    var order_items = SalesOrderStore.order_items;
    var columns = [
        {
            title: 'Product Details',
            dataIndex: 'name',
            width: 450,
            render: function (t, record) {
                var _a, _b, _c;
                if (!record.quote_has_product) {
                    return (React.createElement("div", null,
                        React.createElement("div", null, record.name),
                        React.createElement("div", { className: 'italic' }, record.product_description)));
                }
                var product = record.quote_has_product.product;
                return (React.createElement(Part, { part: {
                        name: product.name,
                        dimensions: product === null || product === void 0 ? void 0 : product.dimensions,
                        material: (_a = product === null || product === void 0 ? void 0 : product.material) === null || _a === void 0 ? void 0 : _a.name,
                        coating: (_b = product === null || product === void 0 ? void 0 : product.coating) === null || _b === void 0 ? void 0 : _b.name,
                        finish: (_c = product === null || product === void 0 ? void 0 : product.finish) === null || _c === void 0 ? void 0 : _c.name,
                        customer_notes: product === null || product === void 0 ? void 0 : product.customer_notes,
                    } }));
            },
        },
        {
            title: 'Part Files',
            render: function (t, record) {
                var _a;
                if (!record.quote_has_product) {
                    return null;
                }
                return (React.createElement(PartFiles, { product: (_a = record.quote_has_product) === null || _a === void 0 ? void 0 : _a.product, show_pending_quoted_files: true, record: record, action: 'create' }));
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
            render: function (t, record) {
                if (!record.quote_has_product) {
                    return record.quantity;
                }
                var min_quantity = record.quote_has_product.quantity;
                return (React.createElement(InputNumber, { placeholder: "" + min_quantity, value: record.quantity, onChange: function (v) {
                        if (v) {
                            SalesOrderStore.updateOrderItem(record, v);
                        }
                    } }));
            },
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            width: 100,
            render: function (t, record) {
                return (React.createElement("span", null,
                    React.createElement(Price, { value: record.unit_price })));
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            width: 100,
            render: function (t, record) {
                return (React.createElement(Fragment, null,
                    React.createElement("span", null,
                        React.createElement(Price, { value: record.total }))));
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: function (t, record) {
                return (React.createElement("div", { className: 'cursor-pointer', style: {
                        color: 'red',
                    }, onClick: function () {
                        var index = SalesOrderStore.order_items.indexOf(record);
                        SalesOrderStore.removeOrderItem(record, index);
                    } },
                    React.createElement(LegacyIcon, { type: 'close' })));
            },
            align: 'right',
        },
    ];
    return (React.createElement(Fragment, null,
        React.createElement(Table, { rowKey: function (obj) { return String(order_items.indexOf(obj)); }, className: 'variation', pagination: false, dataSource: order_items, columns: columns })));
};
export default observer(LineItems);
