import React, { Fragment, useState } from 'react';
import { UploadFile } from 'components/Inputs/UploadFile';
import '@ant-design/compatible/assets/index.css';
import { Divider, Spin, Card } from 'antd';
import { get } from '../../shared';
import { ThreeDViewerFromStepFile } from '../ThreeDViewer/';

let pollingInterval;

export const PartViewer = () => {
    const [loading, setLoading] = useState(false);
    const [step_file, setStepFile] = useState();

    return (
        <div className="text-center">
            <Divider>
                <div className={'title'}> 3D Viewer</div>
            </Divider>

            <div>
                {!step_file ? (
                    <div
                        style={{
                            maxWidth: '400px',
                            margin: 'auto',
                        }}
                    >
                        <span>Select STEP, STP or IGES File</span>
                        <UploadFile
                            type={'3D'}
                            onUpload={file => {
                                setStepFile();
                                setLoading(true);
                                clearInterval(pollingInterval);

                                pollingInterval = setInterval(() => {
                                    get(`customer/step-files/${file.response.id}/status`)
                                        .then(results => {
                                            if (results.data.ready) {
                                                clearInterval(pollingInterval);
                                                setStepFile(results.data.step_file.id);
                                                setLoading(false);
                                            }
                                        })
                                        .catch(error => {
                                            alert(error);
                                            clearInterval(pollingInterval);
                                            setLoading(false);
                                        });
                                }, 2000);
                            }}
                        />

                        <Spin spinning={loading} tip={'Analyzing...'} />
                    </div>
                ) : (
                    <div>
                        <div>
                            <a
                                style={{
                                    position: 'absolute',
                                    right: 40,
                                    top: 89,
                                }}
                                href={'/#'}
                                onClick={e => {
                                    e.preventDefault();
                                    setStepFile();
                                }}
                            >
                                Close
                            </a>
                            <ThreeDViewerFromStepFile id={step_file} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
