import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Card, Layout } from 'antd';

import HeaderComponent from './HeaderComponent';
import LoadingComponent from './LoadingComponent';
import { DealDetail, DealList } from '../pages/Deal';
import { QuoteDetailV2, QuoteList, QuoteNew, QuoteReport, RequoteLinkRedirector } from '../pages/Quote';

import NotFoundComponent from '../pages/NotFoundComponent';
import DashboardPage from '../pages/Dashboard';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../pages/LoginComponent';
import Logout from './LogoutComponent';
import { RfqList, RfqToQuote } from '../pages/Rfq/';
import DrawingTextBox from '../pages/Orders/DrawingTextBox';
import { NewPartQualityIssueReport } from '../pages/Orders/NewPartQualityIssueReport';
import CreditCardSubmission from '../pages/Orders/CreditCardSubmission';
import { PartViewer, ShopTimeTracker } from '../pages/Tools';
import {
    ContactsList,
    ManageAddresses,
    ManageRequirements,
    ManageTaxForms,
    MergeAccountsComponent,
    MergeContacts,
    NDAForms,
} from '../pages/Contact/';
import NewSalesOrder from '../pages/Orders/NewSalesOrder';
import { Compose } from './Rfq/message';
import {
    Details,
    ManageSalesOrderDocuments,
    OrderDetail,
    OrdersList,
    QualityKpisReport,
    UpdateHTSOnOrder,
} from '../pages/Orders';
import OrderReports from '../pages/Orders/OrderReports';
import CancelledIntakeReport from '../pages/Orders/CancelledIntakeReport';
import { GetQuote } from '../pages/OneTrueForm';
import { ShippingReceiving2 } from '../pages/ShippingReceivingForm';
import { MergeAccounts } from '../pages/Accounts/merge_accounts';
import { RequoteReport } from '../pages/Quote/ReportRequotesByAuthor';
import ReportQuoteTimeMetrics from '../pages/Quote/ReportQuoteTimeMetrics';
import EditSalesOrder from '../pages/Orders/EditSalesOrder';
import { UserStore } from '../stores/UserStore';
import OrdersRedirect from '../pages/Orders/OrderRedirect';
import { InventoryDetail, InventoryList } from '../pages/Inventory';
import CreditMemoPage from '../pages/Orders/CreditMemoPage';
import PurchaseOrder from '../pages/PurchaseOrders/PurchaseOrder';
import PurchaseOrderCreate from '../pages/PurchaseOrders/PurchaseOrderCreate';
import PurchaseOrderEdit from '../pages/PurchaseOrders/PurchaseOrderEdit';
import { LineItemsOnlyIntakeReportForm } from '../pages/Orders/LineItemsOnlyIntakeReport';
import { LeadTimeTrackingReportForm } from '../pages/Orders/LeadTimeTracking/LeadTimeTrackingReport';
import OrderReportsToday from 'pages/Orders/OrderReportsToday';
import { AccountRequirements } from '../pages/Accounts';
import ChecklistReviewPage from '../pages/Orders/ChecklistReviewPage';
import OuttakeAuditReport from 'pages/Orders/OuttakeAuditReport';
import ZOuttakeReport from 'pages/Orders/ZOuttakeReport';
import RequestDesignChange from '../pages/Orders/RquestDesignChange';
import BusinessRuleIndexPage from '../pages/BusinessRules/IndexPage';
import BusinessRuleEditPage from '../pages/BusinessRules/EditPage';
import BusinessRuleNewPage from '../pages/BusinessRules/NewPage';
import RedactionTool from '../pages/Orders/RedactionTool';

import packages from '../../package.json';
import GuidedTour from '../components/GuidedTour';
import { RouteProps } from 'react-router';
import { StorageListPage } from '../pages/StorageLocation';
import OrderApprovedSample from '../pages/Orders/OrderApprovedSample';
import { QuickTurnOrdersList } from 'pages/Orders/QuickTurnOrdersList';
import { QuickTurnOrderDetail } from 'pages/Orders/QuickTurnOrderDetail';

const { Header, Content, Sider } = Layout;

interface IRouteProp extends RouteProps {
    name: string;
}

const Main = () => {
    const { user, isLoading, isAuthenticated } = UserStore;

    useEffect(() => {
        UserStore.loadUser();
    }, []);

    useEffect(() => {
        window.localStorage.setItem('app_version', packages.version);
    }, [packages.version]);

    const withLayout = (ChildComponent: any, props: any, pageName: string) => {
        return (
            <Layout>
                <Sider className={'sider'} breakpoint="md" collapsedWidth="0">
                    {/*{user &&  <HeaderMenu user={user}/>}*/}
                </Sider>
                <Layout>
                    <Header style={{ zIndex: 2, width: '100%', padding: 0 }}>
                        <HeaderComponent
                            user={user}
                            handleLogout={() => {
                                UserStore.logout();
                            }}
                        />
                    </Header>
                    <Content style={{ margin: '5px 15px 0px' }}>
                        <Card
                            style={{ padding: 5, minHeight: '90vh' }}
                            bodyStyle={{
                                padding: 0,
                            }}
                        >
                            <GuidedTour pageName={pageName} />
                            <ChildComponent {...props} />
                        </Card>
                    </Content>
                    {/*<Layout.Footer>*/}
                    {/*    <div>*/}
                    {/*        Version:*/}
                    {/*        {window.localStorage.getItem('app_version')}*/}
                    {/*    </div>*/}
                    {/*</Layout.Footer>*/}
                </Layout>
            </Layout>
        );
    };

    const PrivateRoute = ({ component: ChildComponent, name, ...rest }: IRouteProp) => {
        return (
            <Route
                {...rest}
                render={props => {
                    if (isLoading) {
                        return (
                            <div style={{ paddingTop: 200, textAlign: 'center' }}>
                                <LoadingComponent visible={true} />
                            </div>
                        );
                    } else if (!isAuthenticated) {
                        const next = window.location.pathname;
                        return <Redirect to={'/login?next=' + next} />;
                    } else {
                        return withLayout(ChildComponent, props, name);
                    }
                }}
            />
        );
    };

    const PublicRoute = ({ component: ChildComponent, name, ...rest }: IRouteProp) => {
        return (
            <Route
                {...rest}
                render={props => {
                    return withLayout(ChildComponent, props, name);
                }}
            />
        );
    };

    if (isLoading) {
        return (
            <div style={{ paddingTop: 200, textAlign: 'center' }}>
                <LoadingComponent visible={true} />
            </div>
        );
    }

    return (
        <Switch>
            <PrivateRoute name="Dashboard" exact path="/" component={DashboardPage} />

            <PrivateRoute name="Part Viewers" exact path="/viewer" component={PartViewer} />

            <PrivateRoute name="Deal" exact path="/deals" component={DealList} />
            <PrivateRoute name="Deals Detail" exact path="/deals/:deal" component={DealDetail} />

            <PrivateRoute name="Shipping Receiving" exact path="/shipping-receiving/" component={ShippingReceiving2} />
            <PrivateRoute name="One True Form" exact path="/get-quote" component={GetQuote} />

            <PrivateRoute name="Quotes" exact path="/quotes" component={QuoteList} />
            <PrivateRoute name="Quotes" exact path={'/customer/quotes'} component={QuoteList} />
            <PrivateRoute name="Quote Tool" exact path="/quotes/:id" component={QuoteDetailV2} />
            <PrivateRoute name="New Quote" exact path="/quote/new/" component={QuoteNew} />
            <PrivateRoute name="Get Requote" exact path="/requote/:id" component={RequoteLinkRedirector} />

            <PrivateRoute name="DWG Text Box" exact path={'/orders/dwg'} component={DrawingTextBox} />
            <PrivateRoute name="DWG Text Box" exact path={'/tools/shop-time-tracker'} component={ShopTimeTracker} />

            <PrivateRoute name="RFQ" exact path="/rfq" component={RfqList} />
            <PrivateRoute name="New RFQ" exact path="/rfq/new" component={GetQuote} />
            <PrivateRoute name="View RFQ" exact path="/rfq/:id/create" component={RfqToQuote} />

            <PrivateRoute name="RFQ Module List" exact path="/module/quotes.rfq" component={RfqList} />
            <PrivateRoute name="RFQ Module Detail" exact path="/module/quotes.rfq/:id" component={Compose} />

            <PrivateRoute name="Request For Order List" exact path="/rfo/" component={OrdersList} />
            <PrivateRoute name="Request For Order New" exact path="/rfo/new/" component={NewSalesOrder} />

            <PrivateRoute
                name="List QuickTurn Orders"
                exact
                path="/quickturn/sales-orders/"
                component={QuickTurnOrdersList}
            />
            <PrivateRoute
                name="QuickTurn Order Details"
                exact
                path="/quickturn/sales-orders/:quickturn_sales_order_id"
                component={QuickTurnOrderDetail}
            />

            <PrivateRoute name="List Orders" exact path="/sales-orders/" component={OrdersList} />
            <PrivateRoute name="View Order" exact path="/sales-order-detail/:sales_order_id" component={OrderDetail} />
            <PrivateRoute
                name="Order Details"
                exact
                path="/new-sales-order-detail/:sales_order_id"
                component={Details}
            />
            <PrivateRoute name="New Order" exact path="/sales-order/new" component={NewSalesOrder} />
            <PrivateRoute name="Edit Order" exact path="/sales-order/:sales_order_id/" component={EditSalesOrder} />
            <PrivateRoute
                name="Edit Order"
                exact
                path="/sales-order/:sales_order_id/credit-memos"
                component={CreditMemoPage}
            />
            <PrivateRoute
                name={'order-approved-sample'}
                exact
                path="/sales-order/:id/approved-sample"
                component={OrderApprovedSample}
            />
            <PrivateRoute
                name={'checklist'}
                exact
                path="/sales-order/:id/checklists-view"
                component={ChecklistReviewPage}
            />
            <PrivateRoute
                name={'request-design-change'}
                exact
                path="/sales-order/:id/request-design-change"
                component={RequestDesignChange}
            />

            <PrivateRoute name="DWG Text Box" exact path={'/sales-orders/:id/dwg'} component={DrawingTextBox} />

            <PrivateRoute name="Update HTS" exact path={'/sales-orders/hts/'} component={UpdateHTSOnOrder} />

            <PrivateRoute
                name="Credit Card Submission"
                exact
                path={'/sales-orders/credit-card-submission/'}
                component={CreditCardSubmission}
            />

            <PrivateRoute
                name={'sales-order-process'}
                exact
                path={'/sales-order/:process/:zoho_id/'}
                component={OrdersRedirect}
            />

            <PublicRoute
                name="Sales Order Documents"
                exact
                path={'/sales-orders/documents'}
                component={ManageSalesOrderDocuments}
            />

            <PrivateRoute
                name="Part Quality Issue Report"
                exact
                path={'/sales-orders/quality-issue/'}
                component={NewPartQualityIssueReport}
            />

            <PrivateRoute name="New Order" exact path="/inventory/" component={InventoryList} />
            <PrivateRoute name="Edit Order" exact path="/inventory/:id/" component={InventoryDetail} />
            <PrivateRoute name="Edit Order" exact path="/storage-location/" component={StorageListPage} />

            <PrivateRoute name="Accounts" exact path="/accounts/merge" component={MergeAccounts} />
            <PrivateRoute name="Contacts" exact path="/contacts" component={ContactsList} />

            <PrivateRoute
                name="Requirements"
                exact
                path="/accounts-requirements/:zoho_id?/"
                component={AccountRequirements}
            />
            <PrivateRoute name="Requirements" exact path="/additional-requirements" component={ManageRequirements} />
            <PrivateRoute name="Tax Exempt Form" exact path="/contact/taxes-form" component={ManageTaxForms} />
            <PrivateRoute name="Addresses" exact path="/contact/addresses" component={ManageAddresses} />
            <PrivateRoute name="NDA Form" exact path="/contact/nda-form" component={NDAForms} />
            <PrivateRoute name="Merge Contacts" exact path="/contact/merge-contacts" component={MergeContacts} />
            <PrivateRoute
                name="Merge Accounts"
                exact
                path="/contact/merge-accounts"
                component={MergeAccountsComponent}
            />

            <PrivateRoute name="Report Quotes" exact path="/reports/quotes" component={QuoteReport} />
            <PrivateRoute name="Report Requotes" exact path="/reports/requotes" component={RequoteReport} />
            <PrivateRoute
                name={'rreport-avg-times'}
                exact
                path="/reports/report-avg-times/"
                component={ReportQuoteTimeMetrics}
            />
            <PrivateRoute name={'must-to-ship'} exact path="/reports/must-ship-today/" component={OrderReports} />
            <PrivateRoute name="today-intakes" exact path="/reports/today-intakes/" component={OrderReportsToday} />
            <PrivateRoute
                name={'cancelled-intake'}
                exact
                path="/reports/cancelled-intake/"
                component={CancelledIntakeReport}
            />
            <PrivateRoute name={'quality-kpis'} exact path="/reports/quality-kpis/" component={QualityKpisReport} />
            <PrivateRoute
                name={'audit-report'}
                exact
                path="/reports/outtake-audit-report/"
                component={OuttakeAuditReport}
            />
            <PrivateRoute
                name={'z-outtake-report'}
                exact
                path="/reports/purchase-orders-z-outtakes/"
                component={ZOuttakeReport}
            />

            <PrivateRoute name="Redaction Tool" exact path={'/orders/redaction-tool/:id'} component={RedactionTool} />

            <PrivateRoute name="Purchase Orders" exact path="/purchase-orders/" component={PurchaseOrder} />
            <PrivateRoute name="Purchase Order New" exact path="/purchase-orders/new" component={PurchaseOrderCreate} />
            <PrivateRoute
                name="Edit Purchase Order"
                exact
                path="/purchase-orders/:purchase_order_id/"
                component={PurchaseOrderEdit}
            />

            <PrivateRoute name={'business-rules'} exact path="/business-rules/" component={BusinessRuleIndexPage} />
            <PrivateRoute name={'business-rules'} exact path="/business-rules/new/" component={BusinessRuleNewPage} />
            <PrivateRoute name={'business-rules'} exact path="/business-rules/:id/" component={BusinessRuleEditPage} />

            <PrivateRoute
                name={'only-intake'}
                exact
                path="/reports/line-items-only-intake/"
                component={LineItemsOnlyIntakeReportForm}
            />

            <PrivateRoute
                name={'lead-time-tracking'}
                exact
                path="/reports/lead-time-tracking/"
                component={LeadTimeTrackingReportForm}
            />
            <PrivateRoute name="Logout" exact path="/logout" component={Logout} />
            <Route exact path="/login" component={Login} />
            <PublicRoute name="NotFound" component={NotFoundComponent} />
        </Switch>
    );
};

export default observer(Main);
