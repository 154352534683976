import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { QuoteStore } from '../../Stores';
var ReQuoteChanges = function (_a) {
    var quote_id = _a.quote_id, _b = _a.values, values = _b === void 0 ? [] : _b;
    var _c = useState(values), changes = _c[0], setChanges = _c[1];
    return (React.createElement("div", { className: 'p-4' },
        React.createElement(Checkbox.Group, { onChange: function (values) {
                setChanges(values);
            }, defaultValue: values, options: [
                { label: 'Quantity', value: 'Quantity' },
                { label: 'Lead Time', value: 'Lead Time' },
                { label: 'Material', value: 'Material' },
                { label: 'Coating or Finish', value: 'Coating or Finish' },
                { label: 'New File Revision', value: 'New File Revision' },
                { label: 'Add/Remove Lines', value: 'Add/Remove Lines' },
                { label: 'Pricing', value: 'Pricing' },
                { label: 'Additional Requirements', value: 'Additional Requirements' },
                { label: 'Refresh', value: 'Refresh' },
            ] }),
        React.createElement(Button, { onClick: function () {
                var payload = {
                    requote_changes: changes,
                };
                QuoteStore.updateQuote(quote_id, payload);
            } }, "Save")));
};
export default ReQuoteChanges;
