var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { UploadFile } from '@partsbadger/library';
import SalesOrderStore from './Stores/SalesOrderStore';
export var UploadFileInfo = function (_a) {
    var typeFile = _a.typeFile, record = _a.record, setShowDragger = _a.setShowDragger, accept = _a.accept;
    var order_items = SalesOrderStore.order_items;
    var updateStepFile = function (file_id, filename) {
        SalesOrderStore.updateOrderItem3D(record, file_id, filename);
        var payload = __assign(__assign({}, record), { name_3d_file: filename, production_3d_file_id: file_id });
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
        order_items.map(function (order) {
            var _a, _b, _c, _d, _e, _f;
            if (((_b = (_a = order.quote_has_product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.master_product) &&
                ((_d = (_c = order.quote_has_product) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.master_product) === ((_f = (_e = record.quote_has_product) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.master_product)) {
                updateStepFileAll(order, file_id, filename);
            }
        });
        setShowDragger(false);
    };
    var updateDrawingFile = function (file_id, filename) {
        SalesOrderStore.updateOrderItem2D(record, file_id, filename);
        var payload = __assign(__assign({}, record), { name_2d_file: filename, production_2d_file_id: file_id });
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
        order_items.map(function (order) {
            var _a, _b, _c, _d, _e, _f;
            if (((_b = (_a = order.quote_has_product) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.master_product) &&
                ((_d = (_c = order.quote_has_product) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.master_product) === ((_f = (_e = record.quote_has_product) === null || _e === void 0 ? void 0 : _e.product) === null || _f === void 0 ? void 0 : _f.master_product)) {
                updateDrawingFileAll(order, file_id, filename);
            }
        });
        setShowDragger(false);
    };
    var onUpload = function (file) {
        if (typeFile === '3D') {
            updateStepFile(file.response.id, file.name);
            SalesOrderStore.analyze_3d_file(file.response.id);
        }
        else if (typeFile === '2D') {
            updateDrawingFile(file.response.id, file.response.filename);
        }
    };
    var updateStepFileAll = function (record, file_id, filename) {
        SalesOrderStore.updateOrderItem3D(record, file_id, filename);
        var payload = __assign(__assign({}, record), { name_3d_file: filename, production_3d_file_id: file_id });
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
    };
    var updateDrawingFileAll = function (record, file_id, filename) {
        SalesOrderStore.updateOrderItem2D(record, file_id, filename);
        var payload = __assign(__assign({}, record), { name_2d_file: filename, production_2d_file_id: file_id });
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
    };
    return (React.createElement("div", { className: 'py-2' },
        React.createElement(UploadFile, { fileType: typeFile === '3D' ? '3D' : '2D', accept: accept, multiple: false, onUpload: function (file) { return onUpload(file); } })));
};
