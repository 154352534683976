import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import Icon from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Col, Divider, Input, message, Row, Table, Upload } from "antd";

import { DocumentForm } from "pages/Orders/SalesOrderDocuments/components/DocumentForm";
import { SalesOrders } from "pages/Orders/SalesOrderDocuments/components/DropBox";

import { useNohoLogin } from "Hooks/useNohoLogin";

import { NOHO_API_URL } from "constants/index";


message.config({
    right: 0,
    duration: 1,
    maxCount: 2,
});

const success = () => {


    message.success('Updated', 1);
};

const UploadFile = ({token, ...props}) => {

    const handleChange = (info) => {

        const {status} = info.file;

        if (props.onUpload && status === "done") {
            props.onUpload(info.file)
        }

        if (props.onRemove && status === "remove") {
            props.onRemove(info.file)
        }
    };

    return (
        <Upload.Dragger
            name="file"
            action={NOHO_API_URL + "/file-upload/"}
            headers={{
                'Authorization': `Token ${token}`
            }}
            {...props}
            onChange={handleChange}
            showUploadList={false}
        >
            <p className="ant-upload-hint">
                <span>Drag and drop your files here</span>
            </p>

        </Upload.Dragger>
    )

};

const PreviewPDF = ({url, hidePreview}) => {
    return (
        <div
            className="mt-10"
            style={{
                position: 'absolute',
                height: 1200,
                width: 1200,
                right: 0,
                left: 480,
                background: "white",
                border: "solid 1px",
                margin: 'auto',
                zIndex: 9999
            }}
        >
            <Icon className="float-right " type="close" onClick={() => hidePreview()}>
            </Icon>
            <iframe title={"Quote Preview"} src={url} frameBorder="0"
                    height={window.innerHeight} width="100%"/>
        </div>
    )
}
const SalesOrderLineItemsList = ({token, loading, line_items, selectedRowKeys, setSelectedRowKeys, handleRemove, handleAdd, handleUpdate}) => {


    const [previewURL, setPreviewURL] = useState();
    const fileRef = useRef();

    const filters = [];

    line_items.map(item => {
        if (filters.filter(e => e.value === item.material).length === 0) {
            filters.push({
                text: item.material,
                value: item.material,
            })
        }
    });


    const CertificateRender = ({item}) => {


        return (
            <div key={item.id} ref={fileRef}>

                {item.document_type === "MATERIAL_CERTIFICATE" && (
                    <React.Fragment>
                        <span>
                            <Input placeholder="Certificate Number" size="small" style={{width: 150}}
                                   defaultValue={item.certificate_number}
                                   onBlur={(e) => {
                                       if (item.certificate_number !== e.target.value) {
                                           const payload = {
                                               'certificate_number': e.target.value,
                                           };
                                           handleUpdate(item.id, payload);
                                       }


                                   }}/></span> <Divider type="vertical"/>
                    </React.Fragment>
                )}

                <span>{item.files.map(f => {
                    return <span onClick={() => {
                        window.open(f.url, '', 'width=800,height=800,left=800,top=200');
                    }}>{f.filename}

                    </span>
                })} </span>
                <span style={{color: "red", textAlign: "right", marginLeft: 5}} className={"link"}
                      onClick={() => handleRemove(item.id)}>
                    <LegacyIcon type={"delete"}/>
                </span>


            </div>
        );
    };


    return (

        <div>

            <Table dataSource={line_items.filter(item => item.name !== "CNC Part")}
                   rowKey={"id"}
                   loading={loading} pagination={false}
                   rowSelection={{
                       selectedRowKeys: selectedRowKeys,
                       onChange: (keys) => {
                           setSelectedRowKeys(keys)
                       }
                   }}>

                <Table.Column
                    title="Part Name"
                    dataIndex="part_name"
                    width={100}
                    render={(t, r) => {
                        return <div>
                                 {r?.name}
                               </div>;
                    }}
                />

                <Table.Column
                    title="Material"
                    dataIndex="material"
                    filters={filters}
                    width={100}
                    onFilter={(value, record) => record.material.indexOf(value) === 0}
                    render={(t, r) => {
                        return r.material
                    }}
                />
                <Table.Column
                    title="Revision"
                    dataIndex="revision"
                    filters={filters}
                    width={100}
                    onFilter={(value, record) => record.material.indexOf(value) === 0}
                    render={(t, r) => {
                        return <span>
                            <Input placeholder="Revision" size="small" style={{width: 80}}
                                   defaultValue={r.revision}
                                   onBlur={(e) => {

                                       if (r.revision !== e.target.value) {

                                           const payload = {
                                               'line_item': r.id,
                                               'revision': e.target.value,
                                           };

                                           axios.patch(NOHO_API_URL + `/sales-order-documents/update-revision/`, payload, {
                                               headers: {
                                                   'Authorization': `Token ${token}`
                                               }
                                           }).then(() => success());
                                       }

                                   }}/></span>
                    }}
                />

                <Table.Column
                    title="Alias"
                    dataIndex="alias"
                    filters={filters}
                    width={300}
                    render={(t, r) => {
                        const name = r.alias || r.name;
                        return <span>
                            <Input placeholder={r.name} size="small"
                                   defaultValue={name}
                                   onBlur={(e) => {

                                       if (name !== e.target.value) {

                                           const payload = {
                                               'line_item': r.id,
                                               'alias': e.target.value,
                                           };

                                           axios.patch(NOHO_API_URL + `/sales-order-documents/update-revision/`, payload, {
                                               headers: {
                                                   'Authorization': `Token ${token}`
                                               }
                                           }).then(() => success());
                                       }

                                   }}/></span>
                    }}
                />


                <Table.Column
                    title="Approved"
                    dataIndex="approved_docs"
                    filters={filters}
                    render={(t, r) => {
                        return <span><Checkbox size="small" defaultChecked={r.approved_docs} onChange={(e) => {

                            const payload = {
                                'line_item': r.id,
                                'approved_docs': e.target.checked,
                            };

                            axios.patch(NOHO_API_URL + `/sales-order-documents/update-revision/`, payload, {
                                headers: {
                                    'Authorization': `Token ${token}`
                                }
                            }).then(() => success());

                        }}/></span>
                    }}
                />


                <Table.Column
                    title="Material Certificate"
                    dataIndex="material_certs"
                    width={400}
                    render={(t, r) => {
                        const file_list = r.documents.filter(item => item.document_type === "MATERIAL_CERTIFICATE").map(item => {
                            return <CertificateRender item={item}/>
                        });

                        return (
                            <div>
                                <div className="p-2">
                                    {file_list}
                                </div>

                                <UploadFile token={token} onUpload={file => {
                                    const payload = {
                                        'document_type': "MATERIAL_CERTIFICATE",
                                        'files': [file.response.id],
                                        'certificate_number': " ",
                                        'sales_order_items': [r.id],
                                    };

                                    handleAdd(payload)
                                }}/>
                            </div>
                        )
                    }}
                />

                <Table.Column
                    title="Finish Certificate"
                    dataIndex="finish_certs"
                    width={400}
                    render={(t, r) => {

                        const file_list = r.documents.filter(item => item.document_type === "FINISH_CERTIFICATE").map(item => {
                            return <CertificateRender item={item}/>
                        });

                        return (
                            <div>
                                <div className="p-2">
                                    {file_list}
                                </div>

                                <UploadFile token={token} onUpload={file => {
                                    const payload = {
                                        'document_type': "FINISH_CERTIFICATE",
                                        'files': [file.response.id],
                                        'certificate_number': " ",
                                        'sales_order_items': [r.id],
                                    };

                                    handleAdd(payload)
                                }}/>
                            </div>
                        )


                    }}
                />
            </Table>


            {previewURL && (
                <PreviewPDF url={previewURL} hidePreview={() => setPreviewURL()}/>
            )}


        </div>
    )
};


export const ManageSalesOrderDocuments = () => {

    const [order, setOrder] = useState({});
    const [lineItems, setLineItems] = useState([]);


    const {key: sales_order_id} = order || {};

    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const {loggedIn, token, GoogleButton} = useNohoLogin();


    const getLineItems = () => {
        if (sales_order_id) {
            axios.get(NOHO_API_URL + `/sales-order-documents/line-items/`, {
                params: {
                    sales_order: sales_order_id
                },
                headers: {
                    'Authorization': `Token ${token}`
                }
            }).then(response => {
                setLineItems(response.data);

                setLoading(false);
            }).catch(err => {
                setLoading(false);
            })
        } else {
            setLineItems([])
        }
    };


    useEffect(() => {

        //Avoid open documents on the browser on drop

        document.addEventListener("dragover", function (e) {
            e.preventDefault();
        }, false);
        document.addEventListener("drop", function (e) {
            e.preventDefault();
        }, false);

        getLineItems();
    }, [sales_order_id]);


    const handleRemove = (id) => {
        setLoading(true);
        axios.delete(NOHO_API_URL + `/sales-order-documents/${id}/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        }).then(() => {
            getLineItems();
        }).finally(() => setLoading(false));
    };

    const handleSave = () => {
        getLineItems();
    };

    const handleAdd = (payload) => {
        axios.post(NOHO_API_URL + '/sales-order-documents/', payload, {
            headers: {
                'Authorization': `Token ${token}`
            }
        }).finally(() => getLineItems())
    };

    const handleUpdate = (id, payload) => {
        axios.patch(NOHO_API_URL + `/sales-order-documents/${id}/`, payload, {
            headers: {
                'Authorization': `Token ${token}`
            }
        }).finally(() => {
            getLineItems();
            success();
        })
    };

    if (!loggedIn) {
        return <div style={{
            display: "flex",
            minHeight: 400,
            margin: 'auto',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: "center"
        }}>
            <div>{GoogleButton}</div>
        </div>
    }


    return (
        <div style={{margin: "auto"}}>
            <Divider>
                <div className={"title"}> Sales Order Documents Needed</div>
            </Divider>

            <Row style={{marginTop: 10}}>
                <Col md={8}>
                    <Form.Item label={"Sales Order"}>
                        <SalesOrders
                            token={token}
                            url={NOHO_API_URL + '/salesorders/'}
                            onChange={item => setOrder(item)}
                        />
                    </Form.Item>
                </Col>

            </Row>

            <Row>
                <Col md={24}>
                    <div style={{width: "95%"}}>
                        <Form.Item label={"Line Items"}>
                            {lineItems.length > 0 && <SalesOrderLineItemsList token={token}
                                                                              line_items={lineItems}
                                                                              selectedRowKeys={selectedRowKeys}
                                                                              setSelectedRowKeys={setSelectedRowKeys}
                                                                              handleRemove={handleRemove}
                                                                              handleAdd={handleAdd}
                                                                              handleUpdate={handleUpdate}
                                                                              loading={loading}
                            />
                            }
                        </Form.Item>
                    </div>
                </Col>
            </Row>


            {selectedRowKeys.length > 0 && (
                <Row>
                    <Col className="m-auto" style={{maxWidth: "400px"}}>
                        <Form.Item label={"Add Document"}>

                            {sales_order_id && lineItems.length > 0 &&
                            <DocumentForm sales_order={sales_order_id} line_items={selectedRowKeys}
                                          token={token}
                                          handleSave={handleSave}/>}

                        </Form.Item>
                    </Col>
                </Row>

            )}

        </div>
    )
}





