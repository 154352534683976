var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Alert, Spin, Tabs } from 'antd';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { SortFiles } from '@partsbadger/one-true-form/lib/components/SortFiles';
import { PartSlot } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
var TabPane = Tabs.TabPane;
var style = {
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
    height: 'auto',
    border: '1px #000 dashed',
};
var ItemInfo = function (_a) {
    var index = _a.index, line_item = _a.line_item, type = _a.type, isDropped = _a.isDropped;
    var _b = useDrag(function () { return ({
        type: type,
        item: { index: index, line_item: line_item, type: type },
    }); }), collected = _b[0], drag = _b[1];
    return (React.createElement("div", { className: 'draggable-item', ref: drag, style: __assign({}, style) }, isDropped ? (React.createElement("span", null)) : (React.createElement("div", { className: "flex flex-row" },
        React.createElement(DragOutlined, { className: "ml-auto" }),
        type === '3D File' && React.createElement("div", { className: "mx-2" }, line_item.filename),
        type === '2D File' && React.createElement("div", { className: "mx-2" }, line_item.filename),
        type === 'Additional File' && React.createElement("div", { className: "mx-2" }, line_item.filename)))));
};
export var ReArrangeFiles = observer(function (_a) {
    var quote_id = _a.quote_id;
    var _b = useState([]), records = _b[0], setRecords = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    useEffect(function () {
        setRecords(QuoteStore.master_products_with_replace_files);
    }, [QuoteStore.masterProductsByPosition]);
    var moveStepFiles = useCallback(function (dragIndex, hoverIndex) {
        setLoading(true);
        var item_drag = records[dragIndex].step_file;
        var item_hover = records[hoverIndex].step_file;
        var data_drag = records[dragIndex];
        var data_hover = records[hoverIndex];
        records[dragIndex].step_file = item_hover;
        records[hoverIndex].step_file = item_drag;
        records[dragIndex].replace_3d_data = {
            shape: data_hover.shape,
            unit: data_hover.unit,
            length: data_hover.length,
            width: data_hover.width,
            height: data_hover.height,
            inner_diameter: data_hover.inner_diameter,
            volume: data_hover.volume,
            volume_3d_printing: data_hover.volume_3d_printing,
            part_volume_percentage: data_hover.part_volume_percentage,
            itar: data_hover.itar,
        };
        records[hoverIndex].replace_3d_data = {
            shape: data_drag.shape,
            unit: data_drag.unit,
            length: data_drag.length,
            width: data_drag.width,
            height: data_drag.height,
            inner_diameter: data_drag.inner_diameter,
            volume: data_drag.volume,
            volume_3d_printing: data_drag.volume_3d_printing,
            part_volume_percentage: data_drag.part_volume_percentage,
            itar: data_drag.itar,
        };
        var drag = records[dragIndex];
        var hover = records[hoverIndex];
        setRecords(update(records, {
            $splice: [
                [hoverIndex, 1, hover],
                [dragIndex, 1, drag],
            ],
        }));
        QuoteStore.syncDataByReplaceMasterProductFiles(records);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(function () { return QuoteStore.getMasterProducts(quote_id); })
            .finally(function () { return setLoading(false); });
    }, [records]);
    var moveDrawingFiles = useCallback(function (dragIndex, hoverIndex) {
        setLoading(true);
        var item_drag = records[dragIndex].drawing_file;
        var item_hover = records[hoverIndex].drawing_file;
        records[dragIndex].drawing_file = item_hover;
        records[hoverIndex].drawing_file = item_drag;
        var drag = records[dragIndex];
        var hover = records[hoverIndex];
        setRecords(update(records, {
            $splice: [
                [hoverIndex, 1, hover],
                [dragIndex, 1, drag],
            ],
        }));
        QuoteStore.syncDataByReplaceMasterProductFiles(records);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(function () { return QuoteStore.getMasterProducts(quote_id); })
            .finally(function () { return setLoading(false); });
    }, [records]);
    var moveAdditionalFiles = useCallback(function (dragIndex, hoverIndex, item_moved) {
        setLoading(true);
        if (item_moved.type == '2D File') {
            records[hoverIndex].other_files.push({
                id: item_moved.line_item.id,
                filename: item_moved.line_item.filename,
                type: item_moved.type,
            });
            var hover = records[hoverIndex];
            records[dragIndex].drawing_file = null;
            var drag = records[dragIndex];
            setRecords(update(records, {
                $splice: [
                    [hoverIndex, 1, hover],
                    [dragIndex, 1, drag],
                ],
            }));
            QuoteStore.syncDataByReplaceMasterProductFiles(records);
            QuoteStore.replaceFilesMasterProduct(quote_id)
                .then(function () { return QuoteStore.getMasterProducts(quote_id); })
                .finally(function () { return setLoading(false); });
        }
        else {
            var index_file = records[dragIndex].other_files
                .map(function (file) {
                return file.id;
            })
                .indexOf(item_moved.line_item.id);
            records[dragIndex].other_files.splice(index_file, 1);
            var add_hover = records[hoverIndex].other_files.findIndex(function (file) { return file.id === item_moved.line_item.id; });
            add_hover === -1 &&
                records[hoverIndex].other_files.push({
                    id: item_moved.line_item.id,
                    filename: item_moved.line_item.filename,
                });
            var drag = records[dragIndex];
            var hover = records[hoverIndex];
            setRecords(update(records, {
                $splice: [
                    [hoverIndex, 1, hover],
                    [dragIndex, 1, drag],
                ],
            }));
            QuoteStore.syncDataByReplaceMasterProductFiles(records);
            QuoteStore.replaceFilesMasterProduct(quote_id)
                .then(function () { return QuoteStore.getMasterProducts(quote_id); })
                .finally(function () { return setLoading(false); });
        }
    }, [records]);
    var moveAdditionalFilesToDrawingFile = useCallback(function (dragIndex, hoverIndex, item_moved) {
        setLoading(true);
        var index_file = records[dragIndex].other_files
            .map(function (file) {
            return file.id;
        })
            .indexOf(item_moved.line_item.id);
        records[hoverIndex].drawing_file = records[dragIndex].other_files.splice(index_file, 1)[0];
        records[hoverIndex].drawing_file.type = 'Additional File';
        var hover = records[hoverIndex];
        var drag = records[dragIndex];
        setRecords(update(records, {
            $splice: [
                [hoverIndex, 1, hover],
                [dragIndex, 1, drag],
            ],
        }));
        QuoteStore.syncDataByReplaceMasterProductFiles(records);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(function () { return QuoteStore.getMasterProducts(quote_id); })
            .finally(function () { return setLoading(false); });
    }, [records]);
    var parts = records.map(function (part_item, index) {
        var file3d = part_item.step_file ? (React.createElement(ItemInfo, { index: index, line_item: part_item.step_file, type: '3D File' })) : null;
        var file2d = part_item.drawing_file ? (React.createElement(ItemInfo, { index: index, line_item: part_item.drawing_file, type: '2D File' })) : null;
        var fileOthers = part_item.other_files.length > 0
            ? part_item.other_files.map(function (item) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(ItemInfo, { index: index, line_item: item, type: 'Additional File' }),
                    React.createElement("br", null)));
            })
            : null;
        return (React.createElement(React.Fragment, null,
            React.createElement(Tabs, null,
                React.createElement(TabPane, { tab: [React.createElement("span", { key: "1" },
                            "Line item: ",
                            part_item.name)], key: "1" }, part_item.editable ? (React.createElement("div", { className: 'row-associate-parts', key: index, style: {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(217, 217, 217, 0.19)',
                        marginBottom: '5px',
                        padding: '10px',
                        position: 'relative',
                    } },
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: file3d, onDrop: function (item) {
                            moveStepFiles(item.index, index);
                        }, onDropNew: function () {
                            console.log('on drop new');
                        } }),
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: file2d, onDrop: function (item) {
                            if (item.type === 'Additional File') {
                                moveAdditionalFilesToDrawingFile(item.index, index, item);
                            }
                            else {
                                moveDrawingFiles(item.index, index);
                            }
                        }, onDropNew: function () {
                            console.log('on drop new');
                        } }),
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: fileOthers, onDrop: function (item) {
                            moveAdditionalFiles(item.index, index, item);
                        }, onDropNew: function () {
                            console.log('todo');
                        } }))) : (React.createElement("div", { className: 'flex justify-center' },
                    React.createElement(Alert, { style: { margin: 5, width: '350px' }, type: 'warning', className: "text-center", message: 'This master product is used in other quotes.' })))))));
    });
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement("div", null,
            React.createElement("div", { className: 'text-sm' }, "1. It is allowed to move files between the same type 3D, 2D and Additional."),
            React.createElement("div", { className: 'text-sm' }, "2. You can move files between 2D and Additional types. "),
            React.createElement("div", { className: 'text-sm' }, "3. You cannot move files from other types to the 3D type.")),
        React.createElement("br", null),
        React.createElement("div", { className: "flex flex-row", style: {
                background: '#fafafa',
            } },
            React.createElement("div", { className: "flex-1 mr-auto text-center" },
                React.createElement(SortFiles, { fileType: '3D File' })),
            React.createElement("div", { className: "flex-1 mr-auto text-center" },
                React.createElement(SortFiles, { fileType: '2D File' })),
            React.createElement("div", { className: "flex-1 mr-auto text-center" },
                React.createElement(SortFiles, { fileType: 'Additional File' }))),
        React.createElement(Spin, { spinning: loading, tip: 'Moving file...' }, parts)));
});
