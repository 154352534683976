import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
var ChangeRequiredQuantitiesForm = function (props) {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var getFieldDecorator = (props === null || props === void 0 ? void 0 : props.form).getFieldDecorator;
    var onSubmit = function () {
        props.form.validateFields(function (err, values) {
            if (!err) {
                setIsLoading(true);
                props.handleChangeQuantities(values.quantities).finally(function () {
                    setIsLoading(false);
                });
            }
        });
    };
    return (React.createElement(Form, { layout: 'inline' },
        React.createElement(Form.Item, { label: 'Customer Requested Quantities' }, getFieldDecorator('quantities', {
            rules: [{ required: true, message: 'Please input one or more quantities' }],
            initialValue: props.quantities || [],
        })(React.createElement(Select, { mode: "tags", disabled: props.isReadOnly, placeholder: '1,10,100,1000', tokenSeparators: [','], onInputKeyDown: function (evt) {
                var charCode = evt.keyCode;
                if (charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    (charCode < 96 || charCode > 105) &&
                    charCode !== 188) {
                    evt.preventDefault();
                }
            }, notFoundContent: 'Type a number', style: { minWidth: 200 } }))),
        React.createElement(Form.Item, null,
            React.createElement(Button, { disabled: props.isReadOnly, title: "Update Quantities and generate range", loading: isLoading, type: 'link', htmlType: 'submit', onClick: onSubmit },
                "Update Quantities",
                React.createElement(SaveOutlined, null)))));
};
export default Form.create()(ChangeRequiredQuantitiesForm);
