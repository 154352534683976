import React, { useEffect, useState } from 'react';
import { post } from '../../shared';
import { SalesOrderOuttakesProps } from '@partsbadger/library/lib/Types/TypesSalesOrder';
import { Card, DatePicker, Table } from 'antd';
import moment, { Moment } from 'moment';

const OuuttakeAuditReport = () => {
    const [loading, setLoading] = useState(false);

    const [outtakesWithOutBoxes, setOuttakesWithOutBoxes] = useState<SalesOrderOuttakesProps[]>([]);

    const [selectedDate, setSelectedDate] = useState<Moment | null>();

    useEffect(() => {
        setLoading(true);
        setSelectedDate(moment());
        loadData().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (selectedDate) {
            getOuttakesWithoutBoxes().finally(() => setLoading(false));
        }
    }, [selectedDate]);

    const loadData = async () => {
        if (selectedDate) {
            await getOuttakesWithoutBoxes();
        }
    };

    const getOuttakesWithoutBoxes = async () => {
        const response = await post(`staff/outtakes/outtakes-without-boxes/`, {
            date: selectedDate?.format('YYYY-MM-DD'),
        });
        setOuttakesWithOutBoxes(response.data);
    };

    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'sales_order_name',
            key: 'sales_order_name',
        },
        {
            title: 'Status',
            dataIndex: 'sales_order_status',
            key: 'status',
        },
        {
            title: 'Status Date',
            dataIndex: 'sales_order_date',
            render: (_: string, record: SalesOrderOuttakesProps) => {
                return moment(record.sales_order_date).format('YYYY-MM-DD');
            },
        },
    ];

    return (
        <>
            <div className={'row'}>
                <DatePicker allowClear={false} defaultValue={moment()} onChange={date => setSelectedDate(date)} />
            </div>

            <Card title={'Outtake Records Missing a Box'}>
                <div className={'w-full'}>
                    <Table
                        key={'outtakes'}
                        columns={[...columns]}
                        loading={loading}
                        dataSource={outtakesWithOutBoxes}
                        pagination={false}
                    />
                </div>
            </Card>
        </>
    );
};

export default OuuttakeAuditReport;
