var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, Radio } from 'antd';
import { Countries, States } from '../Select';
import { AutoCompleteAddressInput } from '../AutoCompleteAddressInput';
var formItemLayout = {
    labelCol: {
        span: 7,
    },
};
var formItem = {
    style: {
        marginBottom: 8,
        paddingBottom: 0,
    },
};
var radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};
export var is_valid_address = function (rule, value) {
    return !(!value.name || !value.street || !value.city || !value.country || !value.zip_code);
};
var AddressFormComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var _q = useState(false), loading = _q[0], setLoading = _q[1];
    var form = props.form;
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue, setFieldsValue = form.setFieldsValue;
    var handleSubmit = function () {
        form.validateFields(function (err, values) {
            if (!err) {
                if (!err) {
                    var payload = {
                        name: values.name,
                        type: props.addressType ? props.addressType : values.type,
                        street: values.street,
                        city: values.city,
                        state: values.state.key,
                        country: values.country.key,
                        zip_code: values.zip_code,
                        primary: values.primary,
                    };
                    if (values.primary) {
                        payload.type = 'Billing and Shipping';
                    }
                    setLoading(true);
                    props.handleSave(payload).finally(function () {
                        setLoading(false);
                        props.handleClose();
                    });
                }
            }
        });
    };
    var labelAddressName = (props === null || props === void 0 ? void 0 : props.addressType)
        ? props.addressType + " To: (Full Name or Company)"
        : 'Full Name or Company';
    return (React.createElement(Form, { layout: "vertical" },
        React.createElement(Form.Item, __assign({ label: labelAddressName }, formItem), getFieldDecorator('name', {
            rules: [
                {
                    required: true,
                },
            ],
            initialValue: (_b = (_a = props.address) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : props.defaultAddressName,
        })(React.createElement(Input, null))),
        React.createElement(Form.Item, __assign({ label: 'Search Address' }, formItem), getFieldDecorator('full_address', {
            rules: [
                {
                    required: false,
                },
            ],
            initialValue: (_c = props.address) === null || _c === void 0 ? void 0 : _c.street,
        })(React.createElement(AutoCompleteAddressInput, { onAutoFill: function (addressItem) {
                setFieldsValue({
                    street: addressItem.street,
                    city: addressItem.city,
                    state: addressItem.state,
                    country: addressItem.country,
                    zip_code: addressItem.zip_code,
                });
            } }))),
        React.createElement(Form.Item, __assign({ label: 'Street' }, formItem, { className: 'mt-4' }), getFieldDecorator('street', {
            rules: [
                {
                    required: true,
                },
            ],
            initialValue: (_d = props.address) === null || _d === void 0 ? void 0 : _d.street,
        })(React.createElement(Input, null))),
        React.createElement(Form.Item, __assign({ label: 'City' }, formItem), getFieldDecorator('city', {
            rules: [
                {
                    required: true,
                },
            ],
            initialValue: (_e = props.address) === null || _e === void 0 ? void 0 : _e.city,
        })(React.createElement(Input, null))),
        React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: 'w-full md:w-1/2' },
                React.createElement(Form.Item, __assign({ label: 'Country' }, formItem), getFieldDecorator('country', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: ((_f = props.address) === null || _f === void 0 ? void 0 : _f.country)
                        ? {
                            key: (_g = props.address) === null || _g === void 0 ? void 0 : _g.country.code,
                            label: (_h = props.address) === null || _h === void 0 ? void 0 : _h.country.name,
                        }
                        : {
                            key: 'US',
                            label: 'United States',
                        },
                })(React.createElement(Countries, null)))),
            React.createElement("div", { className: 'w-full md:w-1/2' },
                React.createElement(Form.Item, __assign({ label: 'State' }, formItem), getFieldDecorator('state', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: ((_j = props.address) === null || _j === void 0 ? void 0 : _j.state)
                        ? {
                            key: props === null || props === void 0 ? void 0 : props.address.state,
                            label: props === null || props === void 0 ? void 0 : props.address.state,
                        }
                        : {},
                })(React.createElement(States, { countryShortCode: (_k = getFieldValue('country')) === null || _k === void 0 ? void 0 : _k.key }))))),
        React.createElement("div", { className: "grid grid-cols-2 gap-4" },
            React.createElement(Form.Item, __assign({ label: 'Zip Code' }, formItem), getFieldDecorator('zip_code', {
                rules: [
                    {
                        required: true,
                        min: 5,
                    },
                ],
                initialValue: (_l = props.address) === null || _l === void 0 ? void 0 : _l.zip_code,
            })(React.createElement(Input, null))),
            !props.addressType && (React.createElement(Form.Item, __assign({ label: 'Type' }, formItem), getFieldDecorator('type', {
                rules: [
                    {
                        required: true,
                    },
                ],
                initialValue: (_m = props.address) === null || _m === void 0 ? void 0 : _m.type,
            })(React.createElement(Radio.Group, { size: 'small' },
                React.createElement(Radio, { style: radioStyle, value: 'Billing' }, "Billing"),
                React.createElement(Radio, { style: radioStyle, value: 'Shipping' }, "Shipping"),
                React.createElement(Radio, { style: radioStyle, value: 'Billing and Shipping' }, "Billing and Shipping")))))),
        React.createElement("div", { className: "grid grid-cols-1 gap-4" },
            React.createElement(Form.Item, null, getFieldDecorator('primary', {
                rules: [
                    {
                        required: false,
                    },
                ],
                valuePropName: 'checked',
                initialValue: (_o = props.address) === null || _o === void 0 ? void 0 : _o.primary,
            })(React.createElement(Checkbox, null, "Make this my default address")))),
        React.createElement("div", { className: "flex flex-col p-4 space-y-2", style: {
                borderTop: 'solid 1px #d9d9d9',
            } },
            React.createElement(Button, { onClick: handleSubmit, type: "primary", loading: loading }, ((_p = props.address) === null || _p === void 0 ? void 0 : _p.id) ? 'Update Address' : 'Create New Address'),
            React.createElement(Button, { type: "link", loading: loading, onClick: function () { return props.handleClose(); } }, "Cancel"))));
};
export var AddressForm = Form.create()(AddressFormComponent);
