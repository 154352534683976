import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Select, Upload } from "antd";
import React, { useState } from "react";
import axios from "axios";

import { NOHO_API_URL } from "constants/index";

export const DocumentForm = Form.create()(({sales_order, line_items, handleSave, ...props}) => {

    const {form, token} = props;
    const {getFieldDecorator} = form;

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                if (line_items.length === 0) {
                    message.error("Please select a line item")
                }

                const payload = {
                    'document_type': values.document_type,
                    'files': values.files?.map(f => f.response.id),
                    'certificate_number': values.certificate_number,
                    'sales_order_items': line_items,
                };

                setLoading(true);

                axios.post(NOHO_API_URL + '/sales-order-documents/', payload, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                }).then(() => {
                    handleSave(payload);
                    form.resetFields();
                }).finally(() => setLoading(false))
            }
        })
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const disabled = line_items.length === 0;

    return (
        <div style={{
            border: 'solid 1px #d4d4d4',
            padding: '10px'
        }}>
            <Form layout={"horizontal"} onSubmit={handleSubmit}>

                <Form.Item label={"Document Type"}>
                    {getFieldDecorator('document_type', {
                        rules: [{required: true, message: 'Please select a value!'}],
                    })(
                        <Select dropdownMatchSelectWidth={false} disabled={disabled}>
                            <Select.Option
                                value="MATERIAL_CERTIFICATE"> Material Certificate </Select.Option>

                            <Select.Option
                                value="FINISH_CERTIFICATE"> Finish Certificate </Select.Option>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item label={"Certificate Number"}>
                    {getFieldDecorator('certificate_number', {
                        rules: [{required: true, message: 'Please select a value!'}],
                    })(
                        <Input disabled={disabled}/>
                    )}
                </Form.Item>
                <Form.Item label={"Documents"}>
                    {getFieldDecorator('files', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                    })(
                        <Upload.Dragger
                            name="file"
                            action={NOHO_API_URL + "/file-upload/"}
                            headers={{
                                'Authorization': `Token ${token}`
                            }}
                            disabled={disabled}
                        >
                            <LegacyIcon type={'upload'}/>
                        </Upload.Dragger>
                    )}
                </Form.Item>

                <Button style={{marginTop: 10}} onClick={handleSubmit} type="primary"
                        loading={loading} disabled={disabled}> Save
                </Button>
            </Form>
        </div>
    );

});