import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { init as initApm } from '@elastic/apm-rum'
import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { ThemeProvider } from './theme/theme-provider';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://40aca52e0ffe4340a5966c5fba22f20e@sentry.io/1767659',
        environment: process.env.NODE_ENV,
        integrations: [
            new CaptureConsoleIntegration({
                // array of methods that should be captured
                // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['error'],
            }),
        ],
        beforeSend(event, hint) {
            if (
                hint &&
                hint.originalException &&
                typeof hint.originalException != 'string' &&
                hint.originalException.message == 'Cannot call hover while not dragging.'
            ) {
                return null;
            }
            return event;
        },
    });
}

ReactDOM.render(
    <ThemeProvider>
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
