import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Divider, Row, Table } from 'antd';
import {fetchDealById} from "../../redux/actions/dealActions";
import {fetchQuotesByDeal} from "../../redux/actions/quoteActions";

import {connect} from "react-redux";
import LoadingComponent from "../../components/LoadingComponent";
import {ErrorRender} from "@partsbadger/library";
import moment from 'moment'
import Stages from "../../components/Stages";
import {FormNewModal} from "../../components/QuoteTool/forms/FormNewModal";
import {QuoteStore} from "../../stores";


const {Column} = Table;


const gridStyle = {
    width: '25%',
    textAlign: 'center',
};

class DealDetailComponent extends React.Component {

    componentDidMount() {
        this.props.fetchDealById(this.props.match.params.deal);
        this.props.fetchQuotesByDeal(this.props.match.params.deal)
    }

    state = {
        viewQuote: false,
        quote: {}
    }

    goBack = () => {
        this.props.history.goBack();
    }


    render() {

        return (
            <Fragment>
                <Row className={"back"}>
                    <ArrowLeftOutlined
                        style={{
                            fontSize: '30px',
                            color: '#08c',
                            float: "left",
                            padding: "5px",
                        }}
                        onClick={() => this.goBack()} />
                </Row>

                <ErrorRender error={this.props.deals.error}/>
                <Divider>
                    <div className={"title"}> Deal Details</div>
                </Divider>
                <RenderDeal deal={this.props.deals}/>
                <FormNewModal {...this.props}/>
                <Divider>
                    <div className={"title"}> Quotes</div>
                </Divider>
                <RenderQuotes quotes={this.props.quotes}
                              deleteQuote={(id) => QuoteStore.deleteQuote(id, this.props.history)} {...this.props}
                />
            </Fragment>
        );

    }

}


/**
 * Render a specific Deal
 * @param deal
 * @returns {*}
 * @constructor
 */
const RenderDeal = (props) => {

    if (props.deal.isLoading)
        return (<LoadingComponent visible={props.deal.isLoading}/>)

    const deal = props.deal.entity;

    if (deal.id)
        return (
            <Fragment>
                <Card title={deal.name}>
                    <Card.Grid style={gridStyle}>
                        <div className={"title"}>Account</div>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>{deal.account && deal.account.name}</Card.Grid>
                    <Card.Grid style={gridStyle}>
                        <div className={"title"}>Contact</div>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>{deal.contact.fullname}</Card.Grid>
                    <Card.Grid style={gridStyle}>
                        <div className={"title"}>Created Time</div>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>{moment(deal.created_time).format("LLL")}</Card.Grid>
                    <Card.Grid style={gridStyle}>
                        <div className={"title"}>Amount</div>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>{deal.amount ? deal.amount : 0}</Card.Grid>

                </Card>
            </Fragment>
        );
    else
        return <Fragment></Fragment>
}


/**
 * Render all the quotes
 * @param quotes
 * @returns {*}
 * @constructor
 */
const RenderQuotes = (props) => {

    if (props.quotes.isLoading)
        return (<LoadingComponent visible={true}/>)


    if (props.quotes) {
        const dataSource = props.quotes.entities.map((quote) => {
            return {
                key: quote.id,
                created_time: moment(quote.created_time).format("LL"),
                name: quote.name,
                stage: quote.stage,
                grand_total: quote.grand_total?.toFixed(2),
                owner: quote.owner,
                line_items: quote.line_items
            };
        });

        return (
            <Fragment>

                <ErrorRender error={props.quotes.error}/>


                <Table dataSource={dataSource}
                       onRow={(record) => {
                           return {
                               onClick: (event) => {
                                   props.history.push("/quotes/" + record.key)
                               },

                           }
                       }}
                >
                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                    />
                    <Column
                        title="Created Time"
                        dataIndex="created_time"
                        key="created_time"
                    />
                    <Column
                        title="Owner"
                        dataIndex="owner"
                        key="owner"
                        render={(text, record) => (
                            <span>
                                        {record.owner ? record.owner.fullname : "--"}
                                        </span>
                        )}
                    />
                    <Column
                        title="Line Items"
                        dataIndex="line_items"
                        key="line_items"
                        render={(text, record) => {

                            if (record.line_items) {
                                return (<ul>
                                    {
                                        record.line_items.map(r => {
                                            return <li>{r.product?.master_product?.name}</li>
                                        })
                                    }
                                </ul>)
                            }

                        }}

                    />
                    <Column
                        title="Stage"
                        dataIndex="stage"
                        key="stage"
                        render={(text, record) => <Stages stage={record.stage}/>}
                    />
                    <Column
                        title="Grand Total"
                        dataIndex="grand_total"
                        key="grand_total"
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(text, record) => (
                            <Fragment>
                                <span>
                                <Link to={"/quotes/" + record.key}>Edit</Link>
                                </span>
                            </Fragment>
                        )}
                    />
                </Table>
            </Fragment>
        );
    } else {
        return null;
    }
}


const mapStateToProps = state => {
    return {
        deals: state.deals,
        quotes: state.quotes,
    };
}


const mapDispatchToProps = (dispatch, ownsProps) => {
    return {
        fetchDealById: (id) => dispatch(fetchDealById(id)),
        fetchQuotesByDeal: (id) => dispatch(fetchQuotesByDeal(id)),
    };
}

export const DealDetail = connect(mapStateToProps, mapDispatchToProps)(DealDetailComponent);

