import React, { useEffect, useState } from 'react';
import { post } from '../../shared';
import { SalesOrderOuttakesProps } from '@partsbadger/library/lib/Types/TypesSalesOrder';
import { Card, Table } from 'antd';
import moment, { Moment } from 'moment';


const ZOuttakeReport = () => {
    const [loading, setLoading] = useState(false);

    const [outtakesWithOutBoxes, setOuttakesWithOutBoxes] = useState<SalesOrderOuttakesProps[]>([]);

    const [selectedDate, setSelectedDate] = useState<Moment | null>();

    useEffect(() => {
        setLoading(true);
        setSelectedDate(moment());
        loadData().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (selectedDate) {
            getOuttakesWithoutBoxes().finally(() => setLoading(false));
        }
    }, [selectedDate]);

    const loadData = async () => {
        if (selectedDate) {
            await getOuttakesWithoutBoxes();
        }
    };

    const getOuttakesWithoutBoxes = async () => {
        const response = await post(`staff/outtakes/z-outtakes-sales-order/`, {
            date: selectedDate?.format('YYYY-MM-DD'),
        });
        setOuttakesWithOutBoxes(response.data);
    };

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Sales Order',
            dataIndex: 'sales_order_name',
            key: 'sales_order_name',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendo_name',
        },
        {
            title: 'Zoho Link',
            dataIndex: 'po_zoho_link',
            key: 'po_zoho_link',
            render: (_: string, record: any) => {
                return (
                    <a href={record.po_zoho_link} target="_blank" rel="noreferrer">
                        <span>Purchase order</span>
                    </a>
                )
            }
        },
        {
            title: 'Completed at',
            dataIndex: 'outtake_date',
            render: (_: string, record: any) => {
                return moment(record.outtake_date).format('YYYY-MM-DD');
            },
        },
        {
            title: 'Days since completed',
            dataIndex: 'days_since_completed',
            render: (_: string, record: any) => {
                return Math.abs(moment(record.outtake_date, 'YYYY-MM-DD').diff(moment().startOf('day'), 'days'));
            },
        },
    ];

    return (
        <>
            <Card title={'Z Outtake'}>
                <div className={'w-full'}>
                    <Table
                        key={'outtakes'}
                        columns={[...columns]}
                        loading={loading}
                        dataSource={outtakesWithOutBoxes}
                    />
                </div>
            </Card>
        </>
    );
};

export default ZOuttakeReport;
