import React from 'react';
import { Button, Table } from 'antd';
import { getCreationTime } from '../../utils/datetime';
var EMPTY_TITLE = '';
var OrderHistory = function (_a) {
    var dataSource = _a.dataSource, onDelete = _a.onDelete, onPrint = _a.onPrint, onViewPrintout = _a.onViewPrintout, onDownloadPdf = _a.onDownloadPdf;
    var columns = [
        {
            title: 'Created',
            dataIndex: 'created_time',
            render: function (_text, _a) {
                var created_time = _a.created_time;
                return React.createElement("span", null, getCreationTime(created_time));
            },
        },
        {
            title: EMPTY_TITLE,
            render: function (_a) {
                var id = _a.id;
                return (React.createElement(Button, { onClick: function () { return onDelete(id); }, type: "primary", danger: true }, "Delete"));
            },
        },
        {
            title: EMPTY_TITLE,
            render: function (_text, _a) {
                var id = _a.id;
                return (React.createElement(Button, { type: "primary", onClick: function () { return onPrint(id); } }, "Print"));
            },
        },
        {
            title: EMPTY_TITLE,
            render: function (_text, _a) {
                var id = _a.id;
                return React.createElement(Button, { onClick: function () { return onViewPrintout(id); } }, "View Printout");
            },
        },
        {
            title: EMPTY_TITLE,
            render: function (_text, _a) {
                var id = _a.id;
                return React.createElement(Button, { onClick: function () { return onDownloadPdf(id); } }, "Download PDF");
            },
        },
    ];
    return (React.createElement(Table, { rowKey: "id", 
        //@ts-ignore
        columns: columns, dataSource: dataSource, pagination: false }));
};
export default OrderHistory;
