import { Button, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { observer } from 'mobx-react';
import { DeleteOutlined } from '@ant-design/icons';
import { IFieldTypes } from './types';

const Option = Select.Option;

interface IVariableForm {
    row: number;
    name: string;
    operator: string;
    value: string;
    handleChange: (values: { name?: string; operator?: string; value?: string }) => void;
    handleClickDelete: () => void;
}

const VariablesForm = (props: IVariableForm) => {
    const { variables, variable_type_operators } = BusinessRuleStore;

    const [fieldType, setFieldType] = useState<IFieldTypes>('string');
    const [options, setOptions] = useState<string[]>([]);

    // Get operator type based on the current variable
    React.useEffect(() => {
        const fieldType = variables.find(v => v.name == props.name);
        if (fieldType) {
            setFieldType(fieldType.field_type);
            setOptions(fieldType.options);
        }
    }, [props.name, variables]);

    return (
        <Row>
            <Form.Item
                label="Variable"
                name={`variable-name-${props.row}`}
                initialValue={props.name}
                rules={[{ required: true }]}
            >
                <Select
                    placeholder="Select a option ..."
                    allowClear
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                        props.handleChange({
                            name: value,
                        });
                    }}
                >
                    {variables.map(v => (
                        <Option key={v.name} value={v.name} type={v.field_type}>
                            {v.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="operator"
                name={`variable-operator-${props.row}`}
                initialValue={props.operator}
                rules={[{ required: true }]}
            >
                <Select
                    placeholder="Select a option"
                    allowClear
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                        props.handleChange({
                            operator: value,
                        });
                    }}
                >
                    {variable_type_operators &&
                        variable_type_operators[fieldType]?.map((operator, index) => (
                            <Option key={operator.name} value={operator.name}>
                                {operator.label}
                            </Option>
                        ))}
                </Select>
            </Form.Item>
            {fieldType != 'boolean' && (
                <Form.Item
                    label="value"
                    name={`variable-value-${props.row}`}
                    initialValue={props.value}
                    rules={[{ required: true }]}
                >
                    {options.length == 0 ? (
                        <Input
                            placeholder={'Input a Value'}
                            onChange={e => {
                                props.handleChange({
                                    value: e.target.value,
                                });
                            }}
                        />
                    ) : (
                        <Select
                            placeholder="Select a option"
                            allowClear
                            dropdownMatchSelectWidth={false}
                            onChange={value => {
                                props.handleChange({
                                    value: value,
                                });
                            }}
                        >
                            {options.map((option, index) => (
                                <Option key={index} value={option}>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
            )}
            <div>
                <DeleteOutlined
                    style={{
                        fontSize: 20,
                        color: 'red',
                    }}
                    onClick={props.handleClickDelete}
                />
            </div>
        </Row>
    );
};

export default observer(VariablesForm);
