var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, observable, makeObservable } from 'mobx';
import { get, post, remove, printSalesOrder } from '../utils/api';
import { openNotificationWithIcon } from './UIStore';
var PurchaseOrderStore = /** @class */ (function () {
    function PurchaseOrderStore() {
        var _this = this;
        this.loading = false;
        this.PurchaseOrder = null;
        this.previousIntakes = [];
        this.activePreview = null;
        this.get = function (poID) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/purchase-orders/" + poID + "/")];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, get("/staff/purchase-orders-intakes/?purchase_order_id=" + poID).then(function (response) {
                                _this.previousIntakes = response.data.results;
                                _this.loading = false;
                            })];
                    case 2:
                        _a.sent();
                        this.PurchaseOrder = res.data;
                        return [2 /*return*/];
                }
            });
        }); };
        this.intake = function (poID, payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/purchase-orders/" + poID + "/intake/", payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.cancel_line_item = function (poID, payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/purchase-orders/" + poID + "/cancel-item/", payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        makeObservable(this, {
            // Observables
            loading: observable,
            PurchaseOrder: observable,
            previousIntakes: observable,
            activePreview: observable,
            // Actions
            get: action,
            intake: action,
            cancel_line_item: action,
            deleteIntake: action,
            setActivePreview: action,
            printPurchaseOrderIntake: action,
            clearData: action.bound,
        });
    }
    PurchaseOrderStore.prototype.deleteIntake = function (id, poID) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, remove("/staff/purchase-orders-intakes/" + id + "/")];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.get(poID)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.setActivePreview = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get(url)];
                    case 1:
                        response = _a.sent();
                        this.activePreview = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.printPurchaseOrderIntake = function (instanceID) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        openNotificationWithIcon('info', 'Printing Meow', '');
                        return [4 /*yield*/, printSalesOrder("/staff/purchase-orders-intakes/" + instanceID + "/printout/")];
                    case 1:
                        _a.sent();
                        openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        openNotificationWithIcon('danger', 'Error', err_1.status + " - " + err_1.statusText);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.clearData = function () {
        this.PurchaseOrder = null;
        this.activePreview = null;
        this.previousIntakes = null;
    };
    return PurchaseOrderStore;
}());
export default new PurchaseOrderStore();
