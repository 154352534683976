import React from 'react';
import { PartThumbnail } from './PartThumbnail';
import { PartName } from './PartName';
import { PartDimensions } from './PartDimensions';
import Humanize from 'humanize-plus';
export var PartItem = function (_a) {
    var master_product = _a.master_product, selected = _a.selected, line_items = _a.line_items, handleClick = _a.handleClick, handleUpdateName = _a.handleUpdateName;
    var classname_selected = selected ? 'part-selected' : '';
    var materials = line_items.filter(function (item) { return item.product.material; }).map(function (item) { var _a; return (_a = item.product.material) === null || _a === void 0 ? void 0 : _a.name; });
    var coatings = line_items.filter(function (item) { return item.product.coating; }).map(function (item) { var _a; return (_a = item.product.coating) === null || _a === void 0 ? void 0 : _a.name; });
    var finishes = line_items.filter(function (item) { return item.product.finish; }).map(function (item) { var _a, _b; return (_b = (_a = item.product) === null || _a === void 0 ? void 0 : _a.finish) === null || _b === void 0 ? void 0 : _b.name; });
    var quantities = line_items.map(function (item) { return item.quantity; });
    var leadTime = line_items.map(function (item) { return item.lead_time; });
    var adjust = line_items.map(function (item) { return item.manual_adjustment; });
    return (React.createElement("div", { id: "" + master_product.name + master_product.id, className: "part-item cursor-pointer  p-4 rounded " + classname_selected + " ", onClick: handleClick },
        React.createElement("div", { className: 'row w-full' },
            React.createElement(PartName, { name: master_product.name, editable: master_product.editable, handleUpdate: handleUpdateName }),
            React.createElement(PartDimensions, { width: parseFloat(master_product.width), height: parseFloat(master_product.height), length: parseFloat(master_product.length), unit: master_product.unit })),
        React.createElement("div", { className: 'row flex justify-between mt-4' },
            React.createElement("div", { className: 'thumbnail w-1/2' },
                React.createElement("div", { style: {
                        maxWidth: '40%',
                    } },
                    React.createElement(PartThumbnail, { master_product: master_product }))),
            React.createElement("div", { className: 'part-information w-1/2' },
                React.createElement("p", null,
                    React.createElement("span", { className: 'font-bold' }, "Lead Time:"),
                    " ",
                    leadTime.toString(),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: 'font-bold' }, "Adjust:"),
                    " ",
                    adjust.toString(),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: 'font-bold' }, "Material:"),
                    " ",
                    materials.toString(),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: 'font-bold' }, "Coating:"),
                    " ",
                    coatings.toString() ? coatings.toString() : 'NA',
                    React.createElement("br", null),
                    React.createElement("span", { className: 'font-bold' }, "Finishes:"),
                    " ",
                    finishes.toString(),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: 'font-bold' }, "Quantities:"),
                    " ",
                    quantities.toString(),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: 'font-bold' }, "Unit Price:"),
                    line_items.map(function (item) {
                        return Humanize.formatNumber(Number(item.unit_price), 2) + ', ';
                    }))))));
};
