var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect } from 'react';
import Pill from './pill';
var PillSelect = function (_a) {
    var data = _a.data, value = _a.value, onChange = _a.onChange;
    var _b = React.useState(value !== null && value !== void 0 ? value : []), selectedPills = _b[0], setSelectedPills = _b[1];
    var handlePillSelect = function (item, exists) {
        var pills = __spreadArray([], selectedPills, true);
        if (exists) {
            pills.splice(pills.indexOf(item), 1);
        }
        else {
            pills.push(item);
        }
        setSelectedPills(pills);
        if (onChange) {
            onChange(pills);
        }
    };
    var renderPill = function (item, index) {
        var label = item.label, value = item.value;
        var isChecked = selectedPills.includes(value);
        return (React.createElement(Pill, { key: "pill-" + index, label: label, checked: isChecked, onClick: function () { return handlePillSelect(value, isChecked); } }));
    };
    useEffect(function () {
        if (value && value.length > 0)
            setSelectedPills(value);
    }, [value]);
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: '5px',
        } }, data.map(function (item, index) { return renderPill(item, index); })));
};
export default PillSelect;
