import React, { useEffect, useRef, useState } from 'react';

import {
    Divider,
    message,
    Modal,
    Radio,
    Select,
    Skeleton,
    Spin,
    Image,
    Popconfirm,
    Button,
    notification,
    Popover,
    Tabs,
} from 'antd';
import Icon, {
    EyeOutlined,
    SyncOutlined,
    UploadOutlined,
    CloseOutlined,
    TagOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import { observer } from 'mobx-react';
import { IRedactedProfile, SalesOrderStore } from '../../../stores/SalesOrderStore';

import EditPdf from '../../../components/SalesOrder/EditPdf';

import { ErrorRender, UploadFile } from '@partsbadger/library';

import {
    AlignFields,
    AlignRadioGroup,
    ButtonAntd,
    ButtonProfileAntd,
    ButtonResetAntd,
    CardAntd,
    CheckboxAntd,
    CheckboxDiv,
    DrawerDiv,
    FieldText,
    FieldTitle,
    FlexCol,
    FlexRow,
    Header,
    InputAntd,
    InputTextAntd,
    PreviewProfilesAntd,
    SelectAntd,
} from './styles';

import PartCard from '../../../components/SalesOrder/EditPdf/PartCard';
import { RedactionToolStore } from '../../../components/SalesOrder/EditPdf/RedactionToolStore';
import { DownloadFile, get } from '@partsbadger/utils';
import axios from 'axios';
import FontTools from '../../../components/SalesOrder/EditPdf/FontTools';
import RedactionViewer from '../../../components/SalesOrder/EditPdf/RedactionViewer';

const { Option } = Select;

interface ITolDataProps {
    tolXS: { show: boolean; tol: number | string };
    tolX: { show: boolean; tol: number | string };
    tolXX: { show: boolean; tol: number | string };
    tolXXX: { show: boolean; tol: number | string };
    tolXXXX: { show: boolean; tol: number | string };
}

interface IProductData {
    PART: string;
    QTY: number;
    REQUIRED_SHIP_DATE: string;
    MATERIAL_CERT_REQUIRED: boolean;
    MATERIAL: string;
    UNS: string;
    FINISH: string;
    COATING: string;
    DWG_UNITS: string;
    TOLERANCE: 'UNLESS OTHERWISE STATED' | 'None';
}

interface IProps {
    match: {
        params: {
            id: number;
        };
    };
    onUpdate2dFile: (id: number) => Promise<void>;
    onUpdate3dFile: (id: number) => Promise<void>;
}

interface I2DAnd3DFile {
    name: string;
    url?: string;
}

interface IProfile {
    name: string;
    url: string;
}

interface IVendorQualifications {
    id: number;
    label: string;
    value: string;
    categoy_id: number;
}
const controller = new AbortController();

const rsdToDDMMYY = (rsd: string) => {
    const rsdSplit = rsd.split('-');
    return `${rsdSplit[2]}/${rsdSplit[1]}/${rsdSplit[0]}`;
};

const productTemplate = (
    order: string,
    product_data: IProductData,
    tolerances_data: ITolDataProps,
    unit: string,
    toleranceType: string
) => {
    let rendered_str = `ORDER: ${order}
PART: ${product_data.PART} 
QTY: ${product_data.QTY}
MATERIAL: ${product_data.MATERIAL} ${product_data.UNS ? `(UNS ${product_data.UNS})` : ''}`;
    // Add Material Certicate
    if (product_data.MATERIAL_CERT_REQUIRED) {
        rendered_str = `${rendered_str}
MATERIAL CERT REQUIRED`;
    }

    // Add finish
    rendered_str = `${rendered_str}
FINISH: ${product_data.FINISH} UNLESS OTHERWISE STATED`;
    //Add dynamic coatings

    if (product_data.COATING != 'N/A' && product_data.COATING != 'None' && product_data.COATING != 'NA') {
        if (product_data.COATING.includes('Heat Treat')) {
            rendered_str = `${rendered_str}
HEAT TREAT: ${''}`;
        }
        product_data.COATING = product_data.COATING.replace(' & Heat Treat & ', ' & ');
        product_data.COATING = product_data.COATING.replace('Heat Treat & ', '');
        product_data.COATING = product_data.COATING.replace(' & Heat Treat', '');
        product_data.COATING = product_data.COATING.replace('Heat Treat', '');
    }

    if (
        product_data.COATING != 'N/A' &&
        product_data.COATING != 'None' &&
        product_data.COATING != 'NA' &&
        product_data.COATING != ''
    ) {
        rendered_str = `${rendered_str}
COATING: ${product_data.COATING}`;
    }

    //Adding values after coating
    rendered_str = `${rendered_str}
DWG UNITS: ${unit === 'in' ? 'INCHES' : 'mm'}`;

    if (toleranceType === 'ISO') {
        `TOLERANCE: UNLESS OTHERWISE STATED`;
        // Adding tolerances
        if (tolerances_data.tolX.show) {
            rendered_str = `${rendered_str}
X.X ± ${tolerances_data.tolX.tol}`;
        }
        if (tolerances_data.tolXX.show) {
            rendered_str = `${rendered_str}
X.XX ± ${tolerances_data.tolXX.tol}`;
        }

        if (tolerances_data.tolXXX.show) {
            rendered_str = `${rendered_str}
X.XXX ± ${tolerances_data.tolXXX.tol}`;
        }
        if (tolerances_data.tolXXXX.show) {
            rendered_str = `${rendered_str}
X.XXXX ± ${tolerances_data.tolXXXX.tol}`;
        }
    } else {
        rendered_str = `${rendered_str}
TOLERANCE: +/- ${tolerances_data.tolXS.tol} UNLESS OTHERWISE STATED`;
    }

    rendered_str = `${rendered_str}
REQUIRED SHIP DATE ${rsdToDDMMYY(product_data.REQUIRED_SHIP_DATE)}

`;

    return rendered_str;
};

const RedactionTool: React.FC<IProps> = props => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [isLoadingProfiles, setIsLoadingProfiles] = useState<boolean>(true);

    const [unit, setUnit] = useState<string>('in');
    const [textArea, setTextArea] = useState<string>();
    const [freeTextArea, setFreeTextArea] = useState<string>();
    const [toleranceType, setToleranceType] = useState<string>('ISO');
    const [tolXS, setTolXS] = useState<string>('');
    const [tolXSChecked, setTolXSChecked] = useState<boolean>(true);
    const [tolX, setTolX] = useState<string>('');
    const [tolXChecked, setTolXChecked] = useState<boolean>(true);
    const [tolXX, setTolXX] = useState<string>('');
    const [tolXXChecked, setTolXXChecked] = useState<boolean>(true);
    const [tolXXX, setTolXXX] = useState<string>('');
    const [tolXXXChecked, setTolXXXChecked] = useState<boolean>(true);
    const [tolXXXX, setTolXXXX] = useState<string>('');
    const [tolXXXXChecked, setTolXXXXChecked] = useState<boolean>(true);
    const [insertDWG, setInsertDWG] = useState<boolean>(false);
    const [insertFTA, setInsertFTA] = useState<boolean>(false);
    const [lineItemId, setLineItemId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [newProfileName, setNewProfileName] = useState<string>();
    const [selectProfileName, setSelectProfileName] = useState<string>();
    const [a2DFile, setA2DFile] = useState<I2DAnd3DFile>();
    const [a3DFile, setA3DFile] = useState<I2DAnd3DFile>();
    const [redactedUrlFile, setRedactedUrlFile] = useState<string | null>();
    const [showAddStepFile, setShowAddStepFile] = useState<boolean>(false);
    const [showAddDrawingFile, setShowAddDrawingFile] = useState<boolean>(false);
    const [resetDWG, setresetDWG] = useState<boolean>(false);
    const [dataVendorQualifications, setDataVendorQualifications] = useState<any>(null);
    const [loadindQualifications, setLoadindQualifications] = useState(false);
    const [isSavingProfiles, setIsSavingProfiles] = useState<boolean>(false);
    const [productName, setProductName] = useState<string>('');
    const [isSendToVendors, setIsSendToVendors] = useState<boolean>(false);

    const [fontColor, setFontColor] = useState<string>('000000');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [fontBold, setFontBold] = useState(false);
    const [fontItalic, setFontItalic] = useState(false);
    const [ftFontSize, setFtFontSize] = useState<number>(15);

    const textAreaRef = useRef<any>(null);
    const freeTextAreaRef = useRef<any>(null);
    const redactedFileNameRef = useRef<any>(null);

    const { id } = props.match.params;

    const order = SalesOrderStore.order;

    const profiles = SalesOrderStore.redactedProfiles;

    const { redaction } = RedactionToolStore;

    useEffect(() => {
        if (SalesOrderStore.order?.id) {
            const products_data: IProductData[] = SalesOrderStore.order.line_items
                .filter(i => i.quote_has_product && i.production_2d_file?.id === lineItemId)
                .map(item => {
                    if (item?.production_2d_file) {
                        setA2DFile({ name: item?.production_2d_file?.filename, url: item?.production_2d_file?.file });
                        setProductName(item?.name);
                    }

                    if (item?.production_3d_file) {
                        setA3DFile({ name: item?.production_3d_file?.filename });
                    }

                    setRedactedUrlFile(item?.production_2d_file?.redacted_file);

                    return {
                        PART: item.name ?? '',
                        REQUIRED_SHIP_DATE: item.required_partner_ship_date ?? '',
                        QTY: item.quantity,
                        MATERIAL: item.quote_has_product?.product?.material?.name ?? '',
                        UNS: item.quote_has_product?.product?.material?.uns ?? '',
                        MATERIAL_CERT_REQUIRED: true,
                        FINISH: item.quote_has_product?.product?.finish?.name ?? '',
                        DWG_UNITS: unit,
                        COATING: item.quote_has_product?.product?.coating?.name ?? '',
                        TOLERANCE: 'UNLESS OTHERWISE STATED',
                    };
                });

            const tolerances = {
                tolX: { show: tolXChecked, tol: tolX },
                tolXX: { show: tolXXChecked, tol: tolXX },
                tolXXX: { show: tolXXXChecked, tol: tolXXX },
                tolXXXX: { show: tolXXXXChecked, tol: tolXXXX },
                tolXS: { show: tolXSChecked, tol: tolXS },
            };

            let render_string = '';

            products_data.map(products_data => {
                render_string =
                    render_string +
                    productTemplate(SalesOrderStore.order?.name ?? '', products_data, tolerances, unit, toleranceType);
            });

            setTextArea(render_string);
        }
    }, [
        SalesOrderStore.order?.id,
        unit,
        tolX,
        tolXS,
        tolXX,
        tolXXX,
        tolXXXX,
        tolXChecked,
        tolXSChecked,
        tolXXChecked,
        tolXXXChecked,
        tolXXXXChecked,
        lineItemId,
        resetDWG,
        toleranceType,
    ]);

    async function getOrder(id: number) {
        setInitialLoading(true);
        const response = await SalesOrderStore.getById(id);

        const getDefaultLineItemId = response ? response.line_items[0].production_2d_file?.id : 0;

        setLineItemId(getDefaultLineItemId);

        setInitialLoading(false);

        vendorQualifications();

        return true;
    }

    async function getAccountProfiles() {
        if (order?.account.id) {
            setIsLoadingProfiles(true);
            await SalesOrderStore.getAccountRedactedProfiles(order.account.id);
            setIsLoadingProfiles(false);
        }
    }

    useEffect(() => {
        if (id) {
            getOrder(id);
        }
    }, [id]);

    useEffect(() => {
        getAccountProfiles();
    }, [order?.account?.id]);

    const onChangeProfiles = async (value: any) => {
        setSelectProfileName(value);
    };

    const onClickProfileAndPDF = async (itemID: number, profile?: IRedactedProfile) => {
        RedactionToolStore.cleanUp();

        if (itemID) {
            setLineItemId(itemID);
            setSelectProfileName(profile?.profile_name);
        } else {
            message.error(`Error, production_2d_file does not have Id.`);
        }
    };

    const onChangeProfileName = async () => {
        if (newProfileName) {
            const params = {
                redactions: [],
                profile_name: newProfileName,
                account: SalesOrderStore.order?.account.id,
            };

            const getNewProfile = {
                name: newProfileName,
                url: '',
            };

            try {
                await SalesOrderStore.saveRedactionProfile(lineItemId, params);
                getAccountProfiles();

                message.success('Success, profile created successfully.');
            } catch (err) {
                message.error(`Error, unable to create profile. ${err}`);
            }
        }
    };

    const onClickSaveProfile = async (profileName: string) => {
        setIsSavingProfiles(true);
        try {
            const params = {
                redactions: SalesOrderStore.savedRedactions,
                profile_name: profileName,
                account: SalesOrderStore.order?.account.id,
            };

            await SalesOrderStore.saveRedactionProfile(lineItemId, params);
            getAccountProfiles();

            message.success('Success, profile saved successfully.');
        } catch (err) {
            message.error(`Error, unable to save profile. ${err}`);
        }
        setIsSavingProfiles(false);
    };

    const uploadFile = async (is3d: boolean, fileId: number) => {
        try {
            const getLineItemId = SalesOrderStore?.order?.line_items.find(item => {
                return item.production_2d_file?.id === lineItemId;
            });

            if (getLineItemId) {
                const params = is3d
                    ? {
                          id: getLineItemId.id,
                          production_3d_file: fileId,
                      }
                    : { id: getLineItemId.id, production_2d_file: fileId };

                await SalesOrderStore.updateItem(id, params);

                if (order) {
                    await SalesOrderStore.getById(id);
                }

                setLineItemId(fileId);

                message.success('Success, file saved successfully.');

                setShowAddDrawingFile(false);

                return true;
            } else {
                message.error(`Error, unable to find the line item id.`);
            }
        } catch (err) {
            message.error(`Error, unable to save file. ${err}`);
        }
    };

    const vendorQualifications = () => {
        setLoadindQualifications(true);
        const children: React.ReactNode[] = [];
        SalesOrderStore.getVendorQualificationsFromVendorApp(id)
            .then(qualifications => {
                qualifications.map((qualification: { id: React.Key; label: string }) => {
                    children.push(<Option key={qualification.id}>{qualification.label}</Option>);
                });
                setDataVendorQualifications(children);
            })

            .finally(() => {
                setLoadindQualifications(false);
            });
    };

    const handleClickOpen2dFile = (url: string | null | undefined) => {
        if (url) window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };

    if (initialLoading) {
        return (
            <div className={'w-full h-full flex items-center justify-content'}>
                <Skeleton />;
            </div>
        );
    }

    return (
        <div className={'redaction-tool'}>
            <div className={'side-left'}>
                <div className={'header-wrapper'}>
                    <Header>
                        <FlexCol>
                            <AlignFields>
                                <FlexCol>
                                    <FlexRow>
                                        <FieldTitle>Order:</FieldTitle>
                                        <FieldText>{SalesOrderStore.order?.name}</FieldText>
                                    </FlexRow>
                                    <FlexRow>
                                        <FieldTitle>Account:</FieldTitle>
                                        <FieldText>{SalesOrderStore.order?.account.name}</FieldText>
                                    </FlexRow>
                                </FlexCol>
                                <FlexCol>
                                    <FlexRow>
                                        <FieldTitle>2D:</FieldTitle>
                                        <FieldText>
                                            {a2DFile ? (
                                                <FlexRow>
                                                    <a
                                                        key={redactedUrlFile}
                                                        download={a2DFile.name}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() => handleClickOpen2dFile(a2DFile?.url)}
                                                    >
                                                        {a2DFile.name.length > 20
                                                            ? a2DFile.name.substring(0, 15) +
                                                              '...' +
                                                              a2DFile.name.substring(
                                                                  a2DFile.name.length - 5,
                                                                  a2DFile.name.length
                                                              )
                                                            : a2DFile.name}
                                                    </a>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() => handleClickOpen2dFile(a2DFile?.url)}
                                                    >
                                                        <EyeOutlined
                                                            title={'View 2D file'}
                                                            style={{ marginTop: '3px' }}
                                                            className={'link ml-2'}
                                                        />
                                                    </a>
                                                    <a>
                                                        <UploadOutlined
                                                            title={'Add 2D file'}
                                                            style={{ marginTop: '3px' }}
                                                            className={'link ml-2'}
                                                            onClick={() => {
                                                                setShowAddDrawingFile(true);
                                                            }}
                                                        />
                                                    </a>
                                                </FlexRow>
                                            ) : (
                                                '--'
                                            )}
                                            <Modal
                                                visible={showAddDrawingFile}
                                                centered
                                                title="Add or Replace Drawing File"
                                                onCancel={() => setShowAddDrawingFile(false)}
                                                footer={[]}
                                            >
                                                <UploadFile
                                                    fileType={'2D'}
                                                    onUpload={(file: any) => {
                                                        uploadFile(false, file.response.id);
                                                    }}
                                                />
                                            </Modal>
                                        </FieldText>
                                    </FlexRow>

                                    <FlexRow>
                                        <FieldTitle>3D:</FieldTitle>
                                        <FieldText>
                                            {a3DFile ? (
                                                <FlexRow>
                                                    <span>{a3DFile.name}</span>
                                                    <Icon
                                                        title={'Add 2D file'}
                                                        className={'link ml-2'}
                                                        style={{ marginTop: '2px' }}
                                                        type="upload"
                                                        onClick={() => {
                                                            setShowAddStepFile(true);
                                                        }}
                                                    />
                                                </FlexRow>
                                            ) : (
                                                '--'
                                            )}
                                            <Modal
                                                open={showAddStepFile}
                                                centered
                                                title="Add Step File"
                                                onCancel={() => setShowAddStepFile(false)}
                                                footer={[]}
                                            >
                                                <UploadFile
                                                    fileType={'3D'}
                                                    onUpload={(file: any) => {
                                                        uploadFile(true, file.response.id);
                                                    }}
                                                />
                                            </Modal>
                                        </FieldText>
                                    </FlexRow>
                                </FlexCol>
                                <FlexCol>
                                    {a2DFile ? (
                                        <>
                                            <FieldTitle>Files Rename:</FieldTitle>
                                            <InputAntd
                                                size="small"
                                                classNameProps=""
                                                defaultValue={SalesOrderStore.order?.name + '_' + productName + '.pdf'}
                                                ref={redactedFileNameRef}
                                                value={SalesOrderStore.order?.name + '_' + productName + '.pdf'}
                                            />

                                            <FlexCol>
                                                <FieldTitle>Qualifications:</FieldTitle>
                                                <Select
                                                    showArrow
                                                    style={{ width: '100%' }}
                                                    tokenSeparators={[',']}
                                                    mode="multiple"
                                                >
                                                    {dataVendorQualifications}
                                                </Select>
                                            </FlexCol>
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </FlexCol>

                                {/* <FlexRow>
                            <Row>
                                <FieldTitle>Files rename:</FieldTitle>
                            </Row>
                            <Row>
                                {a2DFile && redactedUrlFile ? (
                                    <a
                                        key={redactedUrlFile}
                                        href={redactedUrlFile}
                                        download={a2DFile.name}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {a2DFile.name}
                                    </a>
                                ) : (
                                    '--'
                                )}
                            </Row>
                        </FlexRow> */}
                            </AlignFields>

                            <AlignFields>
                                <FieldTitle> Profile:</FieldTitle>

                                <SelectAntd
                                    size="small"
                                    width={250}
                                    onChange={value => onChangeProfiles(value)}
                                    value={selectProfileName}
                                >
                                    {profiles.map((item, index) => {
                                        return (
                                            <>
                                                <Option key={index} value={item.profile_name}>
                                                    <Popover
                                                        trigger="hover"
                                                        placement="right"
                                                        content={
                                                            <>
                                                                <RedactionViewer
                                                                    pdfUrl={a2DFile?.url ? a2DFile?.url : ''}
                                                                    redaction={item.settings}
                                                                    pageNumber={0}
                                                                    scale={0.3}
                                                                />
                                                            </>
                                                        }
                                                    >
                                                        <div style={{ width: '50%', display: 'inline-block' }}>
                                                            {item.profile_name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign: 'right',
                                                                width: '50%',
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title={
                                                                    'Do you want to delete this profile permanently?'
                                                                }
                                                                onConfirm={evt => {
                                                                    evt?.stopPropagation();
                                                                    if (order?.account?.id) {
                                                                        SalesOrderStore.deleteAccountRedactedProfile(
                                                                            order?.account.id,
                                                                            item.id
                                                                        );
                                                                    }
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <DeleteOutlined
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={ev => {
                                                                        ev.stopPropagation();
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </div>
                                                    </Popover>
                                                </Option>
                                            </>
                                        );
                                    })}
                                </SelectAntd>

                                {/* <ButtonAntd size="small" textDecoration="underline" color="#4b66dd" border={0} disabled>
                            <span>Apply to All</span>
                        </ButtonAntd> */}
                                <ButtonAntd
                                    disabled={redaction.length > 0}
                                    textDecoration=""
                                    color=""
                                    border={1}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        setNewProfileName('');
                                        setShowModal(!showModal);
                                    }}
                                >
                                    Create New Profile
                                </ButtonAntd>
                                <Modal
                                    title="Create new Profile"
                                    visible={showModal}
                                    onOk={() => {
                                        onChangeProfileName();
                                        setShowModal(false);
                                    }}
                                    onCancel={() => {
                                        setShowModal(false);
                                    }}
                                >
                                    <p className="font-bold">Profile Name:</p>
                                    <InputAntd classNameProps="" onChange={e => setNewProfileName(e.target.value)} />
                                </Modal>
                                <Spin spinning={isSavingProfiles}>
                                    <ButtonAntd
                                        textDecoration=""
                                        color=""
                                        border={1}
                                        type="primary"
                                        size="small"
                                        disabled={!selectProfileName}
                                        onClick={() => {
                                            if (selectProfileName) {
                                                onClickSaveProfile(selectProfileName);
                                            }
                                        }}
                                    >
                                        Save Profile
                                    </ButtonAntd>
                                </Spin>
                                {/* <ButtonAntd textDecoration="" color="" border={1} type="primary" size="small" disabled>
                            Use Custom
                        </ButtonAntd> */}
                            </AlignFields>
                        </FlexCol>

                        <CheckboxDiv>
                            <Tabs defaultActiveKey={toleranceType} onTabClick={value => setToleranceType(value)}>
                                <Tabs.TabPane tab="ISO 2768 mK" key="ISO">
                                    <FlexRow>
                                        <CheckboxAntd
                                            tabIndex={-1}
                                            checked={tolXChecked}
                                            onChange={e => setTolXChecked(e.target.checked)}
                                        />
                                        TolX
                                        <InputAntd
                                            onChange={e => {
                                                setTolX(e.target.value);
                                            }}
                                            size="small"
                                            classNameProps="ml-8"
                                        />
                                    </FlexRow>
                                    <FlexRow>
                                        <CheckboxAntd
                                            tabIndex={-1}
                                            checked={tolXXChecked}
                                            onChange={e => setTolXXChecked(e.target.checked)}
                                        />
                                        TolXX
                                        <InputAntd
                                            onChange={e => {
                                                setTolXX(e.target.value);
                                            }}
                                            size="small"
                                            classNameProps="ml-6"
                                        />
                                    </FlexRow>
                                    <FlexRow>
                                        <CheckboxAntd
                                            tabIndex={-1}
                                            checked={tolXXXChecked}
                                            onChange={e => setTolXXXChecked(e.target.checked)}
                                        />
                                        TolXXX
                                        <InputAntd
                                            onChange={e => {
                                                setTolXXX(e.target.value);
                                            }}
                                            size="small"
                                            classNameProps="ml-4"
                                        />
                                    </FlexRow>
                                    <FlexRow>
                                        <CheckboxAntd
                                            tabIndex={-1}
                                            checked={tolXXXXChecked}
                                            onChange={e => setTolXXXXChecked(e.target.checked)}
                                        />
                                        TolXXXX
                                        <InputAntd
                                            onChange={e => {
                                                setTolXXXX(e.target.value);
                                            }}
                                            size="small"
                                            classNameProps="ml-2"
                                        />
                                    </FlexRow>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Singular Tolerance" key="SINGULAR">
                                    <FlexRow>
                                        <CheckboxAntd
                                            tabIndex={-1}
                                            checked={tolXSChecked}
                                            onChange={e => setTolXSChecked(e.target.checked)}
                                        />
                                        +/-
                                        <InputAntd
                                            onChange={e => {
                                                setTolXS(e.target.value);
                                            }}
                                            size="small"
                                            classNameProps="ml-2"
                                        />
                                    </FlexRow>
                                </Tabs.TabPane>
                            </Tabs>
                        </CheckboxDiv>
                        <AlignRadioGroup>
                            <Radio.Group
                                onChange={e => {
                                    setUnit(e.target.value);
                                }}
                                value={unit}
                            >
                                <Radio value={'in'}>IN</Radio>
                                <Radio value={'mm'}>MM</Radio>
                            </Radio.Group>
                        </AlignRadioGroup>
                        <FlexCol>
                            <div className="px-10 pb-2">
                                {SalesOrderStore.order?.account?.quoting_notes && (
                                    <>
                                        <FieldTitle>Quoting Notes:</FieldTitle>
                                        <div style={{ width: '10vw', height: '60px', overflow: 'scroll' }}>
                                            <FieldText>{SalesOrderStore.order?.account?.quoting_notes}</FieldText>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="px-10 pb-2">
                                {SalesOrderStore.order?.account?.internal_notes && (
                                    <>
                                        <FieldTitle>Internal Notes:</FieldTitle>
                                        <div style={{ width: '10vw', height: '60px', overflow: 'scroll' }}>
                                            <FieldText>{SalesOrderStore.order?.account?.internal_notes}</FieldText>
                                        </div>
                                    </>
                                )}
                            </div>
                        </FlexCol>
                        <FlexCol>
                            <Popover
                                content={
                                    <FontTools
                                        displayColorPicker={displayColorPicker}
                                        ftFontSize={ftFontSize}
                                        fontColor={fontColor}
                                        fontBold={fontBold}
                                        fontItalic={fontItalic}
                                        setDisplayColorPicker={setDisplayColorPicker}
                                        setFtFontSize={setFtFontSize}
                                        setFontColor={setFontColor}
                                        setFontBold={setFontBold}
                                        setFontItalic={setFontItalic}
                                    />
                                }
                                trigger="hover"
                                placement="left"
                            >
                                <InputTextAntd
                                    ref={freeTextAreaRef}
                                    autoSize={{ minRows: 5, maxRows: 8 }}
                                    fontSize={ftFontSize}
                                    fontColor={fontColor}
                                    fontBold={fontBold}
                                    fontItalic={fontItalic}
                                    value={freeTextArea}
                                    placeholder={'Free Form TextBox...'}
                                    onChange={e => {
                                        setFreeTextArea(e.target.value);
                                    }}
                                />
                            </Popover>
                            <ButtonProfileAntd
                                danger={insertDWG}
                                onClick={() => {
                                    setInsertFTA(!insertFTA);
                                }}
                            >
                                <TagOutlined />
                                {'Insert Text'}
                            </ButtonProfileAntd>
                        </FlexCol>
                    </Header>
                </div>
                <div className={'body-wrapper'}>
                    <div className={'pdf-area-wrapper'}>
                        <EditPdf
                            textAreaRef={textAreaRef}
                            textFreeAreaRef={freeTextAreaRef}
                            insertDWG={insertDWG}
                            insertFTA={insertFTA}
                            line_item_id={lineItemId}
                            ProfileName={selectProfileName}
                            resetProfileName={value => setSelectProfileName(value)}
                            removeDWG={() => setInsertDWG(false)}
                            redactedFileNameRef={redactedFileNameRef}
                            FTAProperties={{
                                fontBold: fontBold,
                                fontColor: fontColor,
                                fontItalic: fontItalic,
                                fontSize: ftFontSize,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={'side-right'}>
                <DrawerDiv className={'part-files-wrapper'}>
                    <CardAntd>
                        <ButtonResetAntd
                            type="link"
                            onClick={() => {
                                setresetDWG(!resetDWG);
                            }}
                        >
                            <SyncOutlined />
                        </ButtonResetAntd>
                        <InputTextAntd
                            ref={textAreaRef}
                            autoSize={{ minRows: 5, maxRows: 10 }}
                            value={textArea}
                            onChange={e => {
                                setTextArea(e.target.value);
                            }}
                        />

                        <ButtonProfileAntd
                            // type={!insertDWG ? 'primary' : 'default'}
                            danger={insertDWG}
                            onClick={() => {
                                setInsertDWG(!insertDWG);
                            }}
                        >
                            {!insertDWG ? 'Insert DWG TXT Box' : 'Remove DWG TXT Box'}
                        </ButtonProfileAntd>
                    </CardAntd>

                    <div className="flex flex-col " style={{ height: '60vh', overflow: 'scroll' }}>
                        {SalesOrderStore.order?.line_items.map((item, index) => {
                            return (
                                <div
                                    key={`${item.name} - ${item.id}`}
                                    style={{
                                        boxShadow: '0 4px 22px 0 rgba(71, 69, 69, 0.178)',
                                        border: '1px solid rgba(255, 255, 255, 0.18)',
                                    }}
                                >
                                    <CardAntd
                                        bordered={false}
                                        classNameProps="flex flex-col m-auto"
                                        styleProps={{ borderRadius: '5px' }}
                                    >
                                        <FlexRow>
                                            <FieldTitle>{index + 1}. Product:</FieldTitle>
                                            <FieldText>{item?.name}</FieldText>
                                        </FlexRow>
                                        <FlexRow>
                                            <FieldTitle>File Name:</FieldTitle>
                                            <FieldText>
                                                {item?.production_2d_file ? item?.production_2d_file?.filename : '--'}
                                            </FieldText>
                                        </FlexRow>
                                        <PartCard
                                            isSelected={item.production_2d_file?.id == lineItemId}
                                            isRedacted={!!item.production_2d_file?.redacted_file}
                                            thumbnail={
                                                item.production_2d_file?.thumbnail_redacted ??
                                                item.production_2d_file?.thumbnail ??
                                                ''
                                            }
                                            onClick={() => {
                                                if (RedactionToolStore.redaction.length > 0) {
                                                    const confirmationMessage =
                                                        'It looks like you have not saved your changes yet. ' +
                                                        'If you leave before saving, your changes will be lost.';

                                                    Modal.confirm({
                                                        title: 'Warning!',
                                                        okText: 'Continue',
                                                        content: confirmationMessage,
                                                        onOk: () => {
                                                            if (item.production_2d_file?.id) {
                                                                onClickProfileAndPDF(item.production_2d_file.id);
                                                            }
                                                        },
                                                    });
                                                } else {
                                                    if (item.production_2d_file?.id) {
                                                        onClickProfileAndPDF(item.production_2d_file.id);
                                                    }
                                                }
                                            }}
                                            onDownloadRedactedFile={() => {
                                                if (item.production_2d_file?.redacted_file) {
                                                    axios
                                                        .get(item.production_2d_file?.redacted_file, {
                                                            responseType: 'blob',
                                                        })
                                                        .then(response => {
                                                            DownloadFile(
                                                                response.data,
                                                                `${SalesOrderStore.order?.name + '_' + item.name + '.pdf'}`
                                                            );
                                                        });
                                                }
                                            }}
                                        />
                                    </CardAntd>
                                    <Divider />
                                </div>
                            );
                        })}
                    </div>
                    <div className={'bottom-actions-wrapper'}>
                        <Spin spinning={isSendToVendors}>
                            <Button
                                onClick={async () => {
                                    const hasAllRedactedFiles =
                                        SalesOrderStore.order?.line_items?.filter(
                                            item => !item.production_2d_file?.redacted_file
                                        )?.length == 0;
                                    if (hasAllRedactedFiles) {
                                        setIsSendToVendors(true);
                                        SalesOrderStore.sendRedactedFilesToVendorAPP(id)
                                            .then(() => {
                                                notification.success({
                                                    message: 'Done!',
                                                    description: 'All files were submitted to vendor app',
                                                    placement: 'bottomRight',
                                                    duration: 10,
                                                });
                                            })
                                            .catch(e => {
                                                notification.error({
                                                    message: 'We could not submit the files',
                                                    description: <ErrorRender error={e} />,
                                                    placement: 'bottomRight',
                                                    duration: 5,
                                                });
                                            })
                                            .finally(() => {
                                                setIsSendToVendors(false);
                                            });
                                    } else {
                                        message.error('Please complete all redacted files');
                                    }
                                }}
                                type="primary"
                                size={'large'}
                            >
                                Send to Vendors
                            </Button>
                        </Spin>
                    </div>
                </DrawerDiv>
            </div>
        </div>
    );
};

export default observer(RedactionTool);
