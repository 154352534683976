var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import { ErrorRender, Price } from '@partsbadger/library';
import { CloseOutlined, CopyOutlined, DownOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Badge, Button, Dropdown, Menu, message, Modal, notification, Table } from 'antd';
import { Cell } from './Cell';
import { QuoteStore } from '../../Stores';
import Formula26Safe from './Formula26Safe';
import VendorQuotedPriceForm from '../../Forms/Quote/VendorQuotedPriceForm';
var LineItems = function (props) {
    var _a;
    var _b = useState(null), editingKey = _b[0], setEditingKey = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(window.innerWidth), innerWidth = _d[0], setInnerWidth = _d[1];
    var _e = useState([]), dataSource = _e[0], setDataSource = _e[1];
    var _f = useState(null), showVendorQuoteForm = _f[0], setShowVendorQuoteForm = _f[1];
    var ref = useRef(null);
    useEffect(function () {
        var handleClickOutside = function (event) {
            var _a, _b, _c, _d, _e, _f;
            if (ref.current) {
                // ANTD Select Option are render outside the component
                try {
                    if ((_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.className) === null || _b === void 0 ? void 0 : _b.includes('ant-select-dropdown-menu-item')) {
                        return;
                    }
                    //for antd4
                    if ((_d = (_c = event.target) === null || _c === void 0 ? void 0 : _c.className) === null || _d === void 0 ? void 0 : _d.includes('ant-select-item-option-content')) {
                        return;
                    }
                    if ((_f = (_e = event.target) === null || _e === void 0 ? void 0 : _e.className) === null || _f === void 0 ? void 0 : _f.includes('dropdown-item-helper')) {
                        return;
                    }
                    if (!ref.current.contains(event.target)) {
                        setEditingKey(null);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        };
        if (editingKey) {
            document.addEventListener('click', handleClickOutside, true);
            return function () {
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    }, [editingKey]);
    useEffect(function () {
        document.addEventListener('keydown', function (event) {
            if (event.code === 'Escape') {
                event.preventDefault();
                setEditingKey(null);
            }
        });
    }, []);
    useEffect(function () {
        setLoading(true);
        setDataSource(props.line_items);
        setLoading(false);
        window.addEventListener('resize', function () {
            setInnerWidth(window.innerWidth);
        });
    }, [props.line_items]);
    var quote_id = (_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.id;
    var isEditing = function (record) { return record.id === editingKey; };
    var handleEdit = function (id) {
        if (props.editable) {
            if (QuoteStore.inProgress) {
                setEditingKey(id);
            }
            else {
                message.error('Please switch to In Progress to allow modifications', 4);
            }
        }
    };
    var columns = [
        {
            title: 'Manufact',
            dataIndex: 'manufacturing_type',
            width: 100,
            editable: true,
            render: function (text, record) {
                var _a, _b, _c, _d, _e, _f;
                return (React.createElement("div", { style: { fontSize: 12, textAlign: 'center' } },
                    record.manufacturing_type,
                    React.createElement("br", null),
                    ((_a = record.debug) === null || _a === void 0 ? void 0 : _a.face_directions) ? 'FD' : 'BF:',
                    ' ', (_d = (_c = (_b = record.debug) === null || _b === void 0 ? void 0 : _b.face_directions) === null || _c === void 0 ? void 0 : _c.toFixed(2)) !== null && _d !== void 0 ? _d : (_f = (_e = record.debug) === null || _e === void 0 ? void 0 : _e.badger) === null || _f === void 0 ? void 0 : _f.toFixed(2)));
            },
        },
        {
            title: 'Formula',
            dataIndex: 'formula',
            width: 100,
            editable: true,
            render: function (text, record) {
                var _a;
                return (React.createElement(React.Fragment, null, record.formula +
                    Formula26Safe(record.formula, props.master_product_id, (_a = record.product.material) === null || _a === void 0 ? void 0 : _a.name, null, null)));
            },
        },
        {
            title: 'Material',
            dataIndex: 'product__material',
            width: 150,
            editable: true,
            render: function (text, record) {
                var _a;
                return (React.createElement("div", null, (_a = record.product.material) === null || _a === void 0 ? void 0 :
                    _a.name,
                    " \u00A0",
                    record.material_group_lt_adjustment > 0 ? (React.createElement("span", { style: { color: 'indianred' } },
                        React.createElement("br", null),
                        "LT: +",
                        record.material_group_lt_adjustment,
                        " days")) : (''),
                    ' ',
                    "\u00A0"));
            },
        },
        {
            title: 'FAI Required',
            dataIndex: 'is_fai_required',
            width: 100,
            editable: true,
            render: function (text, record) { return React.createElement("span", null, record.is_fai_required ? 'Yes' : 'No'); },
        },
        {
            title: 'Fast Lane',
            dataIndex: 'is_fast_lane',
            width: 100,
            editable: true,
            render: function (text, record) { return React.createElement("span", null, record.is_fast_lane ? 'Yes' : 'No'); },
        },
        {
            title: 'Reinforcement',
            dataIndex: 'reinforcement',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.manufacturing_type) === '3D PRINTING' ? text : null); },
        },
        {
            title: 'Reinforcement Percent',
            dataIndex: 'reinforcement_percent',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.manufacturing_type) === '3D PRINTING' ? text : null); },
        },
        {
            title: 'Design Time',
            dataIndex: 'product__design_time',
            width: 120,
            editable: true,
            // render: (text, record) => parseFloat(text),
        },
        //========== Columns Domestic
        {
            title: 'Setup Hours',
            dataIndex: 'setup_hours',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Programming Hours',
            dataIndex: 'programming_hours',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'RunTime',
            dataIndex: 'runtime',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Setup',
            dataIndex: 'setup',
            width: 100,
            editable: false,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Program',
            dataIndex: 'program',
            width: 100,
            editable: false,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'RT',
            dataIndex: 'rt',
            width: 100,
            editable: false,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Total Material',
            dataIndex: 'total_material',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Coating 1',
            dataIndex: 'coating1',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Coating 2',
            dataIndex: 'coating2',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Extra 1',
            dataIndex: 'extra1',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        {
            title: 'Extra 2',
            dataIndex: 'extra2',
            width: 100,
            editable: true,
            render: function (text, record) { return ((record === null || record === void 0 ? void 0 : record.formula) === 'Domestic' ? text : null); },
        },
        //========== End columns Domestic
        {
            title: 'Sides',
            dataIndex: 'product__sides',
            width: 100,
            editable: true,
            max: 7,
        },
        {
            title: 'Finishes',
            dataIndex: 'product__finishes',
            width: 200,
            editable: true,
            render: function (text, record) {
                var _a;
                return (_a = record.product__finishes) === null || _a === void 0 ? void 0 : _a.label;
            },
        },
        {
            title: 'Coatings',
            dataIndex: 'product__coatings',
            width: 200,
            editable: true,
            render: function (text, record) {
                var _a;
                return (_a = record.product__coatings) === null || _a === void 0 ? void 0 : _a.label;
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 80,
            editable: true,
        },
        {
            title: 'Qty Range',
            dataIndex: 'quantity_range',
            width: 100,
            editable: true,
            render: function (text, record) {
                var _a, _b;
                return (React.createElement("span", null, (_a = record.quantity_range) === null || _a === void 0 ? void 0 :
                    _a.lower,
                    " - ", (_b = record.quantity_range) === null || _b === void 0 ? void 0 :
                    _b.upper));
            },
        },
        {
            title: 'LT',
            dataIndex: 'lead_time',
            width: 100,
            editable: true,
        },
        {
            title: 'LT Range',
            dataIndex: 'lead_time_range',
            width: 100,
            editable: true,
            render: function (text, record) {
                var _a, _b;
                return (React.createElement("span", null, (_a = record.lead_time_range) === null || _a === void 0 ? void 0 :
                    _a.lower,
                    " - ", (_b = record.lead_time_range) === null || _b === void 0 ? void 0 :
                    _b.upper));
            },
        },
        {
            title: 'Adjust',
            dataIndex: 'manual_adjustment',
            width: 100,
            editable: true,
        },
        {
            title: 'Vendor Adjust',
            dataIndex: 'vendor_price_adjust',
            width: 100,
            editable: true,
        },
        {
            title: 'Vendor (Quoter)',
            dataIndex: 'vendor_name',
            width: 100,
            editable: false,
            render: function (text, record) {
                return (React.createElement("div", null,
                    React.createElement("div", null, record.vendor_name)));
            },
        },
        {
            title: 'Vendor Quoted Price',
            dataIndex: 'vendor_quoted_price',
            width: 100,
            editable: false,
        },
        {
            title: 'Vendor Price',
            dataIndex: 'vendor_price',
            width: 100,
            editable: false,
            render: function (text, record) {
                return React.createElement(Price, { value: record.vendor_price });
            },
        },
        {
            title: 'Material Price',
            dataIndex: 'material_price',
            width: 100,
            editable: false,
            render: function (text, record) {
                if (!record.fixed_unit_price)
                    return React.createElement(Price, { value: record.material_price });
            },
        },
        {
            title: 'Work Price',
            dataIndex: 'work_price',
            width: 100,
            editable: false,
            render: function (text, record) {
                return React.createElement(Price, { value: record.work_price });
            },
        },
        {
            title: 'Shipping',
            dataIndex: 'shipping_cost',
            width: 100,
            editable: true,
            render: function (text, record) {
                return React.createElement(Price, { value: record.shipping_cost });
            },
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            width: 100,
            editable: true,
            render: function (t, record) {
                return (React.createElement("span", { style: { backgroundColor: '#258125', color: '#fff', padding: '5px', width: '100%' } },
                    React.createElement(Price, { value: record.unit_price })));
            },
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            width: 100,
            editable: true,
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            width: 100,
            editable: false,
            render: function (text, record) {
                var expired = record.is_expired && (React.createElement("span", null,
                    React.createElement(Badge, { count: 'Expired' })));
                return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } },
                    React.createElement("span", null,
                        React.createElement(Price, { style: { display: 'flex', justifyContent: 'center' }, value: (record === null || record === void 0 ? void 0 : record.total_price) - (record === null || record === void 0 ? void 0 : record.discount) })),
                    React.createElement("span", { style: { display: 'flex', fontSize: '11px', justifyContent: 'center' } }, expired)));
            },
            canHide: false,
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            width: 120,
            fixed: true,
            render: function (text, record) {
                var menu = (React.createElement(Menu, null,
                    React.createElement(Menu.Item, { onClick: function () {
                            QuoteStore.duplicateLineItem(record.id);
                        } },
                        React.createElement(CopyOutlined, null),
                        " Duplicate Line"),
                    React.createElement(Menu.Item, { onClick: function () {
                            var payload = {
                                quoted_products: [record.id],
                            };
                            QuoteStore.recalculatePrices(payload).catch(function (err) {
                                notification.error({
                                    key: 'form-errors',
                                    duration: 8,
                                    message: 'Error',
                                    description: React.createElement(ErrorRender, { error: err }),
                                    placement: 'top',
                                    maxCount: 1,
                                });
                            });
                        } },
                        React.createElement(SyncOutlined, null),
                        " Recalculate Prices"),
                    React.createElement(Menu.Item, { onClick: function () { return QuoteStore.deleteLineItem(record.id); } },
                        React.createElement(CloseOutlined, null),
                        " Delete Line"),
                    React.createElement(Menu.Item, { onClick: function () {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            setShowVendorQuoteForm({
                                material: (_b = (_a = record.product.material) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                                finish: (_e = (_d = (_c = record.product) === null || _c === void 0 ? void 0 : _c.finish) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : '',
                                coating: (_h = (_g = (_f = record.product) === null || _f === void 0 ? void 0 : _f.coating) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : '',
                                acceptance_date: '',
                                quantity: record.quantity,
                                vendor_price: record.vendor_price,
                                lead_time: record.lead_time,
                                vendor_notes: record.vendor_notes,
                                quote_line_item: record.id,
                                vendor_name: record.vendor_name,
                                vendor_quoted_price: record.vendor_quoted_price,
                            });
                        } },
                        React.createElement(EditOutlined, null),
                        " Vendor Quote")));
                return (React.createElement("div", null,
                    React.createElement(Dropdown, { overlay: menu },
                        React.createElement("span", { className: 'link' },
                            "Actions ",
                            React.createElement(DownOutlined, null)))));
            },
        },
    ];
    var components = {
        body: {
            cell: Cell,
        },
    };
    // domestic columns
    var domesticColumns = [
        'setup_hours',
        'programming_hours',
        'runtime',
        'setup',
        'program',
        'rt',
        'total_material',
        'coating1',
        'coating2',
        'extra1',
        'extra2',
    ];
    var hiddenColumns = ['work_price', 'quantity_range', 'lead_time_range'];
    var has_3d_printing = dataSource.filter(function (r) { return r.manufacturing_type === '3D PRINTING'; });
    var has_vendor_quotes = dataSource.filter(function (r) {
        return parseFloat(r.vendor_quoted_price) > 0;
    }).length;
    var isDomesticFormula = dataSource.filter(function (r) { return r.formula == 'Domestic'; }).length > 0;
    var hasOnlyFormula30 = dataSource.filter(function (r) { return r.formula == '3.0'; }).length == dataSource.length;
    var hasA2DFormula = dataSource.filter(function (r) { var _a, _b; return r.formula && (((_a = r.formula) === null || _a === void 0 ? void 0 : _a.startsWith('2')) || ((_b = r.formula) === null || _b === void 0 ? void 0 : _b.startsWith('F2'))); }).length > 0;
    if (!isDomesticFormula)
        hiddenColumns = hiddenColumns.concat(domesticColumns);
    if (!hasA2DFormula) {
        hiddenColumns.push('product__sides');
    }
    if (isDomesticFormula) {
        hiddenColumns.push('product__sides');
        hiddenColumns.push('vendor_price_adjust');
        hiddenColumns.push('vendor_price');
        hiddenColumns.push('material_price');
        hiddenColumns.push('shipping_cost');
    }
    if (hasOnlyFormula30 && !has_vendor_quotes) {
        hiddenColumns.push('vendor_price_adjust');
    }
    if (!has_vendor_quotes) {
        hiddenColumns.push('vendor_name');
        hiddenColumns.push('vendor_quoted_price');
    }
    else {
        hiddenColumns.push('vendor_price_adjust');
        hiddenColumns.push('vendor_price');
    }
    if (!has_3d_printing.length) {
        hiddenColumns.push('reinforcement');
        hiddenColumns.push('reinforcement_percent');
        hiddenColumns.push('reinforcement_percent');
        hiddenColumns.push('product__design_time');
    }
    if (!QuoteStore.inProgress) {
        hiddenColumns.push('operation');
    }
    if (!props.editable) {
        hiddenColumns.push('operation');
    }
    columns = columns
        .filter(function (c) {
        if (c.dataIndex) {
            return hiddenColumns.indexOf(c.dataIndex) === -1;
        }
    })
        .map(function (col) {
        if (col.fixed) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            col.fixed = innerWidth <= 2200 ? 'right' : false;
        }
        return __assign(__assign({}, col), { onCell: function (record) { return ({
                record: record,
                dataIndex: col.dataIndex,
                width: col.width,
                editable: col === null || col === void 0 ? void 0 : col.editable,
                editing: isEditing(record),
                handleEdit: handleEdit,
            }); } });
    });
    return (React.createElement("div", { className: 'line-items-wrapper', ref: ref },
        React.createElement(Table, { rowKey: 'id', size: "small", loading: loading, components: components, dataSource: dataSource.sort(function (a, b) { return a.position - b.position; }), className: 'variations', rowClassName: function () { return 'editable-row'; }, onRow: function (record) {
                return {
                    title: "" + record.id,
                    formula: record.formula,
                    position: "-" + record.position,
                    style: {
                        backgroundColor: record.safe_auto_quote ? '#a9ffa94d' : 'inherit',
                    },
                };
            }, bordered: true, pagination: false, rowSelection: {
                selectedRowKeys: props.selectedQuotedProducts,
                onChange: function (selectedRowKeys) {
                    props.handleRowSelections && props.handleRowSelections(selectedRowKeys);
                },
            }, columns: columns, footer: function () {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Button, { disabled: !props.editable, size: 'small', onClick: function () {
                            if (quote_id) {
                                setLoading(true);
                                QuoteStore.duplicateQuotedProduct(props.master_product_id).finally(function () {
                                    return setLoading(false);
                                });
                            }
                        }, icon: React.createElement(LegacyIcon, { type: 'copy' }) }, "Duplicate Product"),
                    ' ',
                    React.createElement(Button, { disabled: !props.editable, size: 'small', onClick: function () {
                            if (quote_id) {
                                QuoteStore.createLineItems(quote_id, {
                                    master_product: props.master_product_id,
                                });
                            }
                        }, icon: React.createElement(LegacyIcon, { type: 'plus' }) }, "Variation")));
            }, scroll: { x: '1200' } }),
        showVendorQuoteForm != null && (React.createElement(Modal, { title: 'Add or Edit Vendor Quote', bodyStyle: {
                height: 330,
            }, visible: true, onCancel: function () {
                setShowVendorQuoteForm(null);
            }, footer: [] },
            React.createElement(VendorQuotedPriceForm, { vendor_quoted_unit_price: showVendorQuoteForm.vendor_quoted_price, vendor_name: showVendorQuoteForm.vendor_name, vendor_notes: showVendorQuoteForm.vendor_notes, handleSave: function (_a) {
                    var vendor_name = _a.vendor_name, vendor_quoted_unit_price = _a.vendor_quoted_unit_price, vendor_notes = _a.vendor_notes;
                    return __awaiter(void 0, void 0, void 0, function () {
                        var payload;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    payload = {
                                        id: showVendorQuoteForm.quote_line_item,
                                        material: showVendorQuoteForm.material,
                                        finish: showVendorQuoteForm.finish,
                                        coating: showVendorQuoteForm.coating,
                                        acceptance_date: new Date(),
                                        vendor_name: vendor_name,
                                        quantity: showVendorQuoteForm.quantity,
                                        vendor_price: vendor_quoted_unit_price,
                                        lead_time: showVendorQuoteForm.lead_time,
                                        vendor_notes: vendor_notes,
                                    };
                                    return [4 /*yield*/, QuoteStore.addVendorQuote(quote_id, payload).catch(function (e) {
                                            notification.error({
                                                message: 'Error',
                                                description: React.createElement(ErrorRender, { error: e }),
                                            });
                                        })];
                                case 1:
                                    _b.sent();
                                    if (quote_id) {
                                        QuoteStore.getLineItems(quote_id);
                                        QuoteStore.getMasterProducts(quote_id);
                                        QuoteStore.getQuote(quote_id);
                                    }
                                    setShowVendorQuoteForm(null);
                                    return [2 /*return*/];
                            }
                        });
                    });
                } })))));
};
export default Form.create()(LineItems);
