import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PartItem } from '../components/Parts/PartItem';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip, Typography } from 'antd';
import { QuoteStore } from '../Stores';
import { ThreeDViewer } from '@partsbadger/threedviewer';
import { MasterProductDetail } from '../components/MasterProduct';
import { ApplicationCostItems, QuoteNotes, QuoteTotals, RFQView } from '../components';
import { AdditionalRequirements, ErrorRender } from '@partsbadger/library';
import { useHotkeys } from 'react-hotkeys-hook';
import { patch } from '@partsbadger/utils';
var Text = Typography.Text, Paragraph = Typography.Paragraph;
function getNext(current, list) {
    var next = list[0];
    var index = list.findIndex(function (id) { return id === current; });
    if (index > -1) {
        index = index + 1;
        if (index < list.length) {
            next = list[index];
        }
    }
    return next;
}
function getPrev(current, list) {
    var next = list[list.length - 1];
    var index = list.findIndex(function (id) { return id === current; });
    if (index > -1) {
        index = index - 1;
        if (index > -1) {
            next = list[index];
        }
    }
    return next;
}
var LayoutV2 = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var quote = _a.quote, user = _a.user, account = _a.account, master_products = _a.master_products, rfq_id = _a.rfq_id, selectedLineItems = _a.selectedLineItems, handleSelectLineItems = _a.handleSelectLineItems, additional_requirements = _a.additional_requirements, setAdditionalRequirements = _a.setAdditionalRequirements, setShowQuoteNotificationDrawer = _a.setShowQuoteNotificationDrawer, setReorderingAppCost = _a.setReorderingAppCost;
    var _x = useState(false), loading = _x[0], setLoading = _x[1];
    var _y = useState(0), new_index = _y[0], setNewIndex = _y[1];
    var _z = useState([]), masterProducts = _z[0], setMasterProducts = _z[1];
    //@ts-ignore
    var master_product = QuoteStore.selected_master_product;
    var master_products_ids = master_products.map(function (item) { return item.master_product.id; });
    var quote_line_items = QuoteStore.lineItems;
    var quote_id = ((_b = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.id) || 0;
    useEffect(function () {
        setMasterProducts(master_products);
        if (quote.stage === 'In Progress') {
            setShowQuoteNotificationDrawer(true);
        }
    }, []);
    useEffect(function () {
        setMasterProducts(master_products);
    }, [master_products]);
    useEffect(function () {
        if (master_product === null || master_product === void 0 ? void 0 : master_product.id) {
            var selected = master_products.find(function (item) { return master_products_ids[0]; });
            QuoteStore.setSelectedMasterProduct(selected.master_product);
        }
    }, [master_products_ids.length]);
    var handlePrevOrNext = function (type) {
        var next_master_product_id = -1;
        if (type === 'next') {
            next_master_product_id = getNext(master_product === null || master_product === void 0 ? void 0 : master_product.id, master_products_ids);
        }
        else {
            next_master_product_id = getPrev(master_product === null || master_product === void 0 ? void 0 : master_product.id, master_products_ids);
        }
        var selected = master_products.find(function (item, index) {
            setNewIndex(index);
            return item.master_product.id === next_master_product_id;
        });
        QuoteStore.setSelectedMasterProduct(selected.master_product);
        location.href = "#" + selected.master_product.name + selected.master_product.id;
    };
    //vh available 89vh
    //hotkeys
    var _0 = useState(false), next = _0[0], setNext = _0[1];
    var _1 = useState(false), prev = _1[0], setPrev = _1[1];
    useEffect(function () {
        if (next) {
            handlePrevOrNext('next');
            setNext(false);
        }
        if (prev) {
            handlePrevOrNext('prev');
            setPrev(false);
        }
    }, [next, prev]);
    useHotkeys('shift + n', function () { return setNext(true); });
    useHotkeys('shift + p', function () { return setPrev(true); });
    return (React.createElement("div", { className: 'quote-tool flex', style: {
            height: '100%',
        } },
        React.createElement("div", { className: 'aside', style: {
                width: '12%',
                height: '100%',
            } },
            React.createElement("div", { className: 'content-parts p-4 border', style: {
                    height: '60%',
                    overflowY: 'auto',
                } }, masterProducts.map(function (row, index) {
                var quote_line_items_by_master_product = quote_line_items.filter(function (item) { return item.product.master_product === row.master_product.id; });
                return (React.createElement(PartItem, { id: row.master_product.name, key: row.master_product.id, selected: (master_product === null || master_product === void 0 ? void 0 : master_product.id) === row.master_product.id, 
                    //@ts-ignore
                    line_items: quote_line_items_by_master_product, master_product: row.master_product, handleClick: function () {
                        //@ts-ignore
                        QuoteStore.setSelectedMasterProduct(row.master_product);
                        setNewIndex(index);
                    }, handleUpdateName: function (name) {
                        patch('/staff/master-products/' + row.master_product.id + '/', { name: name })
                            .then(function () {
                            QuoteStore.getMasterProducts(quote_id);
                        })
                            .catch(function (error) {
                            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
                        });
                    } }));
            })),
            React.createElement("div", { className: 'part-actions w-full flex flex-row items-center shadow', style: {
                    height: '5%',
                } },
                React.createElement("div", { className: 'w-full flex flex-row items-center m-auto justify-between max-w-xs shadow p-4' },
                    React.createElement(Tooltip, { title: "Shift + p" },
                        React.createElement(Button, { size: 'large', type: 'primary', icon: React.createElement(StepBackwardOutlined, null), onClick: function () { return handlePrevOrNext('prev'); } }, "Prev Part")),
                    React.createElement(Tooltip, { title: "Shift + n" },
                        React.createElement(Button, { size: 'large', type: 'primary', icon: React.createElement(StepForwardOutlined, null), onClick: function () { return handlePrevOrNext('next'); } }, "Next Part")))),
            React.createElement("div", { className: 'rfq-view shadow', style: {
                    // width: '20%',
                    height: '35%',
                    overflowY: 'auto',
                } }, rfq_id && React.createElement(RFQView, { rfq_id: rfq_id }))),
        React.createElement("div", { className: 'content-right', style: {
                width: '88%',
                height: '100%',
            } },
            React.createElement("div", { className: 'part-viewers flex justify-content', style: {
                    height: '55%',
                } }, (master_product === null || master_product === void 0 ? void 0 : master_product.id) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'file-3d shadow', style: {
                        width: "" + (((_c = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _c === void 0 ? void 0 : _c.id) && ((_d = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _d === void 0 ? void 0 : _d.file)
                            ? '40%'
                            : ((_e = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _e === void 0 ? void 0 : _e.id) && !((_f = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _f === void 0 ? void 0 : _f.file)
                                ? '100% '
                                : !((_g = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _g === void 0 ? void 0 : _g.id) && '0%'),
                    } }, ((_h = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _h === void 0 ? void 0 : _h.id) && (React.createElement(ThreeDViewer, { url: (_j = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _j === void 0 ? void 0 : _j.file_3djs, canvasStyle: {
                        height: '100%',
                    } }))),
                React.createElement("div", { className: 'file-2d shadow', style: {
                        width: "" + (((_k = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _k === void 0 ? void 0 : _k.id) && ((_l = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _l === void 0 ? void 0 : _l.file)
                            ? '60%'
                            : ((_m = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _m === void 0 ? void 0 : _m.file) && !((_o = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _o === void 0 ? void 0 : _o.id)
                                ? '100% '
                                : !((_p = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _p === void 0 ? void 0 : _p.file) && '0%'),
                    } }, ((_q = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _q === void 0 ? void 0 : _q.file) && (React.createElement(React.Fragment, null, ((_u = (_t = (_s = (_r = master_product.drawing_file) === null || _r === void 0 ? void 0 : _r.file) === null || _s === void 0 ? void 0 : _s.split('.')) === null || _t === void 0 ? void 0 : _t.pop()) === null || _u === void 0 ? void 0 : _u.split('?')[0].toLowerCase()) == 'pdf' ? (React.createElement("embed", { src: (_v = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _v === void 0 ? void 0 : _v.file, type: "application/pdf", width: "100%", height: "100%" })) : (React.createElement("div", { className: 'flex items-center justify-center h-full' },
                    "This file is not a pdf",
                    React.createElement("a", { className: 'ml-2', href: master_product.drawing_file.file }, master_product.drawing_file.filename)))))))) : (React.createElement("div", { className: 'flex flex-col items-center text-xl justify-center w-full' }, "Please select a part on the left!"))),
            React.createElement("div", { className: 'quote-details flex flex-row', style: {
                    height: '45%',
                } },
                React.createElement("div", { className: 'quote-inputs p-2', style: {
                        width: '100%',
                        height: '100%',
                    } },
                    React.createElement("div", { className: 'line-items', style: {
                            height: '65%',
                            overflowY: 'auto',
                        } }, (_w = master_products === null || master_products === void 0 ? void 0 : master_products.filter(function (item) { var _a; return ((_a = item.master_product) === null || _a === void 0 ? void 0 : _a.id) === (master_product === null || master_product === void 0 ? void 0 : master_product.id); })) === null || _w === void 0 ? void 0 :
                        _w.map(function (item, index) {
                            return (React.createElement(MasterProductDetail, { key: item.master_product.id, count: new_index, position: item.position, master_product: item.master_product, setSelectedLineItems: function (keys) {
                                    handleSelectLineItems(keys);
                                }, selectedLineItems: selectedLineItems, item: function (dataMasterProduct) {
                                    setMasterProducts([]);
                                    //@ts-ignore
                                    setMasterProducts(dataMasterProduct);
                                } }));
                        }),
                        !(master_product === null || master_product === void 0 ? void 0 : master_product.id) && (React.createElement("div", { className: 'flex flex-col items-center text-xl justify-center w-full h-full' }, "Please select a part on the left!"))),
                    React.createElement("div", { className: 'quote-inputs flex flex-row w-full', style: {
                            height: '35%',
                        } },
                        React.createElement("div", { className: 'w-1/3', style: { height: '100%' } },
                            React.createElement(QuoteNotes, null)),
                        React.createElement("div", { className: 'w-1/2', style: {
                                height: '100%',
                                overflowY: 'auto',
                            } }, account && (React.createElement(ApplicationCostItems, { quote: quote, account: account, setReorderingAppCost: setReorderingAppCost }))),
                        React.createElement("div", { className: 'w-1/3 flex flex-row' },
                            React.createElement("div", { className: 'w-1/3 p-2' },
                                React.createElement("div", null, "Additional Requirements"),
                                React.createElement("div", { style: {} },
                                    React.createElement(AdditionalRequirements
                                    //@ts-ignore
                                    , { 
                                        //@ts-ignore
                                        requirements: QuoteStore.additional_requirements, onChange: function (values) {
                                            setAdditionalRequirements(values);
                                            QuoteStore.updateQuote(quote.id, {
                                                custom_requirements: values,
                                            });
                                        }, 
                                        //@ts-ignore
                                        value: additional_requirements, hide_extras: false, disabled: !quote.editable })),
                                React.createElement("div", null, "Internal Quoting Notes"),
                                React.createElement("div", null, (user === null || user === void 0 ? void 0 : user.group) && (user === null || user === void 0 ? void 0 : user.group.includes('Design Engineers')) && (React.createElement("div", { className: 'flex flex-col' },
                                    React.createElement("div", { className: 'mt-4', style: {
                                            maxWidth: 800,
                                        } },
                                        React.createElement(Paragraph, { className: 'text-left', style: {
                                                margin: 0,
                                            }, editable: {
                                                onChange: function (notes) {
                                                    var payload = {
                                                        internal_quoting_notes: notes,
                                                    };
                                                    setLoading(true);
                                                    QuoteStore.updateQuote(quote.id, payload)
                                                        .then(function () {
                                                        setLoading(false);
                                                    })
                                                        .catch(function () {
                                                        setLoading(false);
                                                    });
                                                },
                                            } }, quote === null || quote === void 0 ? void 0 : quote.internal_quoting_notes)))))),
                            React.createElement("div", { className: 'w-2/3 p-2 text-xl' },
                                React.createElement("div", { className: 'w-full flex flex-row items-end m-auto justify-end p-4' },
                                    React.createElement(Tooltip, { title: "Shift + p" },
                                        React.createElement(Button, { size: 'small', type: 'primary', icon: React.createElement(StepBackwardOutlined, null), onClick: function () { return handlePrevOrNext('prev'); } }, "Prev Part")),
                                    "\u00A0",
                                    React.createElement(Tooltip, { title: "Shift + n" },
                                        React.createElement(Button, { size: 'small', type: 'primary', icon: React.createElement(StepForwardOutlined, null), onClick: function () { return handlePrevOrNext('next'); } }, "Next Part"))),
                                React.createElement(QuoteTotals, { quote: quote })))))))));
};
export default observer(LayoutV2);
