import React from 'react';
import { InputNumber, Table, Checkbox } from 'antd';
var ItemsList = function (_a) {
    var dataSource = _a.dataSource, onChangeInput = _a.onChangeInput, selectedItems = _a.selectedItems, onSelectItem = _a.onSelectItem;
    var columns = [
        {
            title: 'Line Item',
            dataIndex: 'name',
        },
        {
            title: 'Awarded Vendor',
            dataIndex: 'awardedVendor',
        },
        {
            title: 'Quantity Ordered',
            dataIndex: 'salesOrderLineItemQuantity',
        },
        {
            title: 'Quantity Remaining',
            dataIndex: 'quantityRemainingToReceive',
        },
        {
            title: 'Quantity Received',
            render: function (_a) {
                var lineItemOuttakeQuantity = _a.lineItemOuttakeQuantity, lineItemOutTakeId = _a.lineItemOutTakeId;
                return (React.createElement(InputNumber, { value: lineItemOuttakeQuantity, onChange: function (value) { return onChangeInput && onChangeInput(lineItemOutTakeId, value); } }));
            },
        },
        {
            title: 'Select',
            render: function (_a) {
                var lineItemOutTakeId = _a.lineItemOutTakeId;
                return (React.createElement(Checkbox, { checked: selectedItems.includes(lineItemOutTakeId), onClick: function () { return onSelectItem(lineItemOutTakeId); } }));
            },
        },
    ];
    return (React.createElement(Table, { rowClassName: function (_a) {
            var lineItemOutTakeId = _a.lineItemOutTakeId;
            return (selectedItems.includes(lineItemOutTakeId) ? 'bg-green-200' : '');
        }, rowKey: function (_a) {
            var lineItemOutTakeId = _a.lineItemOutTakeId;
            return "t-" + lineItemOutTakeId;
        }, columns: columns, pagination: false, dataSource: dataSource }));
};
export default ItemsList;
