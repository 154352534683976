import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, remove } from '../shared';

class Contact {
    isLoading = true;
    documents: any[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            documents: observable,

            // Actions
            getDocuments: action,
            remove_contact_document: action,
        });
    }

    getDocuments(contact_id: string) {
        this.isLoading = true;
        return get(`staff/contacts/${contact_id}/`)
            .then((response: any) => {
                runInAction(() => {
                    this.documents = response.data.contact_documents;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    remove_contact_document = async (index: number, document_id: number, contact_id: number) => {
        this.documents = this.documents.filter((d, i: number) => i !== index);
        await remove(`staff/contacts/${contact_id}/remove-document/${document_id}`);
    };
}

export const ContactStore = new Contact();
