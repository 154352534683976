var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import VendorBoxComponent from '../../../Componets/VendorBox';
import { get, getFile, printSalesOrder, remove } from '../../../utils/api';
import { buildOrderInfo, buildVendorBoxSpecifications } from './util';
import { FETCH_STATUS } from '../../../types';
import { openNotificationWithIcon } from '../../../Stores/UIStore';
import DomesticModal from './DomesticModal';
import fileDownload from 'js-file-download';
var SALES_ORDER_INITIAL_STATE = { salesOrder: {}, poFiles: [], specifications: [], dataHistory: [] };
var VendorBox = function (_a) {
    var onSubmit = _a.onSubmit, onUpdateOrder = _a.onUpdateOrder, salesOrderId = _a.salesOrderId, onChangeLineItemInput = _a.onChangeLineItemInput, 
    /** ItemsListProps */
    lineItems = _a.lineItems;
    var _b = React.useState(FETCH_STATUS.loading), fetchStatus = _b[0], setFetchStatus = _b[1];
    // @ts-ignore
    var _c = React.useState(SALES_ORDER_INITIAL_STATE), salesOrderProps = _c[0], setSalesOrderProps = _c[1];
    var _d = React.useState(null), htmlPreview = _d[0], setHtmlPreview = _d[1];
    var _e = React.useState([]), selectedLineItems = _e[0], setSelectedLineItems = _e[1];
    var _f = React.useState(false), customerReturn = _f[0], setCustomerReturn = _f[1];
    var _g = React.useState(false), showDomesticModal = _g[0], setShowDomesticModal = _g[1];
    var fetchOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, dataOrder, quadrant_1, dataHistory_1, _error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    fetchStatus !== FETCH_STATUS.loading && setFetchStatus(FETCH_STATUS.loading);
                    return [4 /*yield*/, Promise.all([
                            get("/staff/sales-orders/" + salesOrderId),
                            get("/staff/intakes/?salesorder_id=" + salesOrderId + "&search=PB"),
                        ])];
                case 1:
                    _a = _c.sent(), _b = _a[0], dataOrder = _b.data, quadrant_1 = _b.data.quadrant, dataHistory_1 = _a[1].data.results;
                    setSalesOrderProps(__assign(__assign({}, buildOrderInfo(dataOrder)), { specifications: buildVendorBoxSpecifications(dataOrder), dataHistory: dataHistory_1, quadrant: quadrant_1 }));
                    setFetchStatus(FETCH_STATUS.success);
                    return [3 /*break*/, 3];
                case 2:
                    _error_1 = _c.sent();
                    setFetchStatus(FETCH_STATUS.error);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        fetchOrder();
    }, []);
    var fetchHistoryPreview = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var data, _error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, get("/staff/intakes/" + id + "/html/?template=intake-printout.html")];
                case 1:
                    data = (_a.sent()).data;
                    setHtmlPreview(data);
                    return [3 /*break*/, 3];
                case 2:
                    _error_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var deleteIntakeSalesOrder = function (historyId) { return __awaiter(void 0, void 0, void 0, function () {
        var oldDataHistory, _error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, remove("/staff/intakes/" + historyId + "/")];
                case 1:
                    _a.sent();
                    oldDataHistory = salesOrderProps.dataHistory;
                    setSalesOrderProps(__assign(__assign({}, salesOrderProps), { dataHistory: oldDataHistory.filter(function (_a) {
                            var id = _a.id;
                            return id !== historyId;
                        }) }));
                    return [3 /*break*/, 3];
                case 2:
                    _error_3 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var printSalesOrderIntake = function (intakeId) { return __awaiter(void 0, void 0, void 0, function () {
        var _error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    openNotificationWithIcon('info', 'Printing Meow', '');
                    return [4 /*yield*/, printSalesOrder("/staff/intakes/" + intakeId + "/printout/")];
                case 1:
                    _a.sent();
                    openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
                    return [3 /*break*/, 3];
                case 2:
                    _error_4 = _a.sent();
                    //@ts-ignore
                    openNotificationWithIcon('danger', 'Error', _error_4.status + " - " + _error_4.statusText);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var downloadPdf = function (intakeId) { return __awaiter(void 0, void 0, void 0, function () {
        var pdf, bytes, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    openNotificationWithIcon('info', 'Generating file', 'Be patient');
                    return [4 /*yield*/, getFile("/staff/intakes/" + intakeId + "/pdf/?template=intake-printout.html")];
                case 1:
                    pdf = _a.sent();
                    return [4 /*yield*/, pdf.blob()];
                case 2:
                    bytes = _a.sent();
                    fileDownload(bytes, 'PrintOut.pdf');
                    openNotificationWithIcon('success', 'Downloaded', 'PDF file');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleChangeInput = function (id, value) { return onChangeLineItemInput(salesOrderId, id, value); };
    var handleSelectLineItem = function (id) {
        var newLineItems = __spreadArray([], selectedLineItems, true);
        if (newLineItems.includes(id)) {
            newLineItems.splice(newLineItems.indexOf(id), 1);
        }
        else {
            newLineItems.push(id);
        }
        setSelectedLineItems(newLineItems);
    };
    var handleQuadrantChange = function (value) { return setSalesOrderProps(__assign(__assign({}, salesOrderProps), { quadrant: value })); };
    var salesOrder = salesOrderProps.salesOrder, _h = salesOrderProps.salesOrder, domestic_process_needed = _h.domestic_process_needed, zoho_id = _h.zoho_id, poFiles = salesOrderProps.poFiles, specifications = salesOrderProps.specifications, dataHistory = salesOrderProps.dataHistory, quadrant = salesOrderProps.quadrant;
    var handleCloseModal = function () { return setShowDomesticModal(false); };
    var handleClearData = function () {
        handleCloseModal();
        setSelectedLineItems([]);
    };
    var handleSubmitOrder = function () {
        onSubmit({
            salesOrderId: salesOrderId,
            selectedLineItems: selectedLineItems,
            customerReturn: customerReturn,
            //@ts-ignore
            domestic_process_needed: domestic_process_needed,
            quadrant: quadrant,
        }, fetchOrder, false);
        handleClearData();
    };
    var handleSubmitAndPrint = function () {
        onSubmit({
            salesOrderId: salesOrderId,
            selectedLineItems: selectedLineItems,
            customerReturn: customerReturn,
            //@ts-ignore
            domestic_process_needed: domestic_process_needed,
            quadrant: quadrant,
        }, fetchOrder, true);
        handleClearData();
    };
    var handleSubmitModalOrder = function () {
        //@ts-ignore
        onUpdateOrder(zoho_id);
        handleClearData();
    };
    var handleSubmitClick = function () {
        if (domestic_process_needed) {
            setShowDomesticModal(true);
            return;
        }
        handleSubmitOrder();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(VendorBoxComponent, { submitButtonsDisabled: !(selectedLineItems.length > 0), onSubmitClick: handleSubmitClick, returnCheck: customerReturn, onReturnCheck: function () { return setCustomerReturn(!customerReturn); }, onSubmitAndPrintClick: handleSubmitAndPrint, onChangeLineItemInput: handleChangeInput, lineItems: lineItems, selectedItems: selectedLineItems, onSelectItem: handleSelectLineItem, fetchStatus: fetchStatus, onFetchOrder: fetchOrder, order: {
                salesOrder: salesOrder,
                poFiles: poFiles,
                specifications: specifications,
                dataSource: dataHistory,
                onDelete: deleteIntakeSalesOrder,
                onPrint: printSalesOrderIntake,
                onViewPrintout: fetchHistoryPreview,
                onDownloadPdf: downloadPdf,
                htmlPreview: htmlPreview,
            }, selectedQuadrant: quadrant !== null && quadrant !== void 0 ? quadrant : 'A', onQuadrantChange: handleQuadrantChange }),
        React.createElement(DomesticModal, { onCloseModal: handleCloseModal, modalVisible: showDomesticModal, onSubmitClick: handleSubmitModalOrder, onIntakeNormalClick: handleSubmitOrder })));
};
export default VendorBox;
