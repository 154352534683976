var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, message, Spin, Card } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import DesignEngineerChecklistQuestion from './DesignEngineerChecklistQuestion';
import ModalMeeting from './ModalMeeting';
import { buildJsonBigOrderChecklist } from '../../utils/utils';
var cols = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
};
var ProductionChecklist = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), loadingSubmit = _b[0], setLoadingSubmit = _b[1];
    var _c = useState([]), checklistQuestions = _c[0], setChecklistQuestions = _c[1];
    var _d = useState(), designEngineerChecklist = _d[0], setDesignEngineerChecklist = _d[1];
    var _e = useState(), checklistEdit = _e[0], setChecklistEdit = _e[1];
    var _f = useState(false), handleCloseModalMeeting = _f[0], setHandleCloseModalMeeting = _f[1];
    var _g = props.form, getFieldDecorator = _g.getFieldDecorator, getFieldValue = _g.getFieldValue, setFieldsValue = _g.setFieldsValue;
    useEffect(function () {
        getProductionChecklist();
    }, []);
    var getProductionChecklist = function () {
        setLoading(true);
        SalesOrderStore.getChecklistType(props.sales_order_id, props.checklistName)
            .then(function (response) {
            setDesignEngineerChecklist(response.checklist_load);
            setChecklistEdit(response.checklist_edit);
            if (Object.keys(response.checklist_edit).length > 0) {
                setChecklistQuestions(response.checklist_edit);
            }
            else {
                setChecklistQuestions(response.checklist);
            }
        })
            .catch(function (error) {
            message.error(error.message);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    var handleCloseModal = function () {
        setHandleCloseModalMeeting(false);
    };
    var handleSubmit = function () {
        props.form.validateFields(function (err, values) {
            if (!err) {
                try {
                    setLoadingSubmit(true);
                    var params = {
                        checklist: buildJsonBigOrderChecklist(values, checklistQuestions),
                        type: 'production_review',
                    };
                    SalesOrderStore.updateSalesOrderChecklist(props.sales_order_id, params)
                        .then(function (response) {
                        message.success('Checklist saved ok', 3);
                        props.handleClose();
                        SalesOrderStore.getOrderById(props.sales_order_id);
                    })
                        .catch(function (error) {
                        message.error(error.message, 5);
                    })
                        .finally(function () {
                        setLoadingSubmit(false);
                    });
                }
                catch (error) {
                    console.error(error);
                    message.error(error.message, 5);
                }
            }
        });
    };
    return (React.createElement("div", { id: "02_div_production_checklist", className: "absolute z-10 h-full border-solid border-2 overflow-y-auto w-1/2" },
        React.createElement(Card, null,
            React.createElement("div", { id: "02_div_production_checklistheader", className: "flex justify-between cursor-move z-10 bg-blue-500 h-12 p-2 sticky top-0" },
                React.createElement("h1", { className: "text-left text-lg" }, props.title),
                React.createElement(Button, { type: "link", className: "text-white", onClick: function () {
                        props.handleClose();
                    } }, "X")),
            React.createElement(Spin, { spinning: loading },
                React.createElement(Form, { className: props.isPointPersonReview
                        ? 'lg:w-1/1 sm:1/1 mx-4 my-4 pointer-events-none'
                        : 'lg:w-1/1 sm:1/1 mx-4 my-4' },
                    React.createElement(Row, { gutter: 4 },
                        React.createElement(Col, __assign({}, cols),
                            React.createElement("h1", { className: "font-bold" }, "Topic")),
                        React.createElement(Col, __assign({}, cols),
                            React.createElement("h1", { className: "font-bold" }, "Order Processer Answer")),
                        React.createElement(Col, __assign({}, cols),
                            React.createElement("h1", { className: "font-bold" }, "Confirm Reviewed")),
                        React.createElement(Col, __assign({}, cols),
                            React.createElement("h1", { className: "font-bold" }, "Flag for Big Order Meeting Review?"))),
                    designEngineerChecklist &&
                        checklistEdit &&
                        Array.isArray(checklistQuestions) &&
                        checklistQuestions.map(function (question) {
                            var key = "" + question.question_id;
                            return (React.createElement(DesignEngineerChecklistQuestion, { key: key, getFieldDecorator: getFieldDecorator, question: question, value: '', onChange: function (e) { }, cols: cols, form: props.form, salesRepChecklist: designEngineerChecklist, checklistEdit: checklistEdit, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions }));
                        }),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement("div", { style: { textAlign: 'center' } },
                            React.createElement(Button, { disabled: props.isPointPersonReview, type: "primary", htmlType: 'submit', loading: loadingSubmit, onClick: handleSubmit }, "Submit"),
                            React.createElement(Button, { disabled: props.isPointPersonReview, type: "primary", loading: false, className: "ml-1", onClick: function () {
                                    setHandleCloseModalMeeting(true);
                                } }, "Trigger Meeting")))),
                handleCloseModalMeeting && (React.createElement(ModalMeeting
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                , { 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    handleClose: handleCloseModal, sales_order_id: props.sales_order_id, type_review: 'production_review', handleSubmit: handleSubmit }))))));
};
export default Form.create()(ProductionChecklist);
