import React from 'react';
import IntakeStore from '../../Stores/IntakeStore';
import { observer } from 'mobx-react';
import VendorBox from './VendorBox';
import { buildLineItems, getOrderLineItemQuantities } from './util';
var VendorBoxOrders = function (_a) {
    var vendorOrderId = _a.vendorOrderId;
    var vendorOrders = IntakeStore.vendorSalesOrders;
    if (vendorOrders === null) {
        return null;
    }
    var handleChangeInput = function (orderId, itemId, value) { return IntakeStore.updateVendorQuantity(orderId, itemId, value); };
    var handleSubmit = function (_a, callback, print) {
        var salesOrderId = _a.salesOrderId, selectedLineItems = _a.selectedLineItems, customerReturn = _a.customerReturn, domestic_process_needed = _a.domestic_process_needed, quadrant = _a.quadrant;
        var quantities = getOrderLineItemQuantities(salesOrderId, selectedLineItems, vendorOrders);
        IntakeStore.intakeSalesOrderVendorBox({ quantities: quantities, salesOrderId: salesOrderId, customerReturn: customerReturn, domestic_process_needed: domestic_process_needed, quadrant: quadrant, vendorOrderId: vendorOrderId }, callback, print);
    };
    var handleUpdateOrder = function (value) { return IntakeStore.updateDomesticOrderStatus(value); };
    return vendorOrders === null || vendorOrders === void 0 ? void 0 : vendorOrders.map(function (item, index) {
        var order = item.order, items = item.items;
        return (React.createElement(VendorBox, { lineItems: buildLineItems(items), salesOrderId: order, onChangeLineItemInput: handleChangeInput, onSubmit: handleSubmit, onUpdateOrder: handleUpdateOrder }));
    });
};
export default observer(VendorBoxOrders);
