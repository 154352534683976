import React from 'react';
import { Button, Col, PageHeader, Row, Typography } from 'antd';
import { observer } from 'mobx-react';

import { BusinessRuleStore } from '../../components/BusinessRules/Stores/BusinessRuleStore';
import TableBusinessRule from '../../components/BusinessRules/TableBusinessRule';
import { useHistory } from 'react-router-dom';

const BusinessRuleIndexPage = () => {
    const history = useHistory();

    React.useEffect(() => {
        BusinessRuleStore.getAllRules('SalesOrder');
    }, []);
    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    title="Business Rules"
                    extra={
                        <Button type={'primary'} onClick={() => history.push('/business-rules/new/')}>
                            Add Rule
                        </Button>
                    }
                />
            </Col>
            <Col span={24}>
                <TableBusinessRule />
            </Col>
        </Row>
    );
};

export default observer(BusinessRuleIndexPage);
