var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
export var AcquisitionSource = function (props) {
    var existing__contact_options = [
        'EC Direct Email in Rep',
        'EC Personal Email Out',
        'EC Outbound Call',
        'EC Inbound Call',
        'EC Direct Email In Sales@',
        'EC Marketing Email Out**',
        'EC Contact Form',
        'EC PartsBadger Phone',
        'EC Social Media Message',
        'Uncertain - Needs Review*',
    ];
    var new_contact_options = [
        'NC Remailer',
        'NC Initial Mailer',
        'NC Direct Email In Rep',
        'NC Personal Email Out',
        'NC Outbound Call',
        'NC Inbound Call',
        'NC Direct Email In Sales@',
        'NC Marketing Email Out**',
        'NC Contact Form',
        'NC PartsBadger Phone',
        'NC Social Media Message',
        'Uncertain - Needs Review*',
    ];
    var options = props.is_new_contact
        ? new_contact_options.map(function (o, index) {
            return (React.createElement(Select.Option, { key: index, value: o }, o));
        })
        : existing__contact_options.map(function (o, index) {
            return (React.createElement(Select.Option, { key: index, value: o }, o));
        });
    return (React.createElement(Select, __assign({ showSearch: true, dropdownMatchSelectWidth: false }, props), options));
};
