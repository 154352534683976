var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { message, Modal, notification, Spin, Steps } from 'antd';
import { QuoteStore } from '../Stores';
import { VendorRFQSubmissionForm } from '../Forms';
import { ModalNotesForm } from '../Forms';
import { observer } from 'mobx-react';
import { ErrorRender } from '@partsbadger/library';
import { WarningOutlined } from '@ant-design/icons';
var QuoteStatus = function (_a) {
    var _b;
    var quote = _a.quote, user = _a.user, isQuoteLoading = _a.isQuoteLoading;
    var _c = useState(false), showVendorNotes = _c[0], setShowVendorNotes = _c[1];
    var _d = useState(false), showReviewNotes = _d[0], setShowReviewNotes = _d[1];
    var _e = useState(false), showReviewedNotes = _e[0], setShowReviewedNotes = _e[1];
    var _f = useState(false), showCompletedNotes = _f[0], setShowCompletedNotes = _f[1];
    var _g = useState(false), showContactFirstQuote = _g[0], setShowContactFirstQuote = _g[1];
    var _h = useState([]), selectedItems = _h[0], setSelectedItems = _h[1];
    var _j = useState(false), isLoading = _j[0], setIsLoading = _j[1];
    var changeStage = function (stage) {
        var _a;
        var payload = {
            stage: stage,
        };
        if (stage === 'Completed' && !((_a = quote.design_engineer) === null || _a === void 0 ? void 0 : _a.id)) {
            // eslint-disable-next-line no-alert
            var res = window.confirm("Set " + user.email + " as the Design Engineer?");
            if (res) {
                payload['design_engineer'] = user.id;
                QuoteStore.updateQuote(quote.id, payload);
            }
            else {
                message.warning('Please set the design Engineer before complete the quote', 8);
            }
        }
        else {
            QuoteStore.updateQuote(quote.id, payload);
        }
    };
    var getStage = function () {
        var stage = quote.stage;
        var stages = [
            'In Queue',
            'In Progress',
            'Waiting Customer',
            'Vendor Quoting',
            'Needs Review',
            'Reviewed',
            'Completed',
            'Cancelled',
            'Closed Won',
            'Closed Lost',
        ];
        return stages.indexOf(stage);
    };
    var modal_review_notes = (React.createElement(ModalNotesForm, { modalTitle: "Review Notes", buttonLabel: "Mark as Needs Review", inputPlaceHolder: "What we should review?", defaultValue: quote.review_notes, handleSave: function (notes) { return __awaiter(void 0, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = {
                            review_notes: notes,
                            stage: 'Needs Review',
                        };
                        setIsLoading(true);
                        return [4 /*yield*/, QuoteStore.updateQuote(quote.id, payload)];
                    case 1:
                        _a.sent();
                        setShowReviewNotes(false);
                        return [2 /*return*/];
                }
            });
        }); }, handleCancel: function () { return setShowReviewNotes(false); } }));
    var modal_reviewed_notes = (React.createElement(ModalNotesForm, { modalTitle: "Reviewed Notes", buttonLabel: "Mark as Reviewed", inputPlaceHolder: ":Optional", defaultValue: quote.reviewed_notes, handleSave: function (notes) { return __awaiter(void 0, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = {
                            reviewed_notes: notes,
                            stage: 'Reviewed',
                        };
                        setIsLoading(true);
                        return [4 /*yield*/, QuoteStore.updateQuote(quote.id, payload)];
                    case 1:
                        _a.sent();
                        setShowReviewedNotes(false);
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); }, handleCancel: function () { return setShowReviewedNotes(false); } }));
    var modal_sales_rep_notes = (React.createElement(ModalNotesForm, { modalTitle: "Additional Notes for " + ((_b = quote === null || quote === void 0 ? void 0 : quote.owner) === null || _b === void 0 ? void 0 : _b.fullname) + " ", buttonLabel: "Mark as Completed", inputPlaceHolder: "Notes: Optional?", defaultValue: quote.reviewed_notes, handleSave: function (notes) { return __awaiter(void 0, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = {
                            sales_rep_notes: notes,
                            stage: 'Completed',
                        };
                        setIsLoading(true);
                        return [4 /*yield*/, QuoteStore.updateQuote(quote.id, payload)];
                    case 1:
                        _a.sent();
                        setShowCompletedNotes(false);
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); }, handleCancel: function () { return setShowCompletedNotes(false); } }));
    var modal_vendor_notes = (React.createElement(Modal, { width: 1200, visible: showVendorNotes, centered: true, title: "Vendor RFQ Submission Form", onCancel: function () { return setShowVendorNotes(false); }, footer: [] },
        React.createElement(VendorRFQSubmissionForm, { loading: isLoading, quote_id: quote.id, selected_items: selectedItems, onSubmitForm: function (rfqType, notes, selectedItems) {
                setIsLoading(true);
                if (selectedItems.length > 0) {
                    QuoteStore.sendToPartners(quote.id, {
                        notes_for_vendors: notes,
                        stage: 'Vendor Quoting',
                        vendor_rfq_type: rfqType,
                        selected_items: selectedItems,
                    }).finally(function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, QuoteStore.getLineItems(quote.id)];
                                case 1:
                                    _a.sent();
                                    setShowVendorNotes(false);
                                    setIsLoading(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                }
                else {
                    setIsLoading(false);
                    notification.error({
                        message: 'Error',
                        description: 'Select the items to be sent to the vendor.',
                    });
                }
            } })));
    var modal_contact_first_quote = (React.createElement(Modal, { width: 600, visible: showContactFirstQuote, centered: true, title: "\uD83C\uDFC6 Contact's First Quote \uD83C\uDFC6", okText: 'Yes', onOk: function () {
            var _a, _b;
            if ((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.id) {
                QuoteStore.updateQuote((_b = QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.id, {
                    whole_quote_adjust: 90,
                })
                    .then(function () {
                    var payload = {
                        quoted_products: QuoteStore.lineItems.map(function (p) { return p.id; }),
                    };
                    QuoteStore.recalculatePrices(payload).catch(function (err) {
                        notification.error({
                            key: 'form-errors',
                            duration: 8,
                            message: 'One of the line items has an error',
                            description: React.createElement(ErrorRender, { error: err }),
                            placement: 'top',
                            maxCount: 1,
                        });
                    });
                    changeStage('Completed');
                })
                    .finally(function () { return setShowContactFirstQuote(false); });
            }
            // QuoteStore.getQuote(QuoteStore.quote.id);
        }, cancelText: 'No', onCancel: function () {
            changeStage('Completed');
            setShowContactFirstQuote(false);
        } },
        "\uD83C\uDFC6\uD83C\uDFC6\uD83C\uDFC6 Wow, this quote is the first from this Contact \uD83C\uDFC6\uD83C\uDFC6\uD83C\uDFC6",
        React.createElement("br", null),
        "Would you like to discount by 10% before completing?"));
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { tip: "Updating...", spinning: isQuoteLoading },
            React.createElement(Steps, { current: getStage() },
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "In Queue", description: "", onClick: function () {
                        changeStage('In Queue');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "In Progress", description: "", onClick: function () {
                        changeStage('In Progress');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Waiting Customer", description: "", onClick: function () {
                        changeStage('Waiting Customer');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Vendor Quoting", description: "", onClick: function () {
                        if (quote.has_dynamic_quantities) {
                            notification.error({
                                duration: 10,
                                placement: 'bottomLeft',
                                message: 'Error sending Vendor RFQ!',
                                description: 'Dynamic Quantities are not supported in vendor app yet',
                            });
                        }
                        else {
                            var mp_ids_selected_1 = [];
                            QuoteStore.lineItems.map(function (line_item) {
                                if (line_item &&
                                    line_item.send_to_vendor &&
                                    line_item.product &&
                                    line_item.product.master_product) {
                                    mp_ids_selected_1.push(Number(line_item.product.master_product));
                                }
                            });
                            if (mp_ids_selected_1.length > 0) {
                                setSelectedItems(mp_ids_selected_1);
                            }
                            else {
                                setSelectedItems(QuoteStore.masterProductsByPosition.map(function (mp) { return mp.master_product.id; }));
                            }
                            setShowVendorNotes(true);
                        }
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Needs Review", description: React.createElement("span", null, quote && quote.review_notes), onClick: function () {
                        setShowReviewNotes(true);
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Reviewed", description: React.createElement("span", null, quote && quote.reviewed_notes), onClick: function () {
                        setShowReviewedNotes(true);
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Completed", description: "", onClick: function () {
                        var _a, _b, _c;
                        if (!((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.grand_total)) {
                            Modal.confirm({
                                title: 'Warning! The total of your quote is 0',
                                centered: true,
                                okText: 'Continue',
                                icon: React.createElement(WarningOutlined, null),
                                cancelButtonProps: {
                                    danger: true,
                                },
                                content: React.createElement("div", null, "Do you want to complete a quote with amount 0?"),
                                onOk: function () {
                                    var _a, _b;
                                    if (((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.stage) !== 'Completed') {
                                        if ((_b = QuoteStore.quoteAccountOverview) === null || _b === void 0 ? void 0 : _b.is_first_quote_for_contact) {
                                            setShowContactFirstQuote(true);
                                        }
                                        else {
                                            changeStage('Completed');
                                        }
                                    }
                                },
                            });
                        }
                        else {
                            if (((_b = QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.stage) !== 'Completed') {
                                if ((_c = QuoteStore.quoteAccountOverview) === null || _c === void 0 ? void 0 : _c.is_first_quote_for_contact) {
                                    setShowContactFirstQuote(true);
                                }
                                else {
                                    changeStage('Completed');
                                }
                            }
                        }
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Cancelled", description: "", onClick: function () {
                        changeStage('Cancelled');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Closed Won", description: "", onClick: function () {
                        changeStage('Closed Won');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Closed Lost", description: "", onClick: function () {
                        changeStage('Closed Lost');
                    } }))),
        showReviewNotes && React.createElement("div", null, modal_review_notes),
        showReviewedNotes && React.createElement("div", null, modal_reviewed_notes),
        showCompletedNotes && React.createElement("div", null, modal_sales_rep_notes),
        showContactFirstQuote && React.createElement("div", null, modal_contact_first_quote),
        showVendorNotes && React.createElement("div", null, modal_vendor_notes)));
};
export default observer(QuoteStatus);
