import React from 'react';
import { Button, Col, Form, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import BusinessVariableForm from './BusinessVariableForm';
import BusinessActionsForm from './BusinessActionsForm';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { ICurrentActions, ICurrentCondition, IRule, IRuleConfiguration } from './types';
import { useHistory } from 'react-router-dom';

const Option = Select.Option;

interface IBusinessForm {
    rule: IRule;
}

const BusinessRuleForm = (props: IBusinessForm) => {
    const [form] = Form.useForm();
    const history = useHistory();

    React.useEffect(() => {
        BusinessRuleStore.getAllContentTypes();
    }, []);

    const handleClickAddNewCondition = () => {
        const new_condition: ICurrentCondition = {
            name: '',
            operator: '',
            value: '',
        };

        BusinessRuleStore.updateRuleField('conditions', {
            all: [...(props.rule.configuration.conditions.all ?? []), new_condition],
        });
    };
    const handleClickAddNewAction = () => {
        const actions: ICurrentActions[] = [
            ...(rule_configuration?.actions ?? []),
            {
                name: '',
                params: [],
            },
        ];
        BusinessRuleStore.updateRuleField('actions', actions);
    };

    const onFinish = async (values: any) => {
        await BusinessRuleStore.saveRule({
            id: entity.id,
            model: values.model.key,
            name: values.name,
            configuration: rule_configuration,
        });
        history.push('/business-rules/');
    };

    const entity = props.rule;

    const rule_configuration = entity.configuration;

    return (
        <Form
            layout={'inline'}
            labelAlign={'left'}
            labelCol={{
                md: 24,
            }}
            wrapperCol={{
                md: 24,
            }}
            form={form}
            onFinish={onFinish}
        >
            <Col span={24}>
                <Form.Item label="Name" name="name" initialValue={entity.name} rules={[{ required: true }]}>
                    <Input placeholder="Insert Rule Name" allowClear />
                </Form.Item>
                <Form.Item
                    label="Module"
                    name="model"
                    initialValue={{
                        key: entity.model,
                        label: entity.model_name,
                    }}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select a option"
                        onChange={value => {
                            BusinessRuleStore.getStructure(value.label);
                            BusinessRuleStore.getAllRules(value.label);
                        }}
                        allowClear
                        dropdownMatchSelectWidth={false}
                        labelInValue
                    >
                        {BusinessRuleStore.ContentTypes.map(c => (
                            <Option key={c.id} value={c.id}>
                                {c.model}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={12}>
                {rule_configuration.conditions.all.map((c, condition_index) => {
                    return (
                        <BusinessVariableForm
                            key={`${c.name}-${condition_index}`}
                            row={condition_index}
                            name={c.name}
                            operator={c.operator}
                            value={c.value}
                            handleChange={values => {
                                const _rule: IRuleConfiguration = {
                                    ...rule_configuration,
                                };

                                _rule.conditions.all[condition_index] = {
                                    ...rule_configuration.conditions.all[condition_index],
                                    ...values,
                                };

                                BusinessRuleStore.updateRuleField('conditions', _rule.conditions);
                            }}
                            handleClickDelete={() => {
                                const _new_conditions = {
                                    all:
                                        rule_configuration?.conditions.all.filter(
                                            (current, index) => index != condition_index
                                        ) ?? [],
                                };

                                BusinessRuleStore.updateRuleField('conditions', _new_conditions);
                            }}
                        />
                    );
                })}
                <Button className={'mt-2'} type={'link'} onClick={handleClickAddNewCondition}>
                    Add Condition
                </Button>
            </Col>
            <Col span={12}>
                {rule_configuration.actions.map((a, action_index) => {
                    return (
                        <BusinessActionsForm
                            key={action_index}
                            row={action_index}
                            name={a.name}
                            params={a.params}
                            handleChange={values => {
                                const _rule: IRuleConfiguration = {
                                    ...rule_configuration,
                                };

                                _rule.actions[action_index] = {
                                    ..._rule.actions[action_index],
                                    ...values,
                                };

                                BusinessRuleStore.updateRuleField('actions', _rule.actions);
                            }}
                            handleClickDelete={() => {
                                BusinessRuleStore.updateRuleField(
                                    'actions',
                                    rule_configuration?.actions.filter((current, index) => index != action_index) ?? []
                                );
                            }}
                        />
                    );
                })}
                <Button className={'mt-2'} type={'link'} onClick={handleClickAddNewAction}>
                    Add Action
                </Button>
            </Col>

            <Col
                span={24}
                style={{
                    marginTop: 30,
                }}
            >
                <Button type={'primary'} size={'large'} htmlType="submit">
                    Save Changes
                </Button>
            </Col>
        </Form>
    );
};

export default observer(BusinessRuleForm);
