var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Button, Checkbox, Skeleton, Tabs, Typography } from 'antd';
import { FETCH_STATUS } from '../types';
import { ItemsList, OrderBox, OrderHistory, OrderPreview, QuadrantSelector, SpecificationBox, } from './IntakeOuttake';
var NewVendorBox = function (_a) {
    var 
    /** SalesOrderProps */
    order = _a.order, fetchStatus = _a.fetchStatus, onFetchOrder = _a.onFetchOrder, 
    /** ItemsListProps */
    lineItems = _a.lineItems, selectedItems = _a.selectedItems, onSelectItem = _a.onSelectItem, onChangeLineItemInput = _a.onChangeLineItemInput, 
    /** InteractionProps */
    submitButtonsDisabled = _a.submitButtonsDisabled, onSubmitClick = _a.onSubmitClick, onSubmitAndPrintClick = _a.onSubmitAndPrintClick, returnCheck = _a.returnCheck, onReturnCheck = _a.onReturnCheck, 
    /** QuadrantSelectorProps */
    selectedQuadrant = _a.selectedQuadrant, onQuadrantChange = _a.onQuadrantChange;
    var renderBody = function () {
        if (fetchStatus === FETCH_STATUS.loading)
            return React.createElement(Skeleton, null);
        if (fetchStatus === FETCH_STATUS.error) {
            return (React.createElement("div", { className: "flex flex-col items-center" },
                React.createElement(Typography.Title, { level: 4 }, "Oops!...The data couldn`t be loaded..."),
                React.createElement(Button, { className: "m-2", type: "primary", onClick: onFetchOrder }, "Reload")));
        }
        /** OrderProps */
        var salesOrder = order.salesOrder, poFiles = order.poFiles, specifications = order.specifications, dataSource = order.dataSource, onDelete = order.onDelete, onPrint = order.onPrint, onViewPrintout = order.onViewPrintout, onDownloadPdf = order.onDownloadPdf, htmlPreview = order.htmlPreview;
        return (React.createElement(Tabs, { defaultActiveKey: "1" },
            React.createElement(Tabs.TabPane, { tab: "Box Information", key: "1" },
                React.createElement("div", { className: "flex flex-row" },
                    React.createElement("div", { className: "flex flex-col flex-1" },
                        React.createElement(OrderBox, { salesOrder: salesOrder, poFiles: poFiles }),
                        specifications.map(function (specification, index) { return (React.createElement(SpecificationBox, __assign({ key: "s-" + index }, specification))); })),
                    React.createElement("div", { className: "flex flex-col flex-1 bg-white ml-2" },
                        React.createElement("div", { className: "flex justify-between mb-8" },
                            React.createElement(QuadrantSelector, { selectedQuadrant: selectedQuadrant, onQuadrantChange: onQuadrantChange }),
                            React.createElement("div", { className: "flex" },
                                React.createElement("div", { className: "mx-2" },
                                    React.createElement("span", { className: "mr-2" }, "Customer Return?"),
                                    React.createElement(Checkbox, { checked: returnCheck, onChange: onReturnCheck })),
                                React.createElement(Button, { className: "font-bold mx-2", type: "primary", disabled: submitButtonsDisabled, onClick: onSubmitClick }, "Submit"),
                                !(salesOrder === null || salesOrder === void 0 ? void 0 : salesOrder.domestic_process_needed) && !(salesOrder === null || salesOrder === void 0 ? void 0 : salesOrder.hardware_required) && (React.createElement(Button, { className: "mx-2", disabled: submitButtonsDisabled, onClick: onSubmitAndPrintClick }, "Submit and Print")))),
                        React.createElement(ItemsList, { dataSource: lineItems, 
                            //@ts-ignore
                            onChangeInput: onChangeLineItemInput, selectedItems: selectedItems, onSelectItem: onSelectItem })))),
            React.createElement(Tabs.TabPane, { tab: "Prev. Intakes", key: "2" },
                React.createElement("div", { className: "flex justify-around" },
                    React.createElement(OrderHistory, { dataSource: dataSource, onDelete: onDelete, onPrint: onPrint, onViewPrintout: onViewPrintout, onDownloadPdf: onDownloadPdf }),
                    htmlPreview && React.createElement(OrderPreview, { html: htmlPreview })))));
    };
    return React.createElement("div", { className: "flex flex-col border border-gray-400 shadow-md mb-3 p-2" }, renderBody());
};
export default NewVendorBox;
