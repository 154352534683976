import React, { useState } from 'react';
import { Table, Empty } from 'antd';
import { IInvoice } from '../../../../utils/types';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { ColumnProps } from 'antd/es/table';

const Invoices: React.FC = () => {
    const columns: ColumnProps<IInvoice>[] = [
        {
            title: 'Invoice Number',
            dataIndex: 'name',
            key: 'name',
            // width: '10%',
        },
        {
            title: 'Status',
            dataIndex: 'invoice_status',
            key: 'invoice_status',
            // align: 'right',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            // align: 'right',
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            // align: 'right',
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            key: 'grand_total',
            // align: 'right',
        },
    ];

    return (
        <Table<IInvoice>
            rowKey={record => record.id.toString()}
            pagination={false}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.invoices}
            scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>Sorry... This order does not have invoices.</span>}
                    />
                ),
            }}
        />
    );
};

export default Invoices;
