import React from 'react';
import { Tag } from 'antd';
import { getCorrectValue, getDateFormat } from '../../utils/vendorBox';
var W_SETTINGS = 'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes';
export var OrderBox = function (_a) {
    var _b = _a.salesOrder, name = _b.name, status = _b.status, partner_ship_date = _b.partner_ship_date, intake_status = _b.intake_status, shipping_status = _b.shipping_status, revised_ship_date = _b.revised_ship_date, domestic_process_needed = _b.domestic_process_needed, hardware_required = _b.hardware_required, payment_terms = _b.payment_terms, required_ship_date = _b.required_ship_date, poFiles = _a.poFiles;
    return (React.createElement("div", { className: "flex border border-solid border-gray-400 rounded-sm p-1 mb-1" },
        React.createElement("div", { className: "flex flex-col w-3/4 p-1" },
            React.createElement("div", { className: "pb-1 border-b border-grey-darkest border-solid" },
                React.createElement("span", { className: "mr-4" },
                    React.createElement("strong", { className: "mr-1" }, "Name:\u00A0"),
                    name),
                domestic_process_needed && (React.createElement(Tag, { color: "orange", className: "text-center" }, "Domestic Finish")),
                hardware_required && (React.createElement(Tag, { color: "volcano", className: "text-center" }, "Hardware Needed"))),
            React.createElement("div", { className: "flex flex-wrap" },
                React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Status:\u00A0"),
                    status),
                React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Req. Partner Ship Date:\u00A0"),
                    partner_ship_date),
                React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Intake status:\u00A0"),
                    intake_status),
                React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Shipping status:\u00A0"),
                    shipping_status),
                revised_ship_date && (React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Next Batch Release Date:\u00A0"),
                    revised_ship_date)),
                React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Terms:\u00A0"),
                    payment_terms && getCorrectValue(payment_terms)),
                React.createElement("span", { className: "mr-4 mb-1" },
                    React.createElement("strong", null, "Req. Ship Date:\u00A0"),
                    required_ship_date && getDateFormat(required_ship_date)))),
        React.createElement("div", { className: "flex flex-col flex-grow px-2 border-l border-grey-darkest border-solid" },
            React.createElement("h3", null, "Customer Po Files"),
            poFiles.map(function (_a) {
                var id = _a.id, filename = _a.filename, file = _a.file;
                return (React.createElement("a", { key: "pf-" + id, onClick: function () { return window.open(file, filename, W_SETTINGS); } }, filename));
            }))));
};
