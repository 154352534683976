import { BASE_URL_WS } from '@partsbadger/utils';
import React, { useEffect } from 'react';
import QuoteStore from '../stores/QuoteStore';
import { observer } from 'mobx-react';
var SocketConnection = function () {
    var quote = QuoteStore.quote, token = QuoteStore.token, isConnectedToSocket = QuoteStore.isConnectedToSocket;
    useEffect(function () {
        // Send the token on the cookie hackie option
        document.cookie = "X-Authorization=" + token + "; path=/";
        var ws = new WebSocket(BASE_URL_WS + "/ws/instant-quote/" + (quote === null || quote === void 0 ? void 0 : quote.uid) + "/");
        ws.onopen = function () {
            QuoteStore.setConnectedSocket(true);
        };
        ws.onmessage = function (msg) {
            var data = JSON.parse(msg.data);
            if (data.type === 'updated.quote_line_item') {
                QuoteStore.setLineItem(JSON.parse(data.message));
            }
        };
        ws.onclose = function () {
            QuoteStore.setConnectedSocket(false);
        };
        ws.onerror = function (error) {
            ws.close();
        };
        return function () {
            if (ws) {
                ws.close();
            }
        };
    }, []);
    return (React.createElement("div", { style: {
            fontSize: 18,
            marginRight: 10,
            color: isConnectedToSocket ? '#32CD32' : 'black',
        } }, "\u2022"));
};
export default observer(SocketConnection);
