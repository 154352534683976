import React from 'react';
import { ButtonProfileAntd, CardAntd } from '../../../pages/Orders/RedactionTool/styles';
import { CheckOutlined, CloudDownloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Image } from 'antd';
interface PartCardProps {
    isSelected: boolean;
    isRedacted: boolean;
    thumbnail: string;
    onClick: () => void;
    onDownloadRedactedFile: () => void;
}

const PartCard = (props: PartCardProps) => {
    return (
        <>
            <ButtonProfileAntd
                styleProps={{
                    display: 'inline-block',
                    position: 'relative',
                    height: 'auto',
                    borderColor: props.isSelected ? '#10f341' : '#FFF',
                }}
            >
                <CardAntd
                    classNameProps="flex flex-row m-0 items-center"
                    bordered={false}
                    styleProps={{
                        height: 'auto',
                        fontSize: '20px',
                        color: '#198754',
                    }}
                    onClick={props.onClick}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                        }}
                    >
                        {props.isRedacted ? (
                            <>
                                <a
                                    onClick={e => {
                                        e.stopPropagation();
                                        props.onDownloadRedactedFile();
                                    }}
                                >
                                    <CloudDownloadOutlined />
                                </a>
                                <CheckOutlined title={'The file is redacted'} />
                            </>
                        ) : (
                            <div
                                style={{
                                    color: 'red',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <WarningOutlined className={'mr-1'} />
                                Pending
                            </div>
                        )}
                    </div>

                    <Image
                        style={{
                            margin: 'auto',
                        }}
                        preview={false}
                        src={props.thumbnail ?? ''}
                        alt={'No Preview'}
                    />
                </CardAntd>
            </ButtonProfileAntd>
        </>
    );
};

export default PartCard;
