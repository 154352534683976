var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { InputNumber, message, Table, Checkbox, Spin, Empty, Typography } from 'antd';
import { DatePicker } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { get } from '@partsbadger/utils';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import { PartFiles } from '../../PartFiles';
import { ButtonApplyToAll, Price, VendorQualifications } from '@partsbadger/library';
import DomesticHardwareModal from './DomesticHardwareModal';
import Part from '../../Part';
import { subtractBusinessDays } from '../../utils/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
var Paragraph = Typography.Paragraph;
var OrderLineItems = function (props) {
    var _a;
    var _b = useState([]), dataVendorQualifications = _b[0], setDataVendorQualifications = _b[1];
    var _c = useState(false), loadingQualifications = _c[0], setLoadingQualifications = _c[1];
    useEffect(function () {
        vendorQualifications();
    }, []);
    var vendorQualifications = function () {
        var sales_order_id = SalesOrderStore.order ? SalesOrderStore.order.id : 0;
        setLoadingQualifications(true);
        SalesOrderStore.getVendorQualificationsFromVendorApp(sales_order_id)
            .then(function (qualifications) {
            setDataVendorQualifications(qualifications);
        })
            .finally(function () {
            setLoadingQualifications(false);
        });
    };
    var order_items = ((_a = SalesOrderStore.order) === null || _a === void 0 ? void 0 : _a.line_items) || [];
    var _d = useState(null), lineItem = _d[0], setLineItem = _d[1];
    var _e = useState(false), isModalVisible = _e[0], setIsModalVisible = _e[1];
    var _f = useState('domestic'), modalType = _f[0], setModalType = _f[1];
    var ZProcessValues = function (lineItem) {
        if (lineItem === null)
            return [];
        if (modalType === 'domestic') {
            return lineItem.domestic_processes !== null ? lineItem.domestic_processes : [];
        }
        return lineItem.hardwares_required !== null ? lineItem.hardwares_required : [];
    };
    if (!order_items.length) {
        return null;
    }
    var onUpdateItem = _.debounce(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, SalesOrderStore.updateRemoteOrderItem(payload)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 500);
    var onUpdateMultipleItem = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _order_items;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _order_items = order_items.map(function (item) {
                        return __assign(__assign({}, item), data);
                    });
                    return [4 /*yield*/, SalesOrderStore.updateRemoteMultipleItems(_order_items)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            title: 'Product Details',
            dataIndex: 'name',
            render: function (t, record) {
                var _a, _b, _c, _d, _e;
                if (record.name === 'CNC Part') {
                    return record.name + " " + ((_a = record.product_description) !== null && _a !== void 0 ? _a : '');
                }
                var product = (_b = record.quote_has_product) === null || _b === void 0 ? void 0 : _b.product;
                return (React.createElement("div", { className: 'flex lg:flex-row md:flex-col sm:flex-col' },
                    React.createElement(Part, { part: {
                            name: record.name,
                            dimensions: product === null || product === void 0 ? void 0 : product.dimensions,
                            material: (_c = product === null || product === void 0 ? void 0 : product.material) === null || _c === void 0 ? void 0 : _c.name,
                            coating: (_d = product === null || product === void 0 ? void 0 : product.coating) === null || _d === void 0 ? void 0 : _d.name,
                            finish: (_e = product === null || product === void 0 ? void 0 : product.finish) === null || _e === void 0 ? void 0 : _e.name,
                            first_article_approval: record.first_article_approval,
                            is_batch_shipment: !!record.is_batch_shipment,
                        } })));
            },
        },
        {
            title: 'Part Files',
            width: 400,
            render: function (t, record) {
                var _a, _b;
                if (!record.quote_has_product) {
                    return null;
                }
                return (React.createElement("div", { className: "flex flex-col" },
                    React.createElement(PartFiles, { show_pending_quoted_files: props.show_pending_files, product: record.quote_has_product.product, record: record, action: 'edit' }),
                    React.createElement("strong", { className: "ml-1" }, "Internal Files:"),
                    React.createElement(Paragraph, { ellipsis: { rows: 1, expandable: true }, style: { display: 'inline' }, className: "m-0 ml-1" }, (_b = (_a = record.quote_has_product.master_product) === null || _a === void 0 ? void 0 : _a.internal_files) === null || _b === void 0 ? void 0 : _b.map(function (file) {
                        return (React.createElement("div", { key: 'main' },
                            React.createElement("div", { key: file.id, className: "link inline-block", 
                                // style={{ display: 'inline' }}
                                onClick: function (e) {
                                    e.stopPropagation();
                                    get("/staff/files/" + file.id + "/internal-file-url/").then(function (response) {
                                        window.open(response.url, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800');
                                    });
                                } },
                                file.filename,
                                "\u00A0")));
                    }))));
            },
        },
        {
            title: 'Required Part Ship Date',
            dataIndex: 'required_part_ship_date',
            render: function (text, record) {
                if (record.name === 'CNC Part') {
                    return null;
                }
                // 2023 Hollidays disabled dates
                var disabledDates = [
                    '2023-05-29',
                    '2023-07-04',
                    '2023-09-04',
                    '2023-11-23',
                    '2023-11-24',
                    '2023-12-25',
                    '2024-01-01',
                ];
                function disabledDate(current) {
                    return disabledDates.find(function (date) { return date === current.format('YYYY-MM-DD'); });
                }
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(DatePicker
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    , { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        disabledDate: disabledDate, value: record.required_part_ship_date ? dayjs(record.required_part_ship_date) : null, onChange: function (date) {
                            if (date) {
                                var required_part_ship_date = dayjs(date).toDate();
                                var partner_ship_date = null;
                                if (required_part_ship_date) {
                                    partner_ship_date = subtractBusinessDays(required_part_ship_date, 8);
                                }
                                onUpdateItem(__assign(__assign({}, record), { required_part_ship_date: date.format('YYYY-MM-DD'), required_partner_ship_date: dayjs(partner_ship_date).format('YYYY-MM-DD') }));
                            }
                        } }),
                    record.required_part_ship_date && (React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: function () {
                            var partner_ship_date = null;
                            if (record.required_part_ship_date) {
                                partner_ship_date = subtractBusinessDays(dayjs(record.required_part_ship_date).toDate(), 8);
                            }
                            return onUpdateMultipleItem({
                                required_part_ship_date: record.required_part_ship_date,
                                required_partner_ship_date: dayjs(partner_ship_date).format('YYYY-MM-DD'),
                            });
                        } }))));
            },
        },
        {
            title: 'Required Partner Ship Date',
            dataIndex: 'required_partner_ship_date',
            render: function (text, record) {
                if (record.name === 'CNC Part') {
                    return null;
                }
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(DatePicker, { value: record.required_partner_ship_date ? dayjs(record.required_partner_ship_date) : null, onChange: function (v) {
                            onUpdateItem(__assign(__assign({}, record), { required_partner_ship_date: v === null || v === void 0 ? void 0 : v.format('YYYY-MM-DD') }));
                        } }),
                    record.required_partner_ship_date && (React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: function () {
                            return onUpdateMultipleItem({
                                required_partner_ship_date: record.required_partner_ship_date,
                            });
                        } }))));
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
            render: function (t, record) {
                return (React.createElement(InputNumber, { defaultValue: record.quantity, onChange: function (v) {
                        if (v) {
                            var _total = v * record.unit_price;
                            onUpdateItem(__assign(__assign({}, record), { quantity: v, total: parseFloat(_total.toFixed(2)) }));
                        }
                    } }));
            },
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            width: 200,
            render: function (t, record) {
                return (React.createElement(InputNumber, { defaultValue: record.unit_price, onChange: function (v) {
                        if (v) {
                            var _total = record.quantity * v;
                            onUpdateItem(__assign(__assign({}, record), { unit_price: v, total: parseFloat(_total.toFixed(2)) }));
                        }
                    } }));
            },
        },
        {
            title: 'Hardware required',
            dataIndex: 'hardware_required',
            render: function (t, record) {
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(Checkbox, { checked: record.hardware_required, onClick: function (v) {
                            setModalType('hardware');
                            var target = v.target;
                            SalesOrderStore.updateLineItemHardwareDomesticLocal(record, 'hardware', target.checked);
                            setLineItem(record);
                            onUpdateItem(__assign({}, record));
                            if (record.hardware_required) {
                                setIsModalVisible(true);
                            }
                        } }),
                    React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: function () {
                            return onUpdateMultipleItem({
                                hardware_required: record.hardware_required,
                                hardwares_required: record.hardwares_required,
                            });
                        } })));
            },
        },
        {
            title: 'Domestic process needed',
            dataIndex: 'domestic_process_needed',
            render: function (t, record) {
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(Checkbox, { checked: record.domestic_process_needed, onClick: function (v) {
                            setModalType('domestic');
                            var target = v.target;
                            SalesOrderStore.updateLineItemHardwareDomesticLocal(record, 'domestic', target.checked);
                            setLineItem(record);
                            onUpdateItem(__assign({}, record));
                            if (record.domestic_process_needed) {
                                setIsModalVisible(true);
                            }
                        } }),
                    React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: function () {
                            return onUpdateMultipleItem({
                                domestic_process_needed: record.domestic_process_needed,
                                domestic_processes: record.domestic_processes,
                            });
                        } })));
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'total',
            width: 200,
            render: function (t, record) {
                return (React.createElement(Fragment, null,
                    React.createElement("span", null,
                        React.createElement(Price, { value: record.total }))));
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: function (t, record) {
                return (React.createElement("div", { className: 'cursor-pointer', style: {
                        color: 'red',
                    }, onClick: function () {
                        SalesOrderStore.removeRemoteOrderItem(record).finally(function () {
                            message.success('Removed!');
                        });
                    } },
                    React.createElement(LegacyIcon, { type: 'close' })));
            },
        },
    ];
    return (React.createElement(Fragment, null,
        React.createElement(Table, { rowKey: 'id', className: 'variation', pagination: false, dataSource: order_items, columns: columns, defaultExpandAllRows: true, expandedRowRender: function (order_item) {
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", null,
                        React.createElement("span", { className: 'text-red-600' }, "*"),
                        " Vendor Qualifications:"),
                    React.createElement(Spin, { spinning: loadingQualifications }, dataVendorQualifications.length > 0 ? (React.createElement(VendorQualifications, { data: dataVendorQualifications, value: order_item.vendor_qualifications ? order_item.vendor_qualifications : [], onChange: function (qualifications) {
                            onUpdateItem(__assign(__assign({}, order_item), { vendor_qualifications: qualifications }));
                        } })) : (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "Vendor Qualifications could not be obtained." }))),
                    order_item.vendor_qualifications && order_item.vendor_qualifications.length > 0 && (React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: function () {
                            return onUpdateMultipleItem({
                                vendor_qualifications: order_item.vendor_qualifications,
                            });
                        } }))));
            }, scroll: { x: 1200 } }),
        React.createElement(DomesticHardwareModal, { tags: ZProcessValues(lineItem), isVisible: isModalVisible, type: modalType, handleOk: function (values) {
                setIsModalVisible(false);
                if (modalType === 'domestic') {
                    if (lineItem !== null) {
                        SalesOrderStore.updateRemoteOrderItemWithOutPartnerShipDate(__assign(__assign({}, lineItem), { domestic_processes: values }));
                    }
                }
                else {
                    if (lineItem !== null) {
                        SalesOrderStore.updateRemoteOrderItemWithOutPartnerShipDate(__assign(__assign({}, lineItem), { hardwares_required: values }));
                    }
                }
            }, handleCancel: function () {
                setIsModalVisible(false);
            } })));
};
export default observer(OrderLineItems);
