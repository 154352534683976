import React from 'react';
import { Button, Modal } from 'antd';
var DomesticModal = function (_a) {
    var modalVisible = _a.modalVisible, onCloseModal = _a.onCloseModal, onSubmitClick = _a.onSubmitClick, onIntakeNormalClick = _a.onIntakeNormalClick;
    return (React.createElement(Modal, { closable: true, onCancel: onCloseModal, title: "Domestic Process", visible: modalVisible, footer: [
            React.createElement(Button, { onClick: onCloseModal }, "Cancel"),
            React.createElement(Button, { type: "primary", onClick: onSubmitClick }, "Submit"),
            React.createElement(Button, { type: "primary", onClick: onIntakeNormalClick }, "Intake Normal"),
        ] },
        React.createElement("p", null, "This order has domestic processes required. Click submit to intake this order as Received in Full - Domestic Finish. If Domestic processes have been completed, please click Intake Normal")));
};
export default DomesticModal;
