import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
import { SalesOrderProps } from '@partsbadger/types';
import { IInvoice, INotes, IQualityDocumentsProps, IShipmentProps, VendorProductsProps } from '../../utils/types';
import { AccountProps, ContactProps } from '@partsbadger/sales_order_tool/lib/types/types';
import { AddressProps } from '@partsbadger/library';

interface IRequestUpdate {
    username: string;
    first_name: string;
    last_name: string;
    company_name: string;
    order: string | undefined;
    email: string;
    notes: string;
}

interface IProfile {
    id: number;
    name: string;
    url: string;
}

export interface IRedactedProfile {
    id: number;
    account: number;
    profile_name: string;
    settings: Setting[];
    thumbnail: null | string;
}

export interface Setting {
    text: string;
    corners: Corner[];
    page_number: number;
    redaction_type: RedactionType;
    font_size?: string;
}

export interface Corner {
    x: number;
    y: number;
}

export enum RedactionType {
    DrawingTextBox = 'DrawingTextBox',
    PatchCustomerInformation = 'PatchCustomerInformation',
    RemoveCustomerInformation = 'RemoveCustomerInformation',
}

class SalesOrder {
    loading = false;
    order?: SalesOrderProps | null = null;
    redactedProfiles: IRedactedProfile[] = [];
    quality_documents: IQualityDocumentsProps[] = [];
    products_tracking_numbers: VendorProductsProps[] = [];
    notes: INotes[] = [];
    notifications: any[] = [];
    shipments: IShipmentProps[] = [];
    account?: AccountProps | null = null;
    contact?: ContactProps | null = null;
    addresses?: Array<AddressProps> = [];
    invoices: Array<IInvoice> = [];
    savedRedactions: Array<any> = [];
    thumbnails?: any[] = [];

    constructor() {
        makeObservable(this, {
            // Observables

            loading: observable,
            order: observable,
            redactedProfiles: observable,
            quality_documents: observable,
            products_tracking_numbers: observable,
            notes: observable,
            notifications: observable,
            shipments: observable,
            account: observable,
            contact: observable,
            addresses: observable,
            invoices: observable,
            savedRedactions: observable,
            thumbnails: observable,

            // Actions
            getById: action,
            getAccountRedactedProfiles: action,
            deleteAccountRedactedProfile: action,
            getQualityDocumentsBySalesOrderId: action,
            getTrackingNumbersBySalesOrderId: action,
            getPackListBySalesOrderId: action,
            getNotifications: action,
            getNotes: action,
            getShipmentsBySalesOrderId: action,
            getContactById: action,
            saveAddress: action,
            sendUpdateRequest: action,
            getInvoiceList: action,
            createNoteByItem: action,
            getOrderById: action,
            update: action,
            redactFile: action,
            saveRedactionProfile: action,
            applyRedactionProfile: action,
            getThumbnails: action,
            getThumbnailsFromProduct: action,
            resetRedactionDefaultProfile: action,
            updateItem: action,
            getVendorQualificationsFromVendorApp: action,
            sendRedactedFilesToVendorAPP: action,
        });
    }

    async getById(id: number | string) {
        runInAction(() => {
            this.loading = true;
        });

        const data = await get(`/staff/sales-orders/${id}/`);

        this.order = data;
        this.loading = false;

        return data;
    }

    async getAccountRedactedProfiles(account_id: number) {
        this.redactedProfiles = await get(`/staff/accounts/${account_id}/redacted-profiles/`);
    }

    async deleteAccountRedactedProfile(account_id: number, redacted_profile_id: number) {
        runInAction(() => {
            this.redactedProfiles = this.redactedProfiles.filter(r => r.id != redacted_profile_id);
        });
        await remove(`/staff/accounts/${account_id}/redacted-profiles/${redacted_profile_id}/`);

        this.getAccountRedactedProfiles(account_id);
    }

    async getQualityDocumentsBySalesOrderId(sales_order_id: number) {
        await get(`/staff/sales-orders/${sales_order_id}/quality-documents/`).then(data => {
            runInAction(() => {
                this.quality_documents = data;
            });
        });
    }

    async getTrackingNumbersBySalesOrderId(sales_order_id: number) {
        await get(`/staff/sales-orders/${sales_order_id}/tracking-numbers/`).then(data => {
            runInAction(() => {
                this.products_tracking_numbers = data;
            });
        });
    }

    async getPackListBySalesOrderId(sales_order_id: number, box_id: number) {
        runInAction(() => {
            this.loading = true;
        });
        return await get(`/staff/sales-orders/${sales_order_id}/download-packing-slip/${box_id}/`, {
            responseType: 'blob',
        });
    }

    async getNotifications(sales_order_id: string) {
        runInAction(() => {
            this.loading = true;
        });

        await get(`/staff/sales-orders/${sales_order_id}/notifications/`)
            .then(data => {
                runInAction(async () => {
                    this.notifications = await data;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    async getNotes(sales_order_id: string) {
        runInAction(() => {
            this.loading = true;
        });

        await get(`/staff/sales-orders/${sales_order_id}/notes/`)
            .then(data => {
                runInAction(async () => {
                    this.notes = await data;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    async getShipmentsBySalesOrderId(sales_order_id: string) {
        runInAction(() => {
            this.loading = true;
        });

        await get(`/staff/sales-orders/${sales_order_id}/shipments/`)
            .then(data => {
                runInAction(() => {
                    this.shipments = data.results;
                    this.loading = false;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    getContactById = (contact_id: number | string | undefined) => {
        get(`/customer/contacts/${contact_id}/`).then(data => {
            this.contact = data;
            this.addresses = this.contact?.addresses;
        });
    };

    saveAddress(contact_id: number | undefined, payload = {}) {
        return post(`/customer/contacts/${contact_id}/addresses/`, payload);
    }

    async sendUpdateRequest(id: number, data: IRequestUpdate) {
        runInAction(() => {
            this.loading = true;
        });

        await post(`/staff/sales-orders/${id}/send-update-request/`, data).then(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }

    getInvoiceList = async (params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await get(`/customer/invoices/`, { params: params });

        runInAction(() => {
            this.invoices = response.results;
            this.loading = false;
        });

        return response.results;
    };

    async createNoteByItem(item_id: number | string, payload = {}) {
        return await post(`/staff/sales-orders/${item_id}/add-note/`, payload);
    }
    setOrder(data?: SalesOrderProps) {
        runInAction(() => {
            this.order = data;
            this.loading = false;
        });
    }

    async getOrderById(sales_order_id: number) {
        this.loading = true;

        await get(`/staff/sales-orders/${sales_order_id}/`)
            .then(data => {
                this.setOrder(data);
                if (data.contact?.id) {
                    this.getContactById(data?.contact.id);
                }
            })
            .finally(() => (this.loading = false));
    }

    async update(order_id: number, payload: any) {
        this.loading = true;

        return patch(`/staff/sales-orders/${order_id}/`, payload).finally(() => {
            this.loading = false;
            this.getOrderById(order_id);
        });
    }

    redactFile = async (id: number, params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/redact-file/`, params).then(data => {
            runInAction(() => {
                this.loading = false;
                this.savedRedactions = [...this.savedRedactions, ...params.redactions];
            });

            return data;
        });

        return response;
    };

    saveRedactionProfile = async (id: number, params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/save-profile-redactions/`, params).then(data => {
            runInAction(() => {
                this.loading = false;
            });

            return data;
        });

        return response;
    };

    applyRedactionProfile = async (id: number, params: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/apply-profile-redactions/`, params).then(data => {
            runInAction(() => {
                this.loading = false;
                this.savedRedactions = [];
            });

            return data;
        });

        return response;
    };

    getThumbnails = async (id: number, line_item_id: any) => {
        const params = {
            account: id,
        };

        let getThumbnails = <Array<IProfile>>[];

        const thumbnails = await post(`/staff/drawing-files/${line_item_id}/get-thumbnails-from-pdf/`, params).then(
            data => {
                runInAction(() => {
                    this.thumbnails = data;
                    this.loading = false;
                });

                return data.map((data: { name: any; url: any }) => ({
                    id: line_item_id,
                    name: data.name,
                    url: data.url,
                }));
            }
        );

        getThumbnails = thumbnails;

        return thumbnails;
    };

    getThumbnailsFromProduct = async (id: number, line_items: any) => {
        const params = {
            account: id,
        };

        let getThumbnails = <Array<IProfile>>[];

        for (const line_item of line_items) {
            const thumbnails = await post(
                `/staff/drawing-files/${line_item?.production_2d_file?.id}/get-thumbnails-from-product/`,
                params
            ).then(data => {
                runInAction(() => {
                    this.thumbnails = data;
                    this.loading = false;
                });

                return data.map((data: { name: any; url: any }) => ({
                    id: line_item?.production_2d_file?.id,
                    name: data.name,
                    url: data.url,
                }));
            });

            if (getThumbnails.length > 0) {
                getThumbnails = [...getThumbnails, ...thumbnails];
            } else {
                getThumbnails = thumbnails;
            }
        }

        return getThumbnails;
    };

    resetRedactionDefaultProfile = async (id: number) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await post(`/staff/drawing-files/${id}/reset-redactions/`).then(data => {
            runInAction(() => {
                this.loading = false;
                this.savedRedactions = [];
            });

            return data;
        });

        if (this.order?.id) {
            this.getOrderById(this.order?.id);
        }

        return response;
    };

    updateItem = async (orderId: number, payload: any) => {
        runInAction(() => {
            this.loading = true;
        });

        const response = await patch(`/staff/sales-orders/${orderId}/update-item/`, payload).then(data => {
            runInAction(() => {
                this.order = data;
                this.loading = false;
            });

            return data;
        });

        return response;
    };

    // get qualifications from vendor app

    getVendorQualificationsFromVendorApp(sales_order_id: number) {
        return get(`/staff/sales-orders/${sales_order_id}/vendor-qualifications/`);
    }

    // Send redacted files to vendors

    sendRedactedFilesToVendorAPP(sales_order_id: number) {
        return get(`/staff/sales-orders/${sales_order_id}/send-redacted-files-to-vendor-app/`);
    }
}

export const SalesOrderStore = new SalesOrder();
