import { action, observable, makeObservable } from 'mobx';
import { get } from '@partsbadger/utils';

class Report {
    quality_kpis: any = null;

    constructor() {
        makeObservable(this, {
            // Observables
            quality_kpis: observable,

            // Actions
            reasonsQualityKpis: action,
        });
    }

    async reasonsQualityKpis(params: any) {
        return await get(`/staff/report-quality-kpis/`, { params });
    }
}

export const ReportStore = new Report();
